import React, { useState, useEffect, useContext } from 'react';
import { useLocation } from 'react-router-dom';
import { useStyles } from '../Projects/styles';
import { useMediaQuery } from 'react-responsive';
import { useTheme } from '@mui/material/styles';
import { useTranslation } from 'react-i18next';
import { Grid, Typography, Card, CardContent, Box } from '@mui/material';
import { UserContext } from '../../contexts/UserContext';
import Loader from '../../uiComponents/Loader';
import axios from 'axios';
import { ReactComponent as FilesFolder } from '../../assets/FilesAndFolderTwoColor.svg';
import ApplicationDocumentTable from './ApplicationDocumentTable';
import i18n from '../../utils/i18n';

const ApplicationDocumentList = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  const theme = useTheme();
  const isLargeScreen = useMediaQuery({ query: '(min-width: 1280px)' });
  const isSmallScreen = useMediaQuery({ query: '(max-width: 767px)' });
  let location = useLocation();
  const [loading, setLoading] = useState(false);
  const [userContext] = useContext(UserContext);
  const topPageRef = React.useRef();
  const [applicationDocuments, setApplicationDocuments] = useState([]);
  useEffect(() => {
    document.getElementById('anchor-div').scrollIntoView();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const apiEndPoint =
      process.env.NODE_ENV === 'development'
        ? process.env.REACT_APP_API_ENDPOINT
        : process.env.REACT_APP_API_ENDPOINT_PROD;
    setLoading(true);
    let id = location.pathname.substring(
      location.pathname.lastIndexOf('/') + 1
    );
    axios({
      method: 'get',
      url: apiEndPoint + '/v1/partner/applications/getAllDocuments',
      params: { id: id },
      withCredentials: true,
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        Authorization: `Bearer ${userContext.token}`,
      },
    })
      .then((res) => {
        setApplicationDocuments(res.data.applicationDocuments);
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
      });
  }, [location, userContext]);

  const documentColumns = [
    {
      id: 'filename',
      numeric: false,
      sort: true,
      disablePadding: false,
      label: i18n.t('application_document_columns.file_name'),
    },
    {
      id: 'createdAt',
      numeric: false,
      sort: true,
      disablePadding: false,
      label: i18n.t('application_document_columns.upload_date'),
    },
  ];

  const handleOpenFile = (idFile, filename, type) => {
    setLoading(true);
    const apiEndPoint =
      process.env.NODE_ENV === 'development'
        ? process.env.REACT_APP_API_ENDPOINT
        : process.env.REACT_APP_API_ENDPOINT_PROD;
    let applicationId = location.pathname.substring(
      location.pathname.lastIndexOf('/') + 1
    );
    if (type) {
      axios({
        method: 'get',
        url: apiEndPoint + '/v1/partner/applications/getDocument',
        withCredentials: true,
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
          Authorization: `Bearer ${userContext.token}`,
        },
        params: {
          id: idFile,
          applicationId: applicationId,
        },
      })
        .then((res) => {
          const linkSource = `data:${type};base64,${res.data}`;
          const downloadLink = document.createElement('a');
          const fileName = filename;
          downloadLink.href = linkSource;
          downloadLink.download = fileName;
          downloadLink.click();
          setLoading(false);
        })
        .catch((error) => {
          setLoading(false);
        });
    } else {
      axios({
        method: 'get',
        url: apiEndPoint + '/v1/partner/applications/getDocument',
        withCredentials: true,
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
          Authorization: `Bearer ${userContext.token}`,
        },
        responseType: 'blob',
        params: {
          id: idFile,
          applicationId: applicationId,
        },
      })
        .then((response) => {
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', filename);
          document.body.appendChild(link);
          link.click();
          link.parentNode.removeChild(link);
          setLoading(false);
        })
        .catch((error) => {
          setLoading(false);
        });
    }
  };

  const [page, setPage] = useState(0);
  return (
    <>
      {loading && <Loader loading={loading} />}
      <Grid
        container
        className={classes.root}
        style={
          isSmallScreen
            ? { paddingTop: 72, position: 'relative' }
            : { paddingTop: 96, position: 'relative' }
        }
      >
        <div
          ref={topPageRef}
          id='anchor-div'
          style={{ position: 'absolute', top: '-120px', left: '0' }}
        ></div>
        <Grid
          container
          item
          justifyContent='center'
          alignContent='flex-start'
          xs={12}
        >
          <Grid
            container
            item
            xs={12}
            justifyContent='space-between'
            className={classes.container}
          >
            <Grid item xs={12} className={classes.box}>
              <Box mb={2.5}>
                <Typography component='h6' variant='h6' align='center'>
                  {t('application_documents.title')}
                </Typography>
              </Box>
              <Box>
                <Typography
                  variant='body1'
                  color='textSecondary'
                  align='center'
                >
                  {t('application_documents.subtitle')}
                </Typography>
              </Box>
            </Grid>
            <Grid container item xs={12} className={classes.box}>
              <Grid item xs={12}>
                <Card
                  className={classes.card}
                  variant='outlined'
                  style={(theme.custom.boxshadow, { marginRight: 24 })}
                >
                  <CardContent>
                    <Grid container>
                      <Grid
                        alignContent='center'
                        container
                        item
                        xs={12}
                        md={isLargeScreen ? 7 : 6}
                      >
                        <Grid item xs={12}>
                          <Box mb={1}>
                            <Typography>
                              {t('application_documents.card_title')}
                            </Typography>
                          </Box>
                        </Grid>
                        <Grid item xs={12}>
                          <Box>
                            <Typography variant='body2' color='textSecondary'>
                              {t('application_documents.card_subtitle')}
                            </Typography>
                          </Box>
                        </Grid>
                      </Grid>
                      <Grid item xs={12} md={isLargeScreen ? 5 : 6}>
                        <FilesFolder
                          style={{
                            width: isLargeScreen ? 'auto' : '100%',
                            float: 'right',
                          }}
                        />
                      </Grid>
                    </Grid>
                  </CardContent>
                </Card>
              </Grid>
            </Grid>
            <Grid container item xs={12} className={classes.box}>
              <Grid item xs={12}>
                <ApplicationDocumentTable
                  data={applicationDocuments}
                  headCells={documentColumns}
                  page={page}
                  setPage={setPage}
                  handleOpenFile={handleOpenFile}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

export default ApplicationDocumentList;
