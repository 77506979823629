import React from 'react';
import { Grid, Box } from '@mui/material';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Typography from '@mui/material/Typography';
import { newProjectDoughnutData } from '../../data/picklistOptions';
import NivoDoughnut from '../../uiComponents/NivoDoughnut';
import { theme as bdtheme } from '../../theme/BrightDesignTheme';
import { useSidebarStyles as useStyles } from './styles';
import { useTranslation } from 'react-i18next';

const NewProjectSidebar = (props) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const steps = props.showMoreDetails
    ? [
        t('new-project-sidebar.step1'),
        t('new-project-sidebar.step2'),
        t('new-project-sidebar.step3'),
        t('new-project-sidebar.step5'),
        t('new-project-sidebar.step4'),
      ]
    : [
        t('new-project-sidebar.step1'),
        t('new-project-sidebar.step2'),
        t('new-project-sidebar.step3'),
        t('new-project-sidebar.step4'),
      ];

  const COLORS = [bdtheme.palette.primary.main, 'rgba(224, 224, 224, 1)'];
  const data = newProjectDoughnutData(
    props.progressStep,
    props.showMoreDetails
  );

  return (
    <Grid container alignItems='center' className={classes.root}>
      <Grid container alignContent='flex-start'>
        <Grid item xs={12}>
          <div className={classes.chartContainer}>
            <NivoDoughnut data={data} center={props.center} colors={COLORS} />
          </div>
          <Box mt={2} mb={5}>
            <Typography variant='body1' align='center' color='textSecondary'>
              {t('cta_sidebar.completed')}
            </Typography>
          </Box>
        </Grid>
        <Grid
          container
          item
          xs={12}
          spacing={0}
          justifyContent='center'
          className={classes.stepperContainer}
        >
          <Stepper
            activeStep={props.projectProgress}
            orientation='vertical'
            className={classes.stepper}
          >
            {steps.map((label, index) => (
              <Step key={label}>
                <StepLabel>{label}</StepLabel>
              </Step>
            ))}
          </Stepper>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default NewProjectSidebar;
