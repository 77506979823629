import React, { useState, useEffect } from 'react';
import { chipsContainer } from '../Login/styles';
import { useTranslation } from 'react-i18next';

import {
  Button,
  IconButton,
  Grid,
  TextField,
  Chip,
  Box,
  Typography,
} from '@mui/material';
import DoneIcon from '@mui/icons-material/Done';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';

import {
  regUppercase,
  regDigit,
  regSpecialSign,
} from '../../utils/FormFieldsValidation';

const ResetPasswordDetails = ({
  password,
  setPassword,
  handleResetPassword,
}) => {
  const { t } = useTranslation();

  const [showPassword, setShowPassword] = useState(false);

  const [disabled, setDisabled] = useState(true);

  useEffect(() => {
    const regPassword = /^(?=.*\d)(?=.*[A-Z])(?=.*[^a-zA-Z0-9])(?!.*\s).{8,}$/;
    setDisabled(!password.match(regPassword));
  }, [password]);

  return (
    <Grid
      container
      item
      xs={12}
      className='reset-details'
      alignItems='flex-start'
      alignContent='center'
      sx={{
        maxWidth: '450px !important',
      }}
    >
      <Grid item xs={12} sx={{ pb: 3 }}>
        <Typography variant='h6'>
          {t('forgot_password.reset_password')}
        </Typography>
      </Grid>
      <Grid
        container
        item
        xs={12}
        sx={{
          px: 3,
          pt: 3,
          pb: 0,
          boxShadow: '0 0 0 1px #E0E0E0',
          borderRadius: 1,
        }}
      >
        <Grid item xs={12}>
          <Box my={2}>
            <TextField
              id='password'
              name='password'
              label={t('forgot_password.new_password')}
              variant='outlined'
              type={showPassword ? 'text' : 'password'}
              fullWidth
              autoComplete='password'
              value={password}
              onKeyUp={(e) => {
                if (e.key === 'Enter' && !disabled) {
                  handleResetPassword(e);
                }
              }}
              onChange={(e) => {
                setPassword(e.target.value);
              }}
              InputProps={{
                endAdornment: (
                  <IconButton
                    aria-label='toggle password visibility'
                    onClick={() => setShowPassword(!showPassword)}
                    onMouseDown={() => setShowPassword(!showPassword)}
                    edge='end'
                    size='large'
                  >
                    {showPassword ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                ),
              }}
            />
          </Box>
        </Grid>
        <Grid container item xs={12} sx={chipsContainer}>
          <Grid container item xs={12} sx={{ mb: 1.5 }}>
            <Chip
              sx={{ mb: 1 }}
              label={'8 ' + t('login.characters')}
              icon={
                password && password.length > 7 ? (
                  <DoneIcon color='secondary' className='icon-done' />
                ) : password.length === 0 ? null : (
                  <ErrorOutlineIcon color='error' className='icon-error' />
                )
              }
            />
            <Chip
              label={t('login.capital_letter')}
              icon={
                password && password.length > 0 ? (
                  regUppercase.test(password) ? (
                    <DoneIcon color='secondary' className='icon-done' />
                  ) : (
                    <ErrorOutlineIcon color='error' className='icon-error' />
                  )
                ) : null
              }
            />
            <Chip
              label={t('login.digit')}
              icon={
                password && password.length > 0 ? (
                  regDigit.test(password) ? (
                    <DoneIcon color='secondary' className='icon-done' />
                  ) : (
                    <ErrorOutlineIcon color='error' className='icon-error' />
                  )
                ) : null
              }
            />
            <Chip
              label={t('login.special_sign')}
              icon={
                password && password.length > 0 ? (
                  regSpecialSign.test(password) ? (
                    <DoneIcon color='secondary' className='icon-done' />
                  ) : (
                    <ErrorOutlineIcon color='error' className='icon-error' />
                  )
                ) : null
              }
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid
        container
        justifyContent='flex-end'
        alignItems='center'
        sx={{ px: 0, py: 3 }}
      >
        <Grid item>
          <Button
            color='primary'
            variant='contained'
            onClick={handleResetPassword}
            disabled={disabled}
          >
            {t('forgot_password.confirm')}
          </Button>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default ResetPasswordDetails;
