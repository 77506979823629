import React from 'react';
import useMediaQuery from '@mui/material/useMediaQuery';
import { Typography, Grid, Box } from '@mui/material';
// import { ReactComponent as DashboardImage } from '../../assets/dashboard.svg';
import { ReactComponent as Campaign } from '../../assets/Campaign_launch.svg';
import { ReactComponent as Connection } from '../../assets/World_Connection.svg';
import { ReactComponent as Knowledge } from '../../assets/Knowledge.svg';

import { useTranslation } from 'react-i18next';

const LoginSideComponent = () => {
  const { t } = useTranslation();
  const isLargeScreen = useMediaQuery('(min-width:1280px)');

  return (
    <Grid
      container
      item
      xs={12}
      sx={{
        maxWidth: '484px !important',
      }}
    >
      <Grid item xs={12}>
        <Box mb={2}>
          <Typography variant={isLargeScreen ? 'h3' : 'h4'}>
            {t('login.text1')}
          </Typography>
        </Box>
        <Box mb={8}>
          <Typography color="textSecondary" variant="h6">
            {t('login.text2')}
          </Typography>
        </Box>
      </Grid>
      <Grid
        container
        item
        justifyContent="center"
        alignItems="center"
        xs={12}
        style={{
          paddingBottom: 56,
          minWidth: 398,
        }}
      >
        <Connection
          style={{
            display: 'inline-block',
            width: 168,
          }}
        />
        <Campaign
          style={{
            display: 'inline-block',
            width: 122,
            marginLeft: -56,
            marginBottom: 56,
          }}
        />
        <Knowledge
          style={{
            display: 'inline-block',
            width: 244,
            marginLeft: -80,
          }}
        />
      </Grid>
    </Grid>
  );
};

export default LoginSideComponent;
