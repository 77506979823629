import React from 'react';
import { Grid, IconButton, Tooltip, Link } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import { trimFileName } from '../JS/HelperFunctions';

const dropzone = {
  overflow: 'hidden',
};

const UploadedFile = ({ name = '...', id, deleteFile, downloadFile }) => {
  return (
    <>
      <Grid
        container
        justifyContent='space-between'
        alignItems='center'
        style={{
          ...dropzone,
          padding: '8px 24px 8px 24px',
          height: '100%',
          flex: 1,
        }}
      >
        <Grid item xs={10} style={{ paddingRight: 24 }}>
          <Tooltip title={name} placement='top-start'>
            <span
              style={{
                alignSelf: 'flex-start',
                margin: '10px 3%',
                wordBreak: 'break-all',
              }}
            >
              <Link
                component='button'
                variant='body2'
                onClick={() => {
                  downloadFile(id, name);
                }}
              >
                {trimFileName(name)}
              </Link>
            </span>
          </Tooltip>
        </Grid>
        <Grid item xs={2}>
          <IconButton
            aria-label='remove file'
            onClick={() => {
              deleteFile(id);
            }}
            sx={{ float: 'right' }}
            size='large'
          >
            <DeleteIcon
              style={{ display: 'block', marginLeft: 'auto', color: '#f44336' }}
            />
          </IconButton>
        </Grid>
      </Grid>
    </>
  );
};

export default UploadedFile;
