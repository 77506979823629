import React from 'react';
import { Box, Grid, Card, CardContent, Typography } from '@mui/material';
import { theme } from '../theme/BrightDesignTheme';
import DescriptionCardRow from './DescriptionCardRow';

const DetailsTableCard = (props) => {
  const {
    variant,
    customMinWidth,
    customMaxWidth,
    cardTitle,
    data,
    handleUpdateField,
    setShowErrorUpdate,
  } = props;

  return (
    <Box
      sx={{
        minWidth: customMinWidth ? customMinWidth : 275,
        maxWidth: customMaxWidth ? customMaxWidth : 786,
        margin: 'auto',
      }}
    >
      <Card
        variant={variant ? variant : ''}
        sx={{
          boxShadow: theme.custom.boxshadow.boxShadow,
          borderRadius: 2,
        }}
      >
        <CardContent sx={{ padding: 3, '&:last-child': { pb: 0 } }}>
          <Grid container>
            <Grid item xs={12}>
              <Box mb={1}>
                <Typography component="h6" variant="h6">
                  {cardTitle}
                </Typography>
              </Box>
            </Grid>
            <DescriptionCardRow
              rowLabel={'label'}
              data={data}
              hasEdit
              textWrap
              handleUpdateField={handleUpdateField}
              setShowErrorUpdate={setShowErrorUpdate}
            />
          </Grid>
        </CardContent>
      </Card>
    </Box>
  );
};

export default DetailsTableCard;
