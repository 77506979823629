import React, { useState, useEffect, useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { useStyles } from './styles';
import { useMediaQuery } from 'react-responsive';
import { theme as bdtheme } from '../../theme/BrightDesignTheme';
import NivoDoughnut from '../../uiComponents/NivoDoughnut';
import { Grid, Button, Divider, Box, Typography } from '@mui/material';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import { newProjectDoughnutData } from '../../data/picklistOptions';
import { useTranslation } from 'react-i18next';
import 'react-dropzone-uploader/dist/styles.css';
import Loader from '../../uiComponents/Loader';
import axios from 'axios';
import { UserContext } from '../../contexts/UserContext';
import DocumentUploader from '../../uiComponents/DocumentUploader';

const NewProjectUploadTypeEmployees = ({
  progressStep,
  setProgressStep,
  center,
  newProjectId,
  showMoreDetails,
  setPercentage,
  setProjectProgress,
  projectType,
  setProjectType,
  setProjectProduct,
  setProjectInt,
  setProjectBusiness,
  setProjectEmployees,
  setNewProjectId,
  setIndustries,
  setActive,
  setExpand,
  setActivitiesInt,
  setActivities,
  setThemes,
  setTarget,
  accountId,
  setAccountId,
  setSectors,
  setSubSectors,
}) => {
  const [userContext] = useContext(UserContext);
  const classes = useStyles();
  const history = useHistory();
  const apiEndPoint =
    process.env.NODE_ENV === 'development'
      ? process.env.REACT_APP_API_ENDPOINT
      : process.env.REACT_APP_API_ENDPOINT_PROD;
  const { t } = useTranslation();
  const isLargeScreen = useMediaQuery({ query: '(min-width: 1280px)' });
  const COLORS = [bdtheme.palette.primary.main, 'rgba(224, 224, 224, 1)'];
  const data = newProjectDoughnutData(progressStep, showMoreDetails);
  const [loading, setLoading] = React.useState(false);

  const [projectPlanId, setProjectPlanId] = useState([]);
  const [othersId, setOthersId] = useState([]);

  const getUploadParamsProjectPlan = ({ file }) => {
    const body = new FormData();
    body.append('project_plan', file);
    body.append('projectId', newProjectId);
    return { url: apiEndPoint + '/v1/partner/upload/', body };
  };

  const getUploadParamsOthers = ({ file }) => {
    const body = new FormData();
    body.append('others', file);
    body.append('projectId', newProjectId);
    return { url: apiEndPoint + '/v1/partner/upload/', body };
  };

  const deleteFile = (id) => {
    setLoading(true);
    axios({
      method: 'post',
      url: apiEndPoint + '/v1/partner/upload/delete',
      data: { documentId: id },
      withCredentials: true,
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        Authorization: `Bearer ${userContext.token}`,
      },
    }).then((res) => {
      if (res.data.success) {
        fetchFiles();
      } else {
        console.log('error', res);
      }
      setLoading(false);
    });
  };

  const handleChangeStatusParamsProjectPlan = ({ xhr, remove }, status) => {
    if (xhr) {
      xhr.onreadystatechange = () => {
        if (xhr.readyState === 4) {
          fetchFiles();
          remove();
        }
      };
    }
  };

  const handleChangeStatusParamsOthers = ({ xhr, remove }, status) => {
    if (xhr) {
      xhr.onreadystatechange = () => {
        if (xhr.readyState === 4) {
          fetchFiles();
          remove();
        }
      };
    }
  };

  const downloadFile = (idFile, filename) => {
    setLoading(true);
    axios({
      method: 'get',
      url: apiEndPoint + '/v1/partner/upload/download',
      withCredentials: true,
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        Authorization: `Bearer ${userContext.token}`,
      },
      responseType: 'blob',
      params: {
        documentId: idFile,
      },
    })
      .then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', filename);
        document.body.appendChild(link);
        link.click();
        link.parentNode.removeChild(link);
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
      });
  };

  const ROUTES = ['/app/newproject/addproject', '/app/newproject/subsidies'];

  const handleNext = () => {
    let backAccountId = accountId;
    setProjectProgress(0);
    setPercentage(0);
    setProjectType('');
    setNewProjectId('');
    setIndustries([]);
    setActive([]);
    setExpand([]);
    setActivitiesInt([]);
    setActivities([]);
    setThemes([]);
    setTarget([]);
    setAccountId('');
    setSectors([]);
    setSubSectors([]);
    setProjectProduct({
      name: '',
      idea: '',
      problemStatement: '',
      startDate: null,
      endDate: null,
      noOfRnDEmployees: '',
      rndHours: '',
      rndCosts: '',
      activitiesCarriedOutBy: '',
      industrySector: '',
      newness: '',
      projectFocus: '',
      preTRLLevel: '',
      postTRLLevel: '',
      subsidyNeed: '',
      projectTypeId: '',
      accountId: '',
      keySectors: '',
      subSectors: '',
      id: '',
    });
    setProjectInt({
      name: '',
      idea: '',
      problemStatement: '',
      startDate: null,
      endDate: null,
      countriesActive: '',
      countriesExpanding: '',
      activities: '',
      subsidyNeed: '',
      projectTypeId: '',
      accountId: '',
      id: '',
    });
    setProjectBusiness({
      name: '',
      idea: '',
      problemStatement: '',
      startDate: null,
      activities: '',
      subsidyNeed: '',
      projectTypeId: '',
      accountId: '',
      id: '',
    });
    setProjectEmployees({
      name: '',
      idea: '',
      problemStatement: '',
      learningWorkPrograms: '',
      investmentInEmployees: '',
      hiredWorkersAtDisadvantage: '',
      willHireWorkersAtDisadvantage: '',
      subsidyNeed: '',
      projectTypeId: '',
      accountId: '',
      id: '',
    });
    setProgressStep(progressStep + 1);
    history.push(`/app/accounts/${backAccountId}`);
  };

  const handleBack = () => {
    setProgressStep(progressStep - 1);
    showMoreDetails ? history.push(ROUTES[1]) : history.push(ROUTES[0]);
  };

  const fetchFiles = () => {
    setLoading(true);
    axios({
      method: 'post',
      url: apiEndPoint + '/v1/partner/project/getAllDocuments',
      withCredentials: true,
      data: { projectId: newProjectId },
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        Authorization: `Bearer ${userContext.token}`,
      },
    })
      .then((res) => {
        let projectPlans = [];
        let others = [];

        if (res.data.projectDocuments.length > 0) {
          res.data.projectDocuments.forEach((projectDocument) => {
            if (projectDocument.documentType === 'project_plan') {
              projectPlans.push({
                id: projectDocument.id,
                filename: projectDocument.filename,
              });
            } else if (projectDocument.documentType === 'others') {
              others.push({
                id: projectDocument.id,
                filename: projectDocument.filename,
              });
            }
          });
        }
        setProjectPlanId(projectPlans);
        setOthersId(others);
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
      });
  };

  useEffect(() => {
    setLoading(true);
    axios({
      method: 'post',
      url: apiEndPoint + '/v1/partner/project/getAllDocuments',
      withCredentials: true,
      data: { projectId: newProjectId },
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        Authorization: `Bearer ${userContext.token}`,
      },
    })
      .then((res) => {
        let projectPlans = [];
        let others = [];

        if (res.data.projectDocuments.length > 0) {
          res.data.projectDocuments.forEach((projectDocument) => {
            if (projectDocument.documentType === 'project_plan') {
              projectPlans.push({
                id: projectDocument.id,
                filename: projectDocument.filename,
              });
            } else if (projectDocument.documentType === 'others') {
              others.push({
                id: projectDocument.id,
                filename: projectDocument.filename,
              });
            }
          });
        }
        setProjectPlanId(projectPlans);
        setOthersId(others);
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      {loading && <Loader loading={loading} />}
      <Grid
        container
        item
        xs={12}
        justifyContent='center'
        className={classes.root + ' ' + classes.rootUpload}
      >
        <Grid
          container
          item
          xs={12}
          alignContent='center'
          justifyContent={isLargeScreen ? 'space-between' : 'center'}
          className={classes.section + ' ' + classes.uploadSection}
        >
          <Grid container item xs={12} className={classes.uploadContainer}>
            <Grid container item xs={12}>
              <Grid item xs={12} style={{ maxWidth: 450 }}>
                <Box mb={1}>
                  <Typography variant='h6'>{t('upload.title')}</Typography>
                </Box>
                <Box>
                  <Typography
                    variant='body2'
                    style={{ color: bdtheme.palette.text.disabled }}
                  >
                    {t('upload.subtitle')}
                  </Typography>
                </Box>
              </Grid>
            </Grid>
            <Grid item xs={12} lg={6}>
              <div
                style={{
                  marginLeft: isLargeScreen ? 0 : 'auto',
                  marginRight: 'auto',
                  maxWidth: 450,
                }}
              >
                <DocumentUploader
                  label={t('upload.label7')}
                  inputContent={t('upload.label5')}
                  getUploadParams={getUploadParamsProjectPlan}
                  onChangeStatus={handleChangeStatusParamsProjectPlan}
                  initialFiles={projectPlanId}
                  deleteFile={deleteFile}
                  downloadFile={downloadFile}
                />
              </div>
            </Grid>
            <Grid item xs={12} lg={6}>
              <div
                style={{
                  marginLeft: isLargeScreen ? 0 : 'auto',
                  marginRight: 'auto',
                  maxWidth: 450,
                }}
              >
                <DocumentUploader
                  label={t('upload.label10')}
                  inputContent={t('upload.label5')}
                  getUploadParams={getUploadParamsOthers}
                  onChangeStatus={handleChangeStatusParamsOthers}
                  initialFiles={othersId}
                  deleteFile={deleteFile}
                  downloadFile={downloadFile}
                />
              </div>
            </Grid>
          </Grid>
          <Grid
            container
            item
            xs={12}
            justifyContent='center'
            className={classes.section + ' ' + classes.sectionMobile}
          >
            <Grid item xs={12}>
              <Grid container item xs={12} className={classes.sectionButtons}>
                <Grid item xs={12}>
                  <Divider />
                </Grid>
                <Grid
                  container
                  item
                  xs={12}
                  className={classes.buttons}
                  style={{ display: 'flex', flexWrap: 'nowrap' }}
                >
                  <>
                    {!isLargeScreen && (
                      <Grid item>
                        <div style={{ height: 40, width: 40 }}>
                          <NivoDoughnut
                            data={data}
                            center={center}
                            colors={COLORS}
                            smallFont
                          />
                        </div>
                      </Grid>
                    )}
                    <Grid
                      item
                      container
                      justifyContent={
                        isLargeScreen ? 'space-between' : 'flex-end'
                      }
                      className={classes.specialButtons}
                    >
                      <Button
                        style={{ marginRight: 16 }}
                        startIcon={<KeyboardArrowLeftIcon />}
                        onClick={handleBack}
                      >
                        {t('buttons.back')}
                      </Button>
                      <Button
                        color='primary'
                        variant='contained'
                        endIcon={<KeyboardArrowRightIcon />}
                        onClick={handleNext}
                      >
                        {t('buttons.checkout')}
                      </Button>
                    </Grid>
                  </>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

export default NewProjectUploadTypeEmployees;
