import * as React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import PopularSubsidiesTableRow from './PopularSubsidiesTableRow';
import { Box } from '@mui/material';

export default function PopularSubsidiesTable(props) {
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const isSelected = (id) => props.selectedPopularSubsidies.indexOf(id) !== -1;

  return (
    <Box sx={{ width: '100%' }}>
      {props.popularSubsidies && props.popularSubsidies.length > 0 && (
        <>
          <TableContainer>
            <Table aria-labelledby='tableTitle'>
              <TableBody>
                {props.popularSubsidies
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((row, index) => {
                    const isItemSelected = isSelected(row.id);
                    const labelId = `enhanced-table-checkbox-${index}`;
                    return (
                      <TableRow
                        role='checkbox'
                        aria-checked={isItemSelected}
                        tabIndex={-1}
                        key={row.id}
                        selected={isItemSelected}
                        sx={{
                          '&.MuiTableRow-root.Mui-selected': {
                            backgroundColor: 'white',
                          },
                        }}
                      >
                        <TableCell
                          component='div'
                          id={labelId}
                          scope='row'
                          padding='none'
                          sx={{ borderBottom: 'none' }}
                        >
                          <PopularSubsidiesTableRow
                            row={row}
                            key={row.id}
                            labelId={labelId}
                            isItemSelected={isItemSelected}
                            popularSubsidies={props.popularSubsidies}
                            setPopularSubsidies={props.setPopularSubsidies}
                            selectedPopularSubsidies={
                              props.selectedPopularSubsidies
                            }
                            setSelectedPopularSubsidies={
                              props.setSelectedPopularSubsidies
                            }
                            hasDelete={props.hasDelete}
                            loginAsUser={props.loginAsUser}
                            hasAmount={props.hasAmount}
                            setLoading={props.setLoading}
                            otherSubsidies={props.otherSubsidies}
                            setOtherSubsidies={props.setOtherSubsidies}
                            selectedOtherSubsidies={
                              props.selectedOtherSubsidies
                            }
                            setSelectedOtherSubsidies={
                              props.setSelectedOtherSubsidies
                            }
                            assignments={props.assignments}
                            setAssignments={props.setAssignments}
                            selectedAssignments={props.selectedAssignments}
                            setSelectedAssignments={
                              props.setSelectedAssignments
                            }
                          />
                        </TableCell>
                      </TableRow>
                    );
                  })}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[5, 10]}
            component='div'
            count={props.popularSubsidies.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            labelRowsPerPage={''}
          />
        </>
      )}
    </Box>
  );
}
