export const account = [
  'company name',
  'company_name',
  'name',
  'company',
  'account',
  'account name',
  'account_name',
];
export const kvk = [
  'coc',
  'chamber of commerce number',
  'kvk-nummer',
  'kvk',
  'kvk nummer',
  'dossier nummer',
  'dossier',
  'dossier_number',
];
export const fname = ['first_name', 'firstname', 'first name'];
export const lname = ['last_name', 'lastname', 'last name'];
export const email = ['email', 'e-mail'];
export const phone = [
  'phone',
  'mobile',
  'mobile number',
  'mobile_number',
  'phone_number',
  'phone number',
];

export const headers = [
  'Company',
  'Chamber of Commerce Number',
  'First Name',
  'Last Name',
  'Email',
  'Phone',
];
