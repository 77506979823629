import React from 'react';
import NumberFormat from 'react-number-format';

const NumberFormatCustom = React.forwardRef((props, ref) => {
  const { inputRef, onChange, ...other } = props;
  return (
    <NumberFormat
      {...other}
      getInputRef={inputRef}
      allowNegative={false}
      onValueChange={(values) => {
        onChange({
          target: {
            name: props.name,
            // value: values.floatValue,
            value: values.value,
          },
        });
      }}
      thousandSeparator={''}
      decimalSeparator={false}
      decimalScale={0}
      allowLeadingZeros={props['data-zero'] && props['data-zero']}
      // decimalPrecision={0}
      isNumericString
      prefix={''}
    />
  );
});

export default NumberFormatCustom;
