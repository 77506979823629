import React, { useState } from 'react';
import useMediaQuery from '@mui/material/useMediaQuery';

import { Grid, Typography, IconButton, Divider } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import { cardRowStyle } from './styles';
import TextfieldCustom from './inputComponents/TextFieldCustom';
import MuiCustomPhoneNumber from './inputComponents/MuiCustomPhoneNumber';

const DescriptionCardRow = (props) => {
  const { hasEdit, data, textWrap, handleUpdateField, setShowErrorUpdate } =
    props;
  const isLargeScreen = useMediaQuery('(min-width:1280px)');
  const [editField, setEditField] = useState();

  return (
    <>
      {data &&
        data.length > 0 &&
        data.map((row, index) => (
          <Grid container item xs={12} key={index}>
            <Grid container item xs={12} sx={{ py: 2.5 }}>
              <Grid item xs={isLargeScreen ? 3 : 12}>
                <Typography
                  noWrap={textWrap}
                  variant="overline"
                  color="textSecondary"
                  sx={{ color: 'text.hint', pr: 1 }}
                >
                  {row.label}{' '}
                </Typography>
              </Grid>
              <Grid
                item
                alignItems={'center'}
                xs={isLargeScreen ? 9 : 12}
                sx={{
                  display: hasEdit ? 'flex' : 'block',
                  ...cardRowStyle,
                }}
              >
                {editField !== null && editField === index ? (
                  row.inputType === 'phone' ? (
                    <MuiCustomPhoneNumber
                      defaultCountry={'nl'}
                      autoFocus
                      fullWidth
                      field={row}
                      setEditField={setEditField}
                      setShowErrorUpdate={setShowErrorUpdate}
                      handleSave={handleUpdateField}
                    />
                  ) : (
                    <TextfieldCustom
                      field={row}
                      id={row.id}
                      variant="standard"
                      size="small"
                      fullWidth
                      autoFocus
                      setEditField={setEditField}
                      handleUpdateField={handleUpdateField}
                      handlePhoneLeadingZero={true}
                      setShowErrorUpdate={setShowErrorUpdate}
                    />
                  )
                ) : (
                  <>
                    <Typography
                      noWrap={textWrap}
                      color="textSecondary"
                      sx={{ color: 'text.disabled', pr: 2 }}
                    >
                      {row.value}
                      {row.hasOwnProperty('valid') && row.valid === false && (
                        <Typography
                          noWrap={textWrap}
                          component="span"
                          sx={{
                            pr: 2,
                            display: 'block',
                            fontSize: 12,
                            color: 'error.main',
                          }}
                        >
                          {row.errorMsg}
                        </Typography>
                      )}
                    </Typography>
                  </>
                )}

                {hasEdit && row.hasEdit && (
                  <IconButton
                    color="primary"
                    data-id={index}
                    onClick={(e) => {
                      setEditField(index);
                      if (
                        e.currentTarget.getAttribute('data-id') ===
                        index.toString()
                      ) {
                      }
                    }}
                  >
                    <EditIcon id={index} sx={{ fontSize: 18 }} />
                  </IconButton>
                )}
              </Grid>
            </Grid>
            <Grid
              item
              xs={12}
              sx={{ display: index === data.length - 1 ? 'none' : '' }}
            >
              <Divider />
            </Grid>
          </Grid>
        ))}
    </>
  );
};

export default DescriptionCardRow;
