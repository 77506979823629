import React, { useState, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useIsMounted } from '../../utils/customHooks';
import { UserContext } from '../../contexts/UserContext';
import {
  Typography,
  Button,
  TextField,
  Grid,
  InputAdornment,
} from '@mui/material';
import EmailIcon from '@mui/icons-material/Email';

const SendInvitation = (props) => {
  const {
    subtitle,
    back,
    confirm,
    setOpenInvitationDialog,
    handleSendInvitation,
  } = props;

  const { t } = useTranslation();
  // eslint-disable-next-line no-unused-vars
  const [userContext, setUserContext] = useContext(UserContext);

  const [email, setEmail] = useState('');

  const regEmail =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

  return (
    <Grid container>
      <Grid container item xs={12} sx={{ pb: 5 }}>
        <Typography>{subtitle}</Typography>
      </Grid>
      <Grid container item xs={12} sx={{ pb: 3 }}>
        <TextField
          variant="standard"
          // size="small"
          error={
            email && Boolean(!regEmail.test(email))
              ? Boolean(!regEmail.test(email))
              : false
          }
          name="Email"
          fullWidth
          label={t('labels.email')}
          placeholder={t('labels.email')}
          value={email || ''}
          onChange={(e) => {
            setEmail(e.target.value);
          }}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <EmailIcon />
              </InputAdornment>
            ),
          }}
          inputProps={{
            fontSize: 14,
          }}
        />
      </Grid>
      <Grid
        container
        justifyContent={'space-between'}
        item
        xs={12}
        sx={{ pt: 3 }}
      >
        {back && (
          <Grid item sx={{}}>
            <Button
              onClick={() => {
                setOpenInvitationDialog(false);
              }}
              color="primary"
              // sx={sxBtnCancel && sxBtnCancel}
            >
              {back}
            </Button>
          </Grid>
        )}{' '}
        {confirm && (
          <Grid item sx={{}}>
            <Button
              disabled={email ? Boolean(!regEmail.test(email)) : true}
              onClick={() => {
                handleSendInvitation(email);
                setOpenInvitationDialog(false);
              }}
              color="primary"
              // sx={sxBtnConfirm && sxBtnConfirm}
            >
              {confirm}
            </Button>
          </Grid>
        )}
      </Grid>
    </Grid>
  );
};

export default SendInvitation;
