import React, { useState, useEffect, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useIsMounted } from '../../utils/customHooks';
import axios from 'axios';
import { ReactComponent as Startup } from '../../assets/StartupTwoColor.svg';
import { UserContext } from '../../contexts/UserContext';
import { WorkspaceContext } from '../../contexts/WorkspaceContext';
import { root, rootAccountDetails, box } from '../styles';
import { useStyles } from './styles';
import { Grid, Box, Typography, Snackbar, IconButton } from '@mui/material';
import Alert from '@mui/material/Alert';
import CloseIcon from '@mui/icons-material/Close';
import Loader from '../../uiComponents/Loader';
import PageDescriptionCard from '../../uiComponents/PageDescriptionCard';
import LeadsTable from './LeadsTable';
import FiltersComponent from '../../uiComponents/FiltersComponent';
import LeadDialog from '../../uiComponents/LeadDialog';
import AddNewLead from './AddNewLead';
import UploadMap from '../../uiComponents/uploadComponents/UploadMap';
import SendInvitation from './SendInvitation';
import AddLeadButton from '../../uiComponents/AddLeadButton';
import ChangeOwner from './ChangeOwner';

const LeadsDetails = () => {
  const classes = useStyles();
  const isLargeScreen = useMediaQuery('(min-width:1280px)');
  const { t } = useTranslation();
  const isMounted = useIsMounted();
  // eslint-disable-next-line no-unused-vars
  const [userContext, setUserContext] = useContext(UserContext);
  const {
    headerCols,
    uploadError,
    setUploadError,
    uploadErrorMsg,
    setUploadErrorMsg,
    partialSuccess,
    setPartialSuccess,
    setPartialRecords,
  } = useContext(WorkspaceContext);

  const [loading, setLoading] = useState(false);
  const [openNewLeadDialog, setOpenNewLeadDialog] = useState(false);
  const [openMapLeadDialog, setOpenMapLeadDialog] = useState(false);
  const [openInvitationDialog, setOpenInvitationDialog] = useState(false);
  const [changeOwnerDialog, setChangeOwnerDialog] = useState(false);
  const [selectedAccountId, setSelectedAccountId] = useState(null);
  const [rowsInserted, setRowsInserted] = useState(0);
  const [onlineRegistration, setOnlineRegistration] = useState(false);
  const [firstScreen, setFirstScreen] = useState(true);
  const [refreshLeads, setRefreshLeads] = useState(false);

  const { pageLeads, setPageLeads, setNewLead, locale } =
    useContext(WorkspaceContext);
  const [searchLeadsText, setSearchLeadsText] = useState('');
  const [startDateLeads, setStartDateLeads] = useState(null);
  const [endDateLeads, setEndDateLeads] = useState(null);
  const [data, setData] = useState([]);
  const [successAddLead, setSuccessAddLead] = useState(false);
  const [successInserted, setSuccessInserted] = useState(false);
  const [successInvitation, setSuccessInvitation] = useState(false);
  const [errorInvitation, setErrorInvitation] = useState(false);

  const [anchorEl, setAnchorEl] = useState(null);
  const openAddMenu = Boolean(anchorEl);

  const handleClickAdd = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleCloseMenuAdd = () => {
    setAnchorEl(null);
  };

  const handleCloseSnackbar = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    if (partialSuccess === true) {
      setOpenMapLeadDialog(false);
    }
    setSuccessAddLead(false);
    setRefreshLeads(false);
    setUploadError(false);
    setRowsInserted(0);
    setUploadErrorMsg('');
    setPartialSuccess(false);
    setSuccessInserted(false);
    setSuccessInvitation(false);
    setErrorInvitation(false);
  };

  const handleCloseDialog = () => {
    try {
      setOpenNewLeadDialog(false);
      setOpenMapLeadDialog(false);
      setOpenInvitationDialog(false);
      setPartialSuccess(false);
      setPartialRecords([]);
      setFirstScreen(true);
      setNewLead({
        company: {
          name: '',
          dossier_number: '',
          establishment_number: '',
          establishment_city: '',
          establishment_street: '',
          correspondence_city: '',
          correspondence_street: '',
          sme: '',
          keySectors: '',
          subSectors: '',
        },
        contact: {
          firstName: '',
          lastName: '',
          Email: '',
          phone: '',
          partnerParticipation: false,
          description: '',
        },
      });
    } catch (e) {
      console.log(e);
    }
  };

  const apiEndPoint =
    process.env.NODE_ENV === 'development'
      ? process.env.REACT_APP_API_ENDPOINT
      : process.env.REACT_APP_API_ENDPOINT_PROD;

  const handleUploadCsvData = (checkboxVal, results) => {
    setLoading(true);

    axios({
      method: 'post',
      url: apiEndPoint + '/v1/partner/leads/bulkUpload',
      withCredentials: true,
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        Authorization: `Bearer ${userContext.token}`,
      },
      data: {
        partnerParticipation: checkboxVal,
        records: results,
      },
    })
      .then((res) => {
        if (res.data.success) {
          setLoading(false);
          setOpenMapLeadDialog(false);
          setSuccessInserted(true);
          if (res.data.rowsInserted) {
            setRowsInserted(res.data.rowsInserted);
          }
        } else {
          setLoading(false);
          setOpenMapLeadDialog(false);
        }
        setLoading(false);
        setOpenMapLeadDialog(false);
      })
      .catch((error) => {
        if (isMounted.current) {
          if (error.response && error.response.status === 500) {
            if (error.response.data.success === false) {
              if (
                error.response.data.message ===
                'Email column is not a valid email adress'
              ) {
                setLoading(false);
                setUploadError(true);
                setUploadErrorMsg(t('leads.field_mapping.error2'));
                setOpenMapLeadDialog(false);
              } else if (
                error.response.data.message === 'Unexpected Error Occured'
              ) {
                setLoading(false);
                setUploadError(true);
                setUploadErrorMsg(t('leads.field_mapping.error3'));
                setOpenMapLeadDialog(false);
              } else if (
                error.response.data.message ===
                'Email already exists in our database'
              ) {
                setLoading(false);
                setOpenMapLeadDialog(false);
                setUploadError(true);
                setUploadErrorMsg(t('leads.field_mapping.error4'));
              }
            } else if (
              error.response.data.message ===
              'Some records failed. Email already exits in our database'
            ) {
              setLoading(false);
              setUploadError(true);
              setPartialSuccess(true);
              setPartialRecords(error.response.data.data);
              setUploadErrorMsg(
                t('leads.field_mapping.error5', {
                  records: error.response.data.data.length,
                })
              );
            }
          }
        }
        setLoading(false);
      });
  };

  const handleChangeOwner = (user) => {
    try {
      setLoading(true);
      axios({
        method: 'post',
        url: apiEndPoint + '/v1/partner/leads/changePartnerOwner',
        withCredentials: true,
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
          Authorization: `Bearer ${userContext.token}`,
        },
        data: {
          accountId: selectedAccountId,
          userId: user.id,
        },
      })
        .then((res) => {
          setLoading(false);
          setRefreshLeads(true);
        })
        .catch((error) => {
          setLoading(false);
        });
    } catch (error) {
      console.log(error);
    }
  };

  const handleSendInvitation = (email) => {
    try {
      setLoading(true);
      axios({
        method: 'post',
        url: apiEndPoint + '/v1/partner/invite/sendReferralLink',
        withCredentials: true,
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
          Authorization: `Bearer ${userContext.token}`,
        },
        data: {
          email: email,
        },
      })
        .then((res) => {
          if (res.data.message === 'Email Sent') {
            setSuccessInvitation(true);
          } else {
            setSuccessInvitation(false);
            setErrorInvitation(true);
          }
          setLoading(false);
        })
        .catch((error) => {
          if (isMounted.current) {
            setErrorInvitation(true);
          }
          setLoading(false);
        });
    } catch (error) {
      console.log(error);
    }
  };

  const handleFilterSearch = (prop, value) => {
    setLoading(true);
    let startOfDate = startDateLeads
      ? new Date(new Date(startDateLeads).setHours(0, 0, 0, 0))
      : null;
    let endOfDate = endDateLeads
      ? new Date(new Date(endDateLeads).setHours(23, 59, 59, 999))
      : null;
    const reqBody = {
      name: searchLeadsText,
      from: startOfDate
        ? new Date(
            startOfDate.getTime() - startOfDate.getTimezoneOffset() * 60 * 1000
          )
        : null,
      to: endOfDate
        ? new Date(
            endOfDate.getTime() - endOfDate.getTimezoneOffset() * 60 * 1000
          )
        : null,
      onlineRegistration: onlineRegistration,
    };
    const params = { ...reqBody, [prop]: value };

    axios({
      method: 'get',
      url: apiEndPoint + '/v1/partner/leads/getAll',
      withCredentials: true,
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        Authorization: `Bearer ${userContext.token}`,
      },
      params: params,
    })
      .then((res) => {
        if (res.data && res.data.length > 0) {
          if (isMounted.current) {
            setData(res.data);
          }
        } else {
          if (isMounted.current) {
            setData(res.data);
          }
        }
        if (isMounted.current) {
          setPageLeads(0);
          setLoading(false);
        }
      })
      .catch((error) => {
        if (isMounted.current) {
          setLoading(false);
        }
      });
  };

  useEffect(() => {
    setLoading(true);
    let startOfDate = startDateLeads
      ? new Date(new Date(startDateLeads).setHours(0, 0, 0, 0))
      : null;
    let endOfDate = endDateLeads
      ? new Date(new Date(endDateLeads).setHours(23, 59, 59, 999))
      : null;
    axios({
      method: 'get',
      url: apiEndPoint + '/v1/partner/leads/getAll',
      withCredentials: true,
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        Authorization: `Bearer ${userContext.token}`,
      },
      params: {
        name: '',
        from: startOfDate
          ? new Date(
              startOfDate.getTime() -
                startOfDate.getTimezoneOffset() * 60 * 1000
            )
          : null,
        to: endOfDate
          ? new Date(
              endOfDate.getTime() - endOfDate.getTimezoneOffset() * 60 * 1000
            )
          : null,
        onlineRegistration: onlineRegistration,
      },
    })
      .then((res) => {
        if (res.data && res.data.length > 0) {
          if (isMounted.current) {
            setData(res.data);
          }
        } else {
          if (isMounted.current) {
            setData(res.data);
          }
        }
        if (isMounted.current) {
          setPageLeads(0);
          setLoading(false);
          setRefreshLeads(false);
          setFirstScreen(true);
        }
      })
      .catch((error) => {
        if (isMounted.current) {
          setLoading(false);
        }
      });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (
      refreshLeads === true ||
      successInserted === true ||
      partialSuccess === true
    ) {
      setLoading(true);
      let startOfDate = startDateLeads
        ? new Date(new Date(startDateLeads).setHours(0, 0, 0, 0))
        : null;
      let endOfDate = endDateLeads
        ? new Date(new Date(endDateLeads).setHours(23, 59, 59, 999))
        : null;
      axios({
        method: 'get',
        url: apiEndPoint + '/v1/partner/leads/getAll',
        withCredentials: true,
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
          Authorization: `Bearer ${userContext.token}`,
        },
        params: {
          name: '',
          from: startOfDate
            ? new Date(
                startOfDate.getTime() -
                  startOfDate.getTimezoneOffset() * 60 * 1000
              )
            : null,
          to: endOfDate
            ? new Date(
                endOfDate.getTime() - endOfDate.getTimezoneOffset() * 60 * 1000
              )
            : null,
          onlineRegistration: onlineRegistration,
        },
      })
        .then((res) => {
          if (res.data && res.data.length > 0) {
            if (isMounted.current) {
              setData(res.data);
            }
          } else {
            if (isMounted.current) {
              setData(res.data);
            }
          }
          if (isMounted.current) {
            setPageLeads(0);
            setLoading(false);
            setRefreshLeads(false);
            setFirstScreen(true);
          }
        })
        .catch((error) => {
          if (isMounted.current) {
            setLoading(false);
          }
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refreshLeads, successInserted, partialSuccess]);

  useEffect(() => {
    window.document.title = t('page_title.accounts');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      {loading && <Loader loading={loading} />}
      <Grid container className={classes.root}>
        <Grid container item xs={12} sx={{ ...root, ...rootAccountDetails }}>
          <Grid item xs={12}>
            <Box mb={2}>
              <Typography variant='h5' align='center'>
                {t('leads.title_1')}
              </Typography>
            </Box>
            <Box mb={3}>
              <Typography
                align='center'
                color='textSecondary'
                sx={{ color: 'text.hint' }}
              >
                {t('leads.subtitle_1')}
              </Typography>
            </Box>
          </Grid>

          <Grid container item xs={12} sx={box}>
            <Grid item xs={12} sx={{ mb: 3 }}>
              <PageDescriptionCard
                hasContent
                hasCardSide={isLargeScreen ? true : false}
                hasActions
                buttonVariant='contained'
                cardTitle={t('leads.title_2')}
                cardContent={t('leads.subtitle_2')}
                cardAction={t('leads.add_new')}
                cardImage={
                  <Startup
                    style={{
                      width: isLargeScreen ? 'auto' : '100%',
                      float: 'right',
                    }}
                  />
                }
                handleClickAdd={handleClickAdd}
                anchorEl={anchorEl}
                open={openAddMenu}
                handleClose={handleCloseMenuAdd}
                setOpenMapLeadDialog={setOpenMapLeadDialog}
                cardActionComponent={
                  <>
                    <AddLeadButton
                      setOpenNewLeadDialog={setOpenNewLeadDialog}
                      setOpenMapLeadDialog={setOpenMapLeadDialog}
                      setOpenInvitationDialog={setOpenInvitationDialog}
                    />
                  </>
                }
              />
            </Grid>
          </Grid>

          <Grid container item xs={12} sx={box}>
            <Grid item xs={12} sx={{ mb: 3 }}>
              <PageDescriptionCard
                hasContent
                cardTitle={t('leads.title_3')}
                cardContentComponent={
                  <FiltersComponent
                    minWidth={230}
                    locale={locale}
                    placeholder={t('leads.placeholder_search')}
                    labelTextfield={t('leads.overview_table.company_name')}
                    labelStartdate={t('labels.referred_date')}
                    labelEnddate={t('labels.referred_date')}
                    separator={t('labels.to')}
                    searchText={searchLeadsText}
                    setSearchText={setSearchLeadsText}
                    startDate={startDateLeads}
                    setStartDate={setStartDateLeads}
                    endDate={endDateLeads}
                    setEndDate={setEndDateLeads}
                    handleFilterSearch={handleFilterSearch}
                    onlineRegistration={onlineRegistration}
                    setOnlineRegistration={setOnlineRegistration}
                    role={userContext.user.role}
                    onlineRegistrationText={t('leads.onlineRegistration')}
                    onlineRegistrationTextDescription={t(
                      'leads.onlineRegistrationText'
                    )}
                  />
                }
              />
            </Grid>
          </Grid>

          <Grid container item xs={12} sx={box}>
            <Grid item xs={12}>
              <Box
                sx={{
                  minWidth: 275,
                  maxWidth: 786,
                  margin: 'auto',
                  boxShadow: (theme) => theme.custom.boxshadow.boxShadow,
                  borderRadius: 2,
                }}
              >
                <Box sx={{}}>
                  <LeadsTable
                    data={data}
                    minWidth={275}
                    maxWidth={786}
                    locale={locale}
                    page={pageLeads}
                    setPage={setPageLeads}
                    role={userContext.user.role}
                    setChangeOwnerDialog={setChangeOwnerDialog}
                    setSelectedAccountId={setSelectedAccountId}
                  />
                </Box>
              </Box>
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      <LeadDialog
        openDialog={openNewLeadDialog}
        setOpenDialog={setOpenNewLeadDialog}
        onClose={handleCloseDialog}
        hasTitle
        hasContent
        customMaxWidth={'md'}
        componentContent={
          <AddNewLead
            loading={loading}
            setLoading={setLoading}
            setOpenNewLeadDialog={setOpenNewLeadDialog}
            setFirstScreen={setFirstScreen}
            setRefreshLeads={setRefreshLeads}
            setSuccessAddLead={setSuccessAddLead}
          />
        }
        title={
          firstScreen
            ? t('leads.dialog_new_lead_title')
            : t('leads.dialog_new_lead_contact_title')
        }
      />

      <LeadDialog
        openDialog={openMapLeadDialog}
        setOpenDialog={setOpenMapLeadDialog}
        hasTitle
        hasContent
        customMaxWidth={'md'}
        componentContent={
          <UploadMap
            key={headerCols}
            col1={t('leads.field_mapping.col1')}
            col2={t('leads.field_mapping.col2')}
            col3={t('leads.field_mapping.col3')}
            error1={t('leads.field_mapping.error2')}
            error2={t('leads.field_mapping.error3')}
            error3={t('leads.field_mapping.error4')}
            error4={t('leads.field_mapping.error5')}
            error5={t('leads.field_mapping.error6')}
            error6={t('leads.field_mapping.error8')}
            importBtn={t('leads.field_mapping.import')}
            cancelBtn={t('labels.cancel')}
            closeBtn={t('labels.close')}
            setOpenMapLeadDialog={setOpenMapLeadDialog}
            subtitle={t('leads.field_mapping_subtitle')}
            checkboxText={t('leads.dialog_new_lead_checkbox')}
            handleSubmit={handleUploadCsvData}
          />
        }
        title={t('leads.field_mapping_title')}
      />

      <LeadDialog
        openDialog={openInvitationDialog}
        setOpenDialog={setOpenInvitationDialog}
        hasTitle
        hasContent
        customMaxWidth={'sm'}
        componentContent={
          <SendInvitation
            setOpenInvitationDialog={setOpenInvitationDialog}
            handleSendInvitation={handleSendInvitation}
            subtitle={t('leads.dialog_send_subtitle')}
            back={t('labels.cancel')}
            confirm={t('labels.send')}
          />
        }
        title={t('leads.dialog_send_title')}
      />

      <LeadDialog
        openDialog={changeOwnerDialog}
        setOpenDialog={setChangeOwnerDialog}
        hasTitle
        hasContent
        customMaxWidth={'sm'}
        componentContent={
          <ChangeOwner
            setChangeOwnerDialog={setChangeOwnerDialog}
            handleChangeOwner={handleChangeOwner}
            subtitle={t('leads.change_owner.sub_title')}
            selectedAccountId={selectedAccountId}
            back={t('labels.cancel')}
            confirm={t('leads.change_owner.change')}
          />
        }
        title={t('leads.change_owner.title')}
      />

      <Snackbar
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        open={successAddLead}
        autoHideDuration={3000}
        onClose={handleCloseSnackbar}
        message={t('leads.success_new_lead')}
      />

      <Snackbar
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        open={successInserted}
        autoHideDuration={3000}
        onClose={handleCloseSnackbar}
        message={t('leads.field_mapping.success', { records: rowsInserted })}
      />

      <Snackbar
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        open={successInvitation}
        autoHideDuration={3000}
        onClose={handleCloseSnackbar}
        message={t('leads.success_send')}
      />

      <Snackbar
        open={uploadError}
        onClose={handleCloseSnackbar}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        style={{ top: 80 }}
      >
        <Alert
          severity='error'
          style={{ position: 'relative', paddingRight: 46 }}
        >
          {uploadErrorMsg}
          <IconButton
            size='small'
            aria-label='close'
            color='inherit'
            onClick={handleCloseSnackbar}
            style={{
              marginLeft: 10,
              position: 'absolute',
              right: 10,
              top: 10,
            }}
          >
            <CloseIcon fontSize='small' />
          </IconButton>
        </Alert>
      </Snackbar>

      <Snackbar
        open={errorInvitation}
        onClose={handleCloseSnackbar}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        style={{ top: 80 }}
      >
        <Alert
          severity='error'
          style={{ position: 'relative', paddingRight: 46 }}
        >
          {t('leads.error_send')}
          <IconButton
            size='small'
            aria-label='close'
            color='inherit'
            onClick={handleCloseSnackbar}
            style={{
              marginLeft: 10,
              position: 'absolute',
              right: 10,
              top: 10,
            }}
          >
            <CloseIcon fontSize='small' />
          </IconButton>
        </Alert>
      </Snackbar>
    </>
  );
};

export default LeadsDetails;
