import i18n from '../utils/i18n';

export const focusOptionss = () => {
  return [
    {
      label: i18n.t('focus.software'),
      value: 'Software Development',
      checked: false,
    },
    {
      label: i18n.t('focus.product'),
      value: 'Product Development',
      checked: false,
    },
    { label: i18n.t('focus.new'), value: 'New Service', checked: false },
  ];
};

export const industryOptionsEn = [
  { label: 'Agricultural & Environment', value: 'Agricultural & Environment' },
  { label: 'Animals & Care', value: 'Animals & Care' },
  { label: 'Architecture & Design', value: 'Architecture & Design' },
  {
    label: 'Art, Culture & Entertainment',
    value: 'Art, Culture & Entertainment',
  },
  { label: 'Automotive', value: 'Automotive' },
  { label: 'Aviation', value: 'Aviation' },
  { label: 'Banks', value: 'Banks' },
  { label: 'Construction', value: 'Build' },
  { label: 'Business services', value: 'Business services' },
  { label: 'Catering industry', value: 'Catering industry' },
  { label: 'Chemistry, Oil & Energy', value: 'Chemistry, Oil & Energy' },
  { label: 'Defence', value: 'Defence' },
  {
    label: 'Employment Agencies & Recruitment & Selection',
    value: 'Employment Agencies & Recruitment & Selection',
  },
  { label: 'Engineering', value: 'Engineering' },
  { label: 'Facility management', value: 'Facility management' },
  { label: 'Fashion & Styling', value: 'Fashion & Styling' },
  { label: 'Finance', value: 'Finance' },
  { label: 'FMCG', value: 'FMCG' },
  { label: 'Gaming', value: 'Gaming' },
  { label: 'General', value: 'General' },
  {
    label: 'Government & Semi-government',
    value: 'Government & Semi-government',
  },
  { label: 'Healthcare & Pharmacy', value: 'Healthcare & Pharmacy' },
  { label: 'ICT', value: 'ICT' },
  { label: 'Industry & Production', value: 'Industry & Production' },
  { label: 'Legal', value: 'Legal' },
  { label: 'Life Sciences', value: 'Life Sciences' },
  { label: 'Marketing communications', value: 'Marketing communications' },
  { label: 'Media & Journalism', value: 'Media & Journalism' },
  { label: 'Municipality', value: 'Municipality' },
  { label: 'Non-profit', value: 'Non-profit' },
  { label: 'Nutrition & Exercise', value: 'Nutrition & Exercise' },
  { label: 'Real Estate & Brokerage', value: 'Real Estate & Brokerage' },
  { label: 'Sea shipping', value: 'Sea shipping' },
  { label: 'Security', value: 'Security' },
  {
    label: 'Technic Transport & Logistics',
    value: 'Technic Transport & Logistics',
  },
  { label: 'Trade Wholesale & Retail', value: 'Trade Wholesale & Retail' },
  { label: 'Travel & Recreation', value: 'Travel & Recreation' },
];

export const industryOptionsNl = [
  { label: 'Agrarisch & Milieu', value: 'Agricultural & Environment' },
  { label: 'Dieren & Verzorging', value: 'Animals & Care' },
  { label: 'Architectuur & Design', value: 'Architecture & Design' },
  {
    label: 'Kunst, cultuur en entertainment',
    value: 'Art, Culture & Entertainment',
  },
  { label: 'Automotive', value: 'Automotive' },
  { label: 'Luchtvaart', value: 'Aviation' },
  { label: 'Banken', value: 'Banks' },
  { label: 'Bouw', value: 'Build' },
  { label: 'Zakelijke Dienstverlening', value: 'Business services' },
  { label: 'Horeca', value: 'Catering industry' },
  { label: 'Chemie, Olie & Energie', value: 'Chemistry, Oil & Energy' },
  { label: 'Defensie', value: 'Defence' },
  {
    label: 'Uitzendbureaus & Werving & Selectie',
    value: 'Employment Agencies & Recruitment & Selection',
  },
  { label: 'Engineering', value: 'Engineering' },
  { label: 'Facilitaire Dienstverlening', value: 'Facility management' },
  { label: 'Fashion & Styling', value: 'Fashion & Styling' },
  { label: 'Finance', value: 'Finance' },
  { label: 'FMCG', value: 'FMCG' },
  { label: 'Gaming', value: 'Gaming' },
  { label: 'Algemeen', value: 'General' },
  {
    label: 'Overheid & Semi-overheid',
    value: 'Government & Semi-government',
  },
  { label: 'Gezondheidszorg & Farmacie', value: 'Healthcare & Pharmacy' },
  { label: 'ICT', value: 'ICT' },
  { label: 'Industrie & Productie', value: 'Industry & Production' },
  { label: 'Juridisch', value: 'Legal' },
  { label: 'Life Sciences', value: 'Life Sciences' },
  { label: 'Marketing & Communicatie', value: 'Marketing communications' },
  { label: 'Media & Journalistiek', value: 'Media & Journalism' },
  { label: 'Gemeente', value: 'Municipality' },
  { label: 'Non-profit', value: 'Non-profit' },
  { label: 'Voeding & Beweging', value: 'Nutrition & Exercise' },
  { label: 'Vastgoed & Makelaardij', value: 'Real Estate & Brokerage' },
  { label: 'Zeevaart', value: 'Sea shipping' },
  { label: 'Beveiliging', value: 'Security' },
  {
    label: 'Techniek Transport & Logistiek',
    value: 'Technic Transport & Logistics',
  },
  {
    label: 'Handel, Groothandel & Detailhandel',
    value: 'Trade Wholesale & Retail',
  },
  { label: 'Reizen & Recreatie', value: 'Travel & Recreation' },
];

export const trlOptions = [
  { label: 'TRL 1', value: 'Level 1' },
  { label: 'TRL 2', value: 'Level 2' },
  { label: 'TRL 3', value: 'Level 3' },
  { label: 'TRL 4', value: 'Level 4' },
  { label: 'TRL 5', value: 'Level 5' },
  { label: 'TRL 6', value: 'Level 6' },
  { label: 'TRL 7', value: 'Level 7' },
  { label: 'TRL 8', value: 'Level 8' },
  { label: 'TRL 9', value: 'Level 9' },
];

const totalPoints = 8;

export const marksTrl = [
  {
    value: 0,
    label: 'Level 1',
  },
  {
    // value: 100 / totalPoints,
    value: 1,
    label: 'Level 2',
  },
  {
    // value: (100 / totalPoints) * 2,
    value: 2,
    label: 'Level 3',
  },
  {
    // value: (100 / totalPoints) * 3,
    value: 3,
    label: 'Level 4',
  },
  {
    // value: (100 / totalPoints) * 4,
    value: 4,
    label: 'Level 5',
  },
  {
    // value: (100 / totalPoints) * 5,
    value: 5,
    label: 'Level 6',
  },
  {
    // value: (100 / totalPoints) * 6,
    value: 6,
    label: 'Level 7',
  },
  {
    // value: (100 / totalPoints) * 7,
    value: 7,
    label: 'Level 8',
  },
  {
    // value: 100,
    value: 8,
    label: 'Level 9',
  },
];

export const marksIntend = [
  {
    value: 0,
    label: '',
  },
  {
    value: 100 / totalPoints,
    label: '',
  },
  {
    value: (100 / totalPoints) * 2,
    label: '',
  },
  {
    value: (100 / totalPoints) * 3,
    label: '',
  },
  {
    value: (100 / totalPoints) * 4,
    label: '',
  },
  {
    value: (100 / totalPoints) * 5,
    label: '',
  },
  {
    value: (100 / totalPoints) * 6,
    label: '',
  },
  {
    value: (100 / totalPoints) * 7,
    label: '',
  },
  {
    value: (100 / totalPoints) * 8,
    label: '',
  },
  {
    value: (100 / totalPoints) * 9,
    label: '',
  },
  {
    value: (100 / totalPoints) * 10,
    label: '',
  },
  {
    value: (100 / totalPoints) * 11,
    label: '',
  },
  {
    value: (100 / totalPoints) * 12,
    label: '',
  },
  {
    value: (100 / totalPoints) * 13,
    label: '',
  },
  {
    value: (100 / totalPoints) * 14,
    label: '',
  },
  {
    value: (100 / totalPoints) * 15,
    label: '',
  },
  {
    value: (100 / totalPoints) * 16,
    label: '',
  },
  {
    value: (100 / totalPoints) * 17,
    label: '',
  },

  {
    value: 100,
    label: '',
  },
];

export const startDates = [
  '6 Months Ago',
  '5 Months Ago',
  '4 Months Ago',
  '3 Months Ago',
  '2 Months Ago',
  '1 Months Ago',
  'This Month',
  'Next Month',
  'Next 2 Month',
  'Next 3 Month',
  'Next 4 Month',
  'Next 5 Month',
  'Next 6 Month',
  'Next 7 Month',
  'Next 8 Month',
  'Next 9 Month',
  'Next 10 Month',
  'Next 11 Month',
  'Next 12 Month',
];

export const rdHoursOptions = [
  { label: '0-1000', value: '0-1000' },
  { label: '1001-2000', value: '1001-2000' },
  { label: '2001-3000', value: '2001-3000' },
  { label: '3001-4000', value: '3001-4000' },
  { label: '4001-5000', value: '4001-5000' },
  { label: '>5000', value: '>5000' },
];

export const rdCostOptions = [
  { label: '€ 0-25K', value: '0-25K' },
  { label: '€ 25K-50K', value: '25K-50K' },
  { label: '€ 50K-100K', value: '50K-100K' },
  { label: '€ 100K-250K', value: '100K-250K' },
  { label: '> € 250K', value: '>250K' },
];

export const newProductOptionss = () => {
  return [
    { label: i18n.t('new_product.not'), value: 'Is not new' },
    { label: i18n.t('new_product.company'), value: 'New to business' },
    { label: i18n.t('new_product.dutch'), value: 'New for the Dutch market' },
    { label: i18n.t('new_product.europe'), value: 'New to Europe' },
    { label: i18n.t('new_product.world'), value: 'New to the world' },
  ];
};

export const rdActivitiesOptions = [
  { label: 'I do it myself', value: 'I do it myself' },
  { label: 'I work with a partner', value: 'I work with a partner' },
  {
    label: 'I outsource entirely to a partner',
    value: 'I outsource entirely to a partner',
  },
];

export const rdActivitiesOptionss = () => {
  return [
    { label: i18n.t('randd_activities.opt1'), value: 'Inhouse' },
    { label: i18n.t('randd_activities.opt2'), value: 'Partly With Partner' },
    {
      label: i18n.t('randd_activities.opt3'),
      value: 'Entirely by Partner',
    },
  ];
};

export const rdActivitiesOptionsNew = () => {
  return [
    { label: i18n.t('randd_activities.opt1'), value: 'Inhouse' },
    { label: i18n.t('randd_activities.opt2'), value: 'Partly With Partner' },
    {
      label: i18n.t('randd_activities.opt3'),
      value: 'Entirely by Partner',
    },
  ];
};

export const internationalActivitiesEn = [
  { label: 'Demonstration', value: 'Demonstration' },
  { label: 'Feasibility', value: 'Feasibility' },
  { label: 'Marketing', value: 'Marketing' },
  { label: 'Coaching', value: 'Coaching' },
  { label: 'Market research', value: 'Market research' },
  {
    label: 'Setting up new Production/Service Facility',
    value: 'Setting up new Production/Service Facility',
  },
];

export const internationalActivitiesNl = [
  { label: 'Demonstratie', value: 'Demonstration' },
  { label: 'Mogelijkheid', value: 'Feasibility' },
  { label: 'Marketing', value: 'Marketing' },
  { label: 'Coaching', value: 'Coaching' },
  { label: 'Marktonderzoek', value: 'Market research' },
  {
    label: 'Opzetten nieuwe productie/service faciliteit',
    value: 'Setting up new Production/Service Facility',
  },
];

export const activitiesBussinessEn = [
  {
    label:
      'Renovation: insulating, ventilating, heating, cooling, freezing, powering',
    value:
      'Renovation: insulating, ventilating, heating, cooling, freezing, powering',
  },
  {
    label: 'Energy transition: solar panels, windmills, hydropower, etc.',
    value: 'Energy transition: solar panels, windmills, hydropower, etc.',
  },
  {
    label:
      'Energy-efficient operating or transport equipment: transport, means of production, machines',
    value:
      'Energy-efficient operating or transport equipment: transport, means of production, machines',
  },
  {
    label: 'Saving processes: energy, water and gas (re) use and saving',
    value: 'Saving processes: energy, water and gas (re) use and saving',
  },
  {
    label: 'Reducing external nuisance: climate, air and soil pollution',
    value: 'Reducing external nuisance: climate, air and soil pollution',
  },
  {
    label: 'Savings in terms of food supply and agricultural production',
    value: 'Savings in terms of food supply and agricultural production',
  },
];
export const activitiesBussinessNl = [
  {
    label:
      'Verbouwing: isoleren, ventileren, verwarmen, koelen, vriezen, aandrijven',
    value:
      'Renovation: insulating, ventilating, heating, cooling, freezing, powering',
  },
  {
    label: 'Energietransitie: zonnepanelen, windmolens, waterkracht, etc.',
    value: 'Energy transition: solar panels, windmills, hydropower, etc.',
  },
  {
    label:
      'Energiezuinige bedrijfs- of transportmiddelen: transportmiddelen, productiemiddelen, machines',
    value:
      'Energy-efficient operating or transport equipment: transport, means of production, machines',
  },
  {
    label:
      'Besparende processen: energie-, water- en gasbesparing / hergebruik',
    value: 'Saving processes: energy, water and gas (re) use and saving',
  },
  {
    label:
      'Terugdringen externe overlast: klimaat-, lucht- en bodemverontreiniging',
    value: 'Reducing external nuisance: climate, air and soil pollution',
  },
  {
    label:
      'Besparing op het gebied van voedselvoorziening en landbouwproductie',
    value: 'Savings in terms of food supply and agricultural production',
  },
];

export const themeOptionsEn = [
  { label: 'Culture', value: 'Culture' },
  {
    label: 'Social cohesion, quality of life',
    value: 'Social cohesion, quality of life',
  },
  { label: 'Social-societal', value: 'Social-societal' },
  { label: 'Sustainability and energy', value: 'Sustainability and energy' },
  { label: 'Heritage', value: 'Heritage' },
  {
    label: 'Labor market and reintegration',
    value: 'Labor market and reintegration',
  },
  { label: 'Daytime activities', value: 'Daytime activities' },
  { label: 'Health and vitality', value: 'Health and vitality' },
  { label: 'Sport', value: 'Sport' },
  { label: 'Living', value: 'Living' },
  { label: 'Civic integration', value: 'Civic integration' },
  { label: 'Others', value: 'Others' },
];

export const themeOptionsNl = [
  { label: 'Cultuur', value: 'Culture' },
  {
    label: 'Sociale cohesie, leefbaarheid',
    value: 'Social cohesion, quality of life',
  },
  { label: 'Sociaal-maatschappelijk', value: 'Social-societal' },
  { label: 'Duurzaamheid en energie', value: 'Sustainability and energy' },
  { label: 'Erfgoed', value: 'Heritage' },
  {
    label: 'Arbeidsmarkt en reïntegratie',
    value: 'Labor market and reintegration',
  },
  { label: 'Dagbesteding', value: 'Daytime activities' },
  { label: 'Gezondheid en vitaliteit', value: 'Health and vitality' },
  { label: 'Sport', value: 'Sport' },
  { label: 'Wonen', value: 'Living' },
  { label: 'Inburgering', value: 'Civic integration' },
  { label: 'Anders', value: 'Others' },
];

export const targetOptionsEn = [
  { label: 'Elderly or demented people', value: 'Elderly or demented people' },
  {
    label: 'People with a physical disability',
    value: 'People with a physical disability',
  },
  {
    label: 'Mentally challenged peopled',
    value: 'Mentally challenged peopled',
  },
  {
    label: 'People with multiple disabilities',
    value: 'People with multiple disabilities',
  },
  { label: 'Children', value: 'Children' },
  { label: 'Young people', value: 'Young people' },
  { label: 'Families with poverty', value: 'Families with poverty' },
  { label: 'Others', value: 'Others' },
];

export const targetOptionsNl = [
  { label: 'Ouderen of dementerenden', value: 'Elderly or demented people' },
  {
    label: 'Mensen met een lichamelijke beperking',
    value: 'People with a physical disability',
  },
  {
    label: 'Mensen met een verstandelijke beperking',
    value: 'Mentally challenged peopled',
  },
  {
    label: 'Mensen met een meervoudige beperking',
    value: 'People with multiple disabilities',
  },
  { label: 'Kinderen', value: 'Children' },
  { label: 'Jongeren', value: 'Young people' },
  { label: 'Gezinnen met armoede', value: 'Families with poverty' },
  { label: 'Anders', value: 'Others' },
];

export const yesNoOptions = [
  { label: i18n.t('yes'), value: 'Yes' },
  { label: i18n.t('no'), value: 'No' },
];

export const countryList = [
  { value: 'Afghanistan', label: 'Afghanistan' },
  { value: 'Albania', label: 'Albania' },
  { value: 'Algeria', label: 'Algeria' },
  { value: 'Andorra', label: 'Andorra' },
  { value: 'Angola', label: 'Angola' },
  { value: 'Antigua and Barbuda', label: 'Antigua and Barbuda' },
  { value: 'Argentina', label: 'Argentina' },
  { value: 'Armenia', label: 'Armenia' },
  { value: 'Australia', label: 'Australia' },
  { value: 'Austria', label: 'Austria' },
  { value: 'Azerbaijan', label: 'Azerbaijan' },
  { value: 'Bahrain', label: 'Bahrain' },
  { value: 'Bangladesh', label: 'Bangladesh' },
  { value: 'Barbados', label: 'Barbados' },
  { value: 'Belarus', label: 'Belarus' },
  { value: 'Belgium', label: 'Belgium' },
  { value: 'Belize', label: 'Belize' },
  { value: 'Benin', label: 'Benin' },
  { value: 'Bhutan', label: 'Bhutan' },
  { value: 'Bolivia', label: 'Bolivia' },
  { value: 'Bosnia and Herzegovina', label: 'Bosnia and Herzegovina' },
  { value: 'Botswana', label: 'Botswana' },
  { value: 'Brazil', label: 'Brazil' },
  { value: 'Brunei', label: 'Brunei' },
  { value: 'Bulgaria', label: 'Bulgaria' },
  { value: 'Burkina Faso', label: 'Burkina Faso' },
  { value: 'Burundi', label: 'Burundi' },
  { value: 'Cabo Verde', label: 'Cabo Verde' },
  { value: 'Cambodia', label: 'Cambodia' },
  { value: 'Cameroon', label: 'Cameroon' },
  { value: 'Canada', label: 'Canada' },

  { value: 'Central African Republic', label: 'Central African Republic' },
  { value: 'Chad', label: 'Chad' },
  { value: 'Chile', label: 'Chile' },
  { value: 'China', label: 'China' },
  { value: 'Colombia', label: 'Colombia' },
  { value: 'Comoros', label: 'Comoros' },
  { value: 'Congo, Republic of the', label: 'Congo, Republic of the' },
  {
    value: 'Congo, Democratic Republic of the',
    label: 'Congo, Democratic Republic of the',
  },
  { value: 'Costa Rica', label: 'Costa Rica' },
  { value: "Côte d'Ivoire", label: "Côte d'Ivoire" },
  { value: 'Croatia', label: 'Croatia' },
  { value: 'Cuba', label: 'Cuba' },
  { value: 'Cyprus', label: 'Cyprus' },
  { value: 'Czech Republic', label: 'Czech Republic' },
  { value: 'Denmark', label: 'Denmark' },
  { value: 'Djibouti', label: 'Djibouti' },
  { value: 'Dominica', label: 'Dominica' },
  { value: 'Dominican Republic', label: 'Dominican Republic' },
  { value: 'East Timor (Timor-Leste)', label: 'East Timor (Timor-Leste)' },
  { value: 'Ecuador', label: 'Ecuador' },
  { value: 'Egypt', label: 'Egypt' },
  { value: 'El Salvador', label: 'El Salvador' },
  { value: 'Equatorial Guinea', label: 'Equatorial Guinea' },
  { value: 'Eritrea', label: 'Eritrea' },
  { value: 'Estonia', label: 'Estonia' },
  { value: 'Ethiopia', label: 'Ethiopia' },
  { value: 'Fiji', label: 'Fiji' },
  { value: 'Finland', label: 'Finland' },
  { value: 'France', label: 'France' },
  { value: 'Gabon', label: 'Gabon' },
  { value: 'Georgia', label: 'Georgia' },
  { value: 'Germany', label: 'Germany' },
  { value: 'Ghana', label: 'Ghana' },
  { value: 'Greece', label: 'Greece' },
  { value: 'Grenada', label: 'Grenada' },
  { value: 'Guatemala', label: 'Guatemala' },
  { value: 'Guinea', label: 'Guinea' },
  { value: 'Guinea-Bissau', label: 'Guinea-Bissau' },
  { value: 'Guyana', label: 'Guyana' },
  { value: 'Haiti', label: 'Haiti' },
  { value: 'Honduras', label: 'Honduras' },
  { value: 'Hungary', label: 'Hungary' },
  { value: 'Iceland', label: 'Iceland' },
  { value: 'India', label: 'India' },
  { value: 'Indonesia', label: 'Indonesia' },
  { value: 'Iran', label: 'Iran' },
  { value: 'Iraq', label: 'Iraq' },
  { value: 'Ireland', label: 'Ireland' },
  { value: 'Israel', label: 'Israel' },
  { value: 'Italy', label: 'Italy' },
  { value: 'Jamaica', label: 'Jamaica' },
  { value: 'Japan', label: 'Japan' },
  { value: 'Jordan', label: 'Jordan' },
  { value: 'Kazakhstan', label: 'Kazakhstan' },
  { value: 'Kenya', label: 'Kenya' },
  { value: 'Kiribati', label: 'Kiribati' },
  { value: 'Korea, North', label: 'Korea, North' },
  { value: 'Korea, South', label: 'Korea, South' },
  { value: 'Kosovo', label: 'Kosovo' },
  { value: 'Kuwait', label: 'Kuwait' },
  { value: 'Kyrgyzstan', label: 'Kyrgyzstan' },
  { value: 'Laos', label: 'Laos' },
  { value: 'Latvia', label: 'Latvia' },
  { value: 'Lebanon', label: 'Lebanon' },
  { value: 'Lesotho', label: 'Lesotho' },
  { value: 'Liberia', label: 'Liberia' },
  { value: 'Libya', label: 'Libya' },
  { value: 'Liechtenstein', label: 'Liechtenstein' },
  { value: 'Lithuania', label: 'Lithuania' },
  { value: 'Luxembourg', label: 'Luxembourg' },
  { value: 'Macedonia', label: 'Macedonia' },
  { value: 'Madagascar', label: 'Madagascar' },
  { value: 'Malawi', label: 'Malawi' },
  { value: 'Malaysia', label: 'Malaysia' },
  { value: 'Maldives', label: 'Maldives' },
  { value: 'Mali', label: 'Mali' },
  { value: 'Malta', label: 'Malta' },
  { value: 'Marshall Islands', label: 'Marshall Islands' },
  { value: 'Mauritania', label: 'Mauritania' },
  { value: 'Mauritius', label: 'Mauritius' },
  { value: 'Mexico', label: 'Mexico' },
  {
    value: 'Micronesia, Federated States of',
    label: 'Micronesia, Federated States of',
  },
  { value: 'Moldova', label: 'Moldova' },
  { value: 'Monaco', label: 'Monaco' },
  { value: 'Mongolia', label: 'Mongolia' },
  { value: 'Montenegro', label: 'Montenegro' },
  { value: 'Morocco', label: 'Morocco' },
  { value: 'Mozambique', label: 'Mozambique' },
  { value: 'Myanmar (Burma)', label: 'Myanmar (Burma)' },
  { value: 'Namibia', label: 'Namibia' },
  { value: 'NauruNauru', label: 'Nauru' },
  { value: 'Nepal', label: 'Nepal' },
  { value: 'Netherlands', label: 'Netherlands' },
  { value: 'New Zealand', label: 'New Zealand' },
  { value: 'Nicaragua', label: 'Nicaragua' },
  { value: 'Niger', label: 'Niger' },
  { value: 'Nigeria', label: 'Nigeria' },
  { value: 'Norway', label: 'Norway' },
  { value: 'Oman', label: 'Oman' },
  { value: 'Pakistan', label: 'Pakistan' },
  { value: 'Palau', label: 'Palau' },
  { value: 'Panama', label: 'Panama' },
  { value: 'Papua New Guinea', label: 'Papua New Guinea' },
  { value: 'Paraguay', label: 'Paraguay' },
  { value: 'Peru', label: 'Peru' },
  { value: 'Philippines', label: 'Philippines' },
  { value: 'Poland', label: 'Poland' },
  { value: 'Portugal', label: 'Portugal' },
  { value: 'Qatar', label: 'Qatar' },
  { value: 'Romania', label: 'Romania' },
  { value: 'Russia', label: 'Russia' },
  { value: 'Rwanda', label: 'Rwanda' },
  { value: 'Saint Kitts and Nevis', label: 'Saint Kitts and Nevis' },
  { value: 'Saint Lucia', label: 'Saint Lucia' },
  {
    value: 'Saint Vincent and the Grenadines',
    label: 'Saint Vincent and the Grenadines',
  },
  { value: 'Samoa', label: 'Samoa' },
  { value: 'San MarinoSan Marino', label: 'San Marino' },
  { value: 'Sao Tome and Principe', label: 'Sao Tome and Principe' },
  { value: 'Saudi Arabia', label: 'Saudi Arabia' },
  { value: 'Senegal', label: 'Senegal' },
  { value: 'Serbia', label: 'Serbia' },
  { value: 'Seychelles', label: 'Seychelles' },
  { value: 'Sierra Leone', label: 'Sierra Leone' },
  { value: 'Singapore', label: 'Singapore' },
  { value: 'Slovakia', label: 'Slovakia' },
  { value: 'Slovenia', label: 'Slovenia' },
  { value: 'Solomon Islands', label: 'Solomon Islands' },
  { value: 'Somalia', label: 'Somalia' },
  { value: 'South Africa', label: 'South Africa' },
  { value: 'Spain', label: 'Spain' },
  { value: 'Sri Lanka', label: 'Sri Lanka' },
  { value: 'Sudan', label: 'Sudan' },
  { value: 'Sudan, South', label: 'Sudan, South' },
  { value: 'Suriname', label: 'Suriname' },
  { value: 'Swaziland', label: 'Swaziland' },
  { value: 'Sweden', label: 'Sweden' },
  { value: 'Switzerland', label: 'Switzerland' },
  { value: 'Syria', label: 'Syria' },
  { value: 'Taiwan', label: 'Taiwan' },
  { value: 'Tajikistan', label: 'Tajikistan' },
  { value: 'Tanzania', label: 'Tanzania' },
  { value: 'Thailand', label: 'Thailand' },
  { value: 'The Bahamas', label: 'The Bahamas' },
  { value: 'The Gambia', label: 'The Gambia' },
  { value: 'Togo', label: 'Togo' },
  { value: 'Tonga', label: 'Tonga' },
  { value: 'Trinidad and Tobago', label: 'Trinidad and Tobago' },
  { value: 'Tunisia', label: 'Tunisia' },
  { value: 'Turkey', label: 'Turkey' },
  { value: 'Turkmenistan', label: 'Turkmenistan' },
  { value: 'Tuvalu', label: 'Tuvalu' },
  { value: 'Uganda', label: 'Uganda' },
  { value: 'Ukraine', label: 'Ukraine' },
  { value: 'United Arab Emirates', label: 'United Arab Emirates' },
  { value: 'United Kingdom', label: 'United Kingdom' },
  { value: 'United States', label: 'United States' },
  { value: 'Uruguay', label: 'Uruguay' },
  { value: 'Uzbekistan', label: 'Uzbekistan' },
  { value: 'Vanuatu', label: 'Vanuatu' },
  { value: 'Vatican City', label: 'Vatican City' },
  { value: 'Venezuela', label: 'Venezuela' },
  { value: 'Vietnam', label: 'Vietnam' },
  { value: 'Yemen', label: 'Yemen' },
  { value: 'Zambia', label: 'Zambia' },
  { value: 'Zimbabwe', label: 'Zimbabwe' },
];

export const subSectorOptionsEn = [
  { label: 'Maritime technology', value: 'Maritime technology' },
  { label: 'Delta technology', value: 'Delta technology' },
  { label: 'Water technology', value: 'Water technology' },
  { label: 'Healthy and safe food', value: 'Healthy and safe food' },
  {
    label: 'Climate-proof rural and urban area',
    value: 'Climate-proof rural and urban area',
  },
  {
    label: 'Climate neutral agriculture and food production',
    value: 'Climate neutral agriculture and food production',
  },
  { label: 'Circularity & climate', value: 'Circularity & climate' },
  {
    label: 'Chemistry in the food chain',
    value: 'Chemistry in the food chain',
  },
  { label: 'Chemistry & Mobility', value: 'Chemistry & Mobility' },
  {
    label: 'Knowledge within the health sector',
    value: 'Knowledge within the health sector',
  },
  {
    label: 'Design of new designs/processes',
    value: 'Design of new designs/processes',
  },
  {
    label: 'Innovative and inclusive society',
    value: 'Innovative and inclusive society',
  },
  { label: 'Wind at sea', value: 'Wind at sea' },
  { label: 'New gas', value: 'New gas' },
  { label: 'Urban energy', value: 'Urban energy' },
  { label: 'Industry', value: 'Industry' },
  { label: 'Bio-based', value: 'Bio-based' },
  { label: 'System integration', value: 'System integration' },
  { label: 'Digitization', value: 'Digitization' },
  { label: 'Health gain for patients', value: 'Health gain for patients' },
  {
    label: 'Care in the living environment',
    value: 'Care in the living environment',
  },
  { label: 'Chronic diseases', value: 'Chronic diseases' },
  {
    label: 'Quality of life for people with dementia',
    value: 'Quality of life for people with dementia',
  },
  {
    label: 'Reduction of the costs of care',
    value: 'Reduction of the costs of care',
  },
  {
    label: 'Improving detection or treatment',
    value: 'Improving detection or treatment',
  },
  {
    label: 'Prevention of diseases or disorders',
    value: 'Prevention of diseases or disorders',
  },
  { label: 'Data-driven', value: 'Data-driven' },
  { label: 'Internationalization', value: 'Internationalization' },
  { label: 'Sustainability', value: 'Sustainability' },
  { label: 'Chain management', value: 'Chain management' },
  { label: 'Human capital', value: 'Human capital' },
  { label: 'Climate & Sustainability', value: 'Climate & Sustainability' },
  { label: 'Automotive', value: 'Automotive' },
  { label: 'Semiconductor machines', value: 'Semiconductor machines' },
  { label: 'Print technology', value: 'Print technology' },
  { label: 'Electronics', value: 'Electronics' },
  { label: 'High Tech materials', value: 'High Tech materials' },
  { label: 'Space travel', value: 'Space travel' },
  { label: 'Photonics', value: 'Photonics' },
  { label: 'Light technology', value: 'Light technology' },
  { label: 'Smart Industry', value: 'Smart Industry' },
  {
    label: 'Advanced measuring equipment',
    value: 'Advanced measuring equipment',
  },
  { label: 'Aviation', value: 'Aviation' },
  { label: 'System Engineering', value: 'System Engineering' },
  { label: 'Health technology', value: 'Health technology' },
  { label: 'Nano technology', value: 'Nano technology' },
  { label: 'Safety technology', value: 'Safety technology' },
  { label: 'Artificial Intelligence', value: 'Artificial Intelligence' },
  { label: 'Big data', value: 'Big data' },
  { label: 'Blockchain', value: 'Blockchain' },
  { label: 'Cyber security', value: 'Cyber security' },
  { label: 'Future network services', value: 'Future network services' },
  { label: 'Others', value: 'Others' },
];

export const subSectorOptionsNl = [
  { label: 'Maritieme technologie', value: 'Maritime technology' },
  { label: 'Deltatechnologie', value: 'Delta technology' },
  { label: 'Watertechnologie', value: 'Water technology' },
  { label: 'Gezond en veilig voedsel', value: 'Healthy and safe food' },
  {
    label: 'Klimaatbestendig landelijk en stedelijk gebied',
    value: 'Climate-proof rural and urban area',
  },
  {
    label: 'Klimaatneutrale landbouw en voedselproductie',
    value: 'Climate neutral agriculture and food production',
  },
  { label: 'Circulariteit & klimaat', value: 'Circularity & climate' },
  {
    label: 'Chemie in de voedselketen',
    value: 'Chemistry in the food chain',
  },
  { label: 'Chemie & mobiliteit', value: 'Chemistry & Mobility' },
  {
    label: 'Kennis binnen de healt sector',
    value: 'Knowledge within the health sector',
  },
  {
    label: 'Ontwerp van nieuwe designs/processen',
    value: 'Design of new designs/processes',
  },
  {
    label: 'Innovatieve en inclusieve samenleving',
    value: 'Innovative and inclusive society',
  },
  { label: 'Wind op zee', value: 'Wind at sea' },
  { label: 'Nieuw gas', value: 'New gas' },
  { label: 'Urban energy', value: 'Urban energy' },
  { label: 'Industrie', value: 'Industry' },
  { label: 'Bio-based', value: 'Bio-based' },
  { label: 'Systeemintegratie', value: 'System integration' },
  { label: 'Digitalisering', value: 'Digitization' },
  {
    label: 'Gezondheidswinst voor patiënten',
    value: 'Health gain for patients',
  },
  {
    label: 'Zorg in de leefomgeving',
    value: 'Care in the living environment',
  },
  { label: 'Chronische ziekten', value: 'Chronic diseases' },
  {
    label: 'Kwaliteit van leven voor mensen met dementie',
    value: 'Quality of life for people with dementia',
  },
  {
    label: 'Verlaging van de kosten van de zorg',
    value: 'Reduction of the costs of care',
  },
  {
    label: 'Verbetering van de opsporing of behandeling',
    value: 'Improving detection or treatment',
  },
  {
    label: 'Preventie van ziektes of aandoeningen',
    value: 'Prevention of diseases or disorders',
  },
  { label: 'Datagedreven', value: 'Data-driven' },
  { label: 'Internationalisering', value: 'Internationalization' },
  { label: 'Duurzaamheid', value: 'Sustainability' },
  { label: 'Ketenregie', value: 'Chain management' },
  { label: 'Human capital', value: 'Human capital' },
  { label: 'Klimaat & Duurzaamheid', value: 'Climate & Sustainability' },
  { label: 'Automotive', value: 'Automotive' },
  { label: 'Halfgeleidermachines', value: 'Semiconductor machines' },
  { label: 'Printtechnologie', value: 'Print technology' },
  { label: 'Electronica', value: 'Electronics' },
  { label: 'High Tech Materialen', value: 'High Tech materials' },
  { label: 'Ruimtevaart', value: 'Space travel' },
  { label: 'Fotonica', value: 'Photonics' },
  { label: 'Lichttechnologie', value: 'Light technology' },
  { label: 'Smart Industry', value: 'Smart Industry' },
  {
    label: 'Geavanceerde meetapparatuur',
    value: 'Advanced measuring equipment',
  },
  { label: 'Luchtvaart', value: 'Aviation' },
  { label: 'Systeem Engineering', value: 'System Engineering' },
  { label: 'Gezondheidstechnologie', value: 'Health technology' },
  { label: 'Nanotechnologie', value: 'Nano technology' },
  { label: 'Veiligheidstechnologie', value: 'Safety technology' },
  { label: 'Artificial Intelligence', value: 'Artificial Intelligence' },
  { label: 'Big data', value: 'Big data' },
  { label: 'Blockchain', value: 'Blockchain' },
  { label: 'Cyber security', value: 'Cyber security' },
  { label: 'Future network services', value: 'Future network services' },
  { label: 'Overige', value: 'Others' },
];

export const sectorOptionsNl = [
  { label: 'Agri & Food', value: 'Agriculture and Food' },
  { label: 'Hightech Systemen en Materialen', value: 'HTSM' },
  { label: 'Energie', value: 'Energy' },
  { label: 'Water & Maritiem', value: 'Water' },
  { label: 'Informatie en communicatie technologie', value: 'ICT' },
  { label: 'Logistiek', value: 'Logistics' },
  { label: 'Life Sciences & Health', value: 'Life Sciences & Health' },
  { label: 'Creatieve Industrie', value: 'Creative industries' },
  { label: 'Chemische Industrie', value: 'Chemical industry' },
  {
    label: 'Tuinbouw en uitgangsmaterialen',
    value: 'Horticulture and starting materials',
  },
  { label: 'Overige', value: 'Others' },
];

export const sectorOptionsEn = [
  { label: 'Agriculture and Food', value: 'Agriculture and Food' },
  { label: 'HTSM', value: 'HTSM' },
  { label: 'Energy', value: 'Energy' },
  { label: 'Water & Maritime', value: 'Water' },
  { label: 'Information, communication & technology', value: 'ICT' },
  { label: 'Logistics', value: 'Logistics' },
  { label: 'Life Sciences & Health', value: 'Life Sciences & Health' },
  { label: 'Creative industries', value: 'Creative industries' },
  { label: 'Chemical industry', value: 'Chemical industry' },
  {
    label: 'Horticulture and starting materials',
    value: 'Horticulture and starting materials',
  },
  { label: 'Others', value: 'Others' },
];

export const subSectorsMap = {
  'Agriculture and Food': [
    'Healthy and safe food',
    'Climate-proof rural and urban area',
    'Climate neutral agriculture and food production',
    'Others',
  ],
  HTSM: [
    'Climate & Sustainability',
    'Automotive',
    'Semiconductor machines',
    'Print technology',
    'Electronics',
    'High Tech materials',
    'Space travel',
    'Photonics',
    'Light technology',
    'Smart Industry',
    'Advanced measuring equipment',
    'Aviation',
    'System Engineering',
    'Health technology',
    'Nano technology',
    'Safety technology',
    'Others',
  ],
  Energy: [
    'Wind at sea',
    'New gas',
    'Urban energy',
    'Industry',
    'Bio-based',
    'System integration',
    'Digitization',
    'Others',
  ],
  Water: ['Maritime technology', 'Delta technology', 'Water technology'],
  ICT: [
    'Artificial Intelligence',
    'Big data',
    'Blockchain',
    'Cyber security',
    'Future network services',
    'Others',
  ],
  Logistics: [
    'Data-driven',
    'Internationalization',
    'Sustainability',
    'Chain management',
    'Human capital',
    'Others',
  ],
  'Life Sciences & Health': [
    'Health gain for patients',
    'Care in the living environment',
    'Chronic diseases',
    'Quality of life for people with dementia',
    'Reduction of the costs of care',
    'Improving detection or treatment',
    'Prevention of diseases or disorders',
    'Others',
  ],
  'Creative industries': [
    'Design of new designs/processes',
    'Innovative and inclusive society',
    'Others',
  ],
  'Chemical industry': [
    'Circularity & climate',
    'Chemistry in the food chain',
    'Chemistry & Mobility',
    'Knowledge within the health sector',
    'Others',
  ],
  'Horticulture and starting materials': [
    'Healthy and safe food',
    'Climate-proof rural and urban area',
    'Climate neutral agriculture and food production',
    'Others',
  ],
  Others: [
    'Healthy and safe food',
    'Climate-proof rural and urban area',
    'Climate neutral agriculture and food production',
    'Climate & Sustainability',
    'Automotive',
    'Semiconductor machines',
    'Print technology',
    'Electronics',
    'High Tech materials',
    'Space travel',
    'Photonics',
    'Light technology',
    'Smart Industry',
    'Advanced measuring equipment',
    'Aviation',
    'System Engineering',
    'Health technology',
    'Nano technology',
    'Safety technology',
    'Wind at sea',
    'New gas',
    'Urban energy',
    'Industry',
    'Bio-based',
    'System integration',
    'Digitization',
    'Maritime technology',
    'Delta technology',
    'Water technology',
    'Artificial Intelligence',
    'Big data',
    'Blockchain',
    'Cyber security',
    'Future network services',
    'Data-driven',
    'Internationalization',
    'Sustainability',
    'Chain management',
    'Human capital',
    'Health gain for patients',
    'Care in the living environment',
    'Chronic diseases',
    'Quality of life for people with dementia',
    'Reduction of the costs of care',
    'Improving detection or treatment',
    'Prevention of diseases or disorders',
    'Design of new designs/processes',
    'Innovative and inclusive society',
    'Circularity & climate',
    'Chemistry in the food chain',
    'Chemistry & Mobility',
    'Knowledge within the health sector',
    'Healthy and safe food',
    'Climate-proof rural and urban area',
    'Climate neutral agriculture and food production',
    'Others',
  ],
};
