import React, { useState, useEffect, useContext } from 'react';
import { useStyles } from './styles';
import { useTheme } from '@mui/material/styles';
import { useMediaQuery } from 'react-responsive';
import { Link, useHistory, withRouter, useLocation } from 'react-router-dom';
import NoResultsFound from '../Applications/NoResultsFound';
import {
  Grid,
  Typography,
  Divider,
  Card,
  CardContent,
  CardActions,
  Button,
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Stack,
  Tooltip,
  IconButton,
  Snackbar,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Dialog,
  Autocomplete,
  TextField,
  CircularProgress,
  Slide,
  AppBar,
  Toolbar,
  RadioGroup,
  FormControlLabel,
  Radio,
  FormControl,
  Alert,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { ReactComponent as Startup } from '../../assets/StartupTwoColor.svg';
import { UserContext } from '../../contexts/UserContext';
import { NewProjectContext } from '../../contexts/NewProject';
import TelegramIcon from '@mui/icons-material/Telegram';
import AddIcon from '@mui/icons-material/Add';
import Loader from '../../uiComponents/Loader';
import axios from 'axios';
import CloseIcon from '@mui/icons-material/Close';
import useDebounce from '../debounce';
import SearchIcon from '@mui/icons-material/Search';
import WebViewer from '@pdftron/pdfjs-express-viewer';
import {
  sectorOptionsEn,
  sectorOptionsNl,
  subSectorOptionsEn,
  subSectorOptionsNl,
  subSectorsMap,
} from '../../data/projectOptions';
import { scanmkbOptionss } from '../../data/picklistOptions';
import i18n from 'i18next';
import SendIcon from '@mui/icons-material/Send';
import AddContact from '../../uiComponents/AddContact';
import LeadDialog from '../../uiComponents/LeadDialog';
import moment from 'moment';
import AccountDetailsButtonGroup from '../../uiComponents/AccountDetailsButtonGroup';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction='up' ref={ref} {...props} />;
});

const AccountDetails = () => {
  const classes = useStyles();
  const mkbOptions = scanmkbOptionss();
  const [sme, setSme] = useState(null);
  const [openAddContactDialog, setOpenAddContactDialog] = useState(false);
  const [sectors, setSectors] = useState([]);
  const [subSectors, setSubSectors] = useState([]);
  const [showError, setShowError] = useState(false);
  const [showSuccess, setShowSuccess] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  const [subSectorDependentOptions, setSubSectorDependentOptions] = useState(
    []
  );
  const [contact, setContact] = React.useState([]);
  const [contacts, setContacts] = React.useState([]);
  const [openPreview, setOpenPreview] = React.useState(false);
  const viewer = React.useRef(null);
  const [openPreviewSentScan, setOpenPreviewSentScan] = React.useState(false);
  const [openPreviewBenchmarkReport, setOpenPreviewBenchmarkReport] =
    React.useState(false);
  const viewerSentScan = React.useRef(null);
  const viewerBenchmarkReport = React.useRef(null);
  const theme = useTheme();
  const isLargeScreen = useMediaQuery({ query: '(min-width: 1280px)' });
  let location = useLocation();
  const history = useHistory();
  const { t } = useTranslation();
  const [userContext] = useContext(UserContext);
  const { setAccountId, setNewProjectAccount } = useContext(NewProjectContext);
  const [loading, setLoading] = useState(false);
  const [rows, setRows] = useState([]);
  const [account, setAccount] = useState({});
  const [users, setUsers] = useState([]);
  const [subsidyScans, setSubsidyScans] = useState([]);
  const [associatedAccounts, setAssociatedAccounts] = useState([]);
  const [successInvitation, setSuccessInvitation] = useState(false);
  const [openSendDialog, setOpenSendDialog] = useState(false);
  const [openSendDialogBenchmarkReport, setOpenSendDialogBenchmarkReport] =
    useState(false);
  const [openSearchAccount, setOpenSearchAccount] = useState(false);
  const [
    openSearchBenchmarkReportAccount,
    setOpenSearchBenchmarkReportAccount,
  ] = useState(false);
  const [open, setOpen] = useState(false);
  const [searchString, setSearchString] = useState('');
  const [companyOptions, setCompanyOptions] = useState([]);
  const [isSearching, setIsSearching] = useState(false);
  const debouncedSearchTerm = useDebounce(searchString, 500);
  const [newLead, setNewLead] = useState({
    company: {
      name: '',
      dossier_number: '',
      establishment_number: '',
      establishment_city: '',
      establishment_street: '',
      correspondence_city: '',
      correspondence_street: '',
      sme: '',
      keySectors: '',
      subSectors: '',
    },
  });

  const [newContact, setNewContact] = useState({
    firstName: '',
    lastName: '',
    email: '',
  });
  const [locale, setLocale] = useState('en');
  const selectLocale = React.useCallback((locale) => {
    setLocale(i18n.languages[0]);
  }, []);
  useEffect(() => {
    selectLocale();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    let subSectorOptions =
      i18n.languages[0] === 'nl' ? subSectorOptionsNl : subSectorOptionsEn;
    let dependentSubSectorsOptions = [];
    let subSectorOptionsValues = new Set();
    let sectorValues = sectors.map((a) => a.value);
    sectorValues.forEach((item) => {
      subSectorsMap[item].forEach((item) => {
        subSectorOptionsValues.add(item);
      });
    });
    subSectorOptions.forEach((subSector) => {
      subSectorOptionsValues.forEach((subSectorValue) => {
        if (subSector.value === subSectorValue) {
          dependentSubSectorsOptions.push(subSector);
        }
      });
    });
    let subSectorValues = subSectors.map((a) => a.value);
    let dependentSubSectorsValues = [];
    subSectorValues.forEach((item) => {
      if (dependentSubSectorsOptions) {
        dependentSubSectorsValues = dependentSubSectorsOptions.filter(
          (opt) => subSectorValues.indexOf(opt.value) !== -1
        );
      }
    });
    setSubSectors(dependentSubSectorsValues);
    setSubSectorDependentOptions(dependentSubSectorsOptions);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sectors]);

  const [sbiCodeSubsidies, setSbiCodeSubsidies] = useState([]);
  const [openDownloadSubsidyScanDialog, setOpenDownloadSubsidyScanDialog] =
    useState(false);

  const handleSelectCompany = (company) => {
    setNewLead((oldValues) => {
      return {
        ...oldValues,
        company: company,
      };
    });
  };

  const handleCancelNewLead = () => {
    setNewLead({
      company: {
        name: '',
        dossier_number: '',
        establishment_number: '',
        establishment_city: '',
        establishment_street: '',
        correspondence_city: '',
        correspondence_street: '',
        sme: '',
        keySectors: '',
        subSectos: '',
      },
    });
    setOpenSearchAccount(false);
  };

  const handleCancelNewLeadBenchmarkReport = () => {
    setNewLead({
      company: {
        name: '',
        dossier_number: '',
        establishment_number: '',
        establishment_city: '',
        establishment_street: '',
        correspondence_city: '',
        correspondence_street: '',
        sme: '',
        keySectors: '',
        subSectos: '',
      },
    });
    setOpenSearchBenchmarkReportAccount(false);
  };

  const handleCloseSnackbar = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setSuccessInvitation(false);
    setShowSuccess(false);
    setShowError(false);
  };

  function createData(ProjectId, ProjectName, ProjectType, ProjectAccountId) {
    return {
      ProjectId,
      ProjectName,
      ProjectType,
      ProjectAccountId,
    };
  }

  const labelTypes = {
    '0123X000001GJUGQA4': t('choose_project.type1'),
    '0123X000001GJUCQA4': t('choose_project.type2'),
    '0123X000001GJUDQA4': t('choose_project.type3'),
    '0123X000001GJUEQA4': t('choose_project.type4'),
    '0123X000001GJUFQA4': t('choose_project.type5'),
  };

  const handleSendInvitation = (email) => {
    const apiEndPoint =
      process.env.NODE_ENV === 'development'
        ? process.env.REACT_APP_API_ENDPOINT
        : process.env.REACT_APP_API_ENDPOINT_PROD;
    try {
      setLoading(true);
      axios({
        method: 'post',
        url: apiEndPoint + '/v1/partner/invite/sendReferralLinkToPlatform',
        withCredentials: true,
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
          Authorization: `Bearer ${userContext.token}`,
        },
        data: {
          email: email,
        },
      })
        .then((res) => {
          setSuccessInvitation(true);
          setLoading(false);
        })
        .catch((error) => {
          setLoading(false);
        });
    } catch (error) {
      console.log(error);
    }
  };

  const handleGenerateSubsidyScan = () => {
    setOpenSearchAccount(true);
  };

  const handleGenerateBenchmarkReport = () => {
    if (
      account.registrationNumber !== '' &&
      account.registrationNumber !== null &&
      account.registrationNumber !== undefined
    ) {
      handleGenerateBenchmarkReportPdf();
    } else {
      setOpenSearchBenchmarkReportAccount(true);
    }
  };

  const handleGenerateSubsidyScanwWithoutKVK = () => {
    let sectorVals = sectors.map((a) => a.value);
    let subSectorVals = subSectors.map((a) => a.value);
    const apiEndPoint =
      process.env.NODE_ENV === 'development'
        ? process.env.REACT_APP_API_ENDPOINT
        : process.env.REACT_APP_API_ENDPOINT_PROD;
    try {
      setLoading(true);
      axios({
        method: 'post',
        url: apiEndPoint + '/v1/partner/leads/getCompanyDetails',
        withCredentials: true,
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
          Authorization: `Bearer ${userContext.token}`,
        },
        data: {
          accountId: account.id,
          company: newLead.company,
          sectors: sectorVals.join(';'),
          subSectors: subSectorVals.join(';'),
          sme: sme,
        },
      })
        .then((res) => {
          setSbiCodeSubsidies(res.data.subsidies);
          setContacts(res.data.contacts);
          if (res.data.subsidies.length > 0) {
            setOpenPreview(true);
            downloadSubsidyScanPDF(res.data.subsidies);
          } else {
            setOpenDownloadSubsidyScanDialog(true);
            setLoading(false);
          }
        })
        .catch((error) => {
          setLoading(false);
        });
    } catch (error) {
      console.log(error);
    }
  };

  const handleGenerateBenchmarkReportPdf = () => {
    const apiEndPoint =
      process.env.NODE_ENV === 'development'
        ? process.env.REACT_APP_API_ENDPOINT
        : process.env.REACT_APP_API_ENDPOINT_PROD;
    try {
      setLoading(true);
      axios({
        method: 'post',
        url: apiEndPoint + '/v1/partner/benchmarkReport/previewReport',
        withCredentials: true,
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
          Authorization: `Bearer ${userContext.token}`,
        },
        data: {
          accountId: account.id,
          company: newLead.company,
        },
        responseType: 'blob',
      })
        .then((res) => {
          setOpenPreviewBenchmarkReport(true);
          setOpenSearchBenchmarkReportAccount(false);
          setLoading(false);
          WebViewer(
            {
              path: '/webviewer/public',
              licenseKey: process.env.REACT_APP_PDFJS_API_KEY,
            },
            viewerBenchmarkReport.current
          ).then((instance) => {
            instance.UI.loadDocument(new Blob([res.data]), {
              filename: account.name + ' - Benchmark Report.pdf',
            });
            const { Core } = instance;
            Core.documentViewer.addEventListener('documentLoaded', () => {
              console.log('document loaded');
            });
          });
        })
        .catch((error) => {
          setShowError(true);
          setErrorMessage(t('benchmark_report.preview_error'));
          setLoading(false);
        });
    } catch (error) {
      console.log(error);
      setShowError(true);
      setErrorMessage(t('benchmark_report.preview_error'));
      setLoading(false);
    }
  };

  const handleSendScan = () => {
    setLoading(true);
    let url = '/v1/partner/leads/sendScan';
    const apiEndPoint =
      process.env.NODE_ENV === 'development'
        ? process.env.REACT_APP_API_ENDPOINT
        : process.env.REACT_APP_API_ENDPOINT_PROD;
    try {
      axios({
        method: 'post',
        url: apiEndPoint + url,
        data: {
          accountId: account.id,
          subsidies: sbiCodeSubsidies,
          contact: contact,
        },
        withCredentials: true,
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
          Authorization: `Bearer ${userContext.token}`,
        },
      })
        .then((res) => {
          setSubsidyScans(res.data.subsidyscans);
          setShowSuccess(true);
          setSuccessMessage(t('quick_subsidyscan.send_scan_success_message'));
          setOpenSendDialog(false);
          setLoading(false);
        })
        .catch((error) => {
          setOpenSendDialog(false);
          setShowError(true);
          setErrorMessage(t('quick_subsidyscan.send_scan_error_message'));
          setLoading(false);
        });
    } catch (err) {
      console.log(err);
      setOpenSendDialog(false);
      setShowError(true);
      setErrorMessage(t('quick_subsidyscan.send_scan_error_message'));
      setLoading(false);
    }
  };

  const handleSendBenchmarkReport = () => {
    setLoading(true);
    let url = '/v1/partner/benchmarkReport/sendReport';
    const apiEndPoint =
      process.env.NODE_ENV === 'development'
        ? process.env.REACT_APP_API_ENDPOINT
        : process.env.REACT_APP_API_ENDPOINT_PROD;
    try {
      axios({
        method: 'post',
        url: apiEndPoint + url,
        data: {
          accountId: account.id,
          contact: contact,
        },
        withCredentials: true,
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
          Authorization: `Bearer ${userContext.token}`,
        },
      })
        .then((res) => {
          setShowSuccess(true);
          setSuccessMessage(t('benchmark_report.send_report_success_message'));
          setOpenSendDialogBenchmarkReport(false);
          setLoading(false);
        })
        .catch((error) => {
          setOpenSendDialogBenchmarkReport(false);
          setShowError(true);
          setErrorMessage(t('benchmark_report.send_report_error_message'));
          setLoading(false);
        });
    } catch (err) {
      console.log(err);
      setOpenSendDialogBenchmarkReport(false);
      setShowError(true);
      setErrorMessage(t('benchmark_report.send_report_error_message'));
      setLoading(false);
    }
  };

  useEffect(() => {
    setLoading(true);
    let id = location.pathname.substring(
      location.pathname.lastIndexOf('/') + 1
    );
    let data = [];
    const apiEndPoint =
      process.env.NODE_ENV === 'development'
        ? process.env.REACT_APP_API_ENDPOINT
        : process.env.REACT_APP_API_ENDPOINT_PROD;
    axios({
      method: 'get',
      url: apiEndPoint + '/v1/partner/leads/getLead',
      withCredentials: true,
      params: { id: id },
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        Authorization: `Bearer ${userContext.token}`,
      },
    })
      .then((res) => {
        res.data.projects.forEach((item, i) => {
          data.push(
            createData(item.id, item.name, item.projectTypeId, item.accountId)
          );
        });
        setRows(data);
        setAccount(res.data.account);
        setSme(res.data.account?.sme ? res.data.account.sme : null);
        let subSectorVals1;
        let subSectorVals = res.data.account?.subSectors
          ? res.data.account.subSectors.split(';')
          : [];
        let subSectorOptions =
          i18n.languages[0] === 'nl' ? subSectorOptionsNl : subSectorOptionsEn;
        subSectorVals1 = subSectorOptions.filter(
          (opt) => subSectorVals.indexOf(opt.value) !== -1
        );
        setSubSectors(subSectorVals1);
        let sectorVals1;
        let sectorVals = res.data.account?.keySectors
          ? res.data.account.keySectors.split(';')
          : [];
        let sectorOptions =
          i18n.languages[0] === 'nl' ? sectorOptionsNl : sectorOptionsEn;
        sectorVals1 = sectorOptions.filter(
          (opt) => sectorVals.indexOf(opt.value) !== -1
        );
        setSectors(sectorVals1);

        setAssociatedAccounts(res.data.associatedAccounts);
        setUsers(res.data.users);
        setSubsidyScans(res.data.subsidyscans);
        setContacts(res.data.contacts);
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
      });
    if (location.state) {
      let name = location.state.name;
      window.document.title = t('page_title.accountX', { name });
    } else {
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const topPageRef = React.useRef();

  useEffect(() => {
    document.getElementById('anchor-div').scrollIntoView();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleAddNewContact = () => {
    const apiEndPoint =
      process.env.NODE_ENV === 'development'
        ? process.env.REACT_APP_API_ENDPOINT
        : process.env.REACT_APP_API_ENDPOINT_PROD;
    try {
      setLoading(true);
      axios({
        method: 'post',
        url: apiEndPoint + '/v1/partner/leads/addContact',
        withCredentials: true,
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
          Authorization: `Bearer ${userContext.token}`,
        },
        data: {
          contact: newContact,
          accountId: account.id,
        },
      })
        .then((res) => {
          setAssociatedAccounts(res.data.associatedAccounts);
          setUsers(res.data.users);
          setNewContact({ firstName: '', lastName: '', email: '' });
          setOpenAddContactDialog(false);
          setLoading(false);
          setSuccessMessage(t('add_contact.success_message'));
          setShowSuccess(true);
        })
        .catch((error) => {
          setLoading(false);
        });
    } catch (error) {
      console.log(error);
    }
  };

  async function searchCharacters(search) {
    const apiEndPoint =
      process.env.NODE_ENV === 'development'
        ? process.env.REACT_APP_API_ENDPOINT
        : process.env.REACT_APP_API_ENDPOINT_PROD;
    return axios({
      method: 'post',
      url: apiEndPoint + '/v1/partner/leads/findCompany',
      withCredentials: true,
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        Authorization: `Bearer ${userContext.token}`,
      },
      data: { company_name: search },
    })
      .then((res) => {
        return res.data;
      })
      .catch((error) => {
        // setLoader(false);
        return [];
      });
  }

  useEffect(() => {
    let mounted = true;
    if (mounted) {
      if (
        newLead &&
        newLead.company &&
        newLead.company?.name &&
        newLead.company.name !== ''
      ) {
        setTimeout(() => {
          setSearchString(newLead.company?.name);
        }, 100);
      }
    }
    return () => {
      mounted = false;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [newLead.company]);

  useEffect(() => {
    const source = axios.CancelToken.source();
    let mounted = true;
    if (mounted) {
      if (debouncedSearchTerm && searchString.length > 2) {
        setIsSearching(true);
        searchCharacters(debouncedSearchTerm).then((results) => {
          if (mounted) {
            setIsSearching(false);
            setCompanyOptions(results);
          }
        });
      } else {
        if (mounted) {
          setCompanyOptions([]);
        }
      }
    }
    if (mounted) {
      if (!searchString) {
        setNewLead({
          company: {
            name: '',
            dossier_number: '',
            establishment_number: '',
            establishment_city: '',
            establishment_street: '',
            correspondence_city: '',
            correspondence_street: '',
          },
        });
      }
    }
    return () => {
      mounted = false;
      source.cancel();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedSearchTerm]);

  useEffect(() => {
    let mounted = true;
    if (mounted) {
      if (!open) {
        setCompanyOptions([]);
      }
    }
    return () => {
      mounted = false;
    };
  }, [open]);

  const downloadSubsidyScanPDF = (subsidies) => {
    const apiEndPoint =
      process.env.NODE_ENV === 'development'
        ? process.env.REACT_APP_API_ENDPOINT
        : process.env.REACT_APP_API_ENDPOINT_PROD;
    let url = '/v1/subsidyscan/generateSBICodeSubsidyScan';
    try {
      axios({
        method: 'post',
        url: apiEndPoint + url,
        data: { accountId: account.id, subsidies: subsidies },
        withCredentials: true,
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
          Authorization: `Bearer ${userContext.token}`,
        },
        responseType: 'blob',
      })
        .then((res) => {
          setLoading(false);
          WebViewer(
            {
              path: '/webviewer/public',
              licenseKey: process.env.REACT_APP_PDFJS_API_KEY,
            },
            viewer.current
          ).then((instance) => {
            instance.UI.loadDocument(new Blob([res.data]), {
              filename: account.name + '-Subsidiescan.pdf',
            });
            const { Core } = instance;
            Core.documentViewer.addEventListener('documentLoaded', () => {
              console.log('document loaded');
            });
          });
        })
        .catch((error) => {
          setLoading(false);
        });
    } catch (err) {
      console.log(err);
    }
  };

  const handleAddContactClose = () => {
    setNewContact({ firstName: '', lastName: '', email: '' });
    setOpenAddContactDialog(false);
  };

  const handlePreviewSentScan = (event, id) => {
    const apiEndPoint =
      process.env.NODE_ENV === 'development'
        ? process.env.REACT_APP_API_ENDPOINT
        : process.env.REACT_APP_API_ENDPOINT_PROD;
    setLoading(true);
    let url = '/v1/subsidyscan/generateSentScanPreview';
    try {
      axios({
        method: 'post',
        url: apiEndPoint + url,
        data: { subsidyScanId: id },
        withCredentials: true,
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
          Authorization: `Bearer ${userContext.token}`,
        },
        responseType: 'blob',
      })
        .then((res) => {
          setOpenPreviewSentScan(true);
          WebViewer(
            {
              path: '/webviewer/public',
              licenseKey: process.env.REACT_APP_PDFJS_API_KEY,
            },
            viewerSentScan.current
          ).then((instance) => {
            instance.UI.loadDocument(new Blob([res.data]), {
              filename: account.name + '-Subsidiescan.pdf',
            });
            const { Core } = instance;
            Core.documentViewer.addEventListener('documentLoaded', () => {
              console.log('document loaded');
            });
            setLoading(false);
          });
        })
        .catch((error) => {
          setLoading(false);
        });
    } catch (err) {
      console.log(err);
      setLoading(false);
    }
  };

  return (
    <>
      {loading && <Loader loading={loading} />}
      <Grid container className={classes.root} style={{ position: 'relative' }}>
        <div
          ref={topPageRef}
          id='anchor-div'
          style={{ position: 'absolute', top: '-120px', left: '0' }}
        ></div>

        <Grid
          container
          item
          justifyContent='center'
          alignContent='flex-start'
          xs={12}
        >
          <Grid
            container
            item
            xs={12}
            justifyContent='space-between'
            className={classes.container}
          >
            <Grid item xs={12} className={classes.box}>
              <Grid container direction={`row`} alignItems='flex-start'>
                <Grid
                  item
                  xs={12}
                  md={6}
                  sx={{ display: 'flex', justifyContent: 'flex-start' }}
                >
                  <Box mb={2.5}>
                    <Typography component='h5' variant='h5' align='center'>
                      {account.name}
                    </Typography>
                  </Box>
                  <Box>
                    <Typography
                      variant='body1'
                      color='textSecondary'
                      align='center'
                    ></Typography>
                  </Box>
                </Grid>

                <Grid
                  item
                  xs={12}
                  md={6}
                  sx={{
                    display: 'flex',
                    justifyContent: 'flex-end',
                  }}
                >
                  {/*<Tooltip
                    title={t('subsidyscan.button.generate_scan_tooltip')}
                  >
                    <Button
                      onClick={handleGenerateSubsidyScan}
                      variant={`contained`}
                    >
                      {t('subsidyscan.button.generate_scan_label')}
                    </Button>
                  </Tooltip>*/}
                  <AccountDetailsButtonGroup
                    handleGenerateSubsidyScan={handleGenerateSubsidyScan}
                    handleGenerateBenchmarkReport={
                      handleGenerateBenchmarkReport
                    }
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid container item xs={12} className={classes.box}>
              <Grid item xs={12}>
                <Card
                  className={classes.card}
                  variant='outlined'
                  style={(theme.custom.boxshadow, { marginRight: 24 })}
                >
                  <CardContent>
                    <Grid container>
                      <Grid
                        container
                        item
                        xs={isLargeScreen ? 7 : 12}
                        alignContent='center'
                      >
                        <Grid item xs={12}>
                          <Box mb={1}>
                            <Typography>{t('accountX.card_title')}</Typography>
                          </Box>
                        </Grid>
                        <Grid item xs={12}>
                          <Box>
                            <Typography variant='body2' color='textSecondary'>
                              {t('accountX.card_subtitle')}
                            </Typography>
                          </Box>
                        </Grid>
                      </Grid>
                      <Grid item xs={isLargeScreen ? 5 : 12}>
                        <Startup
                          style={{
                            width: isLargeScreen ? 'auto' : '100%',
                            float: 'right',
                          }}
                        />
                      </Grid>
                    </Grid>
                  </CardContent>
                  <Divider />
                  <CardActions>
                    <Button
                      variant='contained'
                      color='primary'
                      startIcon={<AddIcon />}
                      onClick={() => {
                        let id = location.pathname.substring(
                          location.pathname.lastIndexOf('/') + 1
                        );
                        setAccountId(id);
                        setNewProjectAccount(account);
                        if (
                          account.legalEntity === null ||
                          account.legalEntity === '' ||
                          account.legalEntity === undefined ||
                          account.sme === null ||
                          account.sme === '' ||
                          account.sme === undefined ||
                          account.keySectors === null ||
                          account.keySectors === '' ||
                          account.keySectors === undefined ||
                          account.province === null ||
                          account.province === '' ||
                          account.province === undefined
                        ) {
                          history.push('/app/newproject/account');
                        } else {
                          history.push('/app/newproject/choose');
                        }
                      }}
                    >
                      {t('accountX.new_project')}
                    </Button>
                  </CardActions>
                </Card>
              </Grid>
            </Grid>
            <Grid container item xs={12} className={classes.box}>
              <Grid item xs={12} mb={2}>
                <Typography variant='h6'>{t('accountX.general')}</Typography>
              </Grid>
              <Grid item xs={12}>
                <Card
                  className={classes.card}
                  variant='outlined'
                  style={(theme.custom.boxshadow, { marginRightt: 24 })}
                >
                  <CardContent>
                    <Grid container>
                      <Grid
                        container
                        item
                        xs={12}
                        className={classes.mTop12 + ' ' + classes.mBottom12}
                      >
                        <Grid item xs={isLargeScreen ? 3 : 12}>
                          <Typography
                            component='h6'
                            variant='button'
                            color='textSecondary'
                          >
                            {t('accountX.address')}{' '}
                          </Typography>
                        </Grid>
                        {account.street && (
                          <Grid item xs={isLargeScreen ? 9 : 12}>
                            <Typography color='textSecondary'>
                              {account.street ? account.street : ''}
                              {account.city ? ', ' + account.city : ''}
                              {account.postcode ? ', ' + account.postcode : ''}
                            </Typography>
                          </Grid>
                        )}
                      </Grid>
                      <Grid item xs={12}>
                        <Divider />
                      </Grid>
                      <Grid container item xs={12} className={classes.mTop12}>
                        <Grid item xs={isLargeScreen ? 3 : 12}>
                          <Typography
                            component='h6'
                            variant='button'
                            color='textSecondary'
                          >
                            {t('my_account.kvk')}{' '}
                          </Typography>
                        </Grid>
                        <Grid item xs={isLargeScreen ? 9 : 12}>
                          <Typography color='textSecondary'>
                            {account.registrationNumber}
                          </Typography>
                        </Grid>
                      </Grid>
                    </Grid>
                  </CardContent>
                </Card>
              </Grid>
            </Grid>
            <Grid container item xs={12} className={classes.box}>
              <Grid item xs={12} mb={2}>
                <Stack
                  direction='row'
                  justifyContent='space-between'
                  alignItems='flex-start'
                >
                  <Typography variant='h6'>
                    {t('accountX.contact_title')}
                  </Typography>
                  <Button
                    variant='contained'
                    startIcon={<AddIcon />}
                    onClick={() => {
                      setOpenAddContactDialog(true);
                    }}
                  >
                    {t('add_contact.button_label')}
                  </Button>
                </Stack>
              </Grid>
              <Grid item xs={12}>
                <>
                  <TableContainer
                    component={Paper}
                    className={classes.tableContainer}
                  >
                    <Table className={classes.table} aria-label='a dense table'>
                      <TableHead>
                        <TableRow>
                          <TableCell
                            style={{ width: '27%', fontWeight: 'bold' }}
                          >
                            {t('accountX.contact_table.name')}
                          </TableCell>
                          <TableCell
                            style={{ width: '42%', fontWeight: 'bold' }}
                          >
                            {t('accountX.contact_table.email')}
                          </TableCell>
                          <TableCell
                            style={{ width: '42%', fontWeight: 'bold' }}
                          >
                            {t('accountX.contact_table.mobile')}
                          </TableCell>
                        </TableRow>
                      </TableHead>
                      {associatedAccounts.length > 0 ? (
                        <TableBody>
                          {associatedAccounts.map((row) => (
                            <TableRow key={row.id}>
                              <TableCell
                                style={{ padding: 16, maxWidth: '214px' }}
                              >
                                <Typography noWrap style={{ width: '100%' }}>
                                  {row.userId.firstName +
                                    ' ' +
                                    row.userId.lastName}
                                </Typography>
                              </TableCell>
                              <TableCell
                                style={{ padding: 16, maxWidth: '214px' }}
                              >
                                <Stack
                                  direction='row'
                                  alignItems='center'
                                  spacing={1}
                                >
                                  <Typography noWrap style={{ width: '100%' }}>
                                    {row.userId.email}
                                  </Typography>
                                  {!row.userId.isActive && (
                                    <IconButton
                                      aria-label='Send Invitation'
                                      onClick={() => {
                                        handleSendInvitation(row.userId.email);
                                      }}
                                    >
                                      <Tooltip
                                        id='button-send invitation'
                                        title={t('leads.menu_send')}
                                      >
                                        <TelegramIcon color='primary' />
                                      </Tooltip>
                                    </IconButton>
                                  )}
                                </Stack>
                              </TableCell>
                              <TableCell
                                style={{ padding: 16, maxWidth: '214px' }}
                              >
                                <Typography noWrap style={{ width: '100%' }}>
                                  {row.userId.phone}
                                </Typography>
                              </TableCell>
                            </TableRow>
                          ))}
                        </TableBody>
                      ) : (
                        <>
                          {users.length > 0 ? (
                            <TableBody>
                              {users.map((row) => (
                                <TableRow key={row.id}>
                                  <TableCell
                                    style={{ padding: 16, maxWidth: '214px' }}
                                  >
                                    <Typography
                                      noWrap
                                      style={{ width: '100%' }}
                                    >
                                      {row.firstName + ' ' + row.lastName}
                                    </Typography>
                                  </TableCell>
                                  <TableCell
                                    style={{ padding: 16, maxWidth: '214px' }}
                                  >
                                    <Stack
                                      direction='row'
                                      alignItems='center'
                                      spacing={1}
                                    >
                                      <Typography
                                        noWrap
                                        style={{ width: '100%' }}
                                      >
                                        {row.email}
                                      </Typography>
                                      {!row.isActive && (
                                        <IconButton
                                          aria-label='Send Invitation'
                                          onClick={() => {
                                            handleSendInvitation(row.email);
                                          }}
                                        >
                                          <Tooltip
                                            id='button-send invitation'
                                            title={t('leads.menu_send')}
                                          >
                                            <TelegramIcon color='primary' />
                                          </Tooltip>
                                        </IconButton>
                                      )}
                                    </Stack>
                                  </TableCell>
                                  <TableCell
                                    style={{ padding: 16, maxWidth: '214px' }}
                                  >
                                    <Typography
                                      noWrap
                                      style={{ width: '100%' }}
                                    >
                                      {row.phone}
                                    </Typography>
                                  </TableCell>
                                </TableRow>
                              ))}
                            </TableBody>
                          ) : (
                            <>
                              <NoResultsFound span={6} />
                            </>
                          )}
                        </>
                      )}
                    </Table>
                  </TableContainer>
                </>
              </Grid>
            </Grid>
            <Grid container item xs={12} className={classes.box}>
              <Grid item xs={12} mb={2}>
                <Stack
                  direction='row'
                  justifyContent='space-between'
                  alignItems='flex-start'
                >
                  <Typography variant='h6'>
                    {t('subsidy_scan_table.title')}
                  </Typography>
                </Stack>
              </Grid>
              <Grid item xs={12}>
                <>
                  <TableContainer
                    component={Paper}
                    className={classes.tableContainer}
                  >
                    <Table className={classes.table} aria-label='a dense table'>
                      <TableHead>
                        <TableRow>
                          <TableCell
                            style={{ width: '27%', fontWeight: 'bold' }}
                          >
                            {t('subsidy_scan_table.header_sent_date')}
                          </TableCell>
                          <TableCell
                            style={{ width: '42%', fontWeight: 'bold' }}
                          >
                            {t('subsidy_scan_table.header_sent_to')}
                          </TableCell>
                          <TableCell
                            style={{ width: '42%', fontWeight: 'bold' }}
                          >
                            {t('subsidy_scan_table.header_sent_by')}
                          </TableCell>
                        </TableRow>
                      </TableHead>
                      {subsidyScans.length > 0 ? (
                        <TableBody>
                          {subsidyScans.map((row) => (
                            <TableRow
                              key={row.id}
                              hover
                              sx={{ cursor: 'pointer' }}
                              onClick={(event) =>
                                handlePreviewSentScan(event, row.id)
                              }
                            >
                              <TableCell
                                style={{ padding: 16, maxWidth: '214px' }}
                              >
                                <Typography noWrap style={{ width: '100%' }}>
                                  {row.sentDate
                                    ? moment(row.sentDate)
                                        .locale(locale)
                                        .format('DD MMM YYYY')
                                    : ''}
                                </Typography>
                              </TableCell>
                              <TableCell
                                style={{ padding: 16, maxWidth: '214px' }}
                              >
                                <Stack
                                  direction='column'
                                  alignItems='center'
                                  spacing={1}
                                >
                                  {row.contacts.map((contact) => (
                                    <Tooltip title={contact.email}>
                                      <Typography
                                        key={contact.id}
                                        noWrap
                                        style={{ width: '100%' }}
                                      >
                                        {contact.firstName +
                                          ' ' +
                                          contact.lastName}
                                      </Typography>
                                    </Tooltip>
                                  ))}
                                </Stack>
                              </TableCell>
                              <TableCell
                                style={{ padding: 16, maxWidth: '214px' }}
                              >
                                <Typography noWrap style={{ width: '100%' }}>
                                  {row.ownerId.firstName +
                                    ' ' +
                                    row.ownerId.lastName}
                                </Typography>
                              </TableCell>
                            </TableRow>
                          ))}
                        </TableBody>
                      ) : (
                        <>
                          <NoResultsFound span={6} />
                        </>
                      )}
                    </Table>
                  </TableContainer>
                </>
              </Grid>
            </Grid>
            <Grid container item xs={12} className={classes.box}>
              <Grid item xs={12} mb={2}>
                <Typography variant='h6'>
                  {t('accountX.project_title')}
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <>
                  <TableContainer
                    component={Paper}
                    className={classes.tableContainer}
                  >
                    <Table className={classes.table} aria-label='a dense table'>
                      <TableHead>
                        <TableRow>
                          <TableCell
                            style={{ width: '27%', fontWeight: 'bold' }}
                          >
                            {t('projects.name')}
                          </TableCell>
                          <TableCell
                            style={{ width: '42%', fontWeight: 'bold' }}
                          >
                            {t('projects.type')}
                          </TableCell>
                        </TableRow>
                      </TableHead>
                      {rows && rows.length > 0 ? (
                        <TableBody>
                          {rows.map((row) => (
                            <TableRow key={row.ProjectId}>
                              <TableCell
                                style={{ padding: 0, maxWidth: '214px' }}
                              >
                                <Link
                                  to={{
                                    pathname: `/app/projects/${row.ProjectId}`,
                                    state: {
                                      name: row.ProjectName,
                                      accountId: row.ProjectAccountId,
                                    },
                                  }}
                                  style={{
                                    padding: 16,
                                    display: 'inline-block',
                                    maxWidth: '100%',
                                  }}
                                >
                                  <Tooltip
                                    title={row.ProjectName}
                                    placement='top-start'
                                  >
                                    <Typography
                                      noWrap
                                      style={{ width: '100%' }}
                                    >
                                      {row.ProjectName}
                                    </Typography>
                                  </Tooltip>
                                </Link>
                              </TableCell>
                              <TableCell
                                style={{ padding: 0, maxWidth: '322px' }}
                              >
                                <Link
                                  to={{
                                    pathname: `/app/projects/${row.ProjectId}`,
                                    state: {
                                      name: row.ProjectName,
                                      accountId: row.ProjectAccountId,
                                    },
                                  }}
                                  style={{
                                    padding: 16,
                                    display: 'inline-block',
                                    maxWidth: '100%',
                                  }}
                                >
                                  <Tooltip
                                    title={labelTypes[row.ProjectType]}
                                    placement='top-start'
                                  >
                                    <Typography
                                      noWrap
                                      style={{ width: '100%' }}
                                    >
                                      {labelTypes[row.ProjectType]}
                                    </Typography>
                                  </Tooltip>
                                </Link>
                              </TableCell>
                            </TableRow>
                          ))}
                        </TableBody>
                      ) : (
                        <NoResultsFound span={6} />
                      )}
                    </Table>
                  </TableContainer>
                </>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Snackbar
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        open={successInvitation}
        autoHideDuration={3000}
        onClose={handleCloseSnackbar}
        message={t('leads.success_send')}
      />
      <Dialog
        open={openSearchAccount}
        onClose={() => {
          handleCancelNewLead();
        }}
        maxWidth={`md`}
        aria-labelledby='alert-dialog-title'
        aria-describedby='alert-dialog-description'
      >
        <DialogTitle
          id='alert-dialog-title'
          onClose={() => {
            handleCancelNewLead();
          }}
        >
          {t('subsidyscan.subsidyscan_searchAccount_dialog.title')}
          <IconButton
            aria-label='close'
            onClick={() => {
              handleCancelNewLead();
            }}
            sx={{
              position: 'absolute',
              right: 12,
              top: 12,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <DialogContentText id='alert-dialog-description'>
            <Typography variant={'body1'} color='textPrimary' component='span'>
              {t('subsidyscan.subsidyscan_searchAccount_dialog.sub_title')}
            </Typography>
            <Grid container>
              {(account.registrationNumber === null ||
                account.registrationNumber === '' ||
                account.registrationNumber === undefined) && (
                <Grid item xs={12} sx={{ pt: 1 }}>
                  <Autocomplete
                    sx={{
                      width: 1,
                      '& .MuiButtonBase-root.MuiIconButton-root.MuiAutocomplete-popupIndicator':
                        {
                          transform: 'rotate(0)',
                        },
                    }}
                    id='company_name'
                    name='company_name'
                    freeSolo
                    forcePopupIcon
                    open={open && searchString.length > 2}
                    onOpen={() => {
                      setOpen(true);
                    }}
                    onClose={() => {
                      setOpen(false);
                    }}
                    options={companyOptions}
                    getOptionLabel={(option) => option.name ?? ''}
                    renderOption={(props, option) => {
                      return (
                        <Box
                          component='li'
                          {...props}
                          key={
                            option.establishment_number && option.dossier_number
                              ? option.establishment_number +
                                option.dossier_number
                              : option.establishment_number
                              ? option.establishment_number
                              : option.dossier_number
                              ? option.dossier_number
                              : option.name + option.establishment_city
                          }
                        >
                          {option.name ?? ''}
                        </Box>
                      );
                    }}
                    loading={isSearching}
                    noOptionsText={t('messages.no_options')}
                    loadingText={t('messages.loading')}
                    popupIcon={
                      <SearchIcon
                        sx={{
                          transform: 'rotate(0)',
                          '& svg': {
                            transform: 'rotate(0)',
                          },
                        }}
                      />
                    }
                    inputValue={searchString}
                    onInputChange={(e, newInputValue) => {
                      setSearchString(newInputValue);
                    }}
                    onChange={(event, newValue) => {
                      handleSelectCompany(newValue);
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        variant='outlined'
                        label={t(
                          'quick_subsidyscan.dialog_new_lead_search_label'
                        )}
                        fullWidth
                        placeholder={t(
                          'quick_subsidyscan.dialog_new_lead_search_placeholder'
                        )}
                        InputLabelProps={{
                          shrink: true,
                        }}
                        InputProps={{
                          ...params.InputProps,
                          endAdornment: (
                            <>
                              {isSearching ? (
                                <CircularProgress color='inherit' size={20} />
                              ) : null}
                              {params.InputProps.endAdornment}
                            </>
                          ),
                        }}
                      />
                    )}
                  />
                </Grid>
              )}
              <Grid item xs={12} mt={4}>
                <Grid container spacing={3}>
                  <Grid item xs={12} md={6}>
                    <Autocomplete
                      multiple
                      id='sectors'
                      value={sectors}
                      options={
                        i18n.languages[0] === 'nl'
                          ? sectorOptionsNl
                          : sectorOptionsEn
                      }
                      getOptionLabel={(option) => option.label}
                      defaultValue={[]}
                      className={
                        classes.formControl + ' ' + classes.autocompleteMultiple
                      }
                      disableCloseOnSelect
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          variant='outlined'
                          label={t('labels.sectors')}
                          placeholder=''
                          className={classes.helperText}
                          helperText={t('quick_subsidyscan.sectors_label')}
                          InputLabelProps={{
                            shrink: true,
                          }}
                        />
                      )}
                      onChange={(e, values) => {
                        setSectors(values);
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Typography variant='body2'>
                      {t('quick_subsidyscan.sme_question')}
                    </Typography>
                    <FormControl component='fieldset'>
                      <RadioGroup
                        aria-label={'mkb'}
                        name={'acc_sme__c'}
                        value={sme}
                        onChange={(event) => {
                          setSme(event.target.value);
                        }}
                      >
                        {mkbOptions.map((tile, i) => (
                          <FormControlLabel
                            key={i}
                            value={tile.value}
                            control={<Radio />}
                            label={tile.label}
                          ></FormControlLabel>
                        ))}
                      </RadioGroup>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    {subSectorDependentOptions.length > 0 && (
                      <>
                        <Autocomplete
                          multiple
                          id='subSectors'
                          value={subSectors}
                          options={subSectorDependentOptions}
                          getOptionLabel={(option) => option.label}
                          defaultValue={[]}
                          className={
                            classes.formControl +
                            ' ' +
                            classes.autocompleteMultiple
                          }
                          disableCloseOnSelect
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              variant='outlined'
                              label={t('labels.subSectors')}
                              placeholder=''
                              className={classes.helperText}
                              helperText={t(
                                'quick_subsidyscan.subSectors_label'
                              )}
                              InputLabelProps={{
                                shrink: true,
                              }}
                            />
                          )}
                          onChange={(e, values) => {
                            setSubSectors(values);
                          }}
                        />
                      </>
                    )}
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              handleCancelNewLead();
            }}
            color='primary'
            variant={'text'}
          >
            {t('buttons.close')}
          </Button>

          <Button
            disabled={
              account.registrationNumber !== '' &&
              account.registrationNumber !== null &&
              account.registrationNumber !== undefined
                ? sectors.length === 0 ||
                  subSectors.length === 0 ||
                  sme === null
                : !searchString ||
                  (newLead && newLead.company.name === '') ||
                  (searchString &&
                    newLead &&
                    searchString !== newLead?.company?.name) ||
                  sectors.length === 0 ||
                  subSectors.length === 0 ||
                  sme === null
            }
            onClick={() => {
              handleGenerateSubsidyScanwWithoutKVK();
            }}
            color='primary'
            variant={'contained'}
          >
            {t('buttons.next')}
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={openDownloadSubsidyScanDialog}
        onClose={() => {
          setOpenDownloadSubsidyScanDialog(false);
        }}
        maxWidth={`sm`}
        aria-labelledby='alert-dialog-title'
        aria-describedby='alert-dialog-description'
      >
        <DialogTitle
          id='alert-dialog-title'
          onClose={() => {
            handleCancelNewLead();
          }}
        >
          {t('subsidyscan.subsidyscan_download_dialog.title')}
          <IconButton
            aria-label='close'
            onClick={() => {
              setOpenDownloadSubsidyScanDialog(false);
            }}
            sx={{
              position: 'absolute',
              right: 12,
              top: 12,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>

        <DialogContent>
          <DialogContentText id='alert-dialog-description'>
            {sbiCodeSubsidies.length > 0 && (
              <Typography
                variant={'body1'}
                color='textPrimary'
                component='span'
              >
                {t('subsidyscan.subsidyscan_download_dialog.subsidies_title')}
              </Typography>
            )}
            {sbiCodeSubsidies.length === 0 && (
              <Typography
                variant={'body1'}
                color='textPrimary'
                component='span'
              >
                {t(
                  'subsidyscan.subsidyscan_download_dialog.no_subsidies_title'
                )}
              </Typography>
            )}
          </DialogContentText>
        </DialogContent>

        <DialogActions>
          <Button
            onClick={() => {
              setOpenDownloadSubsidyScanDialog(false);
            }}
            color='primary'
            variant={'text'}
          >
            {t('buttons.close')}
          </Button>
          {sbiCodeSubsidies.length > 0 && (
            <Button
              color='primary'
              variant={'contained'}
              onClick={() => {
                downloadSubsidyScanPDF();
              }}
            >
              {t('subsidyscan.button.download')}
            </Button>
          )}
        </DialogActions>
      </Dialog>
      <Dialog
        fullScreen
        open={openPreview}
        onClose={() => {
          setOpenPreview(false);
          handleCancelNewLead();
        }}
        TransitionComponent={Transition}
      >
        <AppBar sx={{ position: 'relative' }} color={'transparent'}>
          <Toolbar>
            <>
              <IconButton
                edge='start'
                onClick={() => {
                  setOpenPreview(false);
                  handleCancelNewLead();
                }}
                aria-label='close'
                color={'primary'}
              >
                <CloseIcon />
              </IconButton>
              <Typography sx={{ ml: 2, flex: 1 }} variant='h6' component='div'>
                {t('buttons.close')}
              </Typography>
              <Button
                variant='contained'
                startIcon={<SendIcon />}
                onClick={() => {
                  setOpenSendDialog(true);
                }}
              >
                {t('quick_subsidyscan.send_scan_button_label')}
              </Button>
            </>
          </Toolbar>
        </AppBar>
        <DialogContent>
          <div className={classes.webviewerContainer}>
            <div className={classes.webviewerFrame} ref={viewer}></div>
          </div>
        </DialogContent>
      </Dialog>
      <Dialog
        fullWidth={true}
        maxWidth={`sm`}
        open={openSendDialog}
        aria-labelledby='alert-dialog-title'
        aria-describedby='alert-dialog-description'
      >
        <DialogTitle id='alert-dialog-title'>
          {t('quick_subsidyscan.send_Scan_dialog_title')}
        </DialogTitle>
        <DialogContent>
          <Grid container spacing={2}>
            <Grid item xs={12} mt={1}>
              <Autocomplete
                id={'contact'}
                multiple
                isOptionEqualToValue={(option, value) => option.id === value.id}
                getOptionLabel={(option) =>
                  option.firstName + ' ' + option.lastName
                }
                options={contacts}
                value={contact}
                fullWidth={true}
                autoHighlight
                onChange={(e, value) => {
                  if (value) {
                    setContact(value);
                  } else {
                    setContact([]);
                  }
                }}
                renderOption={(props, option) => (
                  <Box component='li' {...props} key={option.id}>
                    <Grid container>
                      <Grid item xs={10}>
                        {option.firstName + ' ' + option.lastName}
                      </Grid>
                    </Grid>
                  </Box>
                )}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label={t(
                      'quick_subsidyscan.send_Scan_contact_person_label'
                    )}
                    helperText={t(
                      'quick_subsidyscan.send_Scan_contact_person_helper_text'
                    )}
                    InputProps={{
                      ...params.InputProps,
                      autoComplete: 'new-password',
                    }}
                    variant='outlined'
                  />
                )}
              />
            </Grid>
            <Grid item xs={12}>
              <Typography variant='body1'>
                {t('quick_subsidyscan.send_Scan_dialog_sub_title')}
              </Typography>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              setOpenSendDialog(false);
            }}
          >
            {t('buttons.cancel')}
          </Button>
          <Button
            onClick={() => {
              handleSendScan();
            }}
            disabled={contact.length === 0}
            color='primary'
            variant='contained'
          >
            {t('buttons.confirm')}
          </Button>
        </DialogActions>
      </Dialog>
      <Snackbar
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        open={showSuccess}
        autoHideDuration={3000}
        onClose={handleCloseSnackbar}
        message={successMessage}
      />
      <Snackbar
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        open={showError}
        autoHideDuration={3000}
        onClose={handleCloseSnackbar}
      >
        <Alert
          onClose={handleCloseSnackbar}
          severity='error'
          sx={{ width: '100%' }}
        >
          {errorMessage}
        </Alert>
      </Snackbar>
      <LeadDialog
        openDialog={openAddContactDialog}
        setOpenDialog={setOpenAddContactDialog}
        onClose={handleAddContactClose}
        hasTitle
        hasContent
        customMaxWidth={'md'}
        componentContent={
          <AddContact
            newContact={newContact}
            setNewContact={setNewContact}
            onClose={handleAddContactClose}
            handleAddNewContact={handleAddNewContact}
          />
        }
        title={`Add Contact`}
      />
      <Dialog
        fullScreen
        open={openPreviewSentScan}
        onClose={() => {
          setOpenPreviewSentScan(false);
        }}
        TransitionComponent={Transition}
      >
        <AppBar sx={{ position: 'relative' }} color={'transparent'}>
          <Toolbar>
            <>
              <IconButton
                edge='start'
                onClick={() => {
                  setOpenPreviewSentScan(false);
                }}
                aria-label='close'
                color={'primary'}
              >
                <CloseIcon />
              </IconButton>
              <Typography sx={{ ml: 2, flex: 1 }} variant='h6' component='div'>
                {t('buttons.close')}
              </Typography>
            </>
          </Toolbar>
        </AppBar>
        <DialogContent>
          <div className={classes.webviewerContainer}>
            <div className={classes.webviewerFrame} ref={viewerSentScan}></div>
          </div>
        </DialogContent>
      </Dialog>
      <Dialog
        fullScreen
        open={openPreviewBenchmarkReport}
        onClose={() => {
          setOpenPreviewBenchmarkReport(false);
          handleCancelNewLeadBenchmarkReport();
        }}
        TransitionComponent={Transition}
      >
        <AppBar sx={{ position: 'relative' }} color={'transparent'}>
          <Toolbar>
            <>
              <IconButton
                edge='start'
                onClick={() => {
                  setOpenPreviewBenchmarkReport(false);
                  handleCancelNewLeadBenchmarkReport();
                }}
                aria-label='close'
                color={'primary'}
              >
                <CloseIcon />
              </IconButton>
              <Typography sx={{ ml: 2, flex: 1 }} variant='h6' component='div'>
                {t('buttons.close')}
              </Typography>
              <Button
                variant='contained'
                startIcon={<SendIcon />}
                onClick={() => {
                  setOpenSendDialogBenchmarkReport(true);
                }}
              >
                {t('benchmark_report.button.generate_scan_label')}
              </Button>
            </>
          </Toolbar>
        </AppBar>
        <DialogContent>
          <div className={classes.webviewerContainer}>
            <div
              className={classes.webviewerFrame}
              ref={viewerBenchmarkReport}
            ></div>
          </div>
        </DialogContent>
      </Dialog>
      <Dialog
        open={openSearchBenchmarkReportAccount}
        onClose={() => {
          handleCancelNewLeadBenchmarkReport();
        }}
        maxWidth={`md`}
        aria-labelledby='alert-dialog-title'
        aria-describedby='alert-dialog-description'
      >
        <DialogTitle
          id='alert-dialog-title'
          onClose={() => {
            handleCancelNewLeadBenchmarkReport();
          }}
        >
          {t('benchmark_report.benchmark_report_searchAccount_dialog.title')}
          <IconButton
            aria-label='close'
            onClick={() => {
              handleCancelNewLeadBenchmarkReport();
            }}
            sx={{
              position: 'absolute',
              right: 12,
              top: 12,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <DialogContentText id='alert-dialog-description'>
            <Typography variant={'body1'} color='textPrimary' component='span'>
              {t(
                'benchmark_report.benchmark_report_searchAccount_dialog.sub_title'
              )}
            </Typography>
            <Grid container>
              {(account.registrationNumber === null ||
                account.registrationNumber === '' ||
                account.registrationNumber === undefined) && (
                <Grid item xs={12} sx={{ pt: 1 }}>
                  <Autocomplete
                    sx={{
                      width: 1,
                      '& .MuiButtonBase-root.MuiIconButton-root.MuiAutocomplete-popupIndicator':
                        {
                          transform: 'rotate(0)',
                        },
                    }}
                    id='company_name_benchmark_report'
                    name='company_name_benchmark_report'
                    freeSolo
                    forcePopupIcon
                    open={open && searchString.length > 2}
                    onOpen={() => {
                      setOpen(true);
                    }}
                    onClose={() => {
                      setOpen(false);
                    }}
                    options={companyOptions}
                    getOptionLabel={(option) => option.name ?? ''}
                    renderOption={(props, option) => {
                      return (
                        <Box
                          component='li'
                          {...props}
                          key={
                            option.establishment_number && option.dossier_number
                              ? option.establishment_number +
                                option.dossier_number
                              : option.establishment_number
                              ? option.establishment_number
                              : option.dossier_number
                              ? option.dossier_number
                              : option.name + option.establishment_city
                          }
                        >
                          {option.name ?? ''}
                        </Box>
                      );
                    }}
                    loading={isSearching}
                    noOptionsText={t('messages.no_options')}
                    loadingText={t('messages.loading')}
                    popupIcon={
                      <SearchIcon
                        sx={{
                          transform: 'rotate(0)',
                          '& svg': {
                            transform: 'rotate(0)',
                          },
                        }}
                      />
                    }
                    inputValue={searchString}
                    onInputChange={(e, newInputValue) => {
                      setSearchString(newInputValue);
                    }}
                    onChange={(event, newValue) => {
                      handleSelectCompany(newValue);
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        variant='outlined'
                        label={t(
                          'quick_subsidyscan.dialog_new_lead_search_label'
                        )}
                        fullWidth
                        placeholder={t(
                          'quick_subsidyscan.dialog_new_lead_search_placeholder'
                        )}
                        InputLabelProps={{
                          shrink: true,
                        }}
                        InputProps={{
                          ...params.InputProps,
                          endAdornment: (
                            <>
                              {isSearching ? (
                                <CircularProgress color='inherit' size={20} />
                              ) : null}
                              {params.InputProps.endAdornment}
                            </>
                          ),
                        }}
                      />
                    )}
                  />
                </Grid>
              )}
            </Grid>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              handleCancelNewLeadBenchmarkReport();
            }}
            color='primary'
            variant={'text'}
          >
            {t('buttons.close')}
          </Button>
          <Button
            disabled={
              !searchString ||
              (newLead && newLead.company.name === '') ||
              (searchString &&
                newLead &&
                searchString !== newLead?.company?.name)
            }
            onClick={() => {
              handleGenerateBenchmarkReportPdf();
            }}
            color='primary'
            variant={'contained'}
          >
            {t('buttons.next')}
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        fullWidth={true}
        maxWidth={`sm`}
        open={openSendDialogBenchmarkReport}
        aria-labelledby='alert-dialog-title'
        aria-describedby='alert-dialog-description'
      >
        <DialogTitle id='alert-dialog-title'>
          {t('benchmark_report.send_Scan_dialog_title')}
        </DialogTitle>
        <DialogContent>
          <Grid container spacing={2}>
            <Grid item xs={12} mt={1}>
              <Autocomplete
                id={'contact'}
                multiple
                isOptionEqualToValue={(option, value) => option.id === value.id}
                getOptionLabel={(option) =>
                  option.firstName + ' ' + option.lastName
                }
                options={contacts}
                value={contact}
                fullWidth={true}
                autoHighlight
                onChange={(e, value) => {
                  if (value) {
                    setContact(value);
                  } else {
                    setContact([]);
                  }
                }}
                renderOption={(props, option) => (
                  <Box component='li' {...props} key={option.id}>
                    <Grid container>
                      <Grid item xs={10}>
                        {option.firstName + ' ' + option.lastName}
                      </Grid>
                    </Grid>
                  </Box>
                )}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label={t('benchmark_report.send_Scan_contact_person_label')}
                    helperText={t(
                      'benchmark_report.send_Scan_contact_person_helper_text'
                    )}
                    InputProps={{
                      ...params.InputProps,
                      autoComplete: 'new-password',
                    }}
                    variant='outlined'
                  />
                )}
              />
            </Grid>
            <Grid item xs={12}>
              <Typography variant='body1'>
                {t('benchmark_report.send_Scan_dialog_sub_title')}
              </Typography>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              setOpenSendDialogBenchmarkReport(false);
            }}
          >
            {t('buttons.cancel')}
          </Button>
          <Button
            onClick={() => {
              handleSendBenchmarkReport();
            }}
            disabled={contact.length === 0}
            color='primary'
            variant='contained'
          >
            {t('buttons.confirm')}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default withRouter(AccountDetails);
