import React from 'react';
import * as XLSX from 'xlsx';
import { dropzoneButton, dropzoneLabelButton, preview } from './styles';
import 'react-dropzone-uploader/dist/styles.css';
import Dropzone from 'react-dropzone-uploader';
import { WorkspaceContext } from '../../contexts/WorkspaceContext';

const DropzoneButton = (props) => {
  const {
    padding,
    minHeight,
    fileTypes,
    setOpenMapLeadDialog,
    handleClose,
    inputContent,
    error1,
    error2,
    error3,
    textColor,
    fontSize,
  } = props;

  const {
    setHeaderCols,
    setCsvToJSON,
    setUploadError,
    setUploadErrorMsg,
    locale,
  } = React.useContext(WorkspaceContext);

  const getUploadParams = ({ file, meta }) => {
    // console.log('getUploadParams status::', meta.status);
  };

  const handleChangeStatus = (
    { xhr, file, meta, remove, restart, cancel, ...rest },
    status,
    fileWithMeta
  ) => {
    let headers = [];
    try {
      if (status === 'ready') {
        const reader = new FileReader();
        try {
          reader.readAsArrayBuffer(file);

          reader.onload = function (e) {
            var contents = e.target.result;
            /* Read File */
            var workbook = XLSX.readFile(contents);

            var first_sheet_name = workbook.SheetNames[0];

            /* Get worksheet */
            var worksheet = workbook.Sheets[first_sheet_name];

            /* Csv to json */
            const data = XLSX.utils.sheet_to_json(worksheet, {
              raw: true,
            });
            setCsvToJSON(data);

            const columnCount =
              XLSX.utils.decode_range(worksheet['!ref']).e.c + 1;
            const columns = [];
            try {
              for (let i = 0; i < columnCount; ++i) {
                if (worksheet[`${XLSX.utils.encode_col(i)}1`] !== undefined) {
                  columns[i] = XLSX.utils.encode_col(i);
                  headers[i] = worksheet[`${XLSX.utils.encode_col(i)}1`].v;
                }
              }
            } catch (err) {
              console.log(err);
            }
            const unique = Array.from(new Set(headers));
            if (data.length === 0) {
              setUploadError(true);
              setUploadErrorMsg(error3);
            } else if (data.length > 100) {
              setUploadError(true);
              setUploadErrorMsg(error1);
            } else {
              if (headers.length === unique.length) {
                setHeaderCols(headers);
                setOpenMapLeadDialog(true);
                handleClose();
              } else {
                setUploadError(true);
                setUploadErrorMsg(error2);
              }
            }
            remove();
          };
        } catch (err) {
          console.log(err);
        } finally {
          remove();
          handleClose();
        }
      }
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <Dropzone
      accept={fileTypes ? fileTypes : '.csv'}
      getUploadParams={getUploadParams}
      onChangeStatus={handleChangeStatus}
      maxFiles={1}
      multiple={false}
      autoUpload={false}
      inputContent={inputContent ? inputContent : 'Upload'}
      PreviewComponent={null}
      styles={{
        dropzone: {
          ...dropzoneButton,
          maxWidth: locale === 'en' ? '207px' : '260px',
          minWidth: locale === 'en' ? '207px' : '260px',
          minHeight: minHeight && minHeight,
          padding: padding && '6px 16px',
          marginLeft: '-30px',
        },
        inputLabel: {
          ...dropzoneLabelButton,
          fontSize: fontSize ? fontSize : '16px',
          color: textColor ? textColor : 'rgba(0,0,0,0.87)', // (theme) => theme.palette.text.primary,
          minWidth: locale === 'en' ? '139px' : '192px',
          maxWidth: locale === 'en' ? '139px' : '192px',
          padding: padding && '5px 16px 6px 52px',
          justifyContent: 'flex-start',
        },
        preview: preview,
      }}
    />
  );
};

export default DropzoneButton;
