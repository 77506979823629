import makeStyles from '@mui/styles/makeStyles';

export const useStyles = makeStyles((theme) => ({
  root: {
    padding: '48px 24px',
    minHeight: 'calc(100% - 72px)',
    [theme.breakpoints.down('768')]: {
      minHeight: 'calc(100% - 64px)',
      padding: '24px 16px',
    },
  },
  projectX: {
    marginTop: 48,
  },
  container: {
    maxWidth: 792,
  },
  box: {
    marginBottom: 24,
  },
  boxLastItem: {
    [theme.breakpoints.down('768')]: {
      marginBottom: 0,
    },
  },
  card: {
    width: '100%',
    '& .MuiCardContent-root': {
      padding: 24,
      [theme.breakpoints.down('768')]: {},
    },
    '& .MuiCardActions-root': {
      padding: '6px 24px',
      [theme.breakpoints.down('768')]: {
        padding: '6px 16px',
      },
    },
    borderRadius: '8px',
  },
  boxCardHeader: {},
  tableContainer: {
    boxShadow: 'none',
    border: '1px solid #E0E0E0',
    borderBottom: 'none',
    '& .MuiTableBody-root': {
      '& .MuiTableRow-root:hover': {
        backgroundColor: '#fafafa',
      },
    },
  },
  table: {},
  mTop12: {
    marginTop: 20,
  },
  mBottom12: {
    marginBottom: 20,
  },
  boxTablePhone: {
    [theme.breakpoints.down('768')]: {
      boxShadow: 'none',
      border: '1px solid #E0E0E0',
      borderRadius: 4,
      marginBottom: 0,
    },
    '& .MuiAccordion-rounded': {
      '&:first-child': {},
      '&:last-child': {},
    },
    '& .MuiAccordionSummary-root': {
      padding: '8px 16px',
    },
  },
  boxTableSubsidies: {
    boxShadow: '0px 0px 0px 1px #E0E0E0',
    borderRadius: 4,
    '& .MuiAccordion-root.Mui-expanded:before': {
      opacity: '1',
    },
    '& .MuiAccordionSummary-root': {
      padding: '8px 16px',
      alignItems: 'flex-start',
    },
    '& .MuiAccordionSummary-content': {},
    '& .MuiAccordionDetails-root': {
      flexDirection: 'column',
      borderTop: '1px #E0E0E0 solid',
      borderBottom: '1px #E0E0E0 solid',
    },
    '& *': {
      wordBreak: 'break-word',
      hyphens: 'auto',
    },
  },
  [theme.breakpoints.down('768')]: {
    marginBottom: 86,
  },
}));

export const editProjectStyles = makeStyles((theme) => ({
  root: {
    padding: '48px 24px',
    minHeight: 'calc(100% - 72px)',
    [theme.breakpoints.down('768')]: {
      minHeight: 'calc(100% - 64px)',
      padding: '24px 16px',
    },
  },
  projectX: {
    marginTop: 48,
    [theme.breakpoints.down('768')]: {
      marginTop: 0,
    },
  },
  container: {
    maxWidth: 564,
    '& button:first-of-type': {
      marginRight: 12,
    },
  },
  box: {
    boxShadow: '0px 0px 0px 1px #E0E0E0',
    borderRadius: 4,
    padding: 24,
    marginBottom: 24,
    '& > div': {},
  },
  input: {
    '& .Mui-focused': {
      '& .MuiAutocomplete-popupIndicator': {},
    },
  },
  formControl: {
    '&.MuiAutocomplete-root': {
      [theme.breakpoints.down('768')]: {
        maxWidth: 'calc(100vw - 32px)',
      },
    },
  },
}));

export const useTableStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    height: '100%',
    padding: '64px 24px 40px 24px',
    [theme.breakpoints.down('1280')]: {},
    [theme.breakpoints.down('768')]: {
      padding: 16,
      paddingBottom: 0,
    },
  },
  container: {
    width: '100%',
    maxWidth: 1080,
    margin: 'auto',
  },
  containerNoSubsidies: {
    maxWidth: 450,
    [theme.breakpoints.down('1280')]: {
      paddingBottom: 24,
    },
    [theme.breakpoints.down('768')]: {
      paddingBottom: 72,
    },
  },
  tableHead: {
    '&th': {
      width: '40%',
    },
  },
  header: {},
  content: {
    boxShadow: 'none',
    border: '1px solid #E0E0E0',
    borderRadius: 4,
    [theme.breakpoints.down('769')]: {
      marginBottom: 88,
    },
  },
  subsidyOverride: {
    '& > div > div:nth-of-type(1)': {
      paddingLeft: 0 + '!important',
    },

    '& > div > div:nth-of-type(3) > p': {
      paddingLeft: 0 + '!important',
    },
  },
  table: {
    minWidth: 750,
    [theme.breakpoints.down('769')]: {
      minWidth: 500,
    },
  },
  title: {
    marginBottom: 8,
  },
  total: {
    marginBottom: 24,
  },
  subTitle: {
    color: theme.palette.text.disabled,
    maxWidth: '50%',
    marginBottom: 24,
    [theme.breakpoints.down('1280')]: {
      maxWidth: '100%',
    },
  },
  sectionMobile: {
    [theme.breakpoints.down('768')]: {
      position: 'fixed',
      bottom: 0,
      left: 0,
      background: '#ffffff',
      zIndex: 999,
    },
  },
  buttons: {
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '24px 0',
    '& .MuiButton-endIcon': {
      marginLeft: 0,
    },
    '& .MuiButton-startIcon': {
      marginRight: 0,
    },
    [theme.breakpoints.down('1280')]: {
      margin: 'auto',
      maxWidth: 450,
    },
    [theme.breakpoints.down('1280')]: {
      paddingRight: 0,
      paddingLeft: 0,
    },
    [theme.breakpoints.down('768')]: {
      padding: '12px 0',
    },
    [theme.breakpoints.down('464')]: {
      padding: '12px 16px',
    },
  },
  buttonsSubsidies: {
    [theme.breakpoints.down('768')]: {
      padding: '12px 16px',
    },
    [theme.breakpoints.down('464')]: {
      padding: '12px 16px',
    },
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },
  contentMobile: {
    [theme.breakpoints.down('768')]: {
      marginBottom: 86,
    },
    '& .MuiAccordion-root': {
      '&:before': {},
    },
    '& *': {
      wordBreak: 'break-word',
      hyphens: 'auto',
    },
  },
  boxTableSubsidies: {
    boxShadow: '0px 0px 0px 1px #E0E0E0',
    borderRadius: 4,
    '& .MuiAccordion-root.Mui-expanded:before': {
      opacity: '1',
    },
    '& .MuiAccordionSummary-root': {
      padding: '8px 16px',
      alignItems: 'flex-start',
    },
    '& .MuiAccordionSummary-content': {},
    '& .MuiAccordionDetails-root': {
      flexDirection: 'column',
      borderTop: '1px #E0E0E0 solid',
      borderBottom: '1px #E0E0E0 solid',
    },
    [theme.breakpoints.down('768')]: {
      marginBottom: 86,
    },
    '& *': {
      wordBreak: 'break-word',
      hyphens: 'auto',
    },
  },
  customWidthTooltip: {
    maxWidth: 600,
    width: 600,
  },
  customPositionTooltip: {
    // top: '10px !important',
  },
}));
