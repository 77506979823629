import * as React from 'react';
import Button from '@mui/material/Button';
import ButtonGroup from '@mui/material/ButtonGroup';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import Grow from '@mui/material/Grow';
import Paper from '@mui/material/Paper';
import Popper from '@mui/material/Popper';
import MenuItem from '@mui/material/MenuItem';
import MenuList from '@mui/material/MenuList';
import { useTranslation } from 'react-i18next';
import { ListItemText, Tooltip } from '@mui/material';

export default function AccountDetailsButtonGroup(props) {
  const { handleGenerateBenchmarkReport, handleGenerateSubsidyScan } = props;
  const { t } = useTranslation();
  const [open, setOpen] = React.useState(false);
  const anchorRef = React.useRef(null);

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }
    setOpen(false);
  };

  return (
    <React.Fragment>
      <ButtonGroup
        variant='contained'
        ref={anchorRef}
        aria-label='split button'
        disableElevation
      >
        <Tooltip title={t('benchmark_report.button.generate_scan_tooltip')}>
          <Button
            variant={`contained`}
            onClick={() => {
              handleGenerateBenchmarkReport();
            }}
          >
            {t('benchmark_report.button.generate_scan_label')}
          </Button>
        </Tooltip>
        <Button
          size='small'
          aria-controls={open ? 'split-button-menu' : undefined}
          aria-expanded={open ? 'true' : undefined}
          aria-label='select merge strategy'
          aria-haspopup='menu'
          onClick={handleToggle}
        >
          <ArrowDropDownIcon />
        </Button>
      </ButtonGroup>
      <Popper
        sx={{
          zIndex: 2,
        }}
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        disablePortal
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin:
                placement === 'bottom' ? 'center top' : 'center bottom',
            }}
          >
            <Paper>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList id='split-button-menu' autoFocusItem>
                  <MenuItem
                    key={'add_lead'}
                    onClick={() => {
                      handleGenerateSubsidyScan();
                    }}
                  >
                    <Tooltip
                      title={t('subsidyscan.button.generate_scan_tooltip')}
                    >
                      <ListItemText>
                        {t('subsidyscan.button.generate_scan_label')}
                      </ListItemText>
                    </Tooltip>
                  </MenuItem>
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </React.Fragment>
  );
}
