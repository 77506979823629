import * as React from 'react';
import Checkbox from '@mui/material/Checkbox';
import { useMediaQuery } from 'react-responsive';
import { styled } from '@mui/material/styles';
import {
  Card,
  IconButton,
  CardContent,
  CardActions,
  Typography,
  Collapse,
  Grid,
  TextField,
  Box,
  Stack,
  Autocomplete,
  Select,
  InputLabel,
  MenuItem,
  FormControl,
  Chip,
} from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';
import { useTranslation } from 'react-i18next';
import { ReactComponent as DutchFlag } from '../../assets/077-netherlands.svg';
import { ReactComponent as GermanFlag } from '../../assets/077-germany.svg';
import { ReactComponent as EuFlag } from '../../assets/077-eu.svg';
import { NumberFormatCustom } from '../../JS/FormControls';
import enLocale from 'date-fns/locale/en-US';
import nlLocale from 'date-fns/locale/nl';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import i18n from 'i18next';
import NumberFormat from 'react-number-format';
import axios from 'axios';
import { useLocation } from 'react-router-dom';
import { UserContext } from '../../contexts/UserContext';
import moment from 'moment';
import CheckIcon from '@mui/icons-material/Check';

const localeMap = {
  en: enLocale,
  nl: nlLocale,
};
const ExpandMore = styled((props) => {
  const { expand, ...other } = props;
  return <IconButton {...other} />;
})(({ theme, expand }) => ({
  transform: !expand ? 'rotate(0deg)' : 'rotate(180deg)',
  transition: theme.transitions.create('transform', {
    duration: theme.transitions.duration.shortest,
  }),
}));

const asciiNumberReplace = (str) => {
  try {
    const regPrintableAsc = /&#\d\d;/;
    const regExtendedAsc = /&#\d\d\d;/;
    let desc = str;
    let match1 = regPrintableAsc.exec(desc);
    let match2 = regExtendedAsc.exec(desc);
    while ((match1 = regPrintableAsc.exec(desc)) !== null) {
      let a = desc.slice(match1.index, match1.index + 5);
      let x = desc.slice(match1.index + 2, match1.index + 4);
      desc = desc.replace(a, String.fromCharCode(parseInt(x)));
    }
    while ((match2 = regExtendedAsc.exec(desc)) !== null) {
      let b = desc.slice(match2.index, match2.index + 6);
      let y = desc.slice(match2.index + 2, match2.index + 5);
      desc = desc.replace(b, String.fromCharCode(parseInt(y)));
    }
    return desc;
  } catch (err) {}
};

const MapCountryFlag = [<DutchFlag />, <GermanFlag />, <EuFlag />];

// eslint-disable-next-line no-unused-vars
const flagMap = (str) => {
  if (str) {
    let sx;

    return sx;
  }
};

export default function OtherSubsidiesTableRow(props) {
  const [userContext] = React.useContext(UserContext);
  let location = useLocation();

  const handleClick = (event, id) => {
    const selectedIndex = props.selectedOtherSubsidies.indexOf(id);
    let newSelected = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(props.selectedOtherSubsidies, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(props.selectedOtherSubsidies.slice(1));
    } else if (selectedIndex === props.selectedOtherSubsidies.length - 1) {
      newSelected = newSelected.concat(
        props.selectedOtherSubsidies.slice(0, -1)
      );
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        props.selectedOtherSubsidies.slice(0, selectedIndex),
        props.selectedOtherSubsidies.slice(selectedIndex + 1)
      );
    }
    props.setSelectedOtherSubsidies(newSelected);
  };
  const isSmallScreen = useMediaQuery({ query: '(max-width: 767px)' });
  const [expanded, setExpanded] = React.useState(false);
  const { t } = useTranslation();
  const handleExpandClick = () => {
    setExpanded(!expanded);
  };
  const [createApplication, setCreateApplication] = React.useState('No');
  const handleChangeCreateApplication = (event) => {
    setCreateApplication(event.target.value);
  };
  const [locale, setLocale] = React.useState('en');
  const selectLocale = React.useCallback((locale) => {
    setLocale(i18n.languages[0]);
  }, []);
  React.useEffect(() => {
    selectLocale();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const handleDelete = (id) => {
    const newState = [...props.otherSubsidies];
    if (newState && newState.length > 0) {
      try {
        let idsPopular = props.popularSubsidies.map((obj) => obj.id);
        let idsOther = props.otherSubsidies.map((obj) => obj.id);
        idsOther.splice(idsOther.indexOf(id), 1);
        let projectId = location.pathname.substring(
          location.pathname.lastIndexOf('/') + 1
        );
        var requestBody = {
          projectId: projectId,
          popularSubsidies: idsPopular,
          otherSubsidies: idsOther,
        };
        const apiEndPoint =
          process.env.NODE_ENV === 'development'
            ? process.env.REACT_APP_API_ENDPOINT
            : process.env.REACT_APP_API_ENDPOINT_PROD;
        try {
          axios({
            method: 'post',
            url: apiEndPoint + '/v1/partner/projectscan/addOrRemoveSubsidy',
            data: requestBody,
            withCredentials: true,
            headers: {
              'Content-Type': 'application/json',
              Accept: 'application/json',
              Authorization: `Bearer ${userContext.token}`,
            },
          })
            .then((res) => {
              props.setLoading(false);
              props.setPopularSubsidies(res.data.popularFirstWithScore);
              props.setOtherSubsidies(res.data.otherSubsidiesWithScore);
              props.setAssignments(res.data.assignments);
              props.setSelectedOtherSubsidies([]);
              props.setSelectedPopularSubsidies([]);
              props.setSelectedAssignments([]);
            })
            .catch((error) => {
              props.setLoading(false);
            });
        } catch (err) {
          console.log(err);
        }
        props.setLoading(true);
      } catch (err) {}
    }
  };

  const addPotentialTransaction = (id) => {
    props.setLoading(true);
    let projectId = location.pathname.substring(
      location.pathname.lastIndexOf('/') + 1
    );
    const apiEndPoint =
      process.env.NODE_ENV === 'development'
        ? process.env.REACT_APP_API_ENDPOINT
        : process.env.REACT_APP_API_ENDPOINT_PROD;
    let requestBody = {
      projectId: projectId,
      userId: expertId,
      subsidyId: id,
      appliedAmount: subsidyAmount,
      submissionDate:
        submissionDate &&
        submissionDate !== null &&
        submissionDate !== undefined
          ? moment.utc([
              submissionDate.getFullYear(),
              submissionDate.getMonth(),
              submissionDate.getDate(),
              0,
              0,
              0,
              0,
            ])
          : null,
      createApplication: createApplication,
    };
    try {
      axios({
        method: 'post',
        url: apiEndPoint + '/v1/partner/assignment/createPotentialTransaction',
        data: requestBody,
        withCredentials: true,
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
          Authorization: `Bearer ${userContext.token}`,
        },
      })
        .then((res) => {
          props.setLoading(false);
          props.setPopularSubsidies(res.data.popularFirstWithScore);
          props.setOtherSubsidies(res.data.otherSubsidiesWithScore);
          props.setAssignments(res.data.assignments);
          props.setSelectedOtherSubsidies([]);
          props.setSelectedPopularSubsidies([]);
          props.setSelectedAssignments([]);
        })
        .catch((error) => {
          props.setLoading(false);
        });
    } catch (err) {
      console.log(err);
    }
  };

  const [openStartDate, setOpenStartDate] = React.useState(false);
  const [submissionDate, setSubmissionDate] = React.useState(null);
  const [subsidyAmount, setSubsidyAmount] = React.useState(null);
  const [openAutocomplete, setOpenAutocomplete] = React.useState(false);
  const [experts, setExperts] = React.useState([]);
  const loadingAutocomplete = openAutocomplete && experts.length === 0;
  const [expertId, setExpertId] = React.useState(null);

  React.useEffect(() => {
    let active = true;
    if (!loadingAutocomplete) {
      return undefined;
    }
    (async () => {
      let projectId = location.pathname.substring(
        location.pathname.lastIndexOf('/') + 1
      );
      const apiEndPoint =
        process.env.NODE_ENV === 'development'
          ? process.env.REACT_APP_API_ENDPOINT
          : process.env.REACT_APP_API_ENDPOINT_PROD;
      axios({
        method: 'get',
        url: apiEndPoint + '/projectscan/getExperts',
        params: { id: props.row.id, projectId: projectId },
        withCredentials: true,
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
          Authorization: `Bearer ${userContext.token}`,
        },
      })
        .then((res) => {
          if (active) {
            setExperts([...res.data.subsidyExperts]);
          }
        })
        .catch((error) => {
          setExperts([]);
        });
    })();
    return () => {
      active = false;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loadingAutocomplete]);

  React.useEffect(() => {
    if (!openAutocomplete) {
      setExperts([]);
    }
  }, [openAutocomplete]);

  return (
    <Grid container>
      <Card sx={{ maxWidth: 1080, m: 0.5, width: 1 }}>
        <CardContent sx={{ pb: 0 }}>
          <Grid container>
            {!isSmallScreen && (
              <Grid
                item
                sx={{
                  px: { xs: 0, md: 0 },
                  pt: { xs: 0 },
                }}
              >
                {' '}
                <Checkbox
                  color='primary'
                  checked={props.isItemSelected}
                  onChange={(event) => handleClick(event, props.row.id)}
                  inputProps={{
                    'aria-labelledby': props.labelId,
                  }}
                />
              </Grid>
            )}
            <Grid
              container
              item
              xs
              alignContent='flex-start'
              sx={{ px: { xs: 0, md: 0, lg: 0 } }}
            >
              <Grid
                container
                item
                xs={12}
                md={9}
                alignContent='flex-start'
                sx={{
                  pr: { xs: 0 },
                  pl: { lg: 1 },
                  pt: 0,
                }}
              >
                <Grid item xs={12}>
                  <Typography variant='body1'>
                    {props.row.name && props.row.name}
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Typography variant='body2' color='text.secondary'>
                    {props.row.provider && props.row.provider}
                  </Typography>
                </Grid>
              </Grid>
              <Grid
                container
                item
                xs={12}
                md={3}
                alignContent='flex-start'
                sx={{ pt: 0, px: { md: 0 } }}
              >
                <Grid item xs={12}>
                  <Typography variant='body1'>
                    {t('subsidies_dnd_list.average_amount')}
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Typography variant='body2' color='text.secondary'>
                    {props.row.averageSubsidyAmount &&
                    props.row.averageSubsidyAmount !== 0 ? (
                      <>
                        <NumberFormat
                          value={props.row.averageSubsidyAmount}
                          displayType={'text'}
                          thousandSeparator={'.'}
                          fixedDecimalScale={true}
                          decimalSeparator={','}
                          decimalScale={2}
                          isNumericString
                          prefix={'€'}
                        />
                      </>
                    ) : (
                      t('subsidies_dnd_list.not_known')
                    )}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
            {props.hasDelete && !isSmallScreen ? (
              <Grid
                item
                sx={{
                  px: { xs: 0, md: 0 },
                  pt: { xs: 0 },
                }}
              >
                <IconButton
                  aria-label='settings'
                  color='error'
                  onClick={() => {
                    handleDelete(props.row.id);
                  }}
                >
                  <RemoveCircleIcon />
                </IconButton>
              </Grid>
            ) : (
              <Grid
                item
                sx={{
                  px: { xs: 3, md: 4 },
                  pt: { xs: 2 },
                }}
              >
                &nbsp;
              </Grid>
            )}
          </Grid>
          <Grid container sx={{ pt: 3 }}>
            {props.loginAsUser && !isSmallScreen && (
              <>
                <Grid item>
                  <Stack direction='row' spacing={1}>
                    <TextField
                      id='appliedAmount'
                      sx={{ width: 120 }}
                      name='appliedAmount'
                      variant='outlined'
                      type='currency'
                      autoComplete='off'
                      placeholder='€'
                      size='small'
                      label={t('assignment_input_fields.subsidy_amount')}
                      value={subsidyAmount ?? ''}
                      InputProps={{
                        inputComponent: NumberFormatCustom,
                      }}
                      onChange={(e) => {
                        setSubsidyAmount(e.target.value);
                      }}
                    />

                    <LocalizationProvider
                      dateAdapter={AdapterDateFns}
                      adapterLocale={localeMap[locale]}
                    >
                      <DatePicker
                        onClick={() => setOpenStartDate(true)}
                        onClose={() => setOpenStartDate(false)}
                        open={openStartDate}
                        clearable
                        value={submissionDate}
                        inputVariant='outlined'
                        inputFormat='dd MMM yyyy'
                        id={props.row.id}
                        key={props.row.id}
                        onChange={(newValue) => {
                          setSubmissionDate(newValue);
                        }}
                        name='submissionDate'
                        label={t('assignment_input_fields.applied_date')}
                        disableMaskedInput={true}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            variant='outlined'
                            size='small'
                            onMouseDown={(e) => {
                              setOpenStartDate(true);
                            }}
                            sx={{ width: 180 }}
                          />
                        )}
                      ></DatePicker>
                    </LocalizationProvider>
                    <FormControl>
                      <InputLabel id='createApplication'>
                        {t('assignment_input_fields.create_application')}
                      </InputLabel>
                      <Select
                        labelId='createApplication'
                        id='createApplication-select'
                        value={createApplication}
                        label={t('assignment_input_fields.create_application')}
                        sx={{ width: 150 }}
                        onChange={handleChangeCreateApplication}
                        disabled={userContext.account.type !== 'Active klant'}
                        size='small'
                      >
                        <MenuItem value={'No'}>{t('yes_no.no')}</MenuItem>
                        <MenuItem value={'Yes'}>{t('yes_no.yes')}</MenuItem>
                      </Select>
                    </FormControl>
                    <Autocomplete
                      id={'asynchronous-' + props.row.id}
                      open={openAutocomplete}
                      onOpen={() => {
                        setOpenAutocomplete(true);
                      }}
                      onClose={() => {
                        setOpenAutocomplete(false);
                      }}
                      isOptionEqualToValue={(option, value) =>
                        option.id === value.id
                      }
                      getOptionLabel={(option) =>
                        option.firstName + ' ' + option.lastName
                      }
                      options={experts}
                      disabled={userContext.account.type !== 'Active klant'}
                      sx={{ width: 230 }}
                      autoHighlight
                      loading={loadingAutocomplete}
                      onChange={(e, value) => {
                        if (value) {
                          setExpertId(value.id);
                        } else {
                          setExpertId(null);
                        }
                      }}
                      renderOption={(props, option) => (
                        <Box component='li' {...props}>
                          <Grid container>
                            <Grid item xs={10}>
                              {option.firstName + ' ' + option.lastName}
                            </Grid>
                            <Grid item xs={2}>
                              {option.experience && (
                                <CheckIcon color='secondary' />
                              )}
                            </Grid>
                            <Grid item xs={12}>
                              <Chip
                                size='small'
                                color='warning'
                                label={
                                  option.totalApplicationsInProgress +
                                  t('assignment_experts_work_in_progress')
                                }
                              />
                            </Grid>
                          </Grid>
                        </Box>
                      )}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label={t('assignment_input_fields.choose_an_expert')}
                          InputProps={{
                            ...params.InputProps,
                            autoComplete: 'new-password',
                            endAdornment: (
                              <React.Fragment>
                                {loadingAutocomplete ? (
                                  <CircularProgress color='inherit' size={30} />
                                ) : null}
                                {params.InputProps.endAdornment}
                              </React.Fragment>
                            ),
                          }}
                          size='small'
                          variant='outlined'
                        />
                      )}
                    />
                    <IconButton
                      aria-label='settings'
                      color='secondary'
                      onClick={() => {
                        addPotentialTransaction(props.row.id);
                      }}
                    >
                      <AddCircleIcon />
                    </IconButton>
                  </Stack>
                </Grid>
              </>
            )}
          </Grid>
        </CardContent>

        <Collapse in={expanded} timeout='auto' unmountOnExit>
          <CardContent>
            <Grid container>
              <Grid
                item
                sx={{
                  px: { xs: 0, md: 0 },
                  pt: { xs: 0 },
                }}
              >
                &nbsp;
              </Grid>
              <Grid item xs sx={{ px: { xs: 0, md: 2, lg: 5 } }}>
                <Typography variant='body2' color='text.secondary'>
                  {props.row.shortDescription &&
                    asciiNumberReplace(props.row?.shortDescription)
                      .replace(/&lt;/g, '<')
                      .replace(/&gt;/g, '>')
                      .replace(/&amp;/g, '&')
                      .replace(/(<([^>]+)>)/gi, '    ')}
                </Typography>
              </Grid>
              {!isSmallScreen && (
                <Grid
                  item
                  sx={{
                    px: { xs: 2, md: 3 },
                    pt: { xs: 0 },
                  }}
                >
                  {props.row?.type === 'European'
                    ? MapCountryFlag[2]
                    : props.row?.type === 'National' ||
                      props.row?.type === 'Regional'
                    ? MapCountryFlag[0]
                    : MapCountryFlag[0]}
                </Grid>
              )}
            </Grid>
          </CardContent>
        </Collapse>
        {!props.loginAsUser && (
          <CardActions disableSpacing sx={{ justifyContent: 'center', p: 0 }}>
            <ExpandMore
              expand={expanded}
              onClick={handleExpandClick}
              aria-expanded={expanded}
              aria-label='show more'
              sx={{ p: 0 }}
            >
              <ExpandMoreIcon color='primary' fontSize={'large'} />
            </ExpandMore>
          </CardActions>
        )}
      </Card>
    </Grid>
  );
}
