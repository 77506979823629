import i18n from '../utils/i18n';

export const projectTypeOptionss = () => {
  return [
    {
      id: '0123X000001GJUGQA4',
      label: i18n.t('choose_project.type1'),
      subLabel: i18n.t('choose_project.type1_text'),
    },
    {
      id: '0123X000001GJUCQA4',
      label: i18n.t('choose_project.type2'),
      subLabel: i18n.t('choose_project.type2_text'),
    },
    {
      id: '0123X000001GJUDQA4',
      label: i18n.t('choose_project.type3'),
      subLabel: i18n.t('choose_project.type3_text'),
    },
    {
      id: '0123X000001GJUEQA4',
      label: i18n.t('choose_project.type4'),
      subLabel: i18n.t('choose_project.type4_text'),
    },
    {
      id: '0123X000001GJUFQA4',
      label: i18n.t('choose_project.type5'),
      subLabel: i18n.t('choose_project.type5_text'),
    },
  ];
};

export const projectTypeOptionsNew = () => {
  return [
    {
      id: '0123X000001GJUGQA4',
      label: i18n.t('choose_project_new.type1'),
      subLabel: i18n.t('choose_project_new.type1_text'),
    },
    {
      id: '0123X000001GJUCQA4',
      label: i18n.t('choose_project_new.type2'),
      subLabel: i18n.t('choose_project_new.type2_text'),
    },
    {
      // id: '0123X000001GJUDQA4',
      id: '0123X000001GJUEQA4',
      label: i18n.t('choose_project_new.type3'),
      subLabel: i18n.t('choose_project_new.type3_text'),
    },
    {
      // id: '0123X000001GJUEQA4',
      id: '0123X000001GJUDQA4',
      label: i18n.t('choose_project_new.type4'),
      subLabel: i18n.t('choose_project_new.type4_text'),
    },
  ];
};

export const mapProjectType = (type) => {
  switch (type) {
    case '0123X000001GJUGQA4':
      return 'Techniek & Innovatie';
    case '0123X000001GJUCQA4':
      return 'Internationaal Ondernemen';
    case '0123X000001GJUDQA4':
      return 'Energie & Duurzaamheid';
    case '0123X000001GJUEQA4':
      return 'Personeel';
    case '0123X000001GJUFQA4':
      return 'Overige';
    default:
      return '';
  }
};

export const projectTypeThemess = () => {
  return [
    {
      id: '0123X000001GJUGQA4',
      value: 'Techniek & Innovatie',
      label: i18n.t('choose_project.type1'),
      subLabel: i18n.t('choose_project.type1_text'),
    },
    {
      id: '0123X000001GJUCQA4',
      value: 'Internationaal Ondernemen',
      label: i18n.t('choose_project.type2'),
      subLabel: i18n.t('choose_project.type2_text'),
    },
    {
      id: '0123X000001GJUDQA4',
      value: 'Energie & Duurzaamheid',
      label: i18n.t('choose_project.type3'),
      subLabel: i18n.t('choose_project.type3_text'),
    },
    {
      id: '0123X000001GJUEQA4',
      value: 'Personeel',
      label: i18n.t('choose_project.type4'),
      subLabel: i18n.t('choose_project.type4_text'),
    },
    {
      value: 'Overige',
      id: '0123X000001GJUFQA4',
      label: i18n.t('choose_project.type5'),
      subLabel: i18n.t('choose_project.type5_text'),
    },
  ];
};
