import React from 'react';
import { useTranslation } from 'react-i18next';
import {
  Grid,
  Typography,
  TableBody,
  TableRow,
  TableCell,
} from '@mui/material';

const NoResultsFound = (props) => {
  const { t } = useTranslation();

  return (
    <TableBody>
      <TableRow
        sx={{
          '&:last-child td': {
            borderBottom: 0,
          },
        }}
      >
        <TableCell
          colSpan={props.span}
          style={{
            width: '100%',
          }}
        >
          <Grid container justifyContent="center" justifyItems="center">
            <Grid container justifyContent="center">
              <Typography variant="h6" sx={{ fontSize: 24 }}>
                {t('messages.no_results_title')}
              </Typography>
            </Grid>
            <Grid container justifyContent="center">
              <Typography
                sx={{ color: (theme) => theme.palette.text.disabled }}
              >
                {t('messages.no_results_subtitle')}
              </Typography>
            </Grid>
          </Grid>
        </TableCell>
      </TableRow>
    </TableBody>
  );
};

export default NoResultsFound;
