import React from 'react';
import useMediaQuery from '@mui/material/useMediaQuery';
import {
  Box,
  Grid,
  Card,
  CardActions,
  CardContent,
  Typography,
  Divider,
} from '@mui/material';
import { theme } from '../theme/BrightDesignTheme';

const PageDescriptionCard = (props) => {
  const {
    variant,
    customMinWidth,
    customMaxWidth,
    hasContent,
    hasActions,
    cardTitle,
    cardContent,
    cardContentComponent,
    hasCardSide,
    cardImage,
    cardAction,
    cardActionComponent,
    buttonVariant,
    startIcon,
    handleCardAction,
    handleClickAdd,
    hasUpload,
    uploadComponent,
  } = props;
  const isLargeScreen = useMediaQuery('(min-width:1280px)');

  return (
    <Box
      sx={{
        minWidth: customMinWidth ? customMinWidth : 275,
        maxWidth: customMaxWidth ? customMaxWidth : 786,
        margin: 'auto',
      }}
    >
      <Card
        variant={variant ? variant : ''}
        sx={{
          boxShadow: theme.custom.boxshadow.boxShadow,
          borderRadius: 2,
        }}
      >
        {hasContent && (
          <CardContent
            sx={{
              padding: 3,
            }}
          >
            <Grid container>
              <Grid
                alignContent='center'
                container
                item
                xs={isLargeScreen && hasCardSide ? 7 : 12}
              >
                <Grid item xs={12}>
                  <Box mb={1.5}>
                    <Typography variant='h6'>{cardTitle}</Typography>
                  </Box>
                </Grid>
                <Grid item xs={12}>
                  <Box>
                    {cardContent && (
                      <Typography
                        variant='body2'
                        color='textSecondary'
                        sx={{ color: 'text.hint' }}
                      >
                        {cardContent}
                      </Typography>
                    )}
                    {cardContentComponent && cardContentComponent}
                  </Box>
                </Grid>
              </Grid>
              {hasCardSide && (
                <Grid item xs={isLargeScreen ? 5 : 12}>
                  {cardImage && cardImage}
                </Grid>
              )}
            </Grid>
          </CardContent>
        )}
        {hasActions && (
          <>
            <Divider />
            <CardActions>
              {cardActionComponent ? cardActionComponent : <></>}
            </CardActions>
          </>
        )}
      </Card>
    </Box>
  );
};

export default PageDescriptionCard;
