import React, { useState, useEffect, useContext } from 'react';
import { useHistory, withRouter } from 'react-router-dom';
import { Grid, Divider, Typography, TextField, Button } from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';
import enLocale from 'date-fns/locale/en-US';
import nlLocale from 'date-fns/locale/nl';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import { theme as bdtheme } from '../../theme/BrightDesignTheme';
import { newProjectDoughnutData } from '../../data/picklistOptions';
import NivoDoughnut from '../../uiComponents/NivoDoughnut';
import { useMediaQuery } from 'react-responsive';
import { useStyles } from './styles';
import { ReactComponent as SadFace } from '../../assets/Sad_face_TwoColor_1.svg';
import { useTranslation } from 'react-i18next';
import i18n from 'i18next';
import { handleChange } from '../../JS/FormControls';
import { NewProjectContext } from '../../contexts/NewProject';
import { UserContext } from '../../contexts/UserContext';
import axios from 'axios';
import Loader from '../../uiComponents/Loader';
import NoResultsDialog from '../../uiComponents/NoResultsDialog';
import NewProjectBaseDetails from './NewProjectBaseDetails';
import CustomDialog from '../../uiComponents/CustomDialog';

const localeMap = {
  en: enLocale,
  nl: nlLocale,
};
const localeCancelLabelMap = {
  en: 'Cancel',
  nl: 'Annuleren',
};
const localeClearLabelMap = {
  en: 'Clear',
  nl: 'Verwijderen',
};

const NewProjectTypeInternationalOptimized = (props) => {
  const classes = useStyles();
  const history = useHistory();
  const isLarge = useMediaQuery({ query: '(min-width: 1280px)' });
  const isLargeScreen = useMediaQuery({ query: '(min-width: 1280px)' });
  const { t } = useTranslation();
  const COLORS = [bdtheme.palette.primary.main, 'rgba(224, 224, 224, 1)'];
  const data = newProjectDoughnutData(
    props.progressStep,
    props.showMoreDetails
  );
  const {
    projectType,
    projectInt,
    setProjectInt,
    newProjectId,
    setNewProjectId,
    active,
    setActive,
    expand,
    setExpand,
    activitiesInt,
    setActivitiesInt,
    setPopularSubsidies,
    setOtherSubsidies,
    setAssignments,
    setSelectedPopularSubsidies,
    setSelectedAssignments,
    accountId,
  } = useContext(NewProjectContext);

  const [loading, setLoading] = React.useState(false);
  const [userContext] = useContext(UserContext);
  const [disabled, setDisabled] = useState(true);
  const [nosubsidies, setNosubsidies] = useState(false);

  const [openDialogReset, setOpenDialogReset] = useState(false);
  const { showMoreDetails, setShowMoreDetails } = props;

  const ROUTES = [
    '/app/newproject/choose',
    '/app/newproject/upload',
    '/app/newproject/subsidies',
  ];
  const [openStartDate, setOpenStartDate] = React.useState(false);
  const [openEndDate, setopenEndDate] = React.useState(false);

  const [locale, setLocale] = useState('en');
  const selectLocale = React.useCallback((locale) => {
    setLocale(i18n.languages[0]);
  }, []);

  const handleChangeSwitch = (event) => {
    if (!event.target.checked) {
      if (
        projectInt.startDate != null ||
        projectInt.endDate != null ||
        active.length > 0 ||
        expand.length > 0 ||
        activitiesInt.length > 0
      ) {
        setOpenDialogReset(true);
      } else {
        setShowMoreDetails(event.target.checked);
      }
    } else {
      setShowMoreDetails(event.target.checked);
    }
  };

  const handleConfirmReset = () => {
    setProjectInt({
      ...projectInt,
      startDate: null,
      endDate: null,
      countriesActive: '',
      countriesExpanding: '',
      activities: '',
    });
    setShowMoreDetails(false);
    setActive([]);
    setExpand([]);
    setActivitiesInt([]);

    if (projectInt.id && projectInt.id !== null) {
      try {
        setLoading(true);

        let vals1 = active.map((a) => a.value);
        let vals2 = expand.map((a) => a.value);
        let vals3 = activitiesInt.map((a) => a.value);

        projectInt.countriesActive = vals1.join(';');
        projectInt.countriesExpanding = vals2.join(';');
        projectInt.activities = vals3.join(';');

        const apiEndPoint =
          process.env.NODE_ENV === 'development'
            ? process.env.REACT_APP_API_ENDPOINT
            : process.env.REACT_APP_API_ENDPOINT_PROD;

        let body = {
          projectId: projectInt.id,
        };
        axios({
          method: 'post',
          url: apiEndPoint + '/v1/partner/project/resetProjectFields',
          withCredentials: true,
          data: body,
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
            Authorization: `Bearer ${userContext.token}`,
          },
        })
          .then((response) => {
            setLoading(false);
          })
          .catch((error) => {
            setLoading(false);
          });
      } catch (err) {}
    }
  };

  const handleNext = () => {
    setLoading(true);
    let vals1 = active.map((a) => a.value);
    let vals2 = expand.map((a) => a.value);
    let vals3 = activitiesInt.map((a) => a.value);
    projectInt.countriesActive = vals1.join(';');
    projectInt.countriesExpanding = vals2.join(';');
    projectInt.activities = vals3.join(';');

    let body = {
      Project: projectInt,
      id: newProjectId,
      accountId: accountId,
    };

    const apiEndPoint =
      process.env.NODE_ENV === 'development'
        ? process.env.REACT_APP_API_ENDPOINT
        : process.env.REACT_APP_API_ENDPOINT_PROD;

    axios({
      method: 'post',
      url: apiEndPoint + '/v1/partner/project/addProject',
      data: body,
      withCredentials: true,
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        Authorization: `Bearer ${userContext.token}`,
      },
    })
      .then((res) => {
        setNewProjectId(res.data.projectId);
        let reqBody = {
          projectId: res.data.projectId,
        };
        axios({
          method: 'post',
          url: apiEndPoint + '/v1/partner/projectscan/projectscanOptimized',
          data: reqBody,
          withCredentials: true,
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
            Authorization: `Bearer ${userContext.token}`,
          },
        })
          .then((res) => {
            setLoading(false);
            props.setProgressStep(props.progressStep + 1);
            setPopularSubsidies(res.data.popularFirstWithScore);
            setOtherSubsidies(res.data.otherSubsidiesWithScore);
            setAssignments(res.data.assignments);
            const newSelectedPopular = res.data.popularFirstWithScore.map(
              (n) => n.id
            );
            setSelectedPopularSubsidies(newSelectedPopular);
            const newSelectedAssignments = res.data.assignments.map(
              (n) => n.subsidyId.id
            );
            setSelectedAssignments(newSelectedAssignments);
            showMoreDetails ? history.push(ROUTES[2]) : history.push(ROUTES[1]);
          })
          .catch((error) => {
            setLoading(false);
          });
      })
      .catch((error) => {
        setLoading(false);
      });
  };

  const handleBack = () => {
    if (props.progressStep !== 0) {
      props.setProgressStep(props.progressStep - 1);
      history.push(ROUTES[0]);
    }
  };

  useEffect(() => {
    if (
      projectInt.name &&
      projectInt.idea &&
      projectInt.problemStatement &&
      projectInt.subsidyNeed &&
      projectInt.projectTypeId
    ) {
      if (!showMoreDetails) {
        setDisabled(false);
      } else if (
        projectInt.startDate != null &&
        projectInt.endDate != null &&
        active.length > 0 &&
        expand.length > 0 &&
        activitiesInt.length > 0
      ) {
        setDisabled(false);
      } else {
        setDisabled(true);
      }
    } else {
      setDisabled(true);
    }

    if (!projectInt.projectTypeId || !projectInt.accountId) {
      setProjectInt({
        ...projectInt,
        projectTypeId: projectType,
        accountId: accountId,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [projectInt, active, expand, activitiesInt, showMoreDetails]);

  useEffect(() => {
    if (
      projectInt.startDate != null ||
      projectInt.endDate != null ||
      active.length > 0 ||
      expand.length > 0 ||
      activitiesInt.length > 0
    ) {
      setShowMoreDetails(true);
    } else {
      setShowMoreDetails(false);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [projectInt.startDate, projectInt.endDate, active, expand, activitiesInt]);

  useEffect(() => {
    let vals1, vals2, vals3;
    if (projectInt.countriesActive) {
      let selVals = projectInt.countriesActive.split(';');
      vals1 = props.countryList.filter(
        (opt) => selVals.indexOf(opt.value) !== -1
      );
      setActive(vals1);
    }
    if (projectInt.countriesExpanding) {
      let selVals = projectInt.countriesExpanding.split(';');
      vals2 = props.countryList.filter(
        (opt) => selVals.indexOf(opt.value) !== -1
      );
      setExpand(vals2);
    }
    if (projectInt.activities) {
      let selVals = projectInt.activities.split(';');
      vals3 = props.internationalActivities.filter(
        (opt) => selVals.indexOf(opt.value) !== -1
      );
      setActivitiesInt(vals3);
    }
    selectLocale();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      {loading && <Loader loading={loading} />}
      <Grid container justifyContent='center' className={classes.root}>
        <Grid
          container
          item
          xs={12}
          justifyContent='center'
          alignContent='center'
        >
          {/*<>SECTION</>*/}

          <NewProjectBaseDetails
            classes={classes}
            isLarge={isLarge}
            isLargeScreen={isLargeScreen}
            project={projectInt}
            setProject={setProjectInt}
            handleChange={handleChange}
            showMoreDetails={showMoreDetails}
            handleChangeSwitch={handleChangeSwitch}
            label1={t('project-details.title')}
            label2={t('project-details.subtitle')}
            label3={t('project-details.project')}
            label4={t('labels.project_name')}
            label5={t('projectX.label2')}
            label6={t('projectX.label3')}
            label7={t('labels.subsidie')}
            label8={t('project-details.more_details')}
            helper1={t('helper_text.project_name')}
            helper2={t('helper_text.subsidie')}
            helper3={t('helper_text.description1')}
            helper4={t('helper_text.description2')}
            placeholder1={t('project-details.placeholder_idea')}
            placeholder2={t('project-details.placeholder_solution')}
          />

          {/*<>SECTION DETAILS</>*/}
          {showMoreDetails && (
            <>
              <Grid container item xs={12} className={classes.section}>
                <Grid container item xs={12}>
                  {/*<> ACTIVE </>*/}
                  <Grid
                    container
                    item
                    xs={12}
                    lg={isLarge ? 6 : 12}
                    justifyContent={isLarge ? 'flex-start' : 'center'}
                    className={classes.subSection}
                  >
                    <Grid container item xs={12}>
                      <Grid item xs={12}>
                        <Typography
                          variant='overline'
                          color='textSecondary'
                          className={classes.subTitle}
                        >
                          {t('project-details.active_countries')}
                        </Typography>
                      </Grid>
                      <Grid item xs={12}>
                        <Autocomplete
                          multiple
                          value={active}
                          id='activeCountries'
                          options={props.countryList}
                          getOptionLabel={(option) => option.label}
                          defaultValue={[]}
                          className={
                            classes.formControl +
                            ' ' +
                            classes.autocompleteMultiple
                          }
                          disableCloseOnSelect
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              variant='outlined'
                              label={t(
                                'project-details.active_countries_label'
                              )}
                              placeholder=''
                              className={classes.helperText}
                              helperText={t('helper_text.select2')}
                              InputLabelProps={{
                                shrink: true,
                              }}
                            />
                          )}
                          onChange={(e, values) => {
                            setActive(values);
                          }}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                  {/*<> EXPAND </>*/}
                  <Grid
                    container
                    item
                    xs={12}
                    lg={isLarge ? 6 : 12}
                    justifyContent={isLarge ? 'flex-end' : 'center'}
                    className={classes.subSection}
                  >
                    <Grid item xs={12}>
                      <Typography
                        variant='overline'
                        color='textSecondary'
                        className={classes.subTitle}
                      >
                        {t('project-details.expand_countries')}
                      </Typography>
                    </Grid>
                    <Grid item xs={12}>
                      <Autocomplete
                        multiple
                        value={expand}
                        id='expandCountries'
                        options={props.countryList}
                        getOptionLabel={(option) => option.label}
                        defaultValue={[]}
                        className={
                          classes.formControl +
                          ' ' +
                          classes.autocompleteMultiple
                        }
                        disableCloseOnSelect
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            variant='outlined'
                            label={t('project-details.expand_countries_label')}
                            placeholder=''
                            className={classes.helperText}
                            helperText={t('helper_text.select2')}
                            InputLabelProps={{
                              shrink: true,
                            }}
                          />
                        )}
                        onChange={(e, values) => {
                          setExpand(values);
                        }}
                      />
                    </Grid>
                  </Grid>
                  <Grid item xs={12}>
                    <Divider className={classes.divider} />
                  </Grid>
                </Grid>
              </Grid>
              {/*<>SECTION</>*/}
              <Grid container item xs={12} className={classes.section}>
                <Grid container alignItems='flex-start' item xs={12}>
                  {/*<>DATES</>*/}
                  <Grid
                    container
                    item
                    xs={12}
                    lg={isLarge ? 6 : 12}
                    justifyContent={isLarge ? 'flex-start' : 'center'}
                    className={classes.subSection}
                  >
                    <Grid container item xs={12}>
                      <Grid item xs={12}>
                        <Typography
                          variant='overline'
                          color='textSecondary'
                          className={classes.subTitle}
                        >
                          {t('project-details.period')}
                        </Typography>
                      </Grid>
                      <Grid item xs={12}>
                        <LocalizationProvider
                          dateAdapter={AdapterDateFns}
                          adapterLocale={localeMap[locale]}
                        >
                          <DatePicker
                            onClick={() => setOpenStartDate(true)}
                            onClose={() => setOpenStartDate(false)}
                            open={openStartDate}
                            className={
                              classes.helperText + ' ' + classes.formControl
                            }
                            clearable
                            inputVariant='outlined'
                            inputFormat='dd MMM yyyy'
                            id='startDate'
                            name='startDate'
                            label={t('labels.start_date')}
                            toolbarPlaceholder=''
                            cancelText={localeCancelLabelMap[locale]}
                            clearText={localeClearLabelMap[locale]}
                            helperText={t('helper_text.start_date')}
                            fullWidth
                            onChange={(date) =>
                              setProjectInt({
                                ...projectInt,
                                startDate: date,
                              })
                            }
                            value={projectInt.startDate ?? null}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                variant='outlined'
                                margin='dense'
                                // size='small'
                                fullWidth
                                onMouseDown={(e) => {
                                  setOpenStartDate(true);
                                }}
                                className={
                                  classes.helperText + ' ' + classes.formControl
                                }
                              />
                            )}
                          ></DatePicker>
                        </LocalizationProvider>
                      </Grid>
                      <Grid item xs={12}>
                        <LocalizationProvider
                          dateAdapter={AdapterDateFns}
                          adapterLocale={localeMap[locale]}
                        >
                          <DatePicker
                            onClick={() => setopenEndDate(true)}
                            onClose={() => setopenEndDate(false)}
                            open={openEndDate}
                            className={
                              classes.helperText + ' ' + classes.formControl
                            }
                            clearable
                            inputVariant='outlined'
                            inputFormat='dd MMM yyyy'
                            id='endDate'
                            name='endDate'
                            label={t('labels.end_date')}
                            toolbarPlaceholder=''
                            // toolbarTitle
                            cancelText={localeCancelLabelMap[locale]}
                            clearText={localeClearLabelMap[locale]}
                            helperText={t('helper_text.end_date')}
                            fullWidth
                            onChange={(date) =>
                              setProjectInt({
                                ...projectInt,
                                endDate: date,
                              })
                            }
                            value={projectInt.endDate ?? null}
                            minDate={projectInt.startDate}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                variant='outlined'
                                margin='dense'
                                // size='small'
                                fullWidth
                                onMouseDown={(e) => {
                                  setopenEndDate(true);
                                }}
                                className={
                                  classes.helperText + ' ' + classes.formControl
                                }
                              />
                            )}
                          ></DatePicker>
                        </LocalizationProvider>
                      </Grid>
                    </Grid>
                  </Grid>

                  <Grid
                    container
                    item
                    xs={12}
                    lg={isLarge ? 6 : 12}
                    justifyContent={isLarge ? 'flex-end' : 'center'}
                    className={classes.subSection}
                  >
                    <Grid container item xs={12}>
                      <Grid item xs={12}>
                        <Typography
                          variant='overline'
                          className={classes.subTitle}
                          style={isLarge ? {} : { display: 'none' }}
                        >
                          &nbsp;
                        </Typography>
                      </Grid>
                      <Grid item xs={12}>
                        <Autocomplete
                          multiple
                          value={activitiesInt}
                          id='activities'
                          options={props.internationalActivities}
                          getOptionLabel={(option) => option.label}
                          defaultValue={[]}
                          className={
                            classes.formControl +
                            ' ' +
                            classes.autocompleteMultiple
                          }
                          disableCloseOnSelect
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              variant='outlined'
                              label={t('labels.activities')}
                              placeholder=''
                              className={classes.helperText}
                              helperText={t('helper_text.select2')}
                              InputLabelProps={{
                                shrink: true,
                              }}
                            />
                          )}
                          onChange={(e, values) => {
                            setActivitiesInt(values);
                          }}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </>
          )}

          {/*<>BUTTONS SECTION</>*/}
          <Grid
            container
            item
            xs={12}
            justifyContent='center'
            className={classes.section + ' ' + classes.sectionMobile}
          >
            <Grid container item xs={12} className={classes.sectionButtons}>
              <Grid item xs={12}>
                <Divider />
              </Grid>
              <Grid
                container
                item
                xs={12}
                className={classes.buttons}
                style={
                  isLargeScreen
                    ? {}
                    : {
                        flexWrap: 'nowrap',
                        justifyContent: 'space-between',
                      }
                }
              >
                <>
                  {!isLargeScreen && (
                    <Grid item>
                      <div style={{ height: 40, width: 40 }}>
                        <NivoDoughnut
                          data={data}
                          center={props.center}
                          colors={COLORS}
                          smallFont
                        />
                      </div>
                    </Grid>
                  )}
                  <Grid
                    item
                    container
                    justifyContent={
                      isLargeScreen ? 'space-between' : 'flex-end'
                    }
                  >
                    <Button
                      style={{ marginRight: 16 }}
                      startIcon={<KeyboardArrowLeftIcon />}
                      onClick={handleBack}
                    >
                      {t('buttons.back')}
                    </Button>
                    <Button
                      color='primary'
                      variant='contained'
                      endIcon={<KeyboardArrowRightIcon />}
                      onClick={handleNext}
                      disabled={disabled}
                    >
                      {t('buttons.next')}
                    </Button>
                  </Grid>
                </>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      <NoResultsDialog
        openDialog={nosubsidies}
        setOpenDialog={setNosubsidies}
        title={t('select_subsidies.no_subsidies')}
        text={t('select_subsidies.no_subsidies_text')}
        image={
          <>
            <SadFace />
          </>
        }
        alignment='center'
        cancel={t('buttons.cancel_2')}
        confirm={t('dialog.to_dashboard')}
        handleConfirm={props.handleConfirm}
      />

      {/* RESET DETAILS */}

      <CustomDialog
        openDialog={openDialogReset}
        setOpenDialog={setOpenDialogReset}
        handleConfirm={handleConfirmReset}
        handleClose={() => {
          setOpenDialogReset(false);
        }}
        title={t('dialog.reset_project_2')}
        text={t('dialog.reset_project_3')}
        cancel={t('yes_no.no')}
        confirm={t('yes_no.yes')}
      />
    </>
  );
};

export default withRouter(NewProjectTypeInternationalOptimized);
