/* MAIN COMPONENTS HAVE 24PX PADDING HORIZONTALY (16PX < LG) */
import React, { useState, useContext, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import BaseLayout from '../layouts/TopBarLevel1Layout';
import NewProjectUploadTypeProduct from '../components/NewProject/NewProjectUploadTypeProduct';
import NewProjectUploadTypeInternational from '../components/NewProject/NewProjectUploadTypeInternational';
import NewProjectUploadTypeBusiness from '../components/NewProject/NewProjectUploadTypeBusiness';
import NewProjectUploadTypeEmployees from '../components/NewProject/NewProjectUploadTypeEmployees';
import NewProjectSidebar from '../components/NewProject/NewProjectSidebar';
import { NewProjectContext } from '../contexts/NewProject';

const NewProjectUploadDocuments = () => {
  const { t } = useTranslation();
  const history = useHistory();

  const {
    projectType,
    newProjectId,
    setPercentage,
    setProjectType,
    setProjectProduct,
    setProjectInt,
    setProjectBusiness,
    setProjectEmployees,
    setNewProjectId,
    setIndustries,
    setActive,
    setExpand,
    setActivitiesInt,
    setActivities,
    setThemes,
    setTarget,
    showMoreDetails,
    setAccountId,
    accountId,
    setSectors,
    setSubSectors,
  } = useContext(NewProjectContext);
  const [progressStep, setProgressStep] = useState(showMoreDetails ? 4 : 3);
  const [projectProgress, setProjectProgress] = useState(
    showMoreDetails ? 4 : 3
  );
  const [openDialog, setOpenDialog] = useState(false);
  let totalSteps = showMoreDetails ? 5 : 4;
  const center = Math.round((100 / totalSteps) * progressStep);

  const handleConfirm = () => {
    const backAccountId = accountId;
    setProjectProgress(0);
    setPercentage(0);
    setProjectType('');
    setNewProjectId('');
    setIndustries([]);
    setActive([]);
    setExpand([]);
    setActivitiesInt([]);
    setActivities([]);
    setThemes([]);
    setTarget([]);
    setAccountId('');
    setSectors([]);
    setSubSectors([]);
    setProjectProduct({
      name: '',
      idea: '',
      problemStatement: '',
      startDate: null,
      endDate: null,
      noOfRnDEmployees: '',
      rndHours: '',
      rndCosts: '',
      activitiesCarriedOutBy: '',
      industrySector: '',
      newness: '',
      projectFocus: '',
      preTRLLevel: '',
      postTRLLevel: '',
      subsidyNeed: '',
      projectTypeId: '',
      accountId: '',
      keySectors: '',
      subSectors: '',
      id: '',
    });
    setProjectInt({
      name: '',
      idea: '',
      problemStatement: '',
      startDate: null,
      endDate: null,
      countriesActive: '',
      countriesExpanding: '',
      activities: '',
      subsidyNeed: '',
      projectTypeId: '',
      accountId: '',
      id: '',
    });
    setProjectBusiness({
      name: '',
      idea: '',
      problemStatement: '',
      startDate: null,
      activities: '',
      subsidyNeed: '',
      projectTypeId: '',
      accountId: '',
      id: '',
    });
    setProjectEmployees({
      name: '',
      idea: '',
      problemStatement: '',
      learningWorkPrograms: '',
      investmentInEmployees: '',
      hiredWorkersAtDisadvantage: '',
      willHireWorkersAtDisadvantage: '',
      subsidyNeed: '',
      projectTypeId: '',
      accountId: '',
      id: '',
    });
    history.push(`/app/accounts/${backAccountId}`);
  };

  useEffect(() => {
    window.document.title = t('page_title.upload');
    window.scrollTo(0, 0);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <BaseLayout
        main={
          <>
            {projectType === '0123X000001GJUGQA4' && (
              <NewProjectUploadTypeProduct
                center={`${center}%`}
                openDialog={openDialog}
                setOpenDialog={setOpenDialog}
                progressStep={progressStep}
                setProgressStep={setProgressStep}
                newProjectId={newProjectId}
                showMoreDetails={showMoreDetails}
                setPercentage={setPercentage}
                setProjectType={setProjectType}
                setNewProjectId={setNewProjectId}
                setIndustries={setIndustries}
                setActive={setActive}
                setExpand={setExpand}
                setActivitiesInt={setActivitiesInt}
                setActivities={setActivities}
                setThemes={setThemes}
                setTarget={setTarget}
                setProjectProduct={setProjectProduct}
                setProjectInt={setProjectInt}
                setProjectBusiness={setProjectBusiness}
                setProjectProgress={setProjectProgress}
                setProjectEmployees={setProjectEmployees}
                accountId={accountId}
                setAccountId={setAccountId}
                setSectors={setSectors}
                setSubSectors={setSubSectors}
              />
            )}
            {projectType === '0123X000001GJUCQA4' && (
              <NewProjectUploadTypeInternational
                center={`${center}%`}
                openDialog={openDialog}
                setOpenDialog={setOpenDialog}
                progressStep={progressStep}
                setProgressStep={setProgressStep}
                newProjectId={newProjectId}
                showMoreDetails={showMoreDetails}
                setPercentage={setPercentage}
                setProjectType={setProjectType}
                setNewProjectId={setNewProjectId}
                setIndustries={setIndustries}
                setActive={setActive}
                setExpand={setExpand}
                setActivitiesInt={setActivitiesInt}
                setActivities={setActivities}
                setThemes={setThemes}
                setTarget={setTarget}
                setProjectProduct={setProjectProduct}
                setProjectInt={setProjectInt}
                setProjectBusiness={setProjectBusiness}
                setProjectEmployees={setProjectEmployees}
                setProjectProgress={setProjectProgress}
                accountId={accountId}
                setAccountId={setAccountId}
                setSectors={setSectors}
                setSubSectors={setSubSectors}
              />
            )}
            {projectType === '0123X000001GJUDQA4' && (
              <NewProjectUploadTypeBusiness
                center={`${center}%`}
                openDialog={openDialog}
                setOpenDialog={setOpenDialog}
                progressStep={progressStep}
                setProgressStep={setProgressStep}
                newProjectId={newProjectId}
                setPercentage={setPercentage}
                showMoreDetails={showMoreDetails}
                setProjectType={setProjectType}
                setNewProjectId={setNewProjectId}
                setIndustries={setIndustries}
                setActive={setActive}
                setExpand={setExpand}
                setActivitiesInt={setActivitiesInt}
                setActivities={setActivities}
                setThemes={setThemes}
                setTarget={setTarget}
                setProjectProduct={setProjectProduct}
                setProjectInt={setProjectInt}
                setProjectBusiness={setProjectBusiness}
                setProjectEmployees={setProjectEmployees}
                setProjectProgress={setProjectProgress}
                accountId={accountId}
                setAccountId={setAccountId}
                setSectors={setSectors}
                setSubSectors={setSubSectors}
              />
            )}
            {projectType === '0123X000001GJUEQA4' && (
              <NewProjectUploadTypeEmployees
                center={`${center}%`}
                openDialog={openDialog}
                setOpenDialog={setOpenDialog}
                progressStep={progressStep}
                setProgressStep={setProgressStep}
                newProjectId={newProjectId}
                showMoreDetails={showMoreDetails}
                setPercentage={setPercentage}
                setProjectType={setProjectType}
                setNewProjectId={setNewProjectId}
                setIndustries={setIndustries}
                setActive={setActive}
                setExpand={setExpand}
                setActivitiesInt={setActivitiesInt}
                setActivities={setActivities}
                setThemes={setThemes}
                setTarget={setTarget}
                setProjectProduct={setProjectProduct}
                setProjectInt={setProjectInt}
                setProjectBusiness={setProjectBusiness}
                setProjectEmployees={setProjectEmployees}
                setProjectProgress={setProjectProgress}
                accountId={accountId}
                setAccountId={setAccountId}
                setSectors={setSectors}
                setSubSectors={setSubSectors}
              />
            )}
          </>
        }
        sidebar={
          <NewProjectSidebar
            progressStep={progressStep}
            setProgressStep={setProgressStep}
            projectProgress={projectProgress}
            setProjectProgress={setProjectProgress}
            center={`${center}%`}
            showMoreDetails={showMoreDetails}
          />
        }
        dialog
        handleConfirm={handleConfirm}
        text={t('dialog.quit_new_project')}
        title={t('projects.card_new')}
      ></BaseLayout>
    </>
  );
};

export default NewProjectUploadDocuments;
