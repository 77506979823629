import React from 'react';
import { styled, alpha } from '@mui/material/styles';
import { tooltipCard, styleStatus } from '../styles';
import { enGB, enUS, nl } from 'date-fns/locale';
import { format } from 'date-fns';
import { tooltipClasses } from '@mui/material/Tooltip';
import {
  TableBody,
  TableCell,
  TableRow,
  Tooltip,
  Chip,
  Typography,
} from '@mui/material';
import CheckCircleOutlinedIcon from '@mui/icons-material/CheckCircleOutlined';
import CloseIcon from '@mui/icons-material/Close';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import CardTooltip from './CardTooltip';
import { statusLeadList } from '../../components/Leads/utils';
import { Link, withRouter } from 'react-router-dom';
import ChangeCircleOutlinedIcon from '@mui/icons-material/ChangeCircleOutlined';
import { useTheme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles((theme) => ({
  editable: {
    '&:hover .MuiSvgIcon-root': {
      display: 'inline-block !important',
      cursor: 'pointer',
    },
    '& [data-prop="ownerId"]': {
      width: '0.8em',
      height: '0.8em',
    },
  },
}));

const localeMap = {
  nl: nl,
  en: enGB || enUS,
};

const SimpleTableContent = (props) => {
  const classes = useStyles();
  const theme = useTheme();
  const {
    rows,
    statusMap,
    tooltipMap,
    hideBottomBorder,
    dateFormat,
    locale,
    role,
    setChangeOwnerDialog,
    setSelectedAccountId,
  } = props;

  const StyledTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: theme.palette.common.white,
      color: theme.palette.text.primary,
      boxShadow: theme.shadows[1],
      fontSize: 11,
      padding: 0,
      borderWidth: 0,
    },
  }));

  const StyledTooltipIcon = styled(({ className, icon, text, ...props }) => (
    <Tooltip
      {...props}
      classes={{ popper: className ?? className }}
      title={text}
      placement='top'
      arrow
    >
      <InfoOutlinedIcon
        sx={{
          color: icon,
          order: 1,
          mr: 0.5,
          ml: -1,
          transform: 'scale(0.75)',
        }}
      />
    </Tooltip>
  ))(({ theme }) => ({
    marginBottom: '8px !important',
  }));

  return (
    <TableBody>
      {rows.map((row, index) => {
        return (
          <TableRow
            hover
            tabIndex={-1}
            key={row.id || index}
            sx={{
              ...(hideBottomBorder && {
                '&:last-child td': {
                  borderBottom: 0,
                },
                '&:last-child a': {
                  borderBottom: 0,
                },
              }),
            }}
          >
            <TableCell
              component={Link}
              to={{
                pathname: `/app/accounts/${row.id}`,
                state: { name: row.name },
              }}
            >
              {row.name}
            </TableCell>
            <TableCell>
              <Chip
                label={
                  statusLeadList.includes(row.status)
                    ? statusMap[row.status]
                    : ''
                }
                variant='outlined'
                sx={{
                  ...(statusLeadList.includes(row.status) && {
                    bgcolor: (theme) =>
                      alpha(
                        styleStatus[row.status],
                        theme.palette.action.selectedOpacity
                      ),
                  }),
                }}
                icon={
                  statusLeadList.includes(row.status) &&
                  row.status !== 'Lead' ? (
                    <StyledTooltipIcon
                      text={
                        statusLeadList.includes(row.status)
                          ? tooltipMap[row.status]
                          : ''
                      }
                      icon={(theme) => theme.palette.text.secondary}
                    ></StyledTooltipIcon>
                  ) : (
                    <></>
                  )
                }
              />
            </TableCell>
            <TableCell>
              {row.createdAt
                ? format(new Date(row.createdAt), dateFormat, {
                    locale: localeMap[locale],
                  })
                : ''}
            </TableCell>
            <TableCell
              className={
                role === 'Admin' || role === '' || role === null
                  ? classes.editable
                  : ''
              }
            >
              {row.ownerFirstName + ' ' + row.ownerLastName}
              <ChangeCircleOutlinedIcon
                data-id={row.ownerId}
                data-prop='ownerId'
                style={{
                  display: 'none',
                  float: 'right',
                  fill: theme.palette.text.secondary,
                }}
                onClick={(event) => {
                  if (
                    event.currentTarget.getAttribute('data-id') ===
                      row.ownerId &&
                    event.currentTarget.getAttribute('data-prop') === 'ownerId'
                  ) {
                    setSelectedAccountId(row.id);
                    setChangeOwnerDialog(true);
                  }
                }}
              />
            </TableCell>
          </TableRow>
        );
      })}
    </TableBody>
  );
};

export default withRouter(SimpleTableContent);
