import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import AddLeadSecondScreen from './AddLeadSecondScreen';
import AddLeadFirstScreen from './AddLeadFirstScreen';

const AddNewLead = (props) => {
  const {
    setLoading,
    setFirstScreen,
    setOpenNewLeadDialog,
    setRefreshLeads,
    setSuccessAddLead,
  } = props;

  const { t } = useTranslation();
  const [startScreen, setStartScreen] = useState(true);

  const [hasValue, setHasValue] = useState(false);
  const [selectedCompany, setSelectedCompany] = useState('');

  useEffect(() => {
    setStartScreen(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      {startScreen ? (
        <AddLeadFirstScreen
          setLoader={setLoading}
          selectedCompany={selectedCompany}
          setSelectedCompany={setSelectedCompany}
          cancel={t('labels.cancel')}
          next={t('labels.next')}
          setStartScreen={setStartScreen}
          setFirstScreen={setFirstScreen}
          setOpenNewLeadDialog={setOpenNewLeadDialog}
          hasValue={hasValue}
          setHasValue={setHasValue}
        />
      ) : (
        <AddLeadSecondScreen
          setLoading={setLoading}
          setSelectedCompany={setSelectedCompany}
          back={t('labels.previous')}
          confirm={t('labels.finish')}
          setStartScreen={setStartScreen}
          setFirstScreen={setFirstScreen}
          setOpenNewLeadDialog={setOpenNewLeadDialog}
          setRefreshLeads={setRefreshLeads}
          setSuccessAddLead={setSuccessAddLead}
          hasValue={hasValue}
          setHasValue={setHasValue}
        />
      )}
    </>
  );
};

export default AddNewLead;
