import React, { useState, useEffect, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useIsMounted } from '../../utils/customHooks';
import axios from 'axios';
import { UserContext } from '../../contexts/UserContext';
import { WorkspaceContext } from '../../contexts/WorkspaceContext';
import {
  Button,
  TextField,
  Autocomplete,
  Grid,
  CircularProgress,
  IconButton,
  Snackbar,
  Box,
  Typography,
  FormControl,
  RadioGroup,
  FormControlLabel,
  Radio,
  Switch,
} from '@mui/material';
import Alert from '@mui/material/Alert';
import CloseIcon from '@mui/icons-material/Close';
import useDebounce from '../debounce';
import SearchIcon from '@mui/icons-material/Search';
import { useStyles } from './styles';
import {
  sectorOptionsEn,
  sectorOptionsNl,
  subSectorOptionsEn,
  subSectorOptionsNl,
  subSectorsMap,
} from '../../data/projectOptions';
import { scanmkbOptionss } from '../../data/picklistOptions';
import i18n from 'i18next';

const AddLeadFirstScreen = (props) => {
  const mkbOptions = scanmkbOptionss();
  const [sme, setSme] = useState(null);
  const {
    setLoader,
    selectedCompany,
    setSelectedCompany,
    cancel,
    next,
    setFirstScreen,
    setStartScreen,
    setOpenNewLeadDialog,
    hasValue,
    setHasValue,
  } = props;
  const classes = useStyles();
  const { t } = useTranslation();
  const isMounted = useIsMounted();
  const [userContext] = useContext(UserContext);
  const { newLead, setNewLead } = useContext(WorkspaceContext);

  const [searchString, setSearchString] = useState(
    newLead && newLead.company && newLead.company?.name
      ? newLead.company?.name
      : ''
  );
  const [sectors, setSectors] = useState([]);
  const [subSectors, setSubSectors] = useState([]);
  const [subSectorDependentOptions, setSubSectorDependentOptions] = useState(
    []
  );
  const [companyOptions, setCompanyOptions] = useState([]);
  const [isSearching, setIsSearching] = useState(false);
  const debouncedSearchTerm = useDebounce(searchString, 500);
  const [open, setOpen] = useState(false);
  const [errorExistingCompany, setErrorExistingCompany] = useState(false);

  useEffect(() => {
    let subSectorOptions =
      i18n.languages[0] === 'nl' ? subSectorOptionsNl : subSectorOptionsEn;
    let dependentSubSectorsOptions = [];
    let subSectorOptionsValues = new Set();
    let sectorValues = sectors.map((a) => a.value);
    sectorValues.forEach((item) => {
      subSectorsMap[item].forEach((item) => {
        subSectorOptionsValues.add(item);
      });
    });
    subSectorOptions.forEach((subSector) => {
      subSectorOptionsValues.forEach((subSectorValue) => {
        if (subSector.value === subSectorValue) {
          dependentSubSectorsOptions.push(subSector);
        }
      });
    });
    let subSectorValues = subSectors.map((a) => a.value);
    let dependentSubSectorsValues = [];
    subSectorValues.forEach((item) => {
      if (dependentSubSectorsOptions) {
        dependentSubSectorsValues = dependentSubSectorsOptions.filter(
          (opt) => subSectorValues.indexOf(opt.value) !== -1
        );
      }
    });
    setSubSectors(dependentSubSectorsValues);
    setSubSectorDependentOptions(dependentSubSectorsOptions);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sectors]);

  const apiEndPoint =
    process.env.NODE_ENV === 'development'
      ? process.env.REACT_APP_API_ENDPOINT
      : process.env.REACT_APP_API_ENDPOINT_PROD;

  const handleCloseSnackbar = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setErrorExistingCompany(false);
  };

  const handleSelectCompany = (company) => {
    if (company === null || company === undefined) {
      setSelectedCompany(company);
      setNewLead((oldValues) => {
        return {
          ...oldValues,
          company: {
            name: '',
            dossier_number: '',
            establishment_number: '',
            establishment_city: '',
            establishment_street: '',
            correspondence_city: '',
            correspondence_street: '',
            sme: '',
            keySectors: '',
            subSectors: '',
            doTheScan: false,
          },
          contact: {
            firstName: '',
            lastName: '',
            Email: '',
            phone: '',
            partnerParticipation: false,
            description: '',
          },
        };
      });
    } else {
      setSelectedCompany(company);
      setNewLead((oldValues) => {
        return {
          ...oldValues,
          company: company,
          contact: {
            firstName: '',
            lastName: '',
            Email: '',
            phone: '',
            partnerParticipation: false,
            description: '',
          },
        };
      });
    }
  };

  const handleCancelNewLead = () => {
    setNewLead({
      company: {
        name: '',
        dossier_number: '',
        establishment_number: '',
        establishment_city: '',
        establishment_street: '',
        correspondence_city: '',
        correspondence_street: '',
        sme: '',
        keySectors: '',
        subSectors: '',
        doTheScan: false,
      },
      contact: {
        firstName: '',
        lastName: '',
        Email: '',
        phone: '',
        partnerParticipation: false,
        description: '',
      },
    });
    setStartScreen(true);
    setFirstScreen(true);
    setOpenNewLeadDialog(false);
  };

  const handleNextScreenNewLead = (number) => {
    setLoader(true);
    axios({
      method: 'get',
      url: apiEndPoint + '/v1/partner/leads/validateExistingCompany',
      withCredentials: true,
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        Authorization: `Bearer ${userContext.token}`,
      },
      params: { registrationNumber: number },
    })
      .then((res) => {
        if (res && res.data === true) {
          if (isMounted.current) {
            setErrorExistingCompany(true);
            setFirstScreen(true);
            setLoader(false);
          }
        } else {
          if (isMounted.current) {
            setErrorExistingCompany(false);
            setStartScreen(false);
            setFirstScreen(false);
            setLoader(false);
          }
        }
      })
      .catch((error) => {
        if (isMounted.current) {
          console.log(error);
          setLoader(false);
        }
      });
  };

  async function searchCharacters(search) {
    return axios({
      method: 'post',
      url: apiEndPoint + '/v1/partner/leads/findCompany',
      withCredentials: true,
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        Authorization: `Bearer ${userContext.token}`,
      },
      data: { company_name: search },
    })
      .then((res) => {
        return res.data;
      })
      .catch((error) => {
        return [];
      });
  }

  useEffect(() => {
    let mounted = true;
    if (mounted) {
      if (newLead && newLead.company && newLead.company?.name) {
        setTimeout(() => {
          setSearchString(newLead.company?.name);
        }, 100);

        setHasValue(true);
      }
    }
    return () => {
      mounted = false;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [newLead.company]);

  useEffect(() => {
    const source = axios.CancelToken.source();
    let mounted = true;
    if (mounted) {
      if (debouncedSearchTerm && searchString.length > 2 && !hasValue) {
        setIsSearching(true);
        searchCharacters(debouncedSearchTerm).then((results) => {
          if (mounted) {
            setIsSearching(false);
            setCompanyOptions(results);
          }
        });
      } else {
        if (mounted) {
          setCompanyOptions([]);
        }
      }
    }
    if (mounted) {
      if (!searchString) {
        setNewLead({
          company: {
            name: '',
            dossier_number: '',
            establishment_number: '',
            establishment_city: '',
            establishment_street: '',
            correspondence_city: '',
            correspondence_street: '',
            sme: '',
            keySectors: '',
            subSectors: '',
            doTheScan: false,
          },
          contact: {
            firstName: '',
            lastName: '',
            Email: '',
            phone: '',
            partnerParticipation: false,
            description: '',
          },
        });
      }
    }
    return () => {
      mounted = false;
      source.cancel();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedSearchTerm]);

  useEffect(() => {
    let mounted = true;
    if (mounted) {
      if (!open) {
        setCompanyOptions([]);
      }
    }
    return () => {
      mounted = false;
    };
  }, [open]);

  return (
    <Grid container>
      <Grid item xs={12} pt={1}>
        <Autocomplete
          sx={{
            width: 1,
            '& .MuiButtonBase-root.MuiIconButton-root.MuiAutocomplete-popupIndicator':
              {
                transform: 'rotate(0)',
              },
          }}
          id='company_name'
          name='company_name'
          freeSolo
          forcePopupIcon
          open={open && searchString.length > 2}
          onOpen={() => {
            setOpen(true);
          }}
          onClose={() => {
            setOpen(false);
          }}
          options={companyOptions}
          getOptionLabel={(option) => option.name ?? ''}
          renderOption={(props, option) => {
            return (
              <Box
                component='li'
                {...props}
                key={
                  option.establishment_number && option.dossier_number
                    ? option.establishment_number + option.dossier_number
                    : option.establishment_number
                    ? option.establishment_number
                    : option.dossier_number
                    ? option.dossier_number
                    : option.name + option.establishment_city
                }
              >
                {option.name ?? ''}
              </Box>
            );
          }}
          loading={isSearching}
          noOptionsText={t('messages.no_options')}
          loadingText={t('messages.loading')}
          popupIcon={
            <SearchIcon
              sx={{
                transform: 'rotate(0)',
                '& svg': {
                  transform: 'rotate(0)',
                },
              }}
            />
          }
          inputValue={searchString}
          onInputChange={(e, newInputValue) => {
            setSearchString(newInputValue);
          }}
          onChange={(event, newValue) => {
            setHasValue(true);
            handleSelectCompany(newValue);
          }}
          onKeyDown={(event, newValue) => {
            if (event.key === 'Enter') {
              event.defaultMuiPrevented = true;
            }
            setHasValue(false);
          }}
          onBlur={(event) => {
            setHasValue(false);
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              variant='outlined'
              label={t('quick_subsidyscan.dialog_new_lead_search_label')}
              fullWidth
              placeholder={t(
                'quick_subsidyscan.dialog_new_lead_search_placeholder'
              )}
              onClick={() => {
                setHasValue(false);
              }}
              InputLabelProps={{
                shrink: true,
              }}
              InputProps={{
                ...params.InputProps,
                endAdornment: (
                  <>
                    {isSearching ? (
                      <CircularProgress color='inherit' size={20} />
                    ) : null}
                    {params.InputProps.endAdornment}
                  </>
                ),
              }}
            />
          )}
        />
      </Grid>
      <Grid item xs={12} mt={4}>
        <FormControlLabel
          control={
            <Switch
              defaultChecked
              checked={Boolean(newLead.company.doTheScan)}
              name={'doTheScan'}
              onChange={(e) => {
                setNewLead((oldValues) => {
                  return {
                    ...oldValues,
                    company: {
                      ...oldValues.company,
                      doTheScan: e.target.checked,
                    },
                  };
                });
              }}
            />
          }
          label={
            <Typography variant='body2' component='span' display={'inline'}>
              {t('quick_subsidyscan.doTheScan')}
            </Typography>
          }
        />
      </Grid>
      {newLead.company.doTheScan && (
        <Grid item xs={12} mt={4}>
          <Grid container spacing={3}>
            <Grid item xs={12} md={6}>
              <Autocomplete
                multiple
                id='sectors'
                value={sectors}
                options={
                  i18n.languages[0] === 'nl' ? sectorOptionsNl : sectorOptionsEn
                }
                getOptionLabel={(option) => option.label}
                defaultValue={[]}
                className={
                  classes.formControl + ' ' + classes.autocompleteMultiple
                }
                disableCloseOnSelect
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant='outlined'
                    label={t('labels.sectors')}
                    placeholder=''
                    className={classes.helperText}
                    helperText={t('quick_subsidyscan.sectors_label')}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                )}
                onChange={(e, values) => {
                  setSectors(values);
                  let vals = values.map((a) => a.value);
                  setNewLead((oldValues) => {
                    return {
                      ...oldValues,
                      company: {
                        ...oldValues.company,
                        keySectors: vals.join(';'),
                      },
                    };
                  });
                }}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <Typography variant='body2'>
                {t('quick_subsidyscan.sme_question')}
              </Typography>
              <FormControl component='fieldset'>
                <RadioGroup
                  aria-label={'mkb'}
                  name={'acc_sme__c'}
                  value={sme}
                  onChange={(event) => {
                    setSme(event.target.value);
                    setNewLead((oldValues) => {
                      return {
                        ...oldValues,
                        company: {
                          ...oldValues.company,
                          sme: event.target.value ?? '',
                        },
                      };
                    });
                  }}
                >
                  {mkbOptions.map((tile, i) => (
                    <FormControlLabel
                      key={i}
                      value={tile.value}
                      control={<Radio />}
                      label={tile.label}
                    ></FormControlLabel>
                  ))}
                </RadioGroup>
              </FormControl>
            </Grid>
            <Grid item xs={12} md={6}>
              {subSectorDependentOptions.length > 0 && (
                <>
                  <Autocomplete
                    multiple
                    id='subSectors'
                    value={subSectors}
                    options={subSectorDependentOptions}
                    getOptionLabel={(option) => option.label}
                    defaultValue={[]}
                    className={
                      classes.formControl + ' ' + classes.autocompleteMultiple
                    }
                    disableCloseOnSelect
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        variant='outlined'
                        label={t('labels.subSectors')}
                        placeholder=''
                        className={classes.helperText}
                        helperText={t('quick_subsidyscan.subSectors_label')}
                        InputLabelProps={{
                          shrink: true,
                        }}
                      />
                    )}
                    onChange={(e, values) => {
                      setSubSectors(values);
                      let vals = values.map((a) => a.value);
                      setNewLead((oldValues) => {
                        return {
                          ...oldValues,
                          company: {
                            ...oldValues.company,
                            subSectors: vals.join(';'),
                          },
                        };
                      });
                    }}
                  />
                </>
              )}
            </Grid>
          </Grid>
        </Grid>
      )}
      <Grid
        container
        justifyContent={'space-between'}
        item
        xs={12}
        sx={{ pt: 3 }}
      >
        {cancel && (
          <Grid item sx={{}}>
            <Button
              onClick={() => {
                handleCancelNewLead();
              }}
              color='primary'
            >
              {cancel}
            </Button>
          </Grid>
        )}
        {next && (
          <Grid item sx={{}}>
            <Button
              disabled={
                newLead.company.doTheScan
                  ? !searchString ||
                    (newLead && newLead.company.name === '') ||
                    (searchString &&
                      newLead &&
                      searchString !== newLead?.company?.name) ||
                    sectors.length === 0 ||
                    subSectors.length === 0 ||
                    sme === null
                  : !searchString ||
                    (newLead && newLead.company.name === '') ||
                    (searchString &&
                      newLead &&
                      searchString !== newLead?.company?.name)
              }
              onClick={() => {
                handleNextScreenNewLead(selectedCompany.dossier_number);
              }}
              color='primary'
            >
              {next}
            </Button>
          </Grid>
        )}
      </Grid>
      <Snackbar
        open={errorExistingCompany}
        onClose={handleCloseSnackbar}
        autoHideDuration={3000}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        style={{ top: 80 }}
        action={
          <IconButton
            size='small'
            aria-label='close'
            color='inherit'
            onClick={handleCloseSnackbar}
          >
            <CloseIcon fontSize='small' />
          </IconButton>
        }
      >
        <Alert
          severity='error'
          style={{ position: 'relative', paddingRight: 46 }}
        >
          {t('leads.existing_lead_error')}
          <IconButton
            size='small'
            aria-label='close'
            color='inherit'
            onClick={handleCloseSnackbar}
            style={{
              marginLeft: 10,
              position: 'absolute',
              right: 10,
              top: 10,
            }}
          >
            <CloseIcon fontSize='small' />
          </IconButton>
        </Alert>
      </Snackbar>
    </Grid>
  );
};

export default AddLeadFirstScreen;
