import i18n from './i18n';

export const yesNoLabels = () => {
  return {
    Yes: i18n.t('options.yes_no_list.yes'),
    No: i18n.t('options.yes_no_list.no'),
  };
};

export const dealsFilterOptions = () => {
  return [
    {
      id: '0',
      label: i18n.t('deals.filter.option_1'),
      value: 'This Year',
    },
    {
      id: '1',
      label: i18n.t('deals.filter.option_2'),
      value: 'This Quarter',
    },
    {
      id: '2',
      label: i18n.t('deals.filter.option_3'),
      value: 'Last Quarter',
    },
    {
      id: '3',
      label: i18n.t('deals.filter.option_4'),
      value: 'Last Year',
    },
    {
      id: '4',
      label: i18n.t('deals.filter.option_5'),
      value: 'Last 2 Years',
    },
    {
      id: '5',
      label: i18n.t('deals.filter.option_6'),
      value: 'All Time',
    },
  ];
};

export const dealsStageOptions = () => {
  return [
    {
      id: '0',
      label: 'Contacted',
      // label: i18n.t('deals.filter.option_3'),
      value: 'Contacted',
    },
    {
      id: '1',
      label: 'Interested in Proposal',
      // label: i18n.t('deals.filter.option_4'),
      value: 'Interested',
    },
    {
      id: '2',
      label: 'Waiting for Approval',
      // label: i18n.t('deals.filter.option_5'),
      value: 'Waiting',
    },
    {
      id: '3',
      label: 'Closed Deal',
      // label: i18n.t('deals.filter.option_6'),
      value: 'Closed',
    },
  ];
};
