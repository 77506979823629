import React from 'react';
import { Grid, Typography } from '@mui/material';

const NoResultsScreen = (props) => {
  return (
    <>
      <Grid container style={props.titleStyle} item xs={12}>
        <Typography variant="h6" component="h6">
          {props.title}
        </Typography>
      </Grid>

      <Grid container style={props.subtitleStyle} item xs={12}>
        <Typography color={props.colorText}>{props.subtitle}</Typography>
      </Grid>

      <Grid container item xs={12}>
        <Grid container style={props.contentStyle} item xs={12}>
          {props.content}
        </Grid>
      </Grid>
    </>
  );
};

export default NoResultsScreen;
