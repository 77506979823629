import React, { useState, useEffect, useContext } from 'react';
import { withRouter, useLocation } from 'react-router-dom';
import BaseLayout from '../layouts/BaseLayout';
import { useTranslation } from 'react-i18next';
import { useDocTitle, useIsMounted } from '../utils/customHooks';
import { root, rootContent } from './styles';
import { Grid, Snackbar, IconButton } from '@mui/material';
import Alert from '@mui/material/Alert';
import CloseIcon from '@mui/icons-material/Close';
import Loader from '../uiComponents/Loader';
import { LoginContext } from '../contexts/LoginContext';
import { UserContext } from '../contexts/UserContext';

import axios from 'axios';

const LoginAsUser = ({ history }) => {
  // eslint-disable-next-line no-unused-vars
  const location = useLocation();
  const search = useLocation().search;
  // let from = location.state?.from?.pathname || '/app';
  const { t } = useTranslation();
  const isMounted = useIsMounted();
  const { passReset, setPassReset } = useContext(LoginContext);
  // eslint-disable-next-line no-unused-vars
  const [userContext, setUserContext] = useContext(UserContext);
  // eslint-disable-next-line no-unused-vars
  const [docTitle, setDocTitle] = useDocTitle('');

  const [loading, setLoading] = useState(false);
  const [showError, setShowError] = useState(false);

  const handleCloseSnackbar = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setShowError(false);
    setPassReset(false);
  };

  useEffect(() => {
    // document.title = t('page_title.login');
    setDocTitle(t('page_title.login'));
    window.localStorage.clear();
    const _id = new URLSearchParams(search).get('id');
    const key = new URLSearchParams(search).get('API');
    handleLogin(_id, key);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      {loading && <Loader loading={loading} />}
      <BaseLayout
        main={
          <>
            <Grid
              container
              sx={root}
              justifyContent={'center'}
              alignItems={'center'}
              className='LoginPage'
            >
              <Grid container item wrap='nowrap' sx={rootContent} xs={12}>
                <Grid
                  container
                  item
                  justifyContent={'flex-start'}
                  alignItems={'center'}
                  xs={6}
                  sx={{ display: { xs: 'none', md: 'flex' } }}
                ></Grid>
                <Grid
                  container
                  item
                  xs
                  sx={{ justifyContent: { xs: 'center', md: 'flex-end' } }}
                >
                  {' '}
                </Grid>
              </Grid>
            </Grid>

            <Snackbar
              open={showError}
              onClose={handleCloseSnackbar}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'center',
              }}
              style={{ top: 80 }}
              action={
                <IconButton
                  size='small'
                  aria-label='close'
                  color='inherit'
                  onClick={handleCloseSnackbar}
                >
                  <CloseIcon fontSize='small' />
                </IconButton>
              }
            >
              <Alert
                severity='error'
                style={{ position: 'relative', paddingRight: 46 }}
              >
                {t('login.error')}
                <IconButton
                  size='small'
                  aria-label='close'
                  color='inherit'
                  onClick={handleCloseSnackbar}
                  style={{
                    marginLeft: 10,
                    position: 'absolute',
                    right: 10,
                    top: 10,
                  }}
                >
                  <CloseIcon fontSize='small' />
                </IconButton>
              </Alert>
            </Snackbar>

            <Snackbar
              open={passReset}
              onClose={handleCloseSnackbar}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'center',
              }}
              style={{ top: 80 }}
              className={'closeAlert'}
            >
              <Alert
                onClose={() => {
                  setPassReset(false);
                }}
                severity='success'
              >
                {t('forgot_password.success_1')}
              </Alert>
            </Snackbar>
          </>
        }
      ></BaseLayout>
    </>
  );

  function handleLogin(_id, key) {
    setLoading(true);

    let requestBody = {
      _id: _id,
    };

    let apiEndPoint =
      process.env.NODE_ENV === 'development'
        ? process.env.REACT_APP_API_ENDPOINT
        : process.env.REACT_APP_API_ENDPOINT_PROD;

    axios({
      method: 'post',
      url: apiEndPoint + '/v1/partner/auth/loginAsUser',
      data: requestBody,
      withCredentials: true,
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        apiKey: key,
      },
    })
      .then((response) => {
        if (response.data.user) {
          if (isMounted.current) {
            setLoading(false);
            localStorage.setItem(
              'partner-token',
              response.data.tokens.access.token
            );
            localStorage.setItem('partner-user', response.data.user);
            setUserContext((oldValues) => {
              return {
                ...oldValues,
                token: response.data.tokens.access.token,
                user: response.data.user,
              };
            });
          }
          history.push('/app/dashboard');
        }
      })
      .catch((error) => {
        if (isMounted.current) {
          setShowError(true);
          setLoading(false);
        }
      });
  }
};

export default withRouter(LoginAsUser);
