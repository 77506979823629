import React from 'react';
import { alpha } from '@mui/material/styles';
import { useTranslation } from 'react-i18next';
import { enGB, enUS, nl } from 'date-fns/locale';
import { format } from 'date-fns';
// eslint-disable-next-line no-unused-vars
import { grey } from '@mui/material/colors';
// eslint-disable-next-line no-unused-vars
import useMediaQuery from '@mui/material/useMediaQuery';
import {
  // Box,
  Grid,
  Card,
  CardContent,
  CardActions,
  Typography,
  Chip,
  Divider,
  IconButton,
  Collapse,
  Link,
  styled,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

const ExpandMore = styled((props) => {
  const { expand, dataId, ...other } = props;
  return <IconButton {...other} data-id={dataId} />;
})(({ theme, expand }) => ({
  transform: !expand ? 'rotate(0deg)' : 'rotate(180deg)',
  margin: 'auto',
  padding: '0px !important',
  transition: theme.transitions.create('transform', {
    duration: theme.transitions.duration.shortest,
  }),
}));

const SimpleCardComponent = (props) => {
  const {
    // customMinWidth,
    // customMaxWidth,
    // variant,
    iconSide,
    data,
    locale,
    dateFormat,
    createLabel,
    closeLabel,
    showMoreInfo,
    hasActions,
    expanded,
    setExpanded,
    expandedId,
    setExpandedId,
  } = props;

  const localeMap = {
    nl: nl,
    en: enGB || enUS,
  };

  const { t } = useTranslation();
  // eslint-disable-next-line no-unused-vars
  const isLargeScreen = useMediaQuery('(min-width:1280px)');
  const isSmallScreen = useMediaQuery('(max-width:996px)');

  const handleExpandClick = (e, data) => {
    if (e.currentTarget && e.currentTarget.getAttribute('data-id')) {
      if (e.currentTarget.getAttribute('data-id') === data.Id) {
        setExpandedId(data.Id);
        if (expanded && expandedId === data.Id) {
          setExpanded(false);
        } else {
          setExpanded(true);
        }
      } /* else {
        if (expanded) {
          setExpanded(true);
        } else {
          setExpanded(false);
        }
      } */
    }
  };

  return (
    <Card
      // variant={variant ? variant : 'standard'}
      sx={{
        // boxShadow: (theme) => theme.custom.boxshadow.boxShadow,
        // bgcolor: grey[50],
        boxShadow: 'none',
        borderColor: grey[300],
        borderWidth: 1,
        borderStyle: 'solid',
        borderRadius: 2,
        padding: 0,
        minWidth: 1,
      }}
    >
      <CardContent
        sx={{
          '@media screen and (max-width:1100px)': {
            // pr: pr && 0.5,
          },
          '@media screen and (min-width:997px) and (max-width:1328px)': {
            pr: 0.5,
          },
        }}
      >
        <Grid container sx={{}}>
          {iconSide && (
            <Grid
              alignContent="flex-start"
              container
              item
              xs
              sx={{ flexGrow: '0 !important', pr: 1 }}
            >
              {iconSide}
            </Grid>
          )}

          <Grid
            alignContent="center"
            container
            item
            xs={iconSide ? true : 12}
            sx={{ flexGrow: 1 }}
            // sx={{ pl: iconSide ? 0.5 : 0 }}
          >
            <Grid
              alignContent="center"
              container
              item
              xs={12}
              // xs={isLargeScreen && hasCardSide ? 7 : 12}
            >
              <Typography>{data.Account.Name ?? data.Account.Name}</Typography>
            </Grid>
            <Grid
              alignContent="center"
              container
              item
              xs={12}
              justifyContent={'space-between'}
              alignItems={'center'}
              // sx={{ pt: 0.5 }}
            >
              <Typography
                variant="body2"
                sx={{
                  color: 'text.hint',
                  float: 'right',
                  '@media screen and (min-width:997px) and (max-width:1328px)':
                    {
                      fontSize: 13,
                    },
                }}
              >
                {createLabel}:{' '}
              </Typography>
              <Typography
                variant="body2"
                sx={{
                  color: 'text.hint',
                  fontSize: !isSmallScreen && 12,
                  pt: !isSmallScreen && 1 / 8,
                }}
              >
                {data &&
                  format(new Date(data.CreatedDate), dateFormat, {
                    locale: localeMap[locale],
                  })}
              </Typography>
            </Grid>
            <Grid
              alignContent="center"
              container
              item
              xs={12}
              justifyContent={'space-between'}
              alignItems={'center'}
              // sx={{ pt: 0.5 }}
              // xs={isLargeScreen && hasCardSide ? 7 : 12}
            >
              <Typography
                variant="body2"
                sx={{
                  color: 'text.hint',
                  '@media screen and (min-width:997px) and (max-width:1328px)':
                    {
                      fontSize: 13,
                    },
                }}
              >
                {closeLabel}:{' '}
              </Typography>
              <Typography
                variant="body2"
                sx={{
                  color: 'text.hint',
                  fontSize: !isSmallScreen && 12,
                  pt: !isSmallScreen && 1 / 8,
                }}
              >
                {data &&
                  format(new Date(data.CloseDate), dateFormat, {
                    locale: localeMap[locale],
                  })}
              </Typography>
            </Grid>
            {showMoreInfo && (
              <Grid
                alignContent="center"
                container
                item
                xs={12}
                sx={{ pt: 1 }}
                // xs={isLargeScreen && hasCardSide ? 7 : 12}
              >
                <Chip
                  label={data.StageName}
                  variant="outlined"
                  sx={{
                    bgcolor: (theme) =>
                      alpha(
                        data.StageName === 'Closed Won'
                          ? theme.palette.secondary.main
                          : theme.palette.error.main,
                        theme.palette.action.selectedOpacity,
                      ),
                  }}
                />
              </Grid>
            )}
          </Grid>
        </Grid>
      </CardContent>
      {hasActions && (
        <>
          <Divider />
          <CardActions sx={{ py: 0 }}>
            <ExpandMore
              expand={expanded && expandedId === data.Id}
              dataId={data.Id}
              onClick={(e) => {
                handleExpandClick(e, data);
              }}
              aria-expanded={expanded}
              aria-label="show more"
            >
              <ExpandMoreIcon color="primary" />
            </ExpandMore>
          </CardActions>
          <Collapse
            in={expanded && expandedId === data.Id}
            timeout="auto"
            unmountOnExit
          >
            <CardContent
              sx={{
                pt: 0,
                '&:last-child': {
                  pb: 2,
                },
              }}
            >
              <Grid container>
                <Grid item container xs={12} sx={{ py: 0 }}>
                  <Typography variant="overline" color="textSecondary">
                    {t('deals.section.label_account_manager')}
                  </Typography>
                </Grid>
                <Grid item container xs={12} sx={{ py: 0 }}>
                  <Typography variant="body2">
                    {data.Account.Owner.Name}
                  </Typography>
                </Grid>

                <Grid item container xs={12} sx={{ py: 0.25 }}>
                  <Typography
                    variant="body2"
                    component={Link}
                    underline="hover"
                    href={`mailto:${data.Account.Owner.Email}`}
                    sx={{ textDecoration: 'none', wordBreak: 'break-word' }}
                  >
                    {data.Account.Owner.Email}
                  </Typography>
                </Grid>
                <Grid item container xs={12} sx={{ py: 0 }}>
                  <Typography variant="body2">
                    {data.Account.Owner.MobilePhone}
                  </Typography>
                </Grid>
              </Grid>
            </CardContent>
          </Collapse>
        </>
      )}
    </Card>
  );
};

export default SimpleCardComponent;
