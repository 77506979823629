import React from 'react';
import NumberFormat from 'react-number-format';
export const regEmail =
  // eslint-disable-next-line no-useless-escape
  /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
export const regPassword =
  /^(?=.*\d)(?=.*[A-Z])(?=.*[^a-zA-Z0-9])(?!.*\s).{8,}$/;
export const regUppercase = /^(?=.*[A-Z])(?!.*\s).{1,}$/;
export const regDigit = /^(?=.*\d)(?!.*\s).{1,}$/;
export const regSpecialSign = /^(?=.*[^a-zA-Z0-9_]).{1,}$/;

export const handleChange = (e, vals, setVals) => {
  const value =
    e.target.type === 'checkbox' ? e.target.checked : e.target.value;
  const name = e.target.name;
  if (e.target.type === 'text' || e.target.type === 'textarea') {
    setVals({
      ...vals,
      [name]: removeEmojis(value),
    });
  } else {
    setVals({
      ...vals,
      [name]: value,
    });
  }
};

export const checkValidation = (vals, regPassword = '', regEmail = '') => {
  const isValid =
    regPassword && regEmail
      ? Object.values(vals).every((value) => value && value.length !== 0) &&
        vals.password.match(regPassword) &&
        vals.email.match(regEmail)
      : Object.values(vals).every((value) => value && value.length !== 0);

  return isValid;
};

export function NumberFormatCustom(props) {
  const { inputRef, onChange, ...other } = props;

  return (
    <NumberFormat
      {...other}
      getInputRef={inputRef}
      allowNegative={false}
      onValueChange={(values) => {
        onChange({
          target: {
            name: props.name,
            value: values.value,
          },
        });
      }}
      thousandSeparator={'.'}
      decimalSeparator={','}
      isNumericString
      prefix={'€ '}
    />
  );
}

export function PhoneNumberFormatCustom(props) {
  const { inputRef, onChange, ...other } = props;

  return (
    <NumberFormat
      {...other}
      getInputRef={inputRef}
      allowNegative={false}
      onValueChange={(values) => {
        onChange({
          target: {
            name: props.name,
            value: values.value,
          },
        });
      }}
      //format="+## ##########"
      isNumericString
      type={'tel'}
    />
  );
}

export function NumberFormatPositives(props) {
  const { inputRef, onChange, ...other } = props;

  return (
    <NumberFormat
      {...other}
      getInputRef={inputRef}
      allowNegative={false}
      onValueChange={(values) => {
        onChange({
          target: {
            name: props.name,
            value: values.value,
          },
        });
      }}
      isNumericString
      customInput={'input'}
      decimalScale={0}
    />
  );
}

export const emoji =
  /([\u2700-\u27BF]|[\uE000-\uF8FF]|\uD83C[\uDC00-\uDFFF]|\uD83D[\uDC00-\uDFFF]|[\u2011-\u26FF]|[\u2580-\u27BF]|\uD83E[\uDD10-\uDDFF])/g;

export function removeEmojis(string) {
  var regex =
    /(?:[\u2700-\u27bf]|(?:\ud83c[\udde6-\uddff]){2}|[\ud800-\udbff][\udc00-\udfff]|[\u0023-\u0039]\ufe0f?\u20e3|\u3299|\u3297|\u303d|\u3030|\u24c2|\ud83c[\udd70-\udd71]|\ud83c[\udd7e-\udd7f]|\ud83c\udd8e|\ud83c[\udd91-\udd9a]|\ud83c[\udde6-\uddff]|\ud83c[\ude01-\ude02]|\ud83c\ude1a|\ud83c\ude2f|\ud83c[\ude32-\ude3a]|\ud83c[\ude50-\ude51]|\u203c|\u2049|[\u25aa-\u25ab]|\u25b6|\u25c0|[\u25fb-\u25fe]|\u00a9|\u00ae|\u2122|\u2139|\ud83c\udc04|[\u2600-\u26FF]|\u2b05|\u2b06|\u2b07|\u2b1b|\u2b1c|\u2b50|\u2b55|\u231a|\u231b|\u2328|\u23cf|[\u23e9-\u23f3]|[\u23f8-\u23fa]|\ud83c\udccf|\u2934|\u2935|[\u2190-\u21ff])/g;
  return string.replace(regex, '');
}
