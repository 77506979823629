import React from 'react';
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
} from '@mui/material';

const NoResultsDialog = (props) => {
  const {
    openDialog,
    setOpenDialog,
    title,
    text,
    cancel,
    confirm,
    handleConfirm,
  } = props;
  return (
    <>
      <Dialog
        open={openDialog}
        onClose={props.onClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle
          id="alert-dialog-title"
          style={{ textAlign: props.alignment }}
        >
          {title}
        </DialogTitle>
        <DialogContent style={{ textAlign: props.alignment }}>
          <DialogContentText id="alert-dialog-description">
            {text}
          </DialogContentText>
          {props.image && props.image}
        </DialogContent>

        <DialogActions>
          <Button
            onClick={() => {
              setOpenDialog(false);
              if (props.handleClose) {
                props.handleClose();
              }
            }}
            color="primary"
          >
            {cancel}
          </Button>
          <Button
            onClick={() => {
              setOpenDialog(false);
              handleConfirm();
              if (props.handleClose) {
                props.handleClose();
              }
            }}
            color="primary"
          >
            {confirm}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default NoResultsDialog;
