import React, { useState, useEffect, useContext } from 'react';
import { useHistory, withRouter } from 'react-router-dom';
import { Grid, Divider, Typography, TextField, Button } from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';
import enLocale from 'date-fns/locale/en-US';
import nlLocale from 'date-fns/locale/nl';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import { theme as bdtheme } from '../../theme/BrightDesignTheme';
import { newProjectDoughnutData } from '../../data/picklistOptions';
import NivoDoughnut from '../../uiComponents/NivoDoughnut';
import { useMediaQuery } from 'react-responsive';
import { useStyles } from './styles';
import { ReactComponent as SadFace } from '../../assets/Sad_face_TwoColor_1.svg';
import { useTranslation } from 'react-i18next';
import i18n from 'i18next';
import { handleChange } from '../../JS/FormControls';
import { NewProjectContext } from '../../contexts/NewProject';
import { UserContext } from '../../contexts/UserContext';
import axios from 'axios';
import Loader from '../../uiComponents/Loader';
import NoResultsDialog from '../../uiComponents/NoResultsDialog';
import NewProjectBaseDetails from './NewProjectBaseDetails';
import CustomDialog from '../../uiComponents/CustomDialog';

const localeMap = {
  en: enLocale,
  nl: nlLocale,
};
const localeCancelLabelMap = {
  en: 'Cancel',
  nl: 'Annuleren',
};
const localeClearLabelMap = {
  en: 'Clear',
  nl: 'Verwijderen',
};

const NewProjectTypeInvestBusinessOptimized = (props) => {
  const classes = useStyles();
  const history = useHistory();
  const isLarge = useMediaQuery({ query: '(min-width: 1280px)' });
  const isLargeScreen = useMediaQuery({ query: '(min-width: 1280px)' });
  const { t } = useTranslation();
  const [openStartDate, setOpenStartDate] = React.useState(false);

  const COLORS = [bdtheme.palette.primary.main, 'rgba(224, 224, 224, 1)'];
  const data = newProjectDoughnutData(
    props.progressStep,
    props.showMoreDetails
  );
  const {
    projectBusiness,
    setProjectBusiness,
    projectType,
    newProjectId,
    setNewProjectId,
    activities,
    setActivities,
    setPopularSubsidies,
    setOtherSubsidies,
    setAssignments,
    setSelectedAssignments,
    setSelectedPopularSubsidies,
    accountId,
  } = useContext(NewProjectContext);
  const [loading, setLoading] = React.useState(false);
  const [userContext] = useContext(UserContext);

  const [disabled, setDisabled] = useState(true);
  const [nosubsidies, setNosubsidies] = useState(false);

  const [openDialogReset, setOpenDialogReset] = useState(false);
  const { showMoreDetails, setShowMoreDetails } = props;

  const [locale, setLocale] = useState('en');
  const selectLocale = React.useCallback((locale) => {
    setLocale(i18n.languages[0]);
  }, []);

  const ROUTES = [
    '/app/newproject/choose',
    '/app/newproject/upload',
    '/app/newproject/subsidies',
  ];

  const handleChangeSwitch = (event) => {
    if (!event.target.checked) {
      if (projectBusiness.startDate != null || activities.length > 0) {
        setOpenDialogReset(true);
      } else {
        setShowMoreDetails(event.target.checked);
      }
    } else {
      setShowMoreDetails(event.target.checked);
    }
  };

  const handleConfirmReset = () => {
    setProjectBusiness({
      ...projectBusiness,
      startDate: null,
      activities: '',
    });
    setShowMoreDetails(false);
    setActivities([]);

    if (projectBusiness.id && projectBusiness.id !== null) {
      try {
        setLoading(true);

        let vals = activities.map((a) => a.value);
        projectBusiness.activities = vals.join(';');

        var apiEndPoint = process.env.REACT_APP_DEMAND_API_ENDPOINT;
        let body = {
          projectId: projectBusiness.id,
        };
        axios({
          method: 'post',
          url: apiEndPoint + '/project/resetProjectFields',
          withCredentials: true,
          data: body,
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
            Authorization: `Bearer ${userContext.token}`,
          },
        })
          .then((response) => {
            setLoading(false);
          })
          .catch((error) => {
            console.log('error', error);
            setLoading(false);
          });
      } catch (err) {}
    }
  };

  const handleNext = () => {
    setLoading(true);

    let vals = activities.map((a) => a.value);
    projectBusiness.activities = vals.join(';');

    var body = {
      Project: projectBusiness,
      id: newProjectId,
      accountId: accountId,
    };

    const apiEndPoint =
      process.env.NODE_ENV === 'development'
        ? process.env.REACT_APP_API_ENDPOINT
        : process.env.REACT_APP_API_ENDPOINT_PROD;

    axios({
      method: 'post',
      url: apiEndPoint + '/v1/partner/project/addProject',
      data: body,
      withCredentials: true,
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        Authorization: `Bearer ${userContext.token}`,
      },
    })
      .then((res) => {
        setNewProjectId(res.data.projectId);
        let reqBody = {
          projectId: res.data.projectId,
        };
        axios({
          method: 'post',
          url: apiEndPoint + '/v1/partner/projectscan/projectscanOptimized',
          data: reqBody,
          withCredentials: true,
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
            Authorization: `Bearer ${userContext.token}`,
          },
        })
          .then((res) => {
            setLoading(false);
            setPopularSubsidies(res.data.popularFirstWithScore);
            setOtherSubsidies(res.data.otherSubsidiesWithScore);
            setAssignments(res.data.assignments);
            const newSelectedPopular = res.data.popularFirstWithScore.map(
              (n) => n.id
            );
            setSelectedPopularSubsidies(newSelectedPopular);
            const newSelectedAssignments = res.data.assignments.map(
              (n) => n.subsidyId.id
            );
            setSelectedAssignments(newSelectedAssignments);
            props.setProgressStep(props.progressStep + 1);
            showMoreDetails ? history.push(ROUTES[2]) : history.push(ROUTES[1]);
          })
          .catch((error) => {
            setLoading(false);
          });
      })
      .catch((error) => {
        setLoading(false);
      });
  };

  const handleBack = () => {
    if (props.progressStep !== 0) {
      props.setProgressStep(props.progressStep - 1);
      history.push(ROUTES[0]);
    }
  };

  useEffect(() => {
    if (projectBusiness.startDate != null || activities.length > 0) {
      setShowMoreDetails(true);
    } else {
      setShowMoreDetails(false);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [projectBusiness.startDate, activities]);

  useEffect(() => {
    if (
      projectBusiness.name &&
      projectBusiness.idea &&
      projectBusiness.problemStatement &&
      projectBusiness.subsidyNeed &&
      projectBusiness.projectTypeId
    ) {
      if (!showMoreDetails) {
        setDisabled(false);
      } else if (projectBusiness.startDate != null && activities.length > 0) {
        setDisabled(false);
      } else {
        setDisabled(true);
      }
    } else {
      setDisabled(true);
    }

    if (!projectBusiness.projectTypeId || !projectBusiness.accountId) {
      setProjectBusiness({
        ...projectBusiness,
        projectTypeId: projectType,
        accountId: accountId,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [projectBusiness, activities, showMoreDetails]);

  useEffect(() => {
    let vals;
    if (projectBusiness.activities) {
      let selVals = projectBusiness.activities.split(';');
      vals = props.activitiesBusiness.filter(
        (opt) => selVals.indexOf(opt.value) !== -1
      );
      setActivities(vals);
    }
    selectLocale();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      {loading && <Loader loading={loading} />}
      <Grid container justifyContent='center' className={classes.root}>
        <Grid
          container
          item
          xs={12}
          justifyContent='center'
          alignContent='center'
        >
          {/*<>SECTION</>*/}
          <NewProjectBaseDetails
            classes={classes}
            isLarge={isLarge}
            isLargeScreen={isLargeScreen}
            project={projectBusiness}
            setProject={setProjectBusiness}
            handleChange={handleChange}
            showMoreDetails={showMoreDetails}
            handleChangeSwitch={handleChangeSwitch}
            label1={t('project-details.title')}
            label2={t('project-details.subtitle')}
            label3={t('project-details.project')}
            label4={t('labels.project_name')}
            label5={t('projectX.label2')}
            label6={t('projectX.label3')}
            label7={t('labels.subsidie')}
            label8={t('project-details.more_details')}
            helper1={t('helper_text.project_name')}
            helper2={t('helper_text.subsidie')}
            helper3={t('helper_text.description1')}
            helper4={t('helper_text.description2')}
            placeholder1={t('project-details.placeholder_idea')}
            placeholder2={t('project-details.placeholder_solution')}
          />

          {/*<>SECTION</>*/}
          {/*<>SECTION DETAILS</>*/}
          {showMoreDetails && (
            <Grid container item xs={12} className={classes.section}>
              <Grid container alignItems='flex-start' item xs={12}>
                <Grid
                  container
                  item
                  xs={12}
                  lg={isLarge ? 6 : 12}
                  justifyContent={isLarge ? 'flex-start' : 'center'}
                  className={classes.subSection}
                >
                  <Grid container item xs={12}>
                    <Grid item xs={12}>
                      <Typography
                        variant='overline'
                        color='textSecondary'
                        className={classes.subTitle}
                      >
                        {t('project-details.period')}
                      </Typography>
                    </Grid>
                    <Grid item xs={12}>
                      <LocalizationProvider
                        dateAdapter={AdapterDateFns}
                        adapterLocale={localeMap[locale]}
                      >
                        <DatePicker
                          className={
                            classes.helperText + ' ' + classes.formControl
                          }
                          onClick={() => setOpenStartDate(true)}
                          onClose={() => setOpenStartDate(false)}
                          open={openStartDate}
                          clearable
                          inputVariant='outlined'
                          inputFormat='dd MMM yyyy'
                          id='date1'
                          name='startDate'
                          label={t('labels.start_date')}
                          toolbarPlaceholder=''
                          cancelText={localeCancelLabelMap[locale]}
                          clearText={localeClearLabelMap[locale]}
                          helperText={t('helper_text.start_date')}
                          fullWidth
                          onChange={(date) =>
                            setProjectBusiness({
                              ...projectBusiness,
                              startDate: date,
                            })
                          }
                          value={projectBusiness.startDate ?? null}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              variant='outlined'
                              margin='dense'
                              // size='small'
                              fullWidth
                              onMouseDown={(e) => {
                                setOpenStartDate(true);
                              }}
                              className={
                                classes.helperText + ' ' + classes.formControl
                              }
                            />
                          )}
                        ></DatePicker>
                      </LocalizationProvider>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid
                  container
                  item
                  xs={12}
                  lg={isLarge ? 6 : 12}
                  justifyContent={isLarge ? 'flex-end' : 'center'}
                  className={classes.subSection}
                >
                  <Grid container item xs={12}>
                    <Grid item xs={12}>
                      <Typography
                        variant='overline'
                        color='textSecondary'
                        className={classes.subTitle}
                        style={isLarge ? {} : { display: 'none' }}
                      >
                        &nbsp;
                      </Typography>
                    </Grid>
                    <Grid item xs={12}>
                      <Autocomplete
                        multiple
                        value={activities}
                        id='activities'
                        options={props.activitiesBusiness}
                        getOptionLabel={(option) => option.label}
                        defaultValue={[]}
                        className={
                          classes.formControl +
                          ' ' +
                          classes.autocompleteMultiple
                        }
                        disableCloseOnSelect
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            variant='outlined'
                            label={t('labels.activities')}
                            placeholder=''
                            className={classes.helperText}
                            helperText={t('helper_text.select2')}
                            InputLabelProps={{
                              shrink: true,
                            }}
                          />
                        )}
                        onChange={(e, values) => {
                          setActivities(values);
                        }}
                      />
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          )}

          {/*<>BUTTONS SECTION</>*/}
          <Grid
            container
            item
            xs={12}
            justifyContent='center'
            className={classes.section + ' ' + classes.sectionMobile}
          >
            <Grid container item xs={12} className={classes.sectionButtons}>
              <Grid item xs={12}>
                <Divider />
              </Grid>
              <Grid
                container
                item
                xs={12}
                className={classes.buttons}
                style={
                  isLargeScreen
                    ? {}
                    : {
                        flexWrap: 'nowrap',
                        justifyContent: 'space-between',
                      }
                }
              >
                <>
                  {!isLargeScreen && (
                    <Grid item>
                      <div style={{ height: 40, width: 40 }}>
                        <NivoDoughnut
                          data={data}
                          center={props.center}
                          colors={COLORS}
                          smallFont
                        />
                      </div>
                    </Grid>
                  )}
                  <Grid
                    item
                    container
                    justifyContent={
                      isLargeScreen ? 'space-between' : 'flex-end'
                    }
                  >
                    <Button
                      style={{ marginRight: 16 }}
                      startIcon={<KeyboardArrowLeftIcon />}
                      onClick={handleBack}
                    >
                      {t('buttons.back')}
                    </Button>

                    <Button
                      color='primary'
                      variant='contained'
                      endIcon={<KeyboardArrowRightIcon />}
                      onClick={handleNext}
                      disabled={disabled}
                    >
                      {t('buttons.next')}
                    </Button>
                  </Grid>
                </>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      <NoResultsDialog
        openDialog={nosubsidies}
        setOpenDialog={setNosubsidies}
        title={t('select_subsidies.no_subsidies')}
        text={t('select_subsidies.no_subsidies_text')}
        image={
          <>
            <SadFace />
          </>
        }
        alignment='center'
        cancel={t('buttons.cancel_2')}
        confirm={t('dialog.to_dashboard')}
        handleConfirm={props.handleConfirm}
      />

      {/* RESET DETAILS */}

      <CustomDialog
        openDialog={openDialogReset}
        setOpenDialog={setOpenDialogReset}
        handleConfirm={handleConfirmReset}
        handleClose={() => {
          setOpenDialogReset(false);
        }}
        title={t('dialog.reset_project_2')}
        text={t('dialog.reset_project_3')}
        cancel={t('yes_no.no')}
        confirm={t('yes_no.yes')}
      />
    </>
  );
};

export default withRouter(NewProjectTypeInvestBusinessOptimized);
