import i18n from '../utils/i18n';

export const MenuItemss = () => {
  return [
    {
      href: '/app/dashboard',
      icon: 'DashboardIcon',
      title: i18n.t('dashboard.mainMenu.navitem1'),
    },
    {
      href: '/app/accounts',
      icon: 'PersonAddIcon',
      title: i18n.t('dashboard.mainMenu.navitem2'),
    },
    /*{
      href: '/app/deals',
      icon: 'BusinessIcon',
      title: i18n.t('dashboard.mainMenu.navitem3'),
    },*/
    {
      href: '/app/applications',
      icon: 'DescriptionIcon',
      title: i18n.t('dashboard.mainMenu.navitem4'),
    },
  ];
};

export const UserItemss = () => {
  return [
    {
      id: 'account',
      href: '/app/account',
      icon: 'AccountCircleIcon',
      title: i18n.t('dashboard.my_account'),
    },
    {
      id: 'logout',
      icon: 'LogoutIcon',
      title: i18n.t('login.logout'),
    },
  ];
};

export const AppbarTitle = {
  '/app/dashboard': i18n.t('dashboard.mainMenu.navitem1'),
  '/app/leads': i18n.t('dashboard.mainMenu.navitem2'),
  '/app/deals': i18n.t('dashboard.mainMenu.navitem3'),
  '/app/applications': i18n.t('dashboard.mainMenu.navitem4'),
  '/app/account': i18n.t('dashboard.my_account'),
};
