import React, { useState, useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { Grid, Divider, Box, Typography, Button } from '@mui/material';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import { useTableStyles as useStyles } from './styles';
import { useMediaQuery } from 'react-responsive';
import { theme as bdtheme } from '../../theme/BrightDesignTheme';
import { doughnutData } from '../../data/picklistOptions';
import NivoDoughnut from '../../uiComponents/NivoDoughnut';
import { useTranslation } from 'react-i18next';
import { NewProjectContext } from '../../contexts/NewProject';
import Loader from '../../uiComponents/Loader';
import PopularSubsidiesTable from './PopularSubsidiesTable';
import OtherSubsidiesTable from './OtherSubsidiesTable';
import AssignmentTable from './AssignmentTable';
import EuroIcon from '@mui/icons-material/Euro';
import RocketLaunchIcon from '@mui/icons-material/RocketLaunch';
import NoResultsScreen from '../../uiComponents/NoResultsScreen';
import { ReactComponent as SadFace } from '../../assets/Sad_face_TwoColor_1.svg';

const SubsidiesListDnd = (props) => {
  const {
    newProjectId,
    popularSubsidies,
    otherSubsidies,
    setPopularSubsidies,
    setOtherSubsidies,
    selectedPopularSubsidies,
    setSelectedPopularSubsidies,
    selectedOtherSubsidies,
    setSelectedOtherSubsidies,
    assignments,
    setAssignments,
    selectedAssignments,
    setSelectedAssignments,
  } = useContext(NewProjectContext);

  const classes = useStyles();
  const history = useHistory();
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);

  const isLargeScreen = useMediaQuery({ query: '(min-width: 1280px)' });
  const isSmallScreen = useMediaQuery({ query: '(max-width: 767px)' });
  const COLORS = [bdtheme.palette.primary.main, 'rgba(224, 224, 224, 1)'];
  const data = doughnutData(props.progressStep, props.showMoreDetails);

  const ROUTES = ['/app/newproject/addproject', '/app/newproject/upload'];

  const handleNext = () => {
    history.push(ROUTES[1]);
  };
  const handleBack = () => {
    if (props.progressStep !== 0) {
      history.push(ROUTES[0]);
    }
  };

  return (
    <>
      {loading && <Loader loading={loading} />}
      <Grid container>
        <Grid
          container
          item
          xs={12}
          sx={{
            mb: { xs: 0 },
            ...(isSmallScreen && { mb: 9, pb: '72px !important' }),
            height: 1,
            maxWidth: '1080px !important',
            mx: 'auto !important',
          }}
        >
          {assignments && assignments.length > 0 && (
            <>
              <Grid item xs={12} pt={1}>
                <Grid container alignItems={'center'} py={2}>
                  <Grid item>
                    <EuroIcon color='secondary' sx={{ fontSize: 40, ml: 0 }} />
                  </Grid>
                  <Grid item pl={2}>
                    <Typography variant='h6'>
                      {t('subsidies_dnd_list.potential_applications')}
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
              <AssignmentTable
                popularSubsidies={popularSubsidies}
                setPopularSubsidies={setPopularSubsidies}
                selectedPopularSubsidies={selectedPopularSubsidies}
                setSelectedPopularSubsidies={setSelectedPopularSubsidies}
                hasDelete={true}
                loginAsUser={false}
                hasAmount={true}
                setLoading={setLoading}
                otherSubsidies={otherSubsidies}
                setOtherSubsidies={setOtherSubsidies}
                selectedOtherSubsidies={selectedOtherSubsidies}
                setSelectedOtherSubsidies={setSelectedOtherSubsidies}
                assignments={assignments}
                setAssignments={setAssignments}
                selectedAssignments={selectedAssignments}
                setSelectedAssignments={setSelectedAssignments}
                newProjectId={newProjectId}
              />
            </>
          )}
          {popularSubsidies && popularSubsidies.length > 0 && (
            <>
              <Grid item xs={12} pt={1}>
                <Grid container alignItems={'center'} py={2}>
                  <Grid item>
                    <RocketLaunchIcon
                      color='secondary'
                      sx={{ fontSize: 40, ml: 0 }}
                    />
                  </Grid>
                  <Grid item pl={2}>
                    <Typography variant='h6'>
                      {t('subsidies_dnd_list.recommended_subsidies')}
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
              <PopularSubsidiesTable
                popularSubsidies={popularSubsidies}
                setPopularSubsidies={setPopularSubsidies}
                selectedPopularSubsidies={selectedPopularSubsidies}
                setSelectedPopularSubsidies={setSelectedPopularSubsidies}
                hasDelete={true}
                loginAsUser={false}
                hasAmount={true}
                setLoading={setLoading}
                otherSubsidies={otherSubsidies}
                setOtherSubsidies={setOtherSubsidies}
                selectedOtherSubsidies={selectedOtherSubsidies}
                setSelectedOtherSubsidies={setSelectedOtherSubsidies}
                assignments={assignments}
                setAssignments={setAssignments}
                selectedAssignments={selectedAssignments}
                setSelectedAssignments={setSelectedAssignments}
                newProjectId={newProjectId}
              />
            </>
          )}
          {otherSubsidies && otherSubsidies.length > 0 && (
            <>
              <Grid item xs={12} pt={2} pb={2}>
                {otherSubsidies &&
                  otherSubsidies.length > 0 &&
                  popularSubsidies.length > 0 && (
                    <Typography variant='h6'>
                      {t('subsidies_dnd_list.other_subsidies')}
                    </Typography>
                  )}
                {otherSubsidies &&
                  otherSubsidies.length > 0 &&
                  popularSubsidies.length === 0 && (
                    <Typography variant='h6'>
                      {t('new_project_subsidies.title')}
                    </Typography>
                  )}
              </Grid>
              <OtherSubsidiesTable
                otherSubsidies={otherSubsidies}
                setOtherSubsidies={setOtherSubsidies}
                selectedOtherSubsidies={selectedOtherSubsidies}
                setSelectedOtherSubsidies={setSelectedOtherSubsidies}
                hasDelete={true}
                loginAsUser={false}
                hasAmount={false}
                setLoading={setLoading}
                popularSubsidies={popularSubsidies}
                setPopularSubsidies={setPopularSubsidies}
                selectedPopularSubsidies={selectedPopularSubsidies}
                setSelectedPopularSubsidies={setSelectedPopularSubsidies}
                assignments={assignments}
                setAssignments={setAssignments}
                selectedAssignments={selectedAssignments}
                setSelectedAssignments={setSelectedAssignments}
                newProjectId={newProjectId}
              />
            </>
          )}
          {!loading &&
            assignments &&
            assignments.length === 0 &&
            otherSubsidies &&
            otherSubsidies.length === 0 &&
            popularSubsidies &&
            popularSubsidies.length === 0 && (
              <>
                <Grid item xs={12} pt={2} pb={2}>
                  <NoResultsScreen
                    title={t('select_subsidies.no_subsidies')}
                    subtitle={t('select_subsidies.no_subsidies_text')}
                    content={<SadFace />}
                    titleStyle={{
                      paddingBottom: 8,
                      justifyContent: 'center',
                    }}
                    subtitleStyle={{
                      paddingBottom: 8,
                      justifyContent: 'center',
                    }}
                    contentStyle={{
                      paddingBottom: 0,
                      justifyContent: 'center',
                    }}
                  />
                </Grid>
              </>
            )}
          {/*<>BUTTONS SECTION</>*/}
          <Grid
            container
            item
            xs={12}
            justifyContent='center'
            className={classes.section + ' ' + classes.sectionMobile}
          >
            <Grid
              container
              item
              xs={12}
              className={classes.sectionButtons}
              alignContent='flex-end'
            >
              <Grid item xs={12}>
                <Box mt={!isSmallScreen ? 6 : 0}>
                  <Divider />
                </Box>
              </Grid>
              <Grid
                container
                item
                xs={12}
                className={classes.buttons + ' ' + classes.buttonsSubsidies}
                style={
                  isLargeScreen
                    ? {}
                    : {
                        flexWrap: 'nowrap',
                        justifyContent: 'space-between',
                      }
                }
              >
                <>
                  {!isLargeScreen && (
                    <Grid item>
                      <div style={{ height: 40, width: 40 }}>
                        <NivoDoughnut
                          data={data}
                          center={props.center}
                          colors={COLORS}
                          smallFont
                        />
                      </div>
                    </Grid>
                  )}
                  <Grid
                    item
                    container
                    justifyContent={
                      isLargeScreen ? 'space-between' : 'flex-end'
                    }
                  >
                    <Button
                      style={{ marginRight: 16 }}
                      startIcon={<KeyboardArrowLeftIcon />}
                      onClick={handleBack}
                    >
                      {t('buttons.back')}
                    </Button>

                    <Button
                      color='primary'
                      variant='contained'
                      endIcon={<KeyboardArrowRightIcon />}
                      onClick={handleNext}
                    >
                      {t('buttons.next')}
                    </Button>
                  </Grid>
                </>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

export default SubsidiesListDnd;
