import React, { useContext, useState, useEffect } from 'react';
import {
  Grid,
  Button,
  Autocomplete,
  TextField,
  ButtonGroup,
  Checkbox,
  Divider,
  IconButton,
  Menu,
  Stack,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Snackbar,
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import CircularProgress from '@mui/material/CircularProgress';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import Grow from '@mui/material/Grow';
import Paper from '@mui/material/Paper';
import Popper from '@mui/material/Popper';
import MenuItem from '@mui/material/MenuItem';
import MenuList from '@mui/material/MenuList';
import SearchIcon from '@mui/icons-material/Search';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import { UserContext } from '../../contexts/UserContext';
import useDebounce from './debounce';
import axios from 'axios';
import Loader from '../../uiComponents/Loader';
import { useMediaQuery } from 'react-responsive';
import CustomSnackbar from './CustomSnackbar';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import ReactGA from 'react-ga';
import SendIcon from '@mui/icons-material/Send';

const SubsidiesListFilter = (props) => {
  const {
    popularSubsidies,
    otherSubsidies,
    setPopularSubsidies,
    setOtherSubsidies,
    selectedPopularSubsidies,
    setSelectedPopularSubsidies,
    selectedOtherSubsidies,
    setSelectedOtherSubsidies,
    assignments,
    loginAsUser,
    setAssignments,
    selectedAssignments,
    setSelectedAssignments,
  } = props;
  let location = useLocation();
  const { t } = useTranslation();
  const [error1, setError1] = useState(false);
  const [errorSendPdf, setErrorSendPdf] = useState(false);
  const [success, setSuccess] = useState(false);
  const isSmallScreen = useMediaQuery({ query: '(max-width: 767px)' });
  const [userContext] = useContext(UserContext);
  const [openExport, setOpenExport] = React.useState(false);
  const options = [
    t('projectScanFilter.export_all'),
    t('projectScanFilter.export_selected'),
  ];
  const anchorRef = React.useRef(null);
  const [anchorElSelectMenu, setAnchorElSelectMenu] = React.useState(null);
  const openSelectMenu = Boolean(anchorElSelectMenu);
  const handleCloseSelectMenu = () => {
    setAnchorElSelectMenu(null);
  };
  const handleClickSelectMenuIcon = (event) => {
    setAnchorElSelectMenu(event.currentTarget);
  };
  const selectedIndexExport = 0;
  const [loading, setLoading] = useState(false);
  const handleMenuItemClick = (event, index) => {
    setLoading(true);
    const apiEndPoint =
      process.env.NODE_ENV === 'development'
        ? process.env.REACT_APP_API_ENDPOINT
        : process.env.REACT_APP_API_ENDPOINT_PROD;
    let id = location.pathname.substring(
      location.pathname.lastIndexOf('/') + 1
    );
    let url =
      index === 0
        ? '/v1/partner/pdf/projectScanAll'
        : index === 1
        ? '/v1/partner/pdf/selectedSubsidies'
        : '/v1/partner/pdf/selectedSubsidies';
    let requestBody =
      index === 0
        ? { projectId: id }
        : index === 1
        ? {
            projectId: id,
            popularSubsidies: selectedPopularSubsidies,
            otherSubsidies: selectedOtherSubsidies,
            assignments: selectedAssignments,
          }
        : {
            projectId: id,
            popularSubsidies: selectedPopularSubsidies,
            otherSubsidies: selectedOtherSubsidies,
            assignments: selectedAssignments,
          };
    try {
      axios({
        method: 'post',
        url: apiEndPoint + url,
        data: requestBody,
        withCredentials: true,
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
          Authorization: `Bearer ${userContext.token}`,
        },
        responseType: 'blob',
      })
        .then((res) => {
          setLoading(false);
          ReactGA.event({
            category: 'Application',
            action: 'Project Scan Downloaded',
          });
          const url = window.URL.createObjectURL(new Blob([res.data]));
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', 'Project Scan.pdf');
          document.body.appendChild(link);
          link.click();
          link.parentNode.removeChild(link);
        })
        .catch((error) => {
          setLoading(false);
        });
    } catch (err) {
      console.log(err);
    }
    setOpenExport(false);
  };

  const handleClick = () => {
    setLoading(true);
    const apiEndPoint =
      process.env.NODE_ENV === 'development'
        ? process.env.REACT_APP_API_ENDPOINT
        : process.env.REACT_APP_API_ENDPOINT_PROD;
    let id = location.pathname.substring(
      location.pathname.lastIndexOf('/') + 1
    );
    let url = '/v1/partner/pdf/projectScanAll';
    try {
      axios({
        method: 'post',
        url: apiEndPoint + url,
        data: { projectId: id },
        withCredentials: true,
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
          Authorization: `Bearer ${userContext.token}`,
        },
        responseType: 'blob',
      })
        .then((res) => {
          setLoading(false);
          ReactGA.event({
            category: 'Application',
            action: 'Project Scan Downloaded',
          });
          const url = window.URL.createObjectURL(new Blob([res.data]));
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', 'Project Scan.pdf');
          document.body.appendChild(link);
          link.click();
          link.parentNode.removeChild(link);
        })
        .catch((error) => {
          setLoading(false);
        });
    } catch (err) {
      console.log(err);
    }
  };

  const handleToggle = () => {
    setOpenExport((prevOpen) => !prevOpen);
  };
  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }
    setOpenExport(false);
  };
  const [open, setOpen] = useState(false);
  const [isSearching, setIsSearching] = useState(false);
  const [subsidyOptions, setSubsidyOptions] = useState([]);

  const [searchString, setSearchString] = useState('');
  const debouncedSearchTerm = useDebounce(searchString, 500);

  const addSubsidy = (value) => {
    setError1(false);
    setErrorSendPdf(false);
    let populars = popularSubsidies.map((obj) => obj);
    let others = otherSubsidies.map((obj) => obj);
    let idsPopular = populars.map((obj) => obj.id);
    let idsOther = others.map((obj) => obj.id);
    let idsassignmentSubsidies = assignments.map((obj) => obj.subsidyId.id);
    if (idsassignmentSubsidies.includes(value.id)) {
      setError1(true);
    } else if (idsPopular.includes(value.id)) {
      setError1(true);
    } else if (idsOther.includes(value.id)) {
      const index = idsOther.indexOf(value.id);
      if (index > -1) {
        idsOther.splice(index, 1);
        idsPopular.unshift(value.id);
      }
      setLoading(true);
      let id = location.pathname.substring(
        location.pathname.lastIndexOf('/') + 1
      );
      let projectId = id;
      var requestBody = {
        projectId: projectId,
        popularSubsidies: idsPopular,
        otherSubsidies: idsOther,
      };
      const apiEndPoint =
        process.env.NODE_ENV === 'development'
          ? process.env.REACT_APP_API_ENDPOINT
          : process.env.REACT_APP_API_ENDPOINT_PROD;
      try {
        axios({
          method: 'post',
          url: apiEndPoint + '/v1/partner/projectscan/addOrRemoveSubsidy',
          data: requestBody,
          withCredentials: true,
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
            Authorization: `Bearer ${userContext.token}`,
          },
        })
          .then((res) => {
            setLoading(false);
            setPopularSubsidies(res.data.popularFirstWithScore);
            setOtherSubsidies(res.data.otherSubsidiesWithScore);
            setAssignments(res.data.assignments);
            setSelectedOtherSubsidies([]);
            setSelectedPopularSubsidies([]);
            setSelectedAssignments([]);
          })
          .catch((error) => {
            setLoading(false);
          });
      } catch (err) {
        console.log(err);
      }
    } else {
      idsPopular.unshift(value.id);
      setLoading(true);
      let id = location.pathname.substring(
        location.pathname.lastIndexOf('/') + 1
      );
      let projectId = id;
      var requestBody1 = {
        projectId: projectId,
        popularSubsidies: idsPopular,
        otherSubsidies: idsOther,
      };
      const apiEndPoint1 =
        process.env.NODE_ENV === 'development'
          ? process.env.REACT_APP_API_ENDPOINT
          : process.env.REACT_APP_API_ENDPOINT_PROD;
      try {
        axios({
          method: 'post',
          url: apiEndPoint1 + '/v1/partner/projectscan/addOrRemoveSubsidy',
          data: requestBody1,
          withCredentials: true,
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
            Authorization: `Bearer ${userContext.token}`,
          },
        })
          .then((res) => {
            setLoading(false);
            setPopularSubsidies(res.data.popularFirstWithScore);
            setOtherSubsidies(res.data.otherSubsidiesWithScore);
            setAssignments(res.data.assignments);
            setSelectedOtherSubsidies([]);
            setSelectedPopularSubsidies([]);
            setSelectedAssignments([]);
          })
          .catch((error) => {
            setLoading(false);
          });
      } catch (err) {
        console.log(err);
      }
    }
  };

  async function searchCharacters(search) {
    const apiEndPoint =
      process.env.NODE_ENV === 'development'
        ? process.env.REACT_APP_API_ENDPOINT
        : process.env.REACT_APP_API_ENDPOINT_PROD;
    return axios({
      method: 'get',
      url: apiEndPoint + '/v1/partner/projectscan/searchSubsidies',
      params: { name: search },
      withCredentials: true,
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        Authorization: `Bearer ${userContext.token}`,
      },
    })
      .then((res) => res.data)
      .catch((error) => {
        return [];
      });
  }

  useEffect(() => {
    if (debouncedSearchTerm && searchString.length > 2) {
      setIsSearching(true);
      searchCharacters(debouncedSearchTerm).then((results) => {
        setSubsidyOptions(results);
        setIsSearching(false);
      });
    } else {
      setSubsidyOptions([]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedSearchTerm]);

  useEffect(() => {
    if (!open) {
      setSubsidyOptions([]);
    }
  }, [open]);

  const handleCloseSnackbar = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setError1(false);
    setErrorSendPdf(false);
    setSuccess(false);
  };

  const handleSelectAllSubsidies = () => {
    const newSelectedPopular = popularSubsidies.map((n) => n.id);
    setSelectedPopularSubsidies(newSelectedPopular);
    const newSelectedother = otherSubsidies.map((n) => n.id);
    setSelectedOtherSubsidies(newSelectedother);
    const newSelectedAssignments = assignments.map((n) => n.subsidyId.id);
    setSelectedAssignments(newSelectedAssignments);
    setAnchorElSelectMenu(null);
  };

  const handleSelectRecommendedSubsidies = () => {
    const newSelectedPopular = popularSubsidies.map((n) => n.id);
    setSelectedPopularSubsidies(newSelectedPopular);
    setSelectedOtherSubsidies([]);
    setSelectedAssignments([]);
    setAnchorElSelectMenu(null);
  };

  const handleSelectOtherSubsidies = () => {
    const newSelectedother = otherSubsidies.map((n) => n.id);
    setSelectedOtherSubsidies(newSelectedother);
    setSelectedPopularSubsidies([]);
    setSelectedAssignments([]);
    setAnchorElSelectMenu(null);
  };

  const handleSelectPotentialSubsidies = () => {
    const newSelectedAssignments = assignments.map((n) => n.subsidyId.id);
    setSelectedAssignments(newSelectedAssignments);
    setSelectedOtherSubsidies([]);
    setSelectedPopularSubsidies([]);
    setAnchorElSelectMenu(null);
  };

  const handleDeselectAllSubsidies = () => {
    setSelectedOtherSubsidies([]);
    setSelectedPopularSubsidies([]);
    setSelectedAssignments([]);
    setAnchorElSelectMenu(null);
  };

  const [openEmailDialog, setOpenEmailDialog] = React.useState(false);
  const [personalMessage, setPersonalMessage] = React.useState('');

  const handlePersonalMessageChange = (event) => {
    setPersonalMessage(event.target.value);
  };
  const handleClickOpenEmailDialog = () => {
    setOpenEmailDialog(true);
  };

  const handleCloseEmailDialog = () => {
    setOpenEmailDialog(false);
  };

  const sendEmail = () => {
    setLoading(true);
    setErrorSendPdf(false);
    const apiEndPoint =
      process.env.NODE_ENV === 'development'
        ? process.env.REACT_APP_API_ENDPOINT
        : process.env.REACT_APP_API_ENDPOINT_PROD;
    let id = location.pathname.substring(
      location.pathname.lastIndexOf('/') + 1
    );
    let url = '/pdf/sendProjecScanPdf';
    try {
      axios({
        method: 'post',
        url: apiEndPoint + url,
        data: {
          projectId: id,
          personalMessage: personalMessage,
          popularSubsidies: selectedPopularSubsidies,
          otherSubsidies: selectedOtherSubsidies,
          assignments: selectedAssignments,
        },
        withCredentials: true,
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
          Authorization: `Bearer ${userContext.token}`,
        },
        responseType: 'blob',
      })
        .then((res) => {
          setSuccess(true);
          setOpenEmailDialog(false);
          setLoading(false);
        })
        .catch((error) => {
          setErrorSendPdf(true);
          setLoading(false);
        });
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <>
      {loading && <Loader loading={loading} />}
      {isSmallScreen && (
        <>
          <Grid container alignContent={'center'} justifyContent={'flex-end'}>
            <Button
              sx={{ mt: 2 }}
              variant='contained'
              onClick={handleClick}
              startIcon={<FileDownloadIcon />}
            >
              {t('projectScanFilter.export_pdf')}
            </Button>
          </Grid>
        </>
      )}
      {!isSmallScreen && (
        <>
          <Grid
            container
            alignItems={'flex-end'}
            direction={'row'}
            justifyContent={'flex-start'}
          >
            <Grid item xs={1.5}>
              <Checkbox
                color='primary'
                sx={{ ml: 3, p: 0 }}
                indeterminate={
                  selectedOtherSubsidies.length +
                    selectedPopularSubsidies.length +
                    selectedAssignments.length >
                    0 &&
                  selectedOtherSubsidies.length +
                    selectedPopularSubsidies.length +
                    selectedAssignments.length <
                    popularSubsidies.length +
                      otherSubsidies.length +
                      assignments.length
                }
                checked={
                  popularSubsidies.length +
                    otherSubsidies.length +
                    assignments.length >
                    0 &&
                  selectedOtherSubsidies.length +
                    selectedPopularSubsidies.length +
                    selectedAssignments.length ===
                    popularSubsidies.length +
                      otherSubsidies.length +
                      assignments.length
                }
                onChange={props.handleSelectAllClick}
                inputProps={{
                  'aria-label': 'select all subsidies',
                }}
              />
              <IconButton
                aria-label='more'
                id='long-button'
                aria-controls={openSelectMenu ? 'long-menu' : undefined}
                aria-expanded={openSelectMenu ? 'true' : undefined}
                aria-haspopup='true'
                onClick={handleClickSelectMenuIcon}
              >
                <ArrowDropDownIcon />
              </IconButton>
              <Menu
                id='long-menu'
                MenuListProps={{
                  'aria-labelledby': 'long-button',
                }}
                anchorEl={anchorElSelectMenu}
                open={openSelectMenu}
                onClose={handleCloseSelectMenu}
              >
                <MenuItem onClick={handleSelectAllSubsidies}>
                  {t('select_subsidies_checkbox_options.all')}
                </MenuItem>
                {assignments && assignments.length > 0 && (
                  <MenuItem onClick={handleSelectPotentialSubsidies}>
                    {t('select_subsidies_checkbox_options.potential')}
                  </MenuItem>
                )}
                <MenuItem onClick={handleSelectRecommendedSubsidies}>
                  {t('select_subsidies_checkbox_options.recommended')}
                </MenuItem>
                <MenuItem onClick={handleSelectOtherSubsidies}>
                  {t('select_subsidies_checkbox_options.others')}
                </MenuItem>
                <MenuItem onClick={handleDeselectAllSubsidies}>
                  {t('select_subsidies_checkbox_options.none')}
                </MenuItem>
              </Menu>
            </Grid>
            <Grid item xs={5.5}>
              <Autocomplete
                id='subsidy'
                name='subsidy'
                open={open && searchString.length > 2}
                onOpen={() => {
                  setOpen(true);
                }}
                onClose={() => {
                  setOpen(false);
                }}
                options={subsidyOptions}
                getOptionLabel={(option) => option.name}
                isOptionEqualToValue={(option, value) =>
                  option.name === value.name
                }
                loading={isSearching}
                forcePopupIcon={false}
                fullWidth
                filterOptions={(x) => x}
                inputValue={searchString}
                onInputChange={(event, newInputValue) => {
                  setSearchString(newInputValue);
                }}
                onChange={(event, value) => {
                  if (value !== null) {
                    addSubsidy(value);
                  }
                  setSearchString('');
                }}
                noOptionsText={''}
                loadingText={''}
                disabled={false}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant='outlined'
                    size='small'
                    label={t('projectScanFilter.add_subsidy')}
                    placeholder={t('projectScanFilter.search_subsidy')}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    fullWidth
                    InputProps={{
                      ...params.InputProps,
                      endAdornment: (
                        <>
                          {isSearching ? (
                            <CircularProgress color='inherit' size={20} />
                          ) : (
                            <SearchIcon color='inherit' size={20} />
                          )}
                          {params.InputProps.endAdornment}
                        </>
                      ),
                    }}
                  />
                )}
                renderOption={(props, option) => (
                  <li {...props}>
                    <AddIcon color='secondary' sx={{ ml: 2, mr: 2 }} />
                    {option.name}
                  </li>
                )}
              />
            </Grid>
            <Grid item xs={5}>
              <Stack
                direction='row'
                spacing={1}
                sx={{
                  mt: 1,
                  float: 'right',
                }}
              >
                {/*<Button
                  variant='contained'
                  endIcon={<SendIcon />}
                  disabled={
                    selectedPopularSubsidies.length === 0 &&
                    selectedOtherSubsidies.length === 0 &&
                    selectedAssignments.length === 0
                  }
                  onClick={handleClickOpenEmailDialog}
                >
                  {t('labels.send')}
                </Button>*/}
                <ButtonGroup
                  variant='contained'
                  color='primary'
                  ref={anchorRef}
                  aria-label='split button'
                  disableElevation
                >
                  <Button onClick={handleClick}>
                    {options[selectedIndexExport]}
                  </Button>
                  <Button
                    color='primary'
                    size='small'
                    aria-controls={open ? 'split-button-menu' : undefined}
                    aria-expanded={open ? 'true' : undefined}
                    aria-label='select merge strategy'
                    aria-haspopup='menu'
                    onClick={handleToggle}
                  >
                    <ArrowDropDownIcon />
                  </Button>
                </ButtonGroup>
                <Popper
                  open={openExport}
                  anchorEl={anchorRef.current}
                  role={undefined}
                  transition
                  disablePortal
                  placement='bottom-end'
                >
                  {({ TransitionProps, placement }) => (
                    <Grow
                      {...TransitionProps}
                      style={{
                        anchorOrigin: 'right top',
                        transformOrigin:
                          placement === 'bottom'
                            ? 'center top'
                            : 'right bottom',
                      }}
                    >
                      <Paper>
                        <ClickAwayListener onClickAway={handleClose}>
                          <MenuList id='split-button-menu'>
                            {options.map((option, index) => (
                              <MenuItem
                                key={option}
                                selected={index === selectedIndexExport}
                                onClick={(event) =>
                                  handleMenuItemClick(event, index)
                                }
                                disabled={
                                  index === 1 &&
                                  selectedPopularSubsidies.length === 0 &&
                                  selectedOtherSubsidies.length === 0 &&
                                  selectedAssignments.length === 0
                                }
                              >
                                {option}
                              </MenuItem>
                            ))}
                          </MenuList>
                        </ClickAwayListener>
                      </Paper>
                    </Grow>
                  )}
                </Popper>
              </Stack>
            </Grid>
            <Grid item md={12} sx={{ mt: 2 }}>
              <Divider />
            </Grid>
          </Grid>
        </>
      )}
      <CustomSnackbar
        showSnackbar={error1}
        handleCloseSnackbar={handleCloseSnackbar}
        text1={t('projectScanFilter.error')}
      />
      <CustomSnackbar
        showSnackbar={errorSendPdf}
        handleCloseSnackbar={handleCloseSnackbar}
        text1={t('select_subsidies_checkbox_options.error')}
      />
      <Snackbar
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        open={success}
        autoHideDuration={3000}
        onClose={handleCloseSnackbar}
        message={t('select_subsidies_checkbox_options.success')}
      />
      <Dialog
        fullWidth={true}
        maxWidth={'sm'}
        open={openEmailDialog}
        onClose={handleCloseEmailDialog}
      >
        <DialogTitle>
          {t('select_subsidies_checkbox_options.dialog_title')}
        </DialogTitle>
        <DialogContent>
          <TextField
            label={t('select_subsidies_checkbox_options.label')}
            multiline
            rows={7}
            fullWidth
            sx={{ mt: 1 }}
            value={personalMessage}
            inputProps={{ maxLength: 500 }}
            onChange={handlePersonalMessageChange}
            placeholder={t(
              'select_subsidies_checkbox_options.personal_message'
            )}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseEmailDialog}>
            {t('select_subsidies_checkbox_options.button1')}
          </Button>
          <Button onClick={sendEmail} disabled={personalMessage.length === 0}>
            {t('select_subsidies_checkbox_options.button2')}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default SubsidiesListFilter;
