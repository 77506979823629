/* MAIN COMPONENTS HAVE 24PX PADDING HORIZONTALY (16PX < LG) */
import React, { useState } from 'react';
import { Switch, Route, withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { useStyles } from './styles';
import CssBaseline from '@mui/material/CssBaseline';
import { Box } from '@mui/material';
import DashboardDetails from '../components/Dashboard/DashboardDetails';
import LeadsDetails from '../components/Leads/LeadsDetails';
import DealsDetails from '../components/Deals/DealsDetails';
import ApplicationListOptimized from '../components/Applications/ApplicationListOptimized';
import ApplicationX from '../components/Applications/ApplicationX';
import ApplicationDocumentList from '../components/Applications/ApplicationDocumentList';
import MyAccountDetails from '../components/MyAccount/MyAccountDetails';
import AccountDetails from '../components/Account/AccountDetails';
import ProjectX from '../components/Projects/ProjectX';
import EditProject from '../components/Projects/EditProject';
import SubsidiesListOptimized from '../components/Projects/SubsidiesListOptimized';
import DocumentsList from '../components/Projects/DocumentsList';
import NavBar from './NavBar';

const DashboardLayout = (props) => {
  const classes = useStyles();
  const [tabValue, setTabValue] = useState(0);

  function TabPanel(props) {
    const { children, value, index, ...other } = props;
    return (
      <div
        role='tabpanel'
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
        style={{ display: 'content' }}
      >
        {value === index && <Box>{children}</Box>}
      </div>
    );
  }

  TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
  };

  return (
    <>
      <div className={classes.root}>
        <CssBaseline />
        <NavBar setTabValue={setTabValue} tabValue={tabValue} />
        <main className={classes.content}>
          <div className={classes.toolbar} />
          <Switch>
            <Route exact path='/app/dashboard' component={DashboardDetails} />
            <Route exact path='/app/accounts' component={LeadsDetails} />
            <Route exact path='/app/deals' component={DealsDetails} />
            <Route
              exact
              path='/app/applications'
              component={ApplicationListOptimized}
            />
            <Route exact path='/app/account' component={MyAccountDetails} />
            <Route
              exact
              path='/app/accounts/:name'
              component={AccountDetails}
            />
            <Route exact path={`/app/projects/:name/edit`}>
              <EditProject />
            </Route>
          </Switch>
          <Route exact path={`/app/projects/:name`}>
            <TabPanel value={tabValue} index={0}>
              <ProjectX />
            </TabPanel>
            <TabPanel value={tabValue} index={1}>
              <SubsidiesListOptimized />
            </TabPanel>
            <TabPanel value={tabValue} index={2}>
              <DocumentsList />
            </TabPanel>
          </Route>
          <Route exact path={`/app/applications/:name`}>
            <TabPanel value={tabValue} index={0}>
              <ApplicationX />
            </TabPanel>
            <TabPanel value={tabValue} index={1}>
              <ApplicationDocumentList />
            </TabPanel>
          </Route>
        </main>
      </div>
    </>
  );
};

export default withRouter(DashboardLayout);
