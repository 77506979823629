export const toolTip = {
  backgroundColor: 'grey',
  // background: 'grey !important',
  opacity: 1,
  border: (theme) => {
    // `2px solid ${theme.palette.primary.main}`;
  },
  borderRadius: (theme) => {
    theme.spacing(1);
  },
  padding: (theme) => {
    theme.spacing(2);
  },
  fontFamily: 'Helvetica',
  fontSize: 12,
  color: (theme) => {
    // theme.palette.primary.main;
  },
  fontWeight: 'bold',
  boxShadow: '0px 5px 15px rgba(0,0,0,0.1)',
  marginBottom: (theme) => {
    theme.spacing(2);
  },
};
