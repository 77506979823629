import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { Button, Grid, TextField, Box, Typography } from '@mui/material';

const ForgotPasswordDetails = ({ email, setEmail, handleForgotPassword }) => {
  const { t } = useTranslation();
  const [disabled, setDisabled] = useState(true);

  useEffect(() => {
    const regEmail =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    setDisabled(!email.match(regEmail));
  }, [email]);

  return (
    <Grid
      container
      item
      xs={12}
      alignItems="flex-start"
      alignContent="center"
      sx={{
        maxWidth: '450px !important',
      }}
      className="forgot-details"
    >
      <Grid item xs={12} sx={{ pb: 3 }}>
        <Typography variant="h6">{t('login.forgot_pass')}</Typography>
      </Grid>
      <Grid
        container
        item
        xs={12}
        sx={{
          px: 3,
          pt: 3,
          pb: 0,
          boxShadow: '0 0 0 1px #E0E0E0',
          borderRadius: 1,
        }}
      >
        <Grid container>
          <Grid item xs={12}>
            <Box mb={2}>
              <TextField
                id="email"
                name="email"
                label={t('login.email')}
                variant="outlined"
                margin="normal"
                fullWidth
                autoComplete="email"
                value={email}
                onKeyUp={(e) => {
                  if (e.key === 'Enter' && !disabled) {
                    handleForgotPassword(e);
                    setEmail('');
                  }
                }}
                onChange={(e) => {
                  setEmail(e.target.value);
                }}
              />
            </Box>
          </Grid>
        </Grid>
      </Grid>
      <Grid
        container
        justifyContent="flex-end"
        alignItems="center"
        sx={{ px: 0, py: 3 }}
      >
        <Grid item sx={{ pr: 3, color: 'primary.main' }}>
          <Link to="/login">
            <Typography variant="body2">{t('login.login')}</Typography>
          </Link>
        </Grid>

        <Grid item>
          <Button
            color="primary"
            variant="contained"
            onClick={(e) => {
              handleForgotPassword(e);
              setEmail('');
            }}
            disabled={disabled}
          >
            {t('forgot_password.confirm')}
          </Button>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default ForgotPasswordDetails;
