import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { chipsContainer } from './styles';
import {
  Button,
  IconButton,
  Grid,
  TextField,
  Chip,
  Box,
  Typography,
} from '@mui/material';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import DoneIcon from '@mui/icons-material/Done';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';

import {
  checkValidation,
  regEmail,
  regPassword,
  regUppercase,
  regDigit,
  regSpecialSign,
} from '../../utils/FormFieldsValidation';

const LoginDetails = ({
  email,
  setEmail,
  password,
  setPassword,
  handleLogin,
}) => {
  const { t } = useTranslation();

  const [showPassword, setShowPassword] = useState(false);

  const [disabled, setDisabled] = useState(true);

  useEffect(() => {
    let user = {
      email: email,
      password: password,
    };
    setDisabled(!checkValidation(user, regPassword, regEmail));
  }, [email, password]);

  return (
    <Grid
      container
      item
      xs={12}
      className={'formContainer login-details'}
      alignItems="flex-start"
      alignContent="center"
      sx={{
        maxWidth: '450px !important',
      }}
    >
      <Grid item xs={12} sx={{ pb: 3 }}>
        <Typography variant="h6">{t('login.login')}</Typography>
      </Grid>
      <Grid
        container
        item
        xs={12}
        sx={{
          px: 3,
          pt: 3,
          pb: 0,
          boxShadow: '0 0 0 1px #E0E0E0',
          borderRadius: 1,
        }}
      >
        <Grid container>
          <Grid item xs={12}>
            <Box mb={2}>
              <TextField
                id="email"
                name="email"
                label={t('login.email')}
                variant="outlined"
                margin="normal"
                fullWidth
                autoComplete="email"
                value={email}
                onChange={(e) => {
                  setEmail(e.target.value);
                }}
              />
            </Box>
          </Grid>
          <Grid item xs={12}>
            <Box my={2}>
              <TextField
                id="password"
                name="password"
                label={t('login.password')}
                variant="outlined"
                type={showPassword ? 'text' : 'password'}
                fullWidth
                autoComplete="password"
                value={password}
                onKeyUp={(e) => {
                  if (e.key === 'Enter' && !disabled) {
                    handleLogin(e);
                  }
                }}
                onChange={(e) => {
                  setPassword(e.target.value);
                }}
                InputProps={{
                  endAdornment: (
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={() => setShowPassword(!showPassword)}
                      onMouseDown={() => setShowPassword(!showPassword)}
                      edge="end"
                      size="large"
                    >
                      {showPassword ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  ),
                }}
              />
            </Box>
          </Grid>
          <Grid
            container
            item
            xs={12}
            sx={chipsContainer}
            className="chipsContainer formControl"
          >
            <Grid container item xs={12} sx={{ mb: 1.5 }}>
              <Chip
                sx={{ mb: 1 }}
                label={'8 ' + t('login.characters')}
                icon={
                  password && password.length > 7 ? (
                    <DoneIcon color="secondary" className="icon-done" />
                  ) : password.length === 0 ? null : (
                    <ErrorOutlineIcon color="error" className="icon-error" />
                  )
                }
              />
              <Chip
                label={t('login.capital_letter')}
                icon={
                  password && password.length > 0 ? (
                    regUppercase.test(password) ? (
                      <DoneIcon color="secondary" className="icon-done" />
                    ) : (
                      <ErrorOutlineIcon color="error" className="icon-error" />
                    )
                  ) : null
                }
              />
              <Chip
                label={t('login.digit')}
                icon={
                  password && password.length > 0 ? (
                    regDigit.test(password) ? (
                      <DoneIcon color="secondary" className="icon-done" />
                    ) : (
                      <ErrorOutlineIcon color="error" className="icon-error" />
                    )
                  ) : null
                }
              />
              <Chip
                label={t('login.special_sign')}
                icon={
                  password && password.length > 0 ? (
                    regSpecialSign.test(password) ? (
                      <DoneIcon color="secondary" className="icon-done" />
                    ) : (
                      <ErrorOutlineIcon color="error" className="icon-error" />
                    )
                  ) : null
                }
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Grid
        container
        justifyContent="flex-end"
        alignItems="center"
        sx={{ py: 3, px: 0 }}
      >
        <Grid item sx={{ pr: 3, color: 'primary.main' }}>
          <Link to="/forgotpassword">
            <Typography variant="body2">{t('login.forgot_pass')}</Typography>
          </Link>
        </Grid>

        <Grid item>
          <Button
            color="primary"
            variant="contained"
            endIcon={<KeyboardArrowRightIcon />}
            onClick={handleLogin}
            disabled={disabled}
            className={'iconRight'}
          >
            {t('login.login')}
          </Button>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default LoginDetails;
