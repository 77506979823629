import React, { useState, useEffect, useContext } from 'react';
import { withRouter, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useStyles } from '../Projects/styles';
import { useTheme } from '@mui/material/styles';
import { UserContext } from '../../contexts/UserContext';
import Loader from '../../uiComponents/Loader';
import axios from 'axios';
import {
  Box,
  Grid,
  Typography,
  Card,
  CardContent,
  Divider,
} from '@mui/material';
import NumberFormat from 'react-number-format';
import i18n from 'i18next';
import moment from 'moment';
import { MapCountryFlag } from './utils';
import SplitButton from './SplitButton';

const ApplicationX = (props, ref) => {
  const applicationStatusTypes = {
    Concept: i18n.t('application_list_status.concept'),
    Potentieel: i18n.t('application_list_status.potential'),
    Recommended: i18n.t('application_list_status.potential'),
    'Klaar voor review': i18n.t('application_list_status.ready_for_review'),
    'Wacht op akkoord': i18n.t('application_list_status.waiting_for_approval'),
    'Klaar voor indienen': i18n.t('application_list_status.ready_for_applying'),
    Vormvrij: i18n.t('application_list_status.concept'),
    Ingediend: i18n.t('application_list_status.applied'),
    'In behandeling': i18n.t('application_list_status.in_processing'),
    Beschikt: i18n.t('application_list_status.granted'),
    Geëffectueerd: i18n.t('application_list_status.granted'),
    Afgerond: i18n.t('application_list_status.granted'),
    Afgewezen: i18n.t('application_list_status.rejected'),
    Ingetrokken: i18n.t('application_list_status.withdrawn'),
    Ruling: i18n.t('application_list_status.granted'),
    'Ruling afgerond': i18n.t('application_list_status.granted'),
  };
  const classes = useStyles();
  const { t } = useTranslation();
  const theme = useTheme();
  let location = useLocation();
  const [userContext] = useContext(UserContext);
  const [loading, setLoading] = useState(false);
  const [application, setApplication] = useState(null);
  const [locale, setLocale] = useState('en');
  const selectLocale = React.useCallback((locale) => {
    setLocale(i18n.languages[0]);
  }, []);
  useEffect(() => {
    selectLocale();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setLoading(true);
    let id = location.pathname.substring(
      location.pathname.lastIndexOf('/') + 1
    );
    const apiEndPoint =
      process.env.NODE_ENV === 'development'
        ? process.env.REACT_APP_API_ENDPOINT
        : process.env.REACT_APP_API_ENDPOINT_PROD;
    axios({
      method: 'get',
      url: apiEndPoint + '/v1/partner/applications/getApplication',
      params: { id: id },
      withCredentials: true,
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        Authorization: `Bearer ${userContext.token}`,
      },
    })
      .then((res) => {
        setApplication(res.data.application);
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
      });
    if (location.state) {
      let name = location.state.name;
      window.document.title = t('page_title.applicationX', { name });
    } else {
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location, userContext]);

  const topPageRef = React.useRef();

  useEffect(() => {
    document.getElementById('anchor-div').scrollIntoView();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      {loading && <Loader loading={loading} />}
      <div
        className={classes.root + ' ' + classes.projectX}
        style={{ position: 'relative' }}
      >
        <div
          ref={topPageRef}
          id='anchor-div'
          style={{ position: 'absolute', top: '-120px', left: '0' }}
        ></div>
        {application && (
          <Grid container justifyContent='center'>
            <Grid container item xs={12} className={classes.container}>
              <Grid item xs={12}>
                <Box mb={2}>
                  <Typography variant='h6' align='center'>
                    {t('applicationX.title')}
                  </Typography>
                </Box>
                <Box mb={3}>
                  <Typography align='center' color='textSecondary'>
                    {t('applicationX.subtitle')}
                  </Typography>
                </Box>
              </Grid>
              {/*1*/}
              <Grid container item xs={12} className={classes.box}>
                <Grid item xs={12}>
                  <Card
                    className={classes.card}
                    variant='outlined'
                    style={(theme.custom.boxshadow, { marginRight: 24 })}
                  >
                    <CardContent>
                      <Grid container>
                        <Grid item xs={12}>
                          <Box mb={1}>
                            <Grid container>
                              <Grid item xs={5}>
                                <Typography
                                  component='h6'
                                  variant='h6'
                                  className={classes.boxCardHeader}
                                >
                                  {t('applicationX.application')}
                                </Typography>
                              </Grid>
                              <Grid item xs={7}>
                                <Grid
                                  container
                                  direction='row'
                                  justifyContent='flex-end'
                                  alignItems='center'
                                >
                                  <Grid item>
                                    <SplitButton
                                      application={application}
                                      setLoading={setLoading}
                                    />
                                  </Grid>
                                </Grid>
                              </Grid>
                            </Grid>
                          </Box>
                        </Grid>
                        <Grid
                          container
                          item
                          xs={12}
                          className={classes.mTop12 + ' ' + classes.mBottom12}
                        >
                          <Grid container item xs={12} md={11}>
                            <Grid item xs={12} md={5}>
                              <Typography
                                variant='overline'
                                color='textSecondary'
                              >
                                {t('applicationX.subsidy_name')}
                              </Typography>
                            </Grid>
                            <Grid item xs={12} md={7}>
                              <Typography color='textSecondary'>
                                {application.subsidyId.name}
                              </Typography>
                            </Grid>
                          </Grid>
                          <Grid item md={1}></Grid>
                        </Grid>
                        <Grid item xs={12}>
                          <Divider />
                        </Grid>
                        {application?.status && application?.status !== '' ? (
                          <>
                            <Grid
                              container
                              item
                              xs={12}
                              className={
                                classes.mTop12 + ' ' + classes.mBottom12
                              }
                            >
                              <Grid container item xs={12} md={11}>
                                <Grid item xs={12} md={5}>
                                  <Typography
                                    variant='overline'
                                    color='textSecondary'
                                  >
                                    {t('applicationX.status')}
                                  </Typography>
                                </Grid>
                                <Grid item xs={12} md={7}>
                                  <span
                                    style={{
                                      background:
                                        application.status === 'Afgewezen' ||
                                        application.status === 'Ingetrokken'
                                          ? theme.palette.error.main
                                          : application.status === 'Beschikt' ||
                                            application.status ===
                                              'Geëffectueerd' ||
                                            application.status === 'Afgerond' ||
                                            application.status === 'Ruling' ||
                                            application.status ===
                                              'Ruling afgerond'
                                          ? theme.palette.secondary.main
                                          : theme.palette.primary.main,
                                      borderRadius: 20,
                                      fontSize: 12,
                                      fontWeight: 'bold',
                                      display: 'inline-block',
                                      padding: '4px 8px',
                                      color: '#fff',
                                    }}
                                  >
                                    {application.status
                                      ? applicationStatusTypes[
                                          application.status
                                        ]
                                      : ''}
                                  </span>
                                </Grid>
                              </Grid>
                              <Grid item md={1}></Grid>
                            </Grid>
                            <Grid item xs={12}>
                              <Divider />
                            </Grid>
                          </>
                        ) : (
                          <></>
                        )}
                        {application?.ownerId?.firstName &&
                        application?.ownerId?.firstName !== '' ? (
                          <>
                            <Grid
                              container
                              item
                              xs={12}
                              className={
                                classes.mTop12 + ' ' + classes.mBottom12
                              }
                            >
                              <Grid container item xs={12} md={11}>
                                <Grid item xs={12} md={5}>
                                  <Typography
                                    variant='overline'
                                    color='textSecondary'
                                  >
                                    {t('applicationX.subsidy_expert')}
                                  </Typography>
                                </Grid>
                                <Grid item xs={12} md={7}>
                                  <Typography color='textSecondary'>
                                    {application?.ownerId?.firstName +
                                      ' ' +
                                      application?.ownerId?.lastName}
                                  </Typography>
                                </Grid>
                              </Grid>
                              <Grid item md={1}></Grid>
                            </Grid>
                            <Grid item xs={12}>
                              <Divider />
                            </Grid>
                          </>
                        ) : (
                          <></>
                        )}
                        {application?.submissionDate ? (
                          <>
                            <Grid
                              container
                              item
                              xs={12}
                              className={
                                classes.mTop12 + ' ' + classes.mBottom12
                              }
                            >
                              <Grid container item xs={12} md={11}>
                                <Grid item xs={12} md={5}>
                                  <Typography
                                    variant='overline'
                                    color='textSecondary'
                                  >
                                    {t('applicationX.submission_date')}
                                  </Typography>
                                </Grid>
                                <Grid item xs={12} md={7}>
                                  <Typography color='textSecondary'>
                                    {application.submissionDate
                                      ? moment(application.submissionDate)
                                          .locale(locale)
                                          .format('DD MMM YYYY')
                                      : ''}
                                  </Typography>
                                </Grid>
                              </Grid>
                              <Grid item md={1}></Grid>
                            </Grid>
                            <Grid item xs={12}>
                              <Divider />
                            </Grid>
                          </>
                        ) : (
                          <></>
                        )}
                        {application?.subsidyId?.type &&
                        application?.subsidyId?.country ? (
                          <>
                            <Grid
                              container
                              item
                              xs={12}
                              className={
                                classes.mTop12 + ' ' + classes.mBottom12
                              }
                            >
                              <Grid container item xs={12} md={11}>
                                <Grid item xs={12} md={5}>
                                  <Typography
                                    variant='overline'
                                    color='textSecondary'
                                  >
                                    {t('applicationX.origin')}
                                  </Typography>
                                </Grid>
                                <Grid item xs={12} md={7}>
                                  {application.subsidyId.type === 'European' ? (
                                    MapCountryFlag['Eu']
                                  ) : application.subsidyId.country ===
                                      'Netherlands' ||
                                    application.subsidyId.country ===
                                      'Germany' ? (
                                    MapCountryFlag[
                                      application.subsidyId.country
                                    ]
                                  ) : (
                                    <> </>
                                  )}
                                </Grid>
                              </Grid>
                            </Grid>
                            <Grid item xs={12}>
                              <Divider />
                            </Grid>
                          </>
                        ) : (
                          <></>
                        )}
                        {application?.appliedAmount ? (
                          <>
                            <Grid
                              container
                              item
                              xs={12}
                              className={
                                classes.mTop12 + ' ' + classes.mBottom12
                              }
                            >
                              <Grid container item xs={12} md={11}>
                                <Grid item xs={12} md={5}>
                                  <Typography
                                    variant='overline'
                                    color='textSecondary'
                                  >
                                    {t('applicationX.applied_amount')}
                                  </Typography>
                                </Grid>
                                <Grid item xs={12} md={7}>
                                  <Typography color='textSecondary'>
                                    <NumberFormat
                                      value={application.appliedAmount || ''}
                                      displayType={'text'}
                                      thousandSeparator={'.'}
                                      decimalSeparator={','}
                                      isNumericString
                                      prefix={'€'}
                                    />
                                  </Typography>
                                </Grid>
                              </Grid>
                            </Grid>
                            <Grid item xs={12}>
                              <Divider />
                            </Grid>
                          </>
                        ) : (
                          <></>
                        )}
                        {application?.grantedAmount &&
                        application?.grantedAmount !== 0 ? (
                          <>
                            <Grid
                              container
                              item
                              xs={12}
                              className={
                                classes.mTop12 + ' ' + classes.mBottom12
                              }
                            >
                              <Grid container item xs={12} md={11}>
                                <Grid item xs={12} md={5}>
                                  <Typography
                                    variant='overline'
                                    color='textSecondary'
                                  >
                                    {t('applicationX.granted_amount')}
                                  </Typography>
                                </Grid>
                                <Grid item xs={12} md={7}>
                                  <Typography color='textSecondary'>
                                    <NumberFormat
                                      value={application.grantedAmount || ''}
                                      displayType={'text'}
                                      thousandSeparator={'.'}
                                      decimalSeparator={','}
                                      isNumericString
                                      prefix={'€'}
                                    />
                                  </Typography>
                                </Grid>
                              </Grid>
                            </Grid>
                            <Grid item xs={12}>
                              <Divider />
                            </Grid>
                          </>
                        ) : (
                          <></>
                        )}
                        {application?.projectName &&
                        application?.projectName !== '' ? (
                          <>
                            <Grid
                              container
                              item
                              xs={12}
                              className={
                                classes.mTop12 + ' ' + classes.mBottom12
                              }
                            >
                              <Grid container item xs={12} md={11}>
                                <Grid item xs={12} md={5}>
                                  <Typography
                                    variant='overline'
                                    color='textSecondary'
                                  >
                                    {t('applicationX.project_name')}
                                  </Typography>
                                </Grid>
                                <Grid item xs={12} md={7}>
                                  <Typography color='textSecondary'>
                                    {application.projectName}
                                  </Typography>
                                </Grid>
                              </Grid>
                              <Grid item md={1}></Grid>
                            </Grid>
                            <Grid item xs={12}>
                              <Divider />
                            </Grid>
                          </>
                        ) : (
                          <></>
                        )}
                        {application?.projectStartDate ? (
                          <>
                            <Grid
                              container
                              item
                              xs={12}
                              className={
                                classes.mTop12 + ' ' + classes.mBottom12
                              }
                            >
                              <Grid container item xs={12} md={11}>
                                <Grid item xs={12} md={5}>
                                  <Typography
                                    variant='overline'
                                    color='textSecondary'
                                  >
                                    {t('applicationX.project_start_date')}
                                  </Typography>
                                </Grid>
                                <Grid item xs={12} md={7}>
                                  <Typography color='textSecondary'>
                                    {application.projectStartDate
                                      ? moment(application.projectStartDate)
                                          .locale(locale)
                                          .format('DD MMM YYYY')
                                      : ''}
                                  </Typography>
                                </Grid>
                              </Grid>
                              <Grid item md={1}></Grid>
                            </Grid>
                            <Grid item xs={12}>
                              <Divider />
                            </Grid>
                          </>
                        ) : (
                          <></>
                        )}
                        {application?.projectEndDate ? (
                          <>
                            <Grid
                              container
                              item
                              xs={12}
                              className={
                                classes.mTop12 + ' ' + classes.mBottom12
                              }
                            >
                              <Grid container item xs={12} md={11}>
                                <Grid item xs={12} md={5}>
                                  <Typography
                                    variant='overline'
                                    color='textSecondary'
                                  >
                                    {t('applicationX.project_end_date')}
                                  </Typography>
                                </Grid>
                                <Grid item xs={12} md={7}>
                                  <Typography color='textSecondary'>
                                    {application.projectEndDate
                                      ? moment(application.projectEndDate)
                                          .locale(locale)
                                          .format('DD MMM YYYY')
                                      : ''}
                                  </Typography>
                                </Grid>
                              </Grid>
                              <Grid item md={1}></Grid>
                            </Grid>
                          </>
                        ) : (
                          <></>
                        )}
                      </Grid>
                    </CardContent>
                  </Card>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        )}
      </div>
    </>
  );
};

export default withRouter(ApplicationX);
