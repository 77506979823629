import React from 'react';
import { Button, Grid, TextField, InputAdornment } from '@mui/material';
import { useTranslation } from 'react-i18next';
import EmailIcon from '@mui/icons-material/Email';
import { useIsMounted } from '../utils/customHooks';

const AddContact = (props) => {
  const { newContact, setNewContact, onClose, handleAddNewContact } = props;
  const [finish, setFinish] = React.useState(false);
  const isMounted = useIsMounted();
  const { t } = useTranslation();
  const regEmail =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

  React.useEffect(() => {
    if (newContact) {
      const isComplete = () => {
        if (
          newContact?.firstName &&
          /(^.{1,60}$)/.test(newContact?.firstName) &&
          newContact?.lastName &&
          /(^.{1,60}$)/.test(newContact?.lastName) &&
          newContact?.email &&
          regEmail.test(newContact?.email)
        ) {
          return true;
        } else {
          return false;
        }
      };
      if (isMounted.current) {
        setFinish(isComplete);
      }
    } else {
      if (isMounted.current) {
        setFinish(false);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [newContact]);

  const handleContactDetails = (prop, value) => {
    setNewContact((oldValues) => {
      return {
        ...oldValues,
        [prop]: value,
      };
    });
  };

  return (
    <>
      <Grid container mt={2}>
        <Grid item xs={12} md={6} sx={{ pb: 3, pr: { md: 2 } }}>
          <TextField
            variant='outlined'
            required={true}
            error={
              newContact &&
              newContact?.firstName &&
              Boolean(!/(^.{1,60}$)/.test(newContact?.firstName))
                ? Boolean(!/(^.{1,60}$)/.test(newContact?.firstName))
                : false
            }
            name='firstName'
            fullWidth
            label={t('labels.first_name')}
            placeholder={t('labels.first_name')}
            value={newContact?.firstName || ''}
            onChange={(e) => {
              handleContactDetails(e.target.name, e.target.value);
            }}
            inputProps={{
              fontSize: 14,
            }}
            InputLabelProps={{ shrink: true }}
          />
        </Grid>
        <Grid item xs={12} md={6} sx={{ pb: 3, pl: { md: 2 } }}>
          <TextField
            variant='outlined'
            error={
              newContact &&
              newContact?.lastName &&
              Boolean(!/(^.{1,60}$)/.test(newContact?.lastName))
                ? Boolean(!/(^.{1,60}$)/.test(newContact?.lastName))
                : false
            }
            name='lastName'
            fullWidth
            label={t('labels.last_name')}
            placeholder={t('labels.last_name')}
            value={newContact?.lastName || ''}
            required={true}
            inputProps={{
              fontSize: 14,
            }}
            onChange={(e) => {
              handleContactDetails(e.target.name, e.target.value);
            }}
          />
        </Grid>
        <Grid item xs={12} md={12} sx={{ pb: 3, pr: { md: 2 } }}>
          <TextField
            variant='outlined'
            required={true}
            error={
              newContact &&
              newContact?.email &&
              Boolean(!regEmail.test(newContact?.email))
                ? Boolean(!regEmail.test(newContact?.email))
                : false
            }
            name='email'
            fullWidth
            label={t('labels.email')}
            placeholder={t('labels.email')}
            value={newContact?.email || ''}
            onChange={(e) => {
              handleContactDetails(e.target.name, e.target.value);
            }}
            InputProps={{
              endAdornment: (
                <InputAdornment position='end'>
                  <EmailIcon />
                </InputAdornment>
              ),
            }}
            inputProps={{
              fontSize: 14,
            }}
          />
        </Grid>
        <Grid
          container
          justifyContent={'space-between'}
          item
          xs={12}
          sx={{ pt: 3 }}
        >
          <Grid item sx={{}}>
            <Button onClick={onClose} color='primary'>
              {t('buttons.cancel')}
            </Button>
          </Grid>
          <Grid item sx={{}}>
            <Button
              disabled={!finish}
              onClick={handleAddNewContact}
              color='primary'
            >
              {t('buttons.add')}
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

export default AddContact;
