import React, { useState, useContext, useEffect } from 'react';
import { Grid, Typography, InputAdornment, TextField } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import enLocale from 'date-fns/locale/en-US';
import nlLocale from 'date-fns/locale/nl';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import axios from 'axios';
import { UserContext } from '../../contexts/UserContext';
import { useTranslation } from 'react-i18next';
import i18n from 'i18next';

const localeMap = {
  en: enLocale,
  nl: nlLocale,
};

const localeCancelLabelMap = {
  en: 'Cancel',
  nl: 'Annuleren',
};
const localeClearLabelMap = {
  en: 'Clear',
  nl: 'Verwijderen',
};

const FiltersComponent = (props) => {
  const {
    classes,
    searchText,
    setSearchText,
    status,
    startDate,
    setStartDate,
    endDate,
    setEndDate,
    setShowErrorDateRange,
    setTodoPage,
    setDonePage,
    setLoading,
    setApplications,
  } = props;
  // eslint-disable-next-line no-unused-vars
  const [locale, setLocale] = useState('en');
  const selectLocale = React.useCallback((locale) => {
    setLocale(i18n.languages[0]);
  }, []);
  const [userContext] = useContext(UserContext);
  const [openStartDate, setOpenStartDate] = useState(false);
  const [openEndDate, setOpenEndDate] = useState(false);
  const { t } = useTranslation();
  useEffect(() => {
    selectLocale();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleFilterSearchText = (searchText) => {
    setLoading(true);
    let startOfDate = startDate
      ? new Date(new Date(startDate).setHours(0, 0, 0, 0))
      : null;
    let endOfDate = endDate
      ? new Date(new Date(endDate).setHours(23, 59, 59, 999))
      : null;
    const apiEndPoint =
      process.env.NODE_ENV === 'development'
        ? process.env.REACT_APP_API_ENDPOINT
        : process.env.REACT_APP_API_ENDPOINT_PROD;
    axios({
      method: 'get',
      url: apiEndPoint + '/v1/partner/applications/getApplications',
      withCredentials: true,
      params: {
        text: searchText,
        status: status,
        from: startOfDate
          ? new Date(
              startOfDate.getTime() -
                startOfDate.getTimezoneOffset() * 60 * 1000
            )
          : null,
        to: endOfDate
          ? new Date(
              endOfDate.getTime() - endOfDate.getTimezoneOffset() * 60 * 1000
            )
          : null,
      },
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        Authorization: `Bearer ${userContext.token}`,
      },
    })
      .then((res) => {
        setApplications(res.data);
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
      });
  };

  const handleFilterSearchStartDate = (searchStartDate) => {
    setLoading(true);
    let startOfDate = searchStartDate
      ? new Date(new Date(searchStartDate).setHours(0, 0, 0, 0))
      : null;
    let endOfDate = endDate
      ? new Date(new Date(endDate).setHours(23, 59, 59, 999))
      : null;
    const apiEndPoint =
      process.env.NODE_ENV === 'development'
        ? process.env.REACT_APP_API_ENDPOINT
        : process.env.REACT_APP_API_ENDPOINT_PROD;
    axios({
      method: 'get',
      url: apiEndPoint + '/v1/partner/applications/getApplications',
      withCredentials: true,
      params: {
        text: searchText,
        status: status,
        from: startOfDate
          ? new Date(
              startOfDate.getTime() -
                startOfDate.getTimezoneOffset() * 60 * 1000
            )
          : null,
        to: endOfDate
          ? new Date(
              endOfDate.getTime() - endOfDate.getTimezoneOffset() * 60 * 1000
            )
          : null,
      },
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        Authorization: `Bearer ${userContext.token}`,
      },
    })
      .then((res) => {
        setApplications(res.data);
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
      });
  };

  const handleFilterSearchEndDate = (searchEndDate) => {
    setLoading(true);
    let startOfDate = startDate
      ? new Date(new Date(startDate).setHours(0, 0, 0, 0))
      : null;
    let endOfDate = searchEndDate
      ? new Date(new Date(searchEndDate).setHours(23, 59, 59, 999))
      : null;
    const apiEndPoint =
      process.env.NODE_ENV === 'development'
        ? process.env.REACT_APP_API_ENDPOINT
        : process.env.REACT_APP_API_ENDPOINT_PROD;
    axios({
      method: 'get',
      url: apiEndPoint + '/v1/partner/applications/getApplications',
      withCredentials: true,
      params: {
        text: searchText,
        status: status,
        from: startOfDate
          ? new Date(
              startOfDate.getTime() -
                startOfDate.getTimezoneOffset() * 60 * 1000
            )
          : null,
        to: endOfDate
          ? new Date(
              endOfDate.getTime() - endOfDate.getTimezoneOffset() * 60 * 1000
            )
          : null,
      },
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        Authorization: `Bearer ${userContext.token}`,
      },
    })
      .then((res) => {
        setApplications(res.data);
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
      });
  };

  return (
    <Grid container alignContent={'center'}>
      <Grid
        item
        xs={12}
        md={7}
        sx={{ pr: { xs: 0, md: 2 }, pb: { xs: 2, md: 0 } }}
      >
        <TextField
          id='searchField'
          className={classes.searchField}
          label={t('application_list.search_field_label')}
          variant='outlined'
          placeholder={t('application_list.search_field_placeholder')}
          fullWidth
          size='small'
          value={searchText}
          onChange={(e) => {
            setSearchText(e.target.value);
          }}
          onBlur={(e) => {
            setTimeout(() => {
              setTodoPage(0);
              setDonePage(0);
            }, 100);
            handleFilterSearchText(e.target.value.trim());
          }}
          onKeyUp={(e) => {
            if (e.key === 'Enter') {
              setTimeout(() => {
                setTodoPage(0);
                setDonePage(0);
              }, 100);
              handleFilterSearchText(e.target.value.trim());
            }
          }}
          InputProps={{
            endAdornment: (
              <InputAdornment position='end'>
                <SearchIcon />
              </InputAdornment>
            ),
          }}
        />
      </Grid>
      <Grid item xs={12} md={5} sx={{ pl: { xs: 0, md: 2 } }}>
        <Grid container>
          <Grid item xs={5} md={5}>
            <LocalizationProvider
              dateAdapter={AdapterDateFns}
              adapterLocale={localeMap[locale]}
            >
              <DatePicker
                onClose={() => setOpenStartDate(false)}
                open={openStartDate}
                id={'startDate'}
                name={'startDate'}
                inputFormat='dd MMM yyyy'
                value={startDate}
                maxDate={endDate}
                cancelText={localeCancelLabelMap[locale]}
                clearText={localeClearLabelMap[locale]}
                fullWidth
                onChange={(newValue) => {
                  setStartDate(newValue);
                  if (newValue === null) {
                    setTimeout(() => {
                      setTodoPage(0);
                      setDonePage(0);
                    }, 100);
                    handleFilterSearchStartDate(newValue);
                  } else if (endDate === null) {
                    setTimeout(() => {
                      setTodoPage(0);
                      setDonePage(0);
                    }, 100);
                    handleFilterSearchStartDate(newValue);
                  } else if (newValue <= endDate) {
                    setTimeout(() => {
                      setTodoPage(0);
                      setDonePage(0);
                    }, 100);
                    handleFilterSearchStartDate(newValue);
                  } else {
                    setShowErrorDateRange(true);
                    return;
                  }
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant='outlined'
                    size='small'
                    label={t('application_list.search_date_label')}
                    onMouseDown={() => {
                      setOpenStartDate(true);
                    }}
                  />
                )}
              ></DatePicker>
            </LocalizationProvider>
          </Grid>
          <Grid item xs={2} md={2}>
            <Typography sx={{ pl: { xs: 2, md: 2.5 }, pr: { xs: 2, md: 2.5 } }}>
              {t('application_list.search_date_between_label')}
            </Typography>
          </Grid>
          <Grid item xs={5} md={5}>
            <LocalizationProvider
              dateAdapter={AdapterDateFns}
              adapterLocale={localeMap[locale]}
            >
              <DatePicker
                onClose={() => setOpenEndDate(false)}
                open={openEndDate}
                id={'endDate'}
                fullWidth
                name={'endDate'}
                inputFormat='dd MMM yyyy'
                value={endDate}
                clearable={true}
                cancelText={localeCancelLabelMap[locale]}
                clearText={localeClearLabelMap[locale]}
                minDate={startDate}
                onChange={(newValue) => {
                  setEndDate(newValue);
                  if (newValue === null) {
                    setTimeout(() => {
                      setTodoPage(0);
                      setDonePage(0);
                    }, 100);
                    handleFilterSearchEndDate(newValue);
                  } else if (startDate === null) {
                    setTimeout(() => {
                      setTodoPage(0);
                      setDonePage(0);
                    }, 100);
                    handleFilterSearchEndDate(newValue);
                  } else if (startDate <= newValue) {
                    setTimeout(() => {
                      setTodoPage(0);
                      setDonePage(0);
                    }, 100);
                    handleFilterSearchEndDate(newValue);
                  } else {
                    setShowErrorDateRange(true);
                    return;
                  }
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant='outlined'
                    size='small'
                    label={t('application_list.search_date_label')}
                    onMouseDown={() => {
                      setOpenEndDate(true);
                    }}
                  />
                )}
              ></DatePicker>
            </LocalizationProvider>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default FiltersComponent;
