import makeStyles from '@mui/styles/makeStyles';

export const useStyles = makeStyles((theme) => ({
  webviewerContainer: {
    width: ' 100%',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
  webviewerFrame: {
    height: '800px',
  },
  root: {
    padding: '48px 24px',
    minHeight: 'calc(100% - 72px)',
    [theme.breakpoints.down('768')]: {
      minHeight: 'calc(100% - 64px)',
      padding: '24px 16px',
    },
  },
  formControl: {
    minWidth: 120,
    display: 'flex',
    width: '100%',
    //marginBottom: 48,
    '&.MuiAutocomplete-root': {
      [theme.breakpoints.down('768')]: {
        maxWidth: 'calc(100vw - 32px)',
      },
    },
  },
  subTitle: {
    display: 'block',
    marginBottom: 16,
  },
  autocompleteMultiple: {
    '& .MuiAutocomplete-endAdornment': {
      top: '14px !important',
    },
  },
}));
