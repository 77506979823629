import React, { useState, useEffect } from 'react';
import { useTheme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import moment from 'moment';
import 'moment/locale/nl';
import NumberFormat from 'react-number-format';
import EditIcon from '@mui/icons-material/Edit';
import { MapCountryFlag } from './utils';
import SortTableHead from './SortTableHead';
import NoResultsFound from './NoResultsFound';
import { getComparator, stableSort } from './utils';
import enLocale from 'date-fns/locale/en-US';
import nlLocale from 'date-fns/locale/nl';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TablePagination,
  TableRow,
  TextField,
} from '@mui/material';
import i18n from 'i18next';
import { Link, withRouter } from 'react-router-dom';
const localeMap = {
  en: enLocale,
  nl: nlLocale,
};

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  table: {
    minWidth: 976,
    '& .MuiTableRow-hover': {},
  },
  todoCell: {
    '& span': {
      background: theme.palette.primary.main,
      borderRadius: 20,
      fontSize: 12,
      fontWeight: 'bold',
      display: 'inline-block',
      padding: '4px 8px',
      color: '#fff',
    },
  },
  editable: {
    '&:hover .MuiSvgIcon-root': {
      display: 'inline-block !important',
      cursor: 'pointer',
    },
    '& .MuiInputBase-input': {
      padding: '0 !important',
    },
    '& [data-prop="submissionDate"]': {
      width: '0.8em',
      height: '0.8em',
    },
    '& [data-prop="appliedAmount"]': {
      width: '0.8em',
      height: '0.8em',
    },
    '& [data-id="dateInput"] .MuiInputBase-root.MuiInput-root': {
      fontSize: '14px  !important',
    },
    '& [data-id="amountInput"] .MuiInputBase-root.MuiInput-root': {
      fontSize: '14px !important',
    },
    '& [data-id="dateInput"] input': {},
  },
  disabledRow: {
    '& .MuiTableCell-body': {
      color: theme.palette.text.secondary,
    },
  },
}));

const TodoTable = (props) => {
  const applicationStatusTypes = {
    Concept: i18n.t('application_list_status.concept'),
    Potentieel: i18n.t('application_list_status.potential'),
    Recommended: i18n.t('application_list_status.potential'),
    'Klaar voor review': i18n.t('application_list_status.ready_for_review'),
    'Wacht op akkoord': i18n.t('application_list_status.waiting_for_approval'),
    'Klaar voor indienen': i18n.t('application_list_status.ready_for_applying'),
    Vormvrij: i18n.t('application_list_status.concept'),
    Ingediend: i18n.t('application_list_status.applied'),
    'In behandeling': i18n.t('application_list_status.in_processing'),
    Beschikt: i18n.t('application_list_status.granted'),
    Geëffectueerd: i18n.t('application_list_status.granted'),
    Afgerond: i18n.t('application_list_status.granted'),
    Afgewezen: i18n.t('application_list_status.rejected'),
    Ingetrokken: i18n.t('application_list_status.withdrawn'),
    Ruling: i18n.t('application_list_status.granted'),
    'Ruling afgerond': i18n.t('application_list_status.granted'),
  };
  const classes = useStyles();
  const theme = useTheme();
  const [showEditField1, setShowEditField1] = useState(false);
  const [showEditField2, setShowEditField2] = useState(false);
  const [selectedRow, setSelectedRow] = useState('');
  const [order, setOrder] = useState('asc');
  const [orderBy, setOrderBy] = useState('name');
  const { page, setPage } = props;
  // eslint-disable-next-line no-unused-vars
  const [dense, setDense] = useState(false);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  // eslint-disable-next-line no-unused-vars
  const [locale, setLocale] = useState('en');
  const selectLocale = React.useCallback((locale) => {
    setLocale(i18n.languages[0]);
  }, []);
  const [openDate, setOpenDate] = useState(false);
  useEffect(() => {
    selectLocale();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const [valueNumberFormat, setValueNumberFormat] = useState();

  // eslint-disable-next-line no-unused-vars
  const handleChangeNumberFormatValue = (event) => {
    setValueNumberFormat(parseInt(event.target.value));
  };

  return (
    <div className={classes.root}>
      <TableContainer style={{}}>
        <Table
          className={classes.table}
          aria-labelledby='tableTitle'
          size={dense ? 'small' : 'medium'}
          aria-label='enhanced table'
        >
          {props.headCells && (
            <SortTableHead
              classes={classes}
              order={order}
              orderBy={orderBy}
              onRequestSort={handleRequestSort}
              rowCount={props.data.length}
              headCells={props.headCells}
              active={true}
            />
          )}

          {props.data && props.data.length > 0 ? (
            <TableBody>
              {stableSort(props.data, getComparator(order, orderBy))
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row, index) => {
                  console.log(props.showApplicationX);
                  return (
                    <TableRow
                      hover
                      tabIndex={-1}
                      key={row.id || index}
                      component={props.showApplicationX ? Link : 'div'}
                      to={{
                        pathname: `/app/applications/${row.id}`,
                        state: { name: row.name },
                      }}
                    >
                      <TableCell style={{ cursor: 'pointer' }}>
                        {row.name || ''}
                      </TableCell>
                      <TableCell>{row.accountName || ''}</TableCell>
                      <TableCell>
                        {row.type === 'European' ? (
                          MapCountryFlag['Eu']
                        ) : row.country === 'Netherlands' ||
                          row.country === 'Germany' ? (
                          MapCountryFlag[row.country]
                        ) : (
                          <> </>
                        )}
                      </TableCell>
                      <TableCell>
                        <>
                          {/* editable appliedAmount */}
                          {selectedRow === row.id && showEditField1 && (
                            <>
                              <NumberFormat
                                customInput={TextField}
                                onValueChange={(values) => {
                                  setValueNumberFormat(values.value);
                                }}
                                onBlur={(e) => {
                                  setShowEditField1(false);
                                  setSelectedRow('');
                                  if (
                                    valueNumberFormat &&
                                    valueNumberFormat !== row.appliedAmount
                                  ) {
                                    setValueNumberFormat('');
                                  }
                                  setValueNumberFormat('');
                                }}
                                value={valueNumberFormat}
                                variant='standard'
                                autoFocus
                                placeholder={
                                  row.appliedAmount
                                    ? row.appliedAmount.toString()
                                    : ''
                                }
                                data-id='amountInput'
                                thousandSeparator={'.'}
                                decimalSeparator={','}
                                isNumericString
                                prefix={'€ '}
                              />
                            </>
                          )}
                          {/* read-only appliedAmount */}
                          {/*  {selectedRow !== row.id && (  */}
                          {((selectedRow === row.id && showEditField2) ||
                            selectedRow !== row.id) && (
                            <>
                              <NumberFormat
                                value={row.appliedAmount || ''}
                                displayType={'text'}
                                thousandSeparator={'.'}
                                decimalSeparator={','}
                                isNumericString
                                prefix={'€'}
                              />
                              <EditIcon
                                data-id={row.id}
                                data-prop='appliedAmount'
                                style={{
                                  display: 'none',
                                  float: 'right',
                                  fill: theme.palette.text.secondary,
                                }}
                                onClick={(event) => {
                                  setValueNumberFormat('');
                                  if (
                                    event.currentTarget.getAttribute(
                                      'data-id'
                                    ) === row.id &&
                                    event.currentTarget.getAttribute(
                                      'data-prop'
                                    ) === 'appliedAmount'
                                  ) {
                                    setShowEditField1(true);
                                    setShowEditField2(false);
                                    setSelectedRow(
                                      event.currentTarget.getAttribute(
                                        'data-id'
                                      )
                                    );
                                  } else {
                                    setShowEditField1(false);
                                    setShowEditField2(false);
                                    setSelectedRow('');
                                  }
                                }}
                              />
                            </>
                          )}
                        </>
                      </TableCell>
                      <TableCell>
                        {row.firstName + ' '}
                        {row.lastName}
                      </TableCell>
                      <TableCell>
                        {/* editable submissionDate */}
                        {selectedRow === row.id && showEditField2 && (
                          <>
                            <LocalizationProvider
                              dateAdapter={AdapterDateFns}
                              adapterLocale={localeMap[locale]}
                            >
                              <DesktopDatePicker
                                open={openDate}
                                onClose={() => {
                                  setShowEditField2(false);
                                  setSelectedRow('');
                                  setOpenDate(false);
                                }}
                                id={'submissionDate'}
                                name={'submissionDate'}
                                inputFormat='dd MMM yyyy'
                                value={row.submissionDate || ''}
                                onChange={(newValue) => {
                                  setShowEditField2(false);
                                  setSelectedRow('');
                                }}
                                renderInput={(params) => (
                                  <TextField
                                    {...params}
                                    variant='standard'
                                    autoFocus
                                    placeholder={'DD MM YYY'}
                                    id='dateInput'
                                    data-id='dateInput'
                                    onMouseDown={(e) => {
                                      setOpenDate(true);
                                    }}
                                    onBlur={(e) => {
                                      if (!openDate) {
                                        setShowEditField2(false);
                                        setSelectedRow('');
                                      } else {
                                      }
                                    }}
                                  />
                                )}
                              ></DesktopDatePicker>
                            </LocalizationProvider>
                          </>
                        )}
                        {/* read-only submissionDate */}
                        {((selectedRow === row.id && showEditField1) ||
                          selectedRow !== row.id) && (
                          <>
                            {row.submissionDate &&
                              moment(row.submissionDate)
                                .locale(locale)
                                .format('DD MMM YYYY')}
                            <EditIcon
                              data-id={row.id}
                              data-prop='submissionDate'
                              style={{
                                display: 'none',
                                float: 'right',
                                fill: theme.palette.text.secondary,
                              }}
                              onClick={(event) => {
                                if (
                                  event.currentTarget.getAttribute(
                                    'data-id'
                                  ) === row.id &&
                                  event.currentTarget.getAttribute(
                                    'data-prop'
                                  ) === 'submissionDate'
                                ) {
                                  setShowEditField2(true);
                                  setShowEditField1(false);
                                  setSelectedRow(
                                    event.currentTarget.getAttribute('data-id')
                                  );
                                } else {
                                  setShowEditField2(false);
                                  setShowEditField1(false);
                                  setSelectedRow('');
                                }
                              }}
                            />
                          </>
                        )}
                      </TableCell>
                      <TableCell className={classes.todoCell}>
                        <span>
                          {row.status ? applicationStatusTypes[row.status] : ''}
                        </span>
                      </TableCell>
                    </TableRow>
                  );
                })}
            </TableBody>
          ) : (
            <NoResultsFound span={6} />
          )}
        </Table>
      </TableContainer>
      {props.data && props.data.length > 10 && (
        <TablePagination
          rowsPerPageOptions={[]}
          component='div'
          count={props.data.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      )}
    </div>
  );
};

export default withRouter(TodoTable);
