import React, { useState, useEffect, useContext } from 'react';
import { withRouter, useLocation } from 'react-router-dom';
import BaseLayout from '../layouts/BaseLayout';
import { useTranslation } from 'react-i18next';
import { useIsMounted } from '../utils/customHooks';
import { root, rootContent } from './styles';
import { Grid, Snackbar, IconButton } from '@mui/material';
import Alert from '@mui/material/Alert';
import CloseIcon from '@mui/icons-material/Close';
import ResetPasswordDetails from '../components/ForgotPassword/ResetPasswordDetails';
import LoginSideComponent from '../components/Login/LoginSideComponent';
import Loader from '../uiComponents/Loader';
import { LoginContext } from '../contexts/LoginContext';
import { UserContext } from '../contexts/UserContext';
import axios from 'axios';

const ResetPassword = ({ history }) => {
  const location = useLocation();
  const { t } = useTranslation();
  const isMounted = useIsMounted();

  const [loading, setLoading] = useState(false);
  // eslint-disable-next-line no-unused-vars
  const { passReset, setPassReset } = useContext(LoginContext);
  // eslint-disable-next-line no-unused-vars
  const [userContext, setUserContext] = useContext(UserContext);
  const [password, setPassword] = useState('');
  const [showError, setShowError] = useState(false);

  const handleCloseSnackbar = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setShowError(false);
  };

  useEffect(() => {
    window.document.title = t('page_title.reset_pass');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      {loading && <Loader loading={loading} />}
      <BaseLayout
        main={
          <>
            <Grid
              container
              sx={root}
              justifyContent={'center'}
              alignItems={'center'}
              className='ResetPasswordPage'
            >
              <Grid container item wrap='nowrap' sx={rootContent} xs={12}>
                <Grid
                  container
                  item
                  justifyContent={'flex-start'}
                  alignItems={'center'}
                  xs={6}
                  sx={{ display: { xs: 'none', md: 'flex' } }}
                >
                  <LoginSideComponent />
                </Grid>
                <Grid
                  container
                  item
                  xs
                  sx={{ justifyContent: { xs: 'center', md: 'flex-end' } }}
                >
                  {' '}
                  <ResetPasswordDetails
                    password={password}
                    setPassword={setPassword}
                    handleResetPassword={handleResetPassword}
                  />
                </Grid>
              </Grid>
            </Grid>

            <Snackbar
              open={showError}
              onClose={handleCloseSnackbar}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'center',
              }}
              style={{ top: 80 }}
            >
              <Alert
                severity='error'
                style={{ position: 'relative', paddingRight: 46 }}
              >
                {t('forgot_password.error_1')}
                <IconButton
                  size='small'
                  aria-label='close'
                  color='inherit'
                  onClick={handleCloseSnackbar}
                  style={{
                    marginLeft: 10,
                    position: 'absolute',
                    right: 10,
                    top: 10,
                  }}
                >
                  <CloseIcon fontSize='small' />
                </IconButton>
              </Alert>
            </Snackbar>
          </>
        }
      ></BaseLayout>
    </>
  );

  function handleResetPassword(e) {
    e.preventDefault();
    setLoading(true);

    let token = location.pathname.substring(
      location.pathname.lastIndexOf('/') + 1
    );

    let apiEndPoint =
      process.env.NODE_ENV === 'development'
        ? process.env.REACT_APP_API_ENDPOINT
        : process.env.REACT_APP_API_ENDPOINT_PROD;

    axios({
      method: 'post',
      url: apiEndPoint + '/v1/partner/auth/reset-password',
      data: { password: password, token: token },
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
      },
    })
      .then((response) => {
        if (isMounted.current) {
          setPassReset(true);
          setLoading(false);
        }
        history.push('/login');
      })
      .catch((error) => {
        if (isMounted.current) {
          setLoading(false);
          setShowError(true);
        }
      });
  }
};

export default withRouter(ResetPassword);
