// eslint-disable-next-line no-unused-vars
import React from 'react';
import withStyles from '@mui/styles/withStyles';
import LinearProgress from '@mui/material/LinearProgress';

const BorderLinearProgress = withStyles((bdtheme) => ({
  root: {
    height: 8,
    width: '100%',
    borderRadius: 4,
  },
  colorPrimary: {
    backgroundColor: '#e5e5e5',
  },
  bar: {
    borderRadius: 4,
    backgroundColor: bdtheme.palette.secondary.main,
  },
}))(LinearProgress);

export default BorderLinearProgress;
