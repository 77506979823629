import React, { useState } from 'react';
import {
  boxFilters1,
  boxFilters2,
  boxFilters3,
  boxFilters4,
} from '../components/styles';
import {
  Grid,
  Typography,
  InputAdornment,
  TextField,
  Tooltip,
  Stack,
} from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import { enGB, enUS, nl } from 'date-fns/locale';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';

const localeMap = {
  nl: nl,
  en: enGB || enUS,
};

const localeCancelLabelMap = {
  en: 'Cancel',
  nl: 'Annuleren',
};
const localeClearLabelMap = {
  en: 'Clear',
  nl: 'Verwijderen',
};

const FiltersComponent = (props) => {
  const {
    locale,
    searchText,
    searchTextFilterParam,
    setSearchText,
    placeholder,
    labelTextfield,
    labelStartdate,
    labelEnddate,
    separator,
    startDate,
    startDateFilterParam,
    setStartDate,
    endDate,
    endDateFilterParam,
    setEndDate,
    handleFilterSearch,
    minWidth,
    onlineRegistration,
    setOnlineRegistration,
    role,
    onlineRegistrationText,
    onlineRegistrationTextDescription,
  } = props;

  // eslint-disable-next-line no-unused-vars
  const [openStartDate, setOpenStartDate] = useState(false);
  const [openEndDate, setOpenEndDate] = useState(false);
  const [hasChange, setHasChange] = useState(false);

  return (
    <Grid container direction='row'>
      <Grid container item xs={12}>
        <Grid
          container
          item
          xs
          style={{ maxWidth: 'fit-content' }}
          sx={{
            minWidth: minWidth ? minWidth : 'fit-content',

            '@media screen and (max-width:800px)': {
              maxWidth: '100% !important',
            },
          }}
        >
          <TextField
            id='searchField'
            label={labelTextfield ? labelTextfield : ''}
            variant='outlined'
            size='small'
            placeholder={placeholder ?? placeholder}
            fullWidth
            value={searchText ?? ''}
            onChange={(e) => {
              setSearchText(e.target.value);
              setHasChange(true);
              if (!e.target.value.trim()) {
                handleFilterSearch &&
                  handleFilterSearch(
                    searchTextFilterParam ? searchTextFilterParam : 'name',
                    e.target.value.trim()
                  );
              }
            }}
            onBlur={(e) => {
              if (e.target.value.trim() && hasChange) {
                handleFilterSearch &&
                  handleFilterSearch(
                    searchTextFilterParam ? searchTextFilterParam : 'name',
                    e.target.value.trim()
                  );
              }
              setHasChange(false);
            }}
            onKeyUp={(e) => {
              if (e.key === 'Enter') {
                e.target.blur();
              }
            }}
            InputProps={{
              endAdornment: (
                <InputAdornment position='end'>
                  <SearchIcon />
                </InputAdornment>
              ),
            }}
          />
        </Grid>
        <Grid
          container
          item
          xs
          justifyContent={{ xs: 'flex-end', md: 'flex-end' }}
          sx={boxFilters1}
        >
          <Grid item sx={boxFilters2}>
            <LocalizationProvider
              dateAdapter={AdapterDateFns}
              adapterLocale={localeMap[locale]}
            >
              <DatePicker
                onClose={() => setOpenStartDate(false)}
                open={openStartDate}
                id={'startDate'}
                name={'startDate'}
                placeholder='Start'
                inputFormat='dd MMM yyyy'
                value={startDate}
                cancelText={localeCancelLabelMap[locale]}
                clearText={localeClearLabelMap[locale]}
                maxDate={endDate}
                onChange={(newValue) => {
                  setStartDate(newValue);
                  let startOfDate = newValue
                    ? new Date(new Date(newValue).setHours(0, 0, 0, 0))
                    : null;
                  if (newValue === null) {
                    handleFilterSearch &&
                      handleFilterSearch(
                        startDateFilterParam ? startDateFilterParam : 'from',
                        startOfDate
                          ? new Date(
                              startOfDate.getTime() -
                                startOfDate.getTimezoneOffset() * 60 * 1000
                            )
                          : null
                      );
                  } else if (endDate === null) {
                    handleFilterSearch &&
                      handleFilterSearch(
                        startDateFilterParam ? startDateFilterParam : 'from',
                        startOfDate
                          ? new Date(
                              startOfDate.getTime() -
                                startOfDate.getTimezoneOffset() * 60 * 1000
                            )
                          : null
                      );
                  }
                  if (newValue <= endDate) {
                    handleFilterSearch &&
                      handleFilterSearch(
                        startDateFilterParam ? startDateFilterParam : 'from',
                        startOfDate
                          ? new Date(
                              startOfDate.getTime() -
                                startOfDate.getTimezoneOffset() * 60 * 1000
                            )
                          : null
                      );
                  } else {
                    return;
                  }
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant='outlined'
                    label={labelStartdate ? labelStartdate : ''}
                    size='small'
                    sx={{ maxWidth: 200 }}
                    onMouseDown={() => {
                      setOpenStartDate(true);
                    }}
                  />
                )}
              ></DatePicker>
            </LocalizationProvider>
          </Grid>
          <Grid item sx={boxFilters3}>
            <Typography>{separator}</Typography>
          </Grid>
          <Grid item sx={boxFilters4}>
            <LocalizationProvider
              dateAdapter={AdapterDateFns}
              adapterLocale={localeMap[locale]}
            >
              <DatePicker
                onClose={() => {
                  setOpenEndDate(false);
                }}
                open={openEndDate}
                id={'endDate'}
                name={'endDate'}
                placeholder='End'
                inputFormat='dd MMM yyyy'
                value={endDate}
                minDate={startDate}
                onChange={(newValue) => {
                  setEndDate(newValue);
                  let endOfDate = newValue
                    ? new Date(new Date(newValue).setHours(23, 59, 59, 999))
                    : null;
                  if (newValue === null) {
                    handleFilterSearch &&
                      handleFilterSearch(
                        endDateFilterParam ? endDateFilterParam : 'to',
                        endOfDate
                          ? new Date(
                              endOfDate.getTime() -
                                endOfDate.getTimezoneOffset() * 60 * 1000
                            )
                          : null
                      );
                  } else if (startDate === null) {
                    handleFilterSearch &&
                      handleFilterSearch(
                        endDateFilterParam ? endDateFilterParam : 'to',
                        endOfDate
                          ? new Date(
                              endOfDate.getTime() -
                                endOfDate.getTimezoneOffset() * 60 * 1000
                            )
                          : null
                      );
                  }
                  if (startDate <= newValue) {
                    handleFilterSearch &&
                      handleFilterSearch(
                        endDateFilterParam ? endDateFilterParam : 'to',
                        endOfDate
                          ? new Date(
                              endOfDate.getTime() -
                                endOfDate.getTimezoneOffset() * 60 * 1000
                            )
                          : null
                      );
                  } else {
                    return;
                  }
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant='outlined'
                    label={labelEnddate ? labelEnddate : ''}
                    size='small'
                    sx={{ maxWidth: 200 }}
                    onMouseDown={() => {
                      setOpenEndDate(true);
                    }}
                  />
                )}
              ></DatePicker>
            </LocalizationProvider>
          </Grid>
        </Grid>
      </Grid>
      {role === 'Admin' && (
        <Grid container item xs={12}>
          <Stack direction='row' alignItems='center' sx={{ mt: 2 }}>
            <FormControlLabel
              control={
                <Switch
                  checked={onlineRegistration}
                  onChange={(event) => {
                    setOnlineRegistration(event.target.checked);
                    handleFilterSearch &&
                      handleFilterSearch(
                        'onlineRegistration',
                        event.target.checked
                      );
                  }}
                />
              }
              label={onlineRegistrationText}
            ></FormControlLabel>
            <Tooltip
              title={onlineRegistrationTextDescription}
              placement='bottom'
              arrow
            >
              <InfoOutlinedIcon />
            </Tooltip>
          </Stack>
        </Grid>
      )}
    </Grid>
  );
};

export default FiltersComponent;
