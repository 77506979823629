import React, { useState, useEffect, useContext } from 'react';
import { useTheme } from '@mui/material/styles';
import { withRouter } from 'react-router-dom';
import {
  Grid,
  Typography,
  Card,
  CardContent,
  Box,
  Snackbar,
  Alert,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { UserContext } from '../../contexts/UserContext';
import Loader from '../../uiComponents/Loader';
import axios from 'axios';
import ChipsComponent from './ChipsComponent';
import FiltersComponent from './FiltersComponent';
import { useStyles } from './newStyles';
import TodoTable from './TodoTable';
import DoneTable from './DoneTable';
import i18n from '../../utils/i18n';

const ApplicationListOptimized = (props) => {
  const classes = useStyles();
  const theme = useTheme();
  const [userContext] = useContext(UserContext);
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation();
  const [searchText, setSearchText] = useState('');
  const year = new Date().getFullYear();
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [showErrorDateRange, setShowErrorDateRange] = useState(false);
  const [status, setStatus] = useState(
    'Potentieel;Concept;Ingediend;Beschikt;Geëffectueerd;Afgerond;Ruling;Ruling afgerond;Klaar voor review;Wacht op akkoord;Klaar voor indienen;In behandeling;Afgewezen;Ingetrokken'
  );
  const [rowsTodo, setRowsTodo] = useState([]);
  const [rowsDone, setRowsDone] = useState([]);
  const [todoPage, setTodoPage] = useState(0);
  const [donePage, setDonePage] = useState(0);
  const [applications, setApplications] = useState([]);
  const columnsTodo = [
    {
      id: 'name',
      numeric: false,
      sort: true,
      disablePadding: false,
      label: i18n.t('application_list_table_header.subsidy'),
      width: '16.66%',
      maxWidth: '16.66%',
    },
    {
      id: 'accountName',
      numeric: false,
      sort: true,
      disablePadding: false,
      label: i18n.t('application_list_table_header.account'),
      width: '16.66%',
      maxWidth: '16.66%',
    },
    {
      id: 'country',
      numeric: false,
      sort: true,
      disablePadding: false,
      label: i18n.t('application_list_table_header.country'),
      width: '16.66%',
      maxWidth: '16.66%',
    },
    {
      id: 'appliedAmount',
      numeric: false,
      sort: true,
      disablePadding: false,
      label: i18n.t('application_list_table_header.applied_amount'),
      width: '16.66%',
      maxWidth: '16.66%',
    },
    {
      id: 'subsidyExert',
      numeric: false,
      sort: true,
      disablePadding: false,
      label: i18n.t('application_list_table_header.subsidy_expert'),
      width: '16.66%',
      maxWidth: '16.66%',
    },
    {
      id: 'submissionDate',
      numeric: false,
      sort: true,
      disablePadding: false,
      label: i18n.t('application_list_table_header.submission_date'),
      width: '16.66%',
      maxWidth: '16.66%',
    },
    {
      id: 'status',
      numeric: true,
      sort: true,
      disablePadding: false,
      label: i18n.t('application_list_table_header.status'),
      width: '16.66%',
      maxWidth: '16.66%',
    },
  ];

  const columnsDone = [
    {
      id: 'name',
      numeric: false,
      sort: true,
      disablePadding: false,
      label: i18n.t('application_list_table_header.subsidy'),
      width: '16.66%',
      maxWidth: '16.66%',
    },
    {
      id: 'accountName',
      numeric: false,
      sort: true,
      disablePadding: false,
      label: i18n.t('application_list_table_header.account'),
      width: '16.66%',
      maxWidth: '16.66%',
    },
    {
      id: 'country',
      numeric: false,
      sort: true,
      disablePadding: false,
      label: i18n.t('application_list_table_header.country'),
      width: '16.66%',
      maxWidth: '16.66%',
    },
    {
      id: 'grantedAmount',
      numeric: false,
      sort: true,
      disablePadding: false,
      label: i18n.t('application_list_table_header.granted_amount'),
      width: '16.66%',
      maxWidth: '16.66%',
    },
    {
      id: 'expertName',
      numeric: false,
      sort: true,
      disablePadding: false,
      label: i18n.t('application_list_table_header.subsidy_expert'),
      width: '16.66%',
      maxWidth: '16.66%',
    },
    {
      id: 'submissionDate',
      numeric: false,
      sort: true,
      disablePadding: false,
      label: i18n.t('application_list_table_header.submission_date'),
      width: '16.66%',
      maxWidth: '16.66%',
    },
    {
      id: 'status',
      numeric: true,
      sort: false,
      disablePadding: false,
      label: i18n.t('application_list_table_header.status'),
      width: '16.66%',
      maxWidth: '16.66%',
    },
  ];

  const handleCloseSnackbar = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setShowErrorDateRange(false);
  };

  useEffect(() => {
    setLoading(true);
    let startOfDate = startDate
      ? new Date(new Date(startDate).setHours(0, 0, 0, 0))
      : null;
    let endOfDate = endDate
      ? new Date(new Date(endDate).setHours(23, 59, 59, 999))
      : null;
    const apiEndPoint =
      process.env.NODE_ENV === 'development'
        ? process.env.REACT_APP_API_ENDPOINT
        : process.env.REACT_APP_API_ENDPOINT_PROD;
    axios({
      method: 'get',
      url: apiEndPoint + '/v1/partner/applications/getApplications',
      withCredentials: true,
      params: {
        text: searchText,
        status: status,
        from: startOfDate
          ? new Date(
              startOfDate.getTime() -
                startOfDate.getTimezoneOffset() * 60 * 1000
            )
          : null,
        to: endOfDate
          ? new Date(
              endOfDate.getTime() - endOfDate.getTimezoneOffset() * 60 * 1000
            )
          : null,
      },
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        Authorization: `Bearer ${userContext.token}`,
      },
    })
      .then((res) => {
        setApplications(res.data);
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
      });

    window.document.title = t('page_title.applications');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function createData(
    id,
    name,
    country,
    type,
    appliedAmount,
    grantedAmount,
    submissionDate,
    accountName,
    subsidyId,
    projectId,
    ownerId,
    status,
    firstName,
    lastName
  ) {
    return {
      id,
      name,
      country,
      type,
      appliedAmount,
      grantedAmount,
      submissionDate,
      accountName,
      subsidyId,
      projectId,
      ownerId,
      status,
      firstName,
      lastName,
    };
  }

  useEffect(() => {
    let dataTodo = [];
    let dataDone = [];
    applications &&
      applications.forEach((item, i) => {
        if (
          item?.status === 'Beschikt' ||
          item?.status === 'Geëffectueerd' ||
          item?.status === 'Afgerond' ||
          item?.status === 'Afgewezen' ||
          item?.status === 'Ingetrokken' ||
          item?.status === 'Ruling' ||
          item?.status === 'Ruling afgerond' ||
          item?.status === 'Ingediend' ||
          item?.status === 'In behandeling'
        ) {
          dataDone.push(
            createData(
              item?._id || '',
              item?.subsidy?.name || '',
              item?.subsidy?.country || '',
              item?.subsidy?.type || '',
              item?.appliedAmount || '',
              item?.grantedAmount || '',
              item?.submissionDate || '',
              item?.account?.name || '',
              item?.subsidyId || '',
              item?.projectId || '',
              item?.ownerId || '',
              item?.status || '',
              item?.owner?.firstName || '',
              item?.owner?.lastName || ''
            )
          );
        } else if (
          item?.status === 'Potentieel' ||
          item?.status === 'Recommended' ||
          item?.status === 'Concept' ||
          item?.status === 'Klaar voor review' ||
          item?.status === 'Wacht op akkoord' ||
          item?.status === 'Vormvrij' ||
          item?.status === 'Klaar voor indienen'
        ) {
          dataTodo.push(
            createData(
              item?._id || '',
              item?.subsidy?.name || '',
              item?.subsidy?.country || '',
              item?.subsidy?.type || '',
              item?.appliedAmount || '',
              item?.grantedAmount || '',
              item?.submissionDate || '',
              item?.account?.name || '',
              item?.subsidyId || '',
              item?.projectId || '',
              item?.ownerId || '',
              item?.status || '',
              item?.owner?.firstName || '',
              item?.owner?.lastName || ''
            )
          );
        }
      });
    setRowsTodo(dataTodo);
    setRowsDone(dataDone);
  }, [applications]);

  return (
    <>
      {loading && <Loader loading={loading} />}
      <Grid container className={classes.root}>
        <Grid
          container
          item
          justifyContent='center'
          alignContent='flex-start'
          xs={12}
        >
          <Grid
            container
            item
            xs={12}
            justifyContent='space-between'
            className={classes.container}
          >
            <Grid item xs={12} className={classes.box}>
              <Box mb={2.5}>
                <Typography component='h5' variant='h5' align='center'>
                  {t('application_list.application_title')}
                </Typography>
              </Box>
              <Box>
                <Typography
                  variant='body1'
                  color='textSecondary'
                  align='center'
                >
                  {t('application_list.application_subtitle')}
                </Typography>
              </Box>
            </Grid>
            <Grid
              container
              item
              xs={12}
              className={classes.box}
              direction='row'
              alignItems='center'
              justifyContent='center'
            >
              <Grid item xs={12}>
                <Card
                  className={classes.card}
                  variant='outlined'
                  style={(theme.custom.boxshadow, { marginRight: 24 })}
                >
                  <CardContent>
                    <Grid container>
                      <Grid alignContent='center' container item xs={12}>
                        <FiltersComponent
                          classes={classes}
                          searchText={searchText}
                          setSearchText={setSearchText}
                          status={status}
                          year={year}
                          startDate={startDate}
                          setStartDate={setStartDate}
                          endDate={endDate}
                          setEndDate={setEndDate}
                          setShowErrorDateRange={setShowErrorDateRange}
                          setTodoPage={setTodoPage}
                          setDonePage={setDonePage}
                          setLoading={setLoading}
                          applications={applications}
                          setApplications={setApplications}
                        />
                        <Grid alignContent='center' container item xs={12}>
                          <ChipsComponent
                            classes={classes}
                            status={status}
                            setStatus={setStatus}
                            startDate={startDate}
                            endDate={endDate}
                            setTodoPage={setTodoPage}
                            setDonePage={setDonePage}
                            setApplications={setApplications}
                            searchText={searchText}
                            setLoading={setLoading}
                          />
                        </Grid>
                      </Grid>
                    </Grid>
                  </CardContent>
                </Card>
              </Grid>
            </Grid>
            <Grid container item xs={12} className={classes.box}>
              <Grid container>
                <Grid item xs={12}>
                  {status &&
                    (status.includes('Potentieel') ||
                      status.includes('Concept') ||
                      status.includes('Klaar voor review') ||
                      status.includes('Vormvrij') ||
                      status.includes('Klaar voor indienen') ||
                      status.includes('Wacht op akkoord')) && (
                      <Grid
                        container
                        item
                        xs={12}
                        className={classes.sectionHeader}
                        style={{ paddingBottom: 24 }}
                      >
                        <Typography
                          color='textPrimary'
                          variant='h6'
                          style={{ fontWeight: 300 }}
                        >
                          {t('application_list.in_progress')}
                        </Typography>
                      </Grid>
                    )}
                </Grid>
                <Grid item xs={12}>
                  {status &&
                    (status.includes('Potentieel') ||
                      status.includes('Concept') ||
                      status.includes('Klaar voor review') ||
                      status.includes('Vormvrij') ||
                      status.includes('Klaar voor indienen') ||
                      status.includes('Wacht op akkoord')) && (
                      <Grid
                        container
                        item
                        xs={12}
                        className={classes.todoTable}
                        style={{ paddingBottom: 24 }}
                      >
                        <TodoTable
                          data={rowsTodo}
                          headCells={columnsTodo}
                          page={todoPage}
                          setPage={setTodoPage}
                          showApplicationX={
                            userContext.user.partnerAccountId ===
                            process.env.REACT_APP_RUITENBURG_ID
                          }
                        />
                      </Grid>
                    )}
                </Grid>
                <Grid item xs={12}>
                  {status &&
                    (status.includes('In behandeling') ||
                      status.includes('Ingediend') ||
                      status.includes('Beschikt') ||
                      status.includes('Geëffectueerd') ||
                      status.includes('Afgerond') ||
                      status.includes('Afgewezen') ||
                      status.includes('Ingetrokken') ||
                      status.includes('Ruling') ||
                      status.includes('Ruling afgerond')) && (
                      <Grid
                        container
                        item
                        xs={12}
                        className={classes.sectionHeader}
                        style={{ padding: '24px 0' }}
                      >
                        <Typography
                          color='textPrimary'
                          variant='h6'
                          style={{ fontWeight: 300 }}
                        >
                          {t('application_list.done')}
                        </Typography>
                      </Grid>
                    )}
                </Grid>
                <Grid item xs={12}>
                  {status &&
                    (status.includes('In behandeling') ||
                      status.includes('Ingediend') ||
                      status.includes('Beschikt') ||
                      status.includes('Geëffectueerd') ||
                      status.includes('Afgerond') ||
                      status.includes('Afgewezen') ||
                      status.includes('Ingetrokken') ||
                      status.includes('Ruling') ||
                      status.includes('Ruling afgerond')) && (
                      <Grid
                        container
                        item
                        xs={12}
                        className={classes.doneTable}
                        style={{ paddingBottom: 24 }}
                      >
                        <DoneTable
                          data={rowsDone}
                          headCells={columnsDone}
                          page={donePage}
                          setPage={setDonePage}
                          showApplicationX={
                            userContext.user.partnerAccountId ===
                            process.env.REACT_APP_RUITENBURG_ID
                          }
                        />
                      </Grid>
                    )}
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Snackbar
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        open={showErrorDateRange}
        autoHideDuration={3000}
        onClose={handleCloseSnackbar}
      >
        <Alert
          onClose={handleCloseSnackbar}
          severity='error'
          sx={{ width: '100%' }}
        >
          {'Datumbereik is niet correct'}
        </Alert>
      </Snackbar>
    </>
  );
};

export default withRouter(ApplicationListOptimized);
