import React, { useState } from 'react';
import MuiPhoneNumber from 'material-ui-phone-number';

const MuiCustomPhoneNumber = (props) => {
  const { field, handleSave, setShowErrorUpdate, fullWidth } = props;

  const [phone, setPhone] = useState(field && field.value ? field.value : '');

  return (
    <MuiPhoneNumber
      // validField={props.validField}
      value={phone || ''}
      autoFocus={props.autoFocus}
      fullWidth={fullWidth}
      // autoFormat={false} // {props.autoFormat}
      defaultCountry={props.defaultCountry}
      error={
        phone &&
        field.hasOwnProperty('pattern') &&
        // 12
        Boolean(!/(^.{10,60}$)/.test(phone.split(' ').join('')))
          ? Boolean(!/(^.{10,60}$)/.test(phone.split(' ').join('')))
          : false
      }
      onChange={(value) => {
        setPhone(value);
      }}
      onBlur={() => {
        props.setEditField(null);

        if (field) {
          if (field.hasOwnProperty('isRequired') && field.isRequired) {
            // props.handleUpdateField(field.fieldName, e.target.value.trim());
          }
          if (
            field.hasOwnProperty('pattern') &&
            phone &&
            field.pattern.test(phone.split(' ').join(''))
          ) {
            if (phone.split(' ').join('') !== field.value.split(' ').join('')) {
              handleSave(field.fieldName, phone);
            } else {
              // setShowErrorUpdate(true);
            }
          }
          if (field && !field.pattern.test(phone.split(' ').join(''))) {
            setShowErrorUpdate(true);
          }
        }
      }}
      inputProps={{
        fontSize: 14,
      }}
    />
  );
};

export default MuiCustomPhoneNumber;
