import makeStyles from '@mui/styles/makeStyles';

export const useStyles = makeStyles((theme) => ({
  root: {
    padding: '48px 24px',
    minHeight: 'calc(100% - 72px)',
    [theme.breakpoints.down('768')]: {
      minHeight: 'calc(100% - 64px)',
      padding: '24px 16px',
    },
  },
}));
