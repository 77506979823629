import React, { useEffect, useState, useContext } from 'react';
import {
  Grid,
  Button,
  ButtonGroup,
  ClickAwayListener,
  Grow,
  Paper,
  Popper,
  MenuItem,
  MenuList,
  ListItemIcon,
} from '@mui/material';
import { UserContext } from '../../contexts/UserContext';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { useTranslation } from 'react-i18next';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import DocumentsDownloadDialog from './DocumentsDownloadDialog';
import DocumentsDownloadTable from './DocumentsDownloadTable';
import axios from 'axios';

const SplitButton = (props) => {
  const { t } = useTranslation();
  const { application, setLoading } = props;

  const columnsDialog = [
    {
      id: 'filename',
      numeric: false,
      sort: false,
      disablePadding: false,
      label: t('mail_items.file'),
      width: '100%',
      maxWidth: '100%',
    },
  ];
  // eslint-disable-next-line no-unused-vars
  const [userContext, setUserContext] = useContext(UserContext);
  const [id, setId] = useState();
  const [open, setOpen] = React.useState(false);
  const anchorRef = React.useRef(null);
  const apiEndPoint =
    process.env.NODE_ENV === 'development'
      ? process.env.REACT_APP_API_ENDPOINT
      : process.env.REACT_APP_API_ENDPOINT_PROD;
  const [openDownloadMailItemsDialog, setOpenDownloadMailItemsDialog] =
    useState(false);
  const [rowsEmailDialog, setRowsEmailDialog] = useState([]);

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }
    setOpen(false);
  };

  function createEmailDataDialog(id, filename, fileId, mimeType) {
    return {
      id,
      filename,
      fileId,
      mimeType,
    };
  }

  useEffect(() => {
    if (application) {
      setId(application.id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [application]);

  const handleOpenEmailDocDialog = () => {
    setOpenDownloadMailItemsDialog(true);
    setLoading(true);
    let data = [];
    let resp, response;
    axios({
      method: 'get',
      url: apiEndPoint + '/v1/partner/applications/getApplicationMailItems',
      withCredentials: true,
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        Authorization: `Bearer ${userContext.token}`,
      },
      params: {
        id: application.id,
      },
    })
      .then((res) => {
        response = JSON.stringify(res.data);
        setLoading(false);
      })
      .then(() => {
        resp = JSON.parse(response);
        resp.forEach((item, i) => {
          data.push(
            createEmailDataDialog(
              item?.id || '',
              item?.name + '.' + item?.fileExtension || '',
              item?.contentVersionId || '',
              item?.mimeType || ''
            )
          );
        });
        setRowsEmailDialog(data);
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
      });
  };

  const handleDownloadEmailDoc = (id, filename, type) => {
    setLoading(true);
    axios({
      method: 'get',
      url: apiEndPoint + '/v1/partner/applications/downloadApplicationMailItem',
      withCredentials: true,
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        Authorization: `Bearer ${userContext.token}`,
      },
      params: {
        id: id,
      },
    })
      .then((res) => {
        const linkSource = `data:${type};base64,${res.data}`;
        const downloadLink = document.createElement('a');
        const fileName = filename;
        downloadLink.href = linkSource;
        downloadLink.download = fileName;
        downloadLink.click();
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
      });
  };

  const downloadPdf = () => {
    const apiEndPoint =
      process.env.NODE_ENV === 'development'
        ? process.env.REACT_APP_API_ENDPOINT
        : process.env.REACT_APP_API_ENDPOINT_PROD;
    setLoading(true);

    axios({
      method: 'get',
      url: apiEndPoint + '/v1/partner/applications/downloadWBSOPdf',
      withCredentials: true,
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        Authorization: `Bearer ${userContext.token}`,
      },
      params: {
        id: id,
      },
    })
      .then((res) => {
        const linkSource = `data:application/pdf;base64,${res.data}`;
        const downloadLink = document.createElement('a');
        const fileName = 'WBSO.pdf';
        downloadLink.href = linkSource;
        downloadLink.download = fileName;
        downloadLink.click();
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
      });
  };

  return (
    <>
      <Grid
        container
        direction='column'
        alignItems='center'
        style={{ minWidth: 'fit-content' }}
      >
        <Grid item xs={12}>
          <ButtonGroup
            variant='contained'
            disableElevation
            color='primary'
            ref={anchorRef}
            aria-label='split button'
          >
            {application?.isWBSO &&
            !application?.isFormFree &&
            (application.status === 'Ingediend' ||
              application.status === 'Beschikt' ||
              application.status === 'Geëffectueerd') ? (
              <>
                <Button onClick={downloadPdf} startIcon={<FileDownloadIcon />}>
                  {t('download_pdf')}
                </Button>
              </>
            ) : (
              <>
                <Button
                  onClick={handleOpenEmailDocDialog}
                  startIcon={<FileDownloadIcon />}
                >
                  {t('mail_items.download_button')}
                </Button>
              </>
            )}
            {application?.isWBSO &&
            !application?.isFormFree &&
            (application.status === 'Ingediend' ||
              application.status === 'Beschikt' ||
              application.status === 'Geëffectueerd') ? (
              <>
                <Button
                  color='primary'
                  size='small'
                  aria-controls={open ? 'split-button-menu' : undefined}
                  aria-expanded={open ? 'true' : undefined}
                  aria-label='select merge strategy'
                  aria-haspopup='menu'
                  onClick={handleToggle}
                >
                  <ArrowDropDownIcon />
                </Button>
              </>
            ) : (
              <></>
            )}
          </ButtonGroup>
          <Popper
            open={open}
            anchorEl={anchorRef.current}
            role={undefined}
            transition
            disablePortal
            placement='bottom-end'
          >
            {({ TransitionProps, placement }) => (
              <Grow
                {...TransitionProps}
                style={{
                  anchorOrigin: 'right top',
                  transformOrigin:
                    placement === 'bottom' ? 'center top' : 'right bottom',
                }}
              >
                <Paper>
                  <ClickAwayListener onClickAway={handleClose}>
                    <MenuList id='split-button-menu'>
                      {application?.isWBSO &&
                      !application?.isFormFree &&
                      (application.status === 'Ingediend' ||
                        application.status === 'Beschikt' ||
                        application.status === 'Geëffectueerd') ? (
                        <>
                          <MenuItem
                            key={'1'}
                            onClick={handleOpenEmailDocDialog}
                          >
                            <ListItemIcon>
                              <FileDownloadIcon fontSize='small' />
                            </ListItemIcon>
                            {t('mail_items.download_button')}
                          </MenuItem>
                        </>
                      ) : (
                        <></>
                      )}
                    </MenuList>
                  </ClickAwayListener>
                </Paper>
              </Grow>
            )}
          </Popper>
        </Grid>
      </Grid>
      <DocumentsDownloadDialog
        maxWidthDialog={`md`}
        fullWidthDialog={true}
        openDialog={openDownloadMailItemsDialog}
        setOpenDialog={setOpenDownloadMailItemsDialog}
        title={t('mail_items.dialog_title')}
        content={
          <DocumentsDownloadTable
            data={rowsEmailDialog}
            headCells={columnsDialog}
            handleDownloadDialogDocFile={handleDownloadEmailDoc}
          />
        }
        cancelText={t('buttons.cancel')}
      />
    </>
  );
};

export default SplitButton;
