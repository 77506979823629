import React, { useState, useEffect } from 'react';
import makeStyles from '@mui/styles/makeStyles';
import moment from 'moment';
import NumberFormat from 'react-number-format';
import { MapCountryFlag } from './utils';
import SortTableHead from './SortTableHead';
import NoResultsFound from './NoResultsFound';
import { getComparator, stableSort } from './utils';
import i18n from '../../utils/i18n';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TablePagination,
  TableRow,
} from '@mui/material';
import { Link, withRouter } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  paper: {
    width: '100%',
    marginBottom: theme.spacing(2),
  },
  table: {
    minWidth: 976,
    '& .MuiTableRow-hover': {},
  },
  doneCell: {
    '& span': {
      background: theme.palette.secondary.main,
      borderRadius: 20,
      fontSize: 12,
      fontWeight: 'bold',
      display: 'inline-block',
      padding: '4px 8px',
      color: '#fff',
    },
  },
  deniedCell: {
    '& span': {
      background: theme.palette.error.main,
      borderRadius: 20,
      fontSize: 12,
      fontWeight: 'bold',
      display: 'inline-block',
      padding: '4px 8px',
      color: '#fff',
    },
  },
}));

const DoneTable = (props) => {
  const classes = useStyles();
  const applicationStatusTypes = {
    Concept: i18n.t('application_list_status.concept'),
    Potentieel: i18n.t('application_list_status.potential'),
    Recommended: i18n.t('application_list_status.potential'),
    'Klaar voor review': i18n.t('application_list_status.ready_for_review'),
    'Wacht op akkoord': i18n.t('application_list_status.waiting_for_approval'),
    'Klaar voor indienen': i18n.t('application_list_status.ready_for_applying'),
    Vormvrij: i18n.t('application_list_status.concept'),
    Ingediend: i18n.t('application_list_status.applied'),
    'In behandeling': i18n.t('application_list_status.in_processing'),
    Beschikt: i18n.t('application_list_status.granted'),
    Geëffectueerd: i18n.t('application_list_status.granted'),
    Afgerond: i18n.t('application_list_status.granted'),
    Afgewezen: i18n.t('application_list_status.rejected'),
    Ingetrokken: i18n.t('application_list_status.withdrawn'),
    Ruling: i18n.t('application_list_status.granted'),
    'Ruling afgerond': i18n.t('application_list_status.granted'),
  };
  const [order, setOrder] = useState('asc');
  const [orderBy, setOrderBy] = useState('name');
  const { page, setPage } = props;
  // eslint-disable-next-line no-unused-vars
  const [dense, setDense] = useState(false);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [locale, setLocale] = useState('en');
  const selectLocale = React.useCallback((locale) => {
    setLocale(i18n.languages[0]);
  }, []);
  useEffect(() => {
    selectLocale();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  return (
    <div className={classes.root}>
      <TableContainer style={{}}>
        <Table
          className={classes.table}
          aria-labelledby='tableTitle'
          size={dense ? 'small' : 'medium'}
          aria-label='enhanced table'
        >
          {props.headCells && (
            <SortTableHead
              classes={classes}
              order={order}
              orderBy={orderBy}
              onRequestSort={handleRequestSort}
              rowCount={props.data.length}
              headCells={props.headCells}
              active={true}
            />
          )}
          {props.data && props.data.length > 0 ? (
            <TableBody>
              {stableSort(props.data, getComparator(order, orderBy))
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row, index) => {
                  return (
                    <TableRow
                      hover
                      tabIndex={-1}
                      key={row.id || index}
                      component={props.showApplicationX ? Link : 'div'}
                      to={{
                        pathname: `/app/applications/${row.id}`,
                        state: { name: row.name },
                      }}
                    >
                      <TableCell style={{ cursor: 'pointer' }}>
                        {row.name || ''}
                      </TableCell>
                      <TableCell>{row.accountName || ''}</TableCell>
                      <TableCell>
                        {row.type === 'European' ? (
                          MapCountryFlag['Eu']
                        ) : row.country === 'Netherlands' ||
                          row.country === 'Germany' ? (
                          MapCountryFlag[row.country]
                        ) : (
                          <> </>
                        )}
                      </TableCell>
                      <TableCell>
                        <NumberFormat
                          value={row.grantedAmount || ''}
                          displayType={'text'}
                          thousandSeparator={'.'}
                          decimalSeparator={','}
                          isNumericString
                          prefix={'€'}
                        />
                      </TableCell>
                      <TableCell>
                        {row.firstName + ' '}
                        {row.lastName}
                      </TableCell>
                      <TableCell>
                        {row.submissionDate
                          ? moment(row.submissionDate)
                              .locale(locale)
                              .format('DD MMM YYYY')
                          : ''}
                      </TableCell>
                      <TableCell
                        className={
                          row.status === 'Afgewezen' ||
                          row.status === 'Ingetrokken'
                            ? classes.deniedCell
                            : classes.doneCell
                        }
                      >
                        <span>
                          {row.status ? applicationStatusTypes[row.status] : ''}
                        </span>
                      </TableCell>
                    </TableRow>
                  );
                })}
            </TableBody>
          ) : (
            <NoResultsFound span={6} />
          )}
        </Table>
      </TableContainer>
      {props.data && props.data.length > 10 && (
        <TablePagination
          rowsPerPageOptions={[]}
          component='div'
          count={props.data.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      )}
    </div>
  );
};

export default withRouter(DoneTable);
