import React, { useState, useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { UserContext } from '../../contexts/UserContext';
import {
  Typography,
  Button,
  TextField,
  Grid,
  Autocomplete,
  Box,
} from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import axios from 'axios';

const ChangeOwner = (props) => {
  const { subtitle, back, confirm, setChangeOwnerDialog, handleChangeOwner } =
    props;

  const { t } = useTranslation();
  // eslint-disable-next-line no-unused-vars
  const [userContext, setUserContext] = useContext(UserContext);

  const [openAutocomplete, setOpenAutocomplete] = useState(false);
  const [users, setUsers] = useState([]);
  const [user, setUser] = useState(null);
  const loadingAutocomplete = openAutocomplete && users.length === 0;

  useEffect(() => {
    let active = true;
    console.log('inside use effect');
    console.log(loadingAutocomplete);
    if (!loadingAutocomplete) {
      return undefined;
    }
    (async () => {
      const apiEndPoint =
        process.env.NODE_ENV === 'development'
          ? process.env.REACT_APP_API_ENDPOINT
          : process.env.REACT_APP_API_ENDPOINT_PROD;
      console.log('inside async');
      axios({
        method: 'get',
        url: apiEndPoint + '/v1/partner/leads/getPartnerUsers',
        withCredentials: true,
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
          Authorization: `Bearer ${userContext.token}`,
        },
      })
        .then((res) => {
          if (active) {
            setUsers([...res.data.users]);
          }
        })
        .catch((error) => {
          setUsers([]);
        });
    })();
    return () => {
      active = false;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loadingAutocomplete]);

  useEffect(() => {
    if (!openAutocomplete) {
      setUsers([]);
    }
  }, [openAutocomplete]);

  return (
    <Grid container>
      <Grid container item xs={12} sx={{ pb: 5 }}>
        <Typography>{subtitle}</Typography>
      </Grid>
      <Grid container item xs={12} sx={{ pb: 3 }}>
        <Autocomplete
          id={'asynchronousSearchUser'}
          open={openAutocomplete}
          onOpen={() => {
            setOpenAutocomplete(true);
          }}
          onClose={() => {
            setOpenAutocomplete(false);
          }}
          isOptionEqualToValue={(option, value) => option._id === value._id}
          getOptionLabel={(option) => option.firstName + ' ' + option.lastName}
          options={users}
          value={user}
          sx={{ width: 1 }}
          autoHighlight
          loading={loadingAutocomplete}
          onChange={(e, value) => {
            if (value) {
              setUser(value);
            } else {
              setUser(null);
            }
          }}
          renderOption={(props, option) => (
            <Box component='li' {...props}>
              <Grid container>
                <Grid item xs={10}>
                  {option.firstName + ' ' + option.lastName}
                </Grid>
              </Grid>
            </Box>
          )}
          renderInput={(params) => (
            <TextField
              {...params}
              label={t('leads.change_owner.owner')}
              InputProps={{
                ...params.InputProps,
                autoComplete: 'new-password',
                endAdornment: (
                  <React.Fragment>
                    {loadingAutocomplete ? (
                      <CircularProgress color='inherit' size={30} />
                    ) : null}
                    {params.InputProps.endAdornment}
                  </React.Fragment>
                ),
              }}
              size='small'
              variant='outlined'
            />
          )}
        />
      </Grid>
      <Grid
        container
        justifyContent={'space-between'}
        item
        xs={12}
        sx={{ pt: 3 }}
      >
        {back && (
          <Grid item sx={{}}>
            <Button
              onClick={() => {
                setChangeOwnerDialog(false);
              }}
              color='primary'
            >
              {back}
            </Button>
          </Grid>
        )}{' '}
        {confirm && (
          <Grid item sx={{}}>
            <Button
              disabled={user === null || user === ''}
              onClick={() => {
                handleChangeOwner(user);
                setChangeOwnerDialog(false);
              }}
              color='primary'
            >
              {confirm}
            </Button>
          </Grid>
        )}
      </Grid>
    </Grid>
  );
};

export default ChangeOwner;
