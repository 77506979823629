import React, { useState, useContext } from 'react';
import { useHistory } from 'react-router-dom';
import {
  Grid,
  Divider,
  Box,
  Card,
  CardContent,
  Typography,
  Button,
} from '@mui/material';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import { useStyles } from './styles';
import photo_card_1 from '../../assets/photo_card_1.jpeg';
import photo_card_2 from '../../assets/photo_card_2.jpeg';
import photo_card_3 from '../../assets/photo_card_3.jpeg';
import photo_card_4 from '../../assets/photo_card_4.jpeg';
import { projectTypeOptionsNew } from '../../data/projectTypeOptions';
import CheckCircleRoundedIcon from '@mui/icons-material/CheckCircleRounded';
import CircleOutlinedIcon from '@mui/icons-material/CircleOutlined';
import { theme as bdtheme } from '../../theme/BrightDesignTheme';
import { doughnutData } from '../../data/picklistOptions';
import NivoDoughnut from '../../uiComponents/NivoDoughnut';
import { useMediaQuery } from 'react-responsive';
import { useTranslation } from 'react-i18next';
import CustomDialog from '../../uiComponents/CustomDialog';
import Loader from '../../uiComponents/Loader';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import {
  cleanProjectBusiness,
  cleanProjectInternational,
  cleanProjectProduct,
  cleanProjectEmployees,
} from '../../JS/HelperFunctions';
import { NewProjectContext } from '../../contexts/NewProject';
import { UserContext } from '../../contexts/UserContext';
import axios from 'axios';

const NewChooseProjectTypeWithImage = (props) => {
  const classes = useStyles();
  const isLargeScreen = useMediaQuery({ query: '(min-width: 1280px)' });
  const isSmallScreen = useMediaQuery({ query: '(max-width: 767px)' });
  const { t } = useTranslation();

  const [loading, setLoading] = useState(false);
  const [userContext] = useContext(UserContext);

  const [openDialogReset, setOpenDialogReset] = useState(false);
  const [tempType, setTempType] = useState();

  const COLORS = [bdtheme.palette.primary.main, 'rgba(224, 224, 224, 1)'];
  const data = doughnutData(props.progressStep, props.showMoreDetails);

  const projectTypeOptions = projectTypeOptionsNew();
  const projectTypeIds = projectTypeOptions.slice(0, 4).map((el) => el.id);

  const history = useHistory();
  const ROUTES = ['/app/newproject/addproject'];

  const {
    newProjectId,
    projectType,
    setProjectType,
    setActivities,
    setProjectBusiness,
    setProjectInt,
    setActive,
    setExpand,
    setActivitiesInt,
    setProjectEmployees,
    setProjectProduct,
    setIndustries,
    setTemporary,
    setSectors,
    setSubSectors,
  } = useContext(NewProjectContext);

  const images = [photo_card_1, photo_card_2, photo_card_3, photo_card_4];

  const handleResetProject = (id, type, tempType) => {
    setLoading(true);

    const apiEndPoint =
      process.env.NODE_ENV === 'development'
        ? process.env.REACT_APP_API_ENDPOINT
        : process.env.REACT_APP_API_ENDPOINT_PROD;

    axios({
      method: 'post',
      url: apiEndPoint + '/v1/partner/project/resetProject',
      data: {
        projectTypeId: tempType,
        projectId: newProjectId,
      },
      withCredentials: true,
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        Authorization: `Bearer ${userContext.token}`,
      },
    })
      .then((response) => {
        setLoading(false);
        cleanProjectInternational(
          setProjectInt,
          setActive,
          setExpand,
          setActivitiesInt
        );
        cleanProjectProduct(
          setProjectProduct,
          setIndustries,
          setSectors,
          setSubSectors
        );
        cleanProjectEmployees(setProjectEmployees);
        cleanProjectBusiness(setActivities, setProjectBusiness);
        try {
          const apiEndPoint =
            process.env.NODE_ENV === 'development'
              ? process.env.REACT_APP_API_ENDPOINT
              : process.env.REACT_APP_API_ENDPOINT_PROD;
          axios({
            method: 'get',
            url: apiEndPoint + '/v1/partner/project/getProject',
            data: { projectId: newProjectId },
            withCredentials: true,
            headers: {
              'Content-Type': 'application/json',
              Accept: 'application/json',
              Authorization: `Bearer ${userContext.token}`,
            },
          })
            .then((response) => {
              if (response.data.success && response.data?.project) {
                setProjectType(response.data?.project?.projectTypeId);
                if (
                  response.data?.project?.projectTypeId === '0123X000001GJUGQA4'
                ) {
                  setProjectProduct(response.data?.project);
                } else if (
                  response.data?.project?.projectTypeId === '0123X000001GJUCQA4'
                ) {
                  setProjectInt(response.data?.project);
                } else if (
                  response.data?.project?.projectTypeId === '0123X000001GJUDQA4'
                ) {
                  setProjectBusiness(response.data.project);
                } else if (
                  response.data?.project?.projectTypeId === '0123X000001GJUEQA4'
                ) {
                  setProjectEmployees(response.data?.project);
                }
                setTemporary(false);
                history.push(ROUTES[0]);
              }
            })
            .catch((error) => {
              console.log(error);
            });
        } catch (err) {
          console.log(err);
        }
      })
      .catch((error) => {
        setLoading(false);
      });
  };

  const handleNext = () => {
    history.push(ROUTES[0]);
  };

  const handleBack = () => {
    if (props.progressStep !== 0) {
      props.setProgressStep(props.progressStep - 1);
      history.push('/app/newproject/account');
    }
  };

  const handleResetDialog = (id) => {
    setOpenDialogReset(true);
    setTempType(id);
  };

  const handleChange = (option) => {
    if (
      projectType &&
      projectType !== null &&
      projectType !== undefined &&
      projectType !== 'undefined'
    ) {
      if (
        newProjectId &&
        newProjectId !== null &&
        newProjectId !== undefined &&
        newProjectId !== 'undefined'
      ) {
        if (option.id !== projectType) {
          handleResetDialog(option.id);
        }
      } else {
        setProjectType(option.id);
      }
    } else {
      setProjectType(option.id);
    }
    return option.id;
  };

  const handleConfirmReset = () => {
    const id = newProjectId;
    const type = projectType;
    setProjectType(tempType);
    handleResetProject(id, type, tempType);
  };

  return (
    <>
      {loading && <Loader loading={loading} />}
      <Grid container justifyContent='center' className={classes.root}>
        <Grid
          container
          item
          xs={12}
          justifyContent='center'
          alignContent='center'
        >
          <Grid
            container
            item
            xs={12}
            justifyContent='center'
            alignContent='center'
            sx={{ maxWidth: '736px !important' }}
          >
            <Grid container item xs={12} className={classes.section}>
              <Grid
                container
                item
                xs={12}
                justifyContent='center'
                className='title-section'
              >
                <Grid item xs={12} lg={12} className={classes.typeSection}>
                  <Box mb={1}>
                    <Typography variant='h6'>
                      {t('choose_project.title')}
                    </Typography>
                  </Box>
                  <Box pb={6}>
                    <Typography
                      variant='body2'
                      className={classes.textDisabled}
                    >
                      {t('choose_project.subtitle')}
                    </Typography>
                  </Box>
                </Grid>
              </Grid>
            </Grid>
            {isLargeScreen && (
              <Grid container item xs={12} className={classes.section}>
                {projectTypeOptions.slice(0, 4).map((option, i) => {
                  return (
                    <Grid
                      item
                      xs={12}
                      md={6}
                      // onClick={() => handleChange(option)}
                      key={i}
                      className={classes.cardBox}
                    >
                      <Card
                        variant='outlined'
                        sx={{
                          width: '345px',
                          height: '100%',
                          boxShadow:
                            '0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px rgba(0, 0, 0, 0.14), 0px 1px 3px rgba(0, 0, 0, 0.12)',
                          border: 'none',
                        }}
                        onClick={() => handleChange(option)}
                      >
                        <CardContent
                          style={{
                            padding: 0,
                            // height: '180px',
                            // height: '152px',
                            cursor: 'pointer',
                          }}
                        >
                          <Grid container>
                            <Grid
                              container
                              item
                              xs={12}
                              alignContent='center'
                              alignItems='center'
                              sx={{ p: 2, height: 80 }}
                            >
                              <Grid item xs={1}>
                                {option.id === props.projectType ? (
                                  <CheckCircleRoundedIcon
                                    color='primary'
                                    fontSize='large'
                                    // style={{ float: 'left' }}
                                  />
                                ) : (
                                  <CircleOutlinedIcon
                                    fontSize='large'
                                    sx={{ color: 'text.secondary' }}
                                    // style={{ float: 'left' }}
                                  />
                                )}
                              </Grid>
                              <Grid item xs={11} sx={{ pl: 3 }}>
                                <Typography>{option.label}</Typography>
                              </Grid>
                            </Grid>
                            <Grid
                              item
                              xs={12}
                              style={{
                                backgroundImage: `url(${images[i]})`,
                                backgroundPosition: 'center',
                                backgroundSize: 'cover',
                                backgroundRepeat: 'no-repeat',
                                height: '194px',
                                // border: '1px solid #E0E0E0',
                              }}
                            ></Grid>
                            <Grid item xs={12} sx={{ p: 2 }}>
                              <Box mt={1}>
                                <Typography
                                  variant='body2'
                                  className={classes.textDisabled}
                                >
                                  {option.subLabel}
                                </Typography>
                              </Box>
                            </Grid>
                          </Grid>
                        </CardContent>
                      </Card>
                    </Grid>
                  );
                })}
              </Grid>
            )}
            {!isLargeScreen && (
              <Grid
                container
                item
                xs={12}
                justifyContent={isLargeScreen ? '' : 'center'}
                className={classes.section}
              >
                <Grid
                  item
                  xs={12}
                  className={classes.typeSection}
                  sx={(theme) => ({
                    [theme.breakpoints.down('768')]: {
                      mb: theme.spacing(3),
                    },
                  })}
                >
                  {projectTypeOptions.slice(0, 4).map((option, i) => (
                    <Grid
                      item
                      xs={12}
                      // onClick={() => handleChange(option)}
                      key={i}
                      sx={{
                        pb: i < 3 ? 4 : 0,
                        // height: '100%'
                      }}
                    >
                      <Card
                        variant='outlined'
                        sx={{
                          // height: '100%',
                          boxShadow:
                            '0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px rgba(0, 0, 0, 0.14), 0px 1px 3px rgba(0, 0, 0, 0.12)',
                          border: 'none',
                        }}
                        onClick={() => handleChange(option)}
                      >
                        <CardContent
                          style={{
                            padding: 0,
                            // height: '180px',
                            // height: '152px',
                            cursor: 'pointer',
                          }}
                        >
                          <Grid container>
                            <Grid
                              container
                              item
                              xs={12}
                              alignItems='center'
                              sx={{ p: 2 }}
                            >
                              <Grid item xs={1}>
                                {option.id === props.projectType ? (
                                  <CheckCircleRoundedIcon
                                    color='primary'
                                    fontSize='large'
                                    // style={{ float: 'left' }}
                                  />
                                ) : (
                                  <CircleOutlinedIcon
                                    fontSize='large'
                                    sx={{ color: 'text.secondary' }}
                                    // style={{ float: 'left' }}
                                  />
                                )}
                              </Grid>
                              <Grid item xs={11} sx={{ pl: 3 }}>
                                <Typography variant='h6'>
                                  {option.label}
                                </Typography>
                              </Grid>
                            </Grid>
                            <Grid
                              item
                              xs={12}
                              style={{
                                backgroundImage: `url(${images[i]})`,
                                backgroundPosition: 'center',
                                backgroundSize: 'cover',
                                backgroundRepeat: 'no-repeat',
                                height: '194px',
                                // border: '1px solid #E0E0E0',
                              }}
                            ></Grid>
                            <Grid item xs={12} sx={{ p: 2 }}>
                              <Box mt={1}>
                                <Typography
                                  variant='body2'
                                  className={classes.textDisabled}
                                >
                                  {option.subLabel}
                                </Typography>
                              </Box>
                            </Grid>
                          </Grid>
                        </CardContent>
                      </Card>
                    </Grid>
                  ))}
                </Grid>
              </Grid>
            )}
            <Grid
              container
              item
              xs={12}
              justifyContent='center'
              className={classes.section + ' ' + classes.sectionMobile}
            >
              <Grid container item xs={12} className={classes.sectionButtons}>
                <Grid item xs={12}>
                  <Divider />
                </Grid>
                <Grid
                  container
                  item
                  xs={12}
                  className={classes.button}
                  style={
                    isLargeScreen
                      ? {}
                      : {
                          flexWrap: 'nowrap',
                          justifyContent: 'space-between',
                        }
                  }
                >
                  <>
                    {!isLargeScreen && (
                      <Grid item>
                        <div style={{ height: 40, width: 40 }}>
                          <NivoDoughnut
                            data={data}
                            center={props.center}
                            colors={COLORS}
                            smallFont
                          />
                        </div>
                      </Grid>
                    )}
                    <Grid
                      item
                      container
                      justifyContent={
                        isLargeScreen ? 'space-between' : 'flex-end'
                      }
                    >
                      <Button
                        style={{ marginRight: 16 }}
                        startIcon={<KeyboardArrowLeftIcon />}
                        onClick={handleBack}
                      >
                        {t('buttons.back')}
                      </Button>
                      <Button
                        color='primary'
                        variant='contained'
                        endIcon={<KeyboardArrowRightIcon />}
                        disabled={
                          !props.projectType ||
                          !projectTypeIds.includes(props.projectType)
                        }
                        onClick={handleNext}
                      >
                        {t('buttons.next')}
                      </Button>
                    </Grid>
                  </>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      {/* RESET PROJECT DETAILS */}

      <CustomDialog
        openDialog={openDialogReset}
        setOpenDialog={setOpenDialogReset}
        handleConfirm={handleConfirmReset}
        handleClose={() => {
          setOpenDialogReset(false);
        }}
        title={t('dialog.reset_project_1')}
        text={t('dialog.reset_project_4')}
        cancel={t('yes_no.no')}
        confirm={t('yes_no.yes')}
      />
    </>
  );
};

export default NewChooseProjectTypeWithImage;
