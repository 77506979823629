import React, { useState } from 'react';
import { Table, TableContainer, TablePagination } from '@mui/material';
import { columnHeader, statusLeadMap, tooltipLeadMap } from './utils';
import SortTableHead from '../../uiComponents/tableComponents/SortTableHead';
import SimpleTableContent from '../../uiComponents/tableComponents/SimpleTableContent';
import NoResultsFound from '../../uiComponents/tableComponents/NoResultsFound';
import { getComparator, stableSort } from '../../uiComponents/utils';

const LeadsTable = (props) => {
  const {
    data,
    minWidth,
    maxWidth,
    locale,
    page,
    setPage,
    role,
    setChangeOwnerDialog,
    setSelectedAccountId,
  } = props;
  const [order, setOrder] = useState('desc');
  const [orderBy, setOrderBy] = useState('createdAt');
  const [dense] = useState(false);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
    setPage(0);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const headCells = columnHeader();
  const statusMap = statusLeadMap();
  const tooltipMap = tooltipLeadMap();

  const rows =
    data && data.length > 0
      ? stableSort(data, getComparator(order, orderBy)).slice(
          page * rowsPerPage,
          page * rowsPerPage + rowsPerPage
        )
      : [];

  return (
    <>
      <TableContainer
        sx={{
          minWidth: minWidth ? minWidth : 200,
          maxWidth: maxWidth ? maxWidth : 1080,
        }}
      >
        <Table
          className={'table'}
          aria-labelledby='tableTitle'
          size={dense ? 'small' : 'medium'}
          aria-label='enhanced table'
        >
          {headCells && (
            <SortTableHead
              sortableHead={true}
              order={order}
              orderBy={orderBy}
              onRequestSort={handleRequestSort}
              rowCount={data.length}
              headCells={headCells}
              active={true}
            />
          )}

          {data && data.length > 0 ? (
            <SimpleTableContent
              rows={rows}
              statusMap={statusMap}
              tooltipMap={tooltipMap}
              locale={locale}
              //hideProps={['id', 'contacts']}
              hideBottomBorder={data.length < rowsPerPage}
              rowsPerPage={rowsPerPage}
              //showTooltip={[]}
              //dateField={['createdAt']}
              dateFormat={'dd MMM yyyy'}
              //mapField={['status']}
              //chipField={['status']}
              //checkedField={['partnerParticipation']}
              role={role}
              setChangeOwnerDialog={setChangeOwnerDialog}
              setSelectedAccountId={setSelectedAccountId}
            />
          ) : (
            <NoResultsFound span={headCells.length} />
          )}
        </Table>
      </TableContainer>
      {data && data.length > rowsPerPage && (
        <TablePagination
          rowsPerPageOptions={[]}
          component='div'
          count={data.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      )}
    </>
  );
};

export default LeadsTable;
