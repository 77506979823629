import React from 'react';
import PropTypes from 'prop-types';

import { TableCell, TableHead, TableRow, TableSortLabel } from '@mui/material';

const SortTableHead = (props) => {
  const { order, orderBy, onRequestSort, headCells, active, sortableHead } =
    props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };
  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell, index) => (
          <TableCell
            key={headCell.id || index}
            align={headCell.alignRight ? 'right' : 'left'}
            padding={headCell.disablePadding ? 'none' : 'normal'}
            sortDirection={orderBy === headCell.id ? order : false}
            style={{
              width: headCell.width,
              maxWidth: headCell.maxWidth,
              fontWeight: 'bold',
            }}
          >
            {headCell.sort || sortableHead ? (
              <TableSortLabel
                active={active ? active : orderBy === headCell.id}
                direction={orderBy === headCell.id ? order : 'asc'}
                onClick={createSortHandler(headCell.id)}
                style={{ flexDirection: 'revert' }}
              >
                {headCell.label}
              </TableSortLabel>
            ) : (
              headCell.label
            )}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
};

SortTableHead.propTypes = {
  onRequestSort: PropTypes.func.isRequired,
  order: PropTypes.oneOf(['asc', 'desc']).isRequired,
  orderBy: PropTypes.string.isRequired,
};

export default SortTableHead;
