import { theme } from '../theme/BrightDesignTheme';

export const profileMenuList = {
  maxWidth: 360,
  '& .MuiList-root': {},
  '& .MuiMenu-list': {},
};

export const profileMenuListItem = {
  '&.MuiListItem-button:hover': {
    // backgroundColor: '#ffffff',
    // cursor: 'default',
  },
  '&.MuiMenuItem-root:hover': {
    backgroundColor: '#ffffff',
    cursor: 'auto',
  },
};

export const profileMenuButton = {
  '&:hover': {
    backgroundColor: 'none',
  },
  '& button': {
    border: '1px solid #E0E0E0',
    '&:hover': {
      border: '1px solid #E0E0E0',
      backgroundColor: '#FAFAFA',
    },
  },
};

export const cardRowStyle = {
  flexWrap: 'nowrap',
  justifyContent: 'space-between',
};

export const tooltipCard = {
  '& .MuiTooltip-tooltip': {
    bgcolor: 'red !important',
  },
};

export const active = {
  bgcolor: 'red',
};
export const prospect = {
  bgcolor: 'green',
};

export const lead = {
  bgcolor: 'blue',
};

export const styleStatus = {
  Lead: theme.palette.warning.main,
  MQL: theme.palette.error.main,
  SQL: theme.palette.primary.main,
  Prospect: theme.palette.primary.main,
  'Prospect 2': theme.palette.primary.main,
  Unqualified: theme.palette.error.main,
  'Active klant': theme.palette.secondary.main,
  Passief: theme.palette.error.main,
  'Inactive klant': theme.palette.error.main,
  'Opgezegd M&K': theme.palette.error.main,
  'Opgezegd Klant': theme.palette.error.main,
  Bounce: theme.palette.error.main,
  Opgeheven: theme.palette.error.main,
};

export const styleStatusApplications = {
  Potentieel: theme.palette.primary.main,
  Concept: theme.palette.primary.main,
  Recommended: theme.palette.primary.main,
  'Klaar voor review': theme.palette.primary.main,
  'Wacht op akkoord': theme.palette.primary.main,
  'Klaar voor indienen': theme.palette.secondary.main,
  Vormvrij: theme.palette.primary.main,
  Ingediend: theme.palette.secondary.main,
  'In behandeling': theme.palette.secondary.main,
  Beschikt: theme.palette.secondary.main,
  Geëffectueerd: theme.palette.secondary.main,
  Afgerond: theme.palette.secondary.main,
  Afgewezen: theme.palette.error.main,
  Ingetrokken: theme.palette.secondary.main,
  Ruling: theme.palette.secondary.main,
  'Ruling afgerond': theme.palette.secondary.main,
  'Geen potentieel': theme.palette.error.main,
  'Traject gestart': theme.palette.secondary.main,
  'Communicatie BD': theme.palette.secondary.main,
  'Klant dient zelf in': theme.palette.secondary.main,
  'Incomplete small risk': theme.palette.error.main,
};

export const dropzone = {
  overflow: 'hidden',
  minHeight: 128,
  maxHeight: 450,
  maxWidth: 450,
  borderWidth: 0,
  borderRadius: '8px',
};

export const dropzoneLabel = {
  fontSize: '14px',
  fontWeight: 'normal',
  color: 'rgba(0, 0, 0, 0.54)',
  letterSpacing: '0.15px',
  fontFamily: 'Lato',
  lineHeight: '143%',
  padding: '44px 92px',
  textAlign: 'center',
};

export const preview = {
  padding: '42px 24px',
  borderBottom: 'none',
  '& progress': {
    color: 'green',
  },
};
