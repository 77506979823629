import React from 'react';
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from '@mui/material';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';

const DocumentsDownloadDialog = (props) => {
  const {
    openDialog,
    setOpenDialog,
    title,
    content,
    action,
    confirmText,
    cancelText,
    handleConfirm,
    dialogWidth,
    disabledBtn,
    fullWidthDialog,
    maxWidthDialog,
  } = props;
  return (
    <Dialog
      fullWidth={fullWidthDialog && fullWidthDialog}
      maxWidth={maxWidthDialog && maxWidthDialog}
      open={openDialog}
      aria-labelledby='alert-dialog-title'
      aria-describedby='alert-dialog-description'
      className={dialogWidth ? dialogWidth : ''}
    >
      <DialogTitle id='alert-dialog-title'>
        {title}
        {!props.onClose ? (
          <IconButton
            aria-label='close'
            onClick={() => {
              setOpenDialog(false);
            }}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
        ) : null}
      </DialogTitle>
      <DialogContent>{content}</DialogContent>
      {!action && (
        <DialogActions>
          {cancelText && (
            <Button
              onClick={() => {
                setOpenDialog(false);
              }}
            >
              {cancelText}
            </Button>
          )}
          {confirmText && (
            <Button
              onClick={() => {
                setOpenDialog(false);
                handleConfirm();
                if (props.handleClose) {
                  props.handleClose();
                }
              }}
              color='primary'
              disabled={disabledBtn ? disabledBtn : false}
              variant='contained'
            >
              {confirmText}
            </Button>
          )}
        </DialogActions>
      )}
    </Dialog>
  );
};

export default DocumentsDownloadDialog;
