import { theme } from '../../theme/BrightDesignTheme';

export const chipsContainer = {
  '& .MuiChip-root ': {
    marginRight: 1,
    background: '#F5F5F5',
  },
  '& svg.icon-error': {
    fill: '#F44336',
  },
  '& svg.icon-done': {
    fill: theme.palette.primary.main,
  },
  '& > div:first-of-type': {
    [theme.breakpoints.down('768')]: {
      marginBottom: 2,
    },
  },
};

export const variant = {};
export const img = {
  '& svg': {
    height: '100%',
    width: '100%',
  },
};
