import React from 'react';
import { useTheme } from '@mui/material/styles';
import {
  Grid,
  Typography,
  TableBody,
  TableRow,
  TableCell,
} from '@mui/material';
import { useTranslation } from 'react-i18next';

const NoResultsFound = (props) => {
  const { t } = useTranslation();
  const theme = useTheme();
  return (
    <TableBody>
      <TableRow>
        <TableCell
          colSpan={props.span}
          style={{
            width: '100%',
          }}
        >
          <Grid container justifyContent='center' justifyItems='center'>
            <Grid container justifyContent='center'>
              <Typography variant='h6' style={{ fontSize: 24 }}>
                {t('application_list_table_no_result.title')}
              </Typography>
            </Grid>
            <Grid container justifyContent='center'>
              <Typography color={theme.palette.text.disabled}>
                {t('application_list_table_no_result.sub_titile')}
              </Typography>
            </Grid>
          </Grid>
        </TableCell>
      </TableRow>
    </TableBody>
  );
};

export default NoResultsFound;
