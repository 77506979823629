import i18n from '../../utils/i18n';

export const columnHeaderList = [
  {
    id: 'name',
    numeric: false,
    disablePadding: true,
    label: i18n.t('leads.overview_table.company_name'),
    width: '25%',
  },
  {
    id: 'createdAt',
    numeric: true,
    disablePadding: false,
    label: i18n.t('leads.overview_table.referred_date'),
  },
  {
    id: 'status',
    numeric: true,
    disablePadding: false,
    label: i18n.t('leads.overview_table.status'),
  },
  {
    id: 'ownerFirstName',
    numeric: false,
    disablePadding: false,
    label: i18n.t('leads.overview_table.owner'),
  },
  /*{
    id: 'participant',
    numeric: true,
    disablePadding: false,
    label: i18n.t('leads.overview_table.participate'),
  },*/
];

export const columnHeader = () => {
  return [
    {
      id: 'name',
      numeric: false,
      disablePadding: false,
      label: i18n.t('leads.overview_table.company_name'),
      width: '30%',
    },
    {
      id: 'status',
      numeric: false,
      disablePadding: false,
      label: i18n.t('leads.overview_table.status'),
      width: '20%',
    },
    {
      id: 'createdAt',
      numeric: false,
      disablePadding: false,
      label: i18n.t('leads.overview_table.referred_date'),
      width: '25%',
    },
    {
      id: 'ownerFirstName',
      numeric: false,
      disablePadding: false,
      label: i18n.t('leads.overview_table.owner'),
    },
    /*{
      id: 'partnerParticipation',
      numeric: false,
      disablePadding: false,
      label: i18n.t('leads.overview_table.participate'),
      width: '25%',
    },*/
  ];
};

export const accordionLabelHeader = () => {
  return {
    name: i18n.t('leads.overview_table.company_name'),
    status: i18n.t('leads.overview_table.status'),
    createdAt: i18n.t('leads.overview_table.referred_date'),
    partnerParticipation: i18n.t('leads.overview_table.participate'),
  };
};

export const statusLeadList = [
  'Lead',
  'MQL',
  'SQL',
  'Prospect',
  'Prospect 2',
  'Unqualified',
  'Active klant',
  'Passief',
  'Inactive klant',
  'Opgezegd M&K',
  'Opgezegd Klant',
  'Bounce',
  'Opgeheven',
];

export const statusLeadMap = () => {
  return {
    Lead: i18n.t('leads.status.lead'),
    MQL: i18n.t('leads.status.MQL'),
    SQL: i18n.t('leads.status.SQL'),
    Prospect: i18n.t('leads.status.prospect'),
    'Prospect 2': i18n.t('leads.status.prospect'),
    Unqualified: i18n.t('leads.status.unqualified'),
    'Active klant': i18n.t('leads.status.customer'),
    Passief: i18n.t('leads.status.inactive'),
    'Inactive klant': i18n.t('leads.status.inactive'),
    'Opgezegd M&K': i18n.t('leads.status.terminated'),
    'Opgezegd Klant': i18n.t('leads.status.terminated'),
    Bounce: i18n.t('leads.status.terminated'),
    Opgeheven: i18n.t('leads.status.terminated'),
  };
};

export const tooltipLeadMap = () => {
  return {
    MQL: i18n.t('leads.tooltip.MQL'),
    SQL: i18n.t('leads.tooltip.SQL'),
    Prospect: i18n.t('leads.tooltip.prospect'),
    'Prospect 2': i18n.t('leads.tooltip.prospect'),
    Unqualified: i18n.t('leads.tooltip.unqualified'),
    'Active klant': i18n.t('leads.tooltip.customer'),
    Passief: i18n.t('leads.tooltip.inactive'),
    'Inactive klant': i18n.t('leads.tooltip.inactive'),
    'Opgezegd M&K': i18n.t('leads.tooltip.terminated'),
    'Opgezegd Klant': i18n.t('leads.tooltip.terminated'),
    Bounce: i18n.t('leads.tooltip.terminated'),
    Opgeheven: i18n.t('leads.tooltip.terminated'),
  };
};
