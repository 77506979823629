import React, { useContext, useEffect, useState } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import {
  Grid,
  Typography,
  Box,
  TextField,
  Button,
  FormControl,
  FormLabel,
  FormControlLabel,
  RadioGroup,
  Radio,
  Select,
  InputLabel,
  OutlinedInput,
  MenuItem,
  Checkbox,
  FormGroup,
} from '@mui/material';
import { editProjectStyles as useStyles } from './styles';
import { useTranslation } from 'react-i18next';
import i18n from 'i18next';
import { NumberFormatCustom, removeEmojis } from '../../JS/FormControls';
import Autocomplete from '@mui/material/Autocomplete';
import enLocale from 'date-fns/locale/en-US';
import nlLocale from 'date-fns/locale/nl';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { UserContext } from '../../contexts/UserContext';
//import { DashboardContext } from '../../Contexts/DashboardContext';
import Loader from '../../uiComponents/Loader';
import axios from 'axios';

const localeMap = {
  en: enLocale,
  nl: nlLocale,
};
const localeCancelLabelMap = {
  en: 'Cancel',
  nl: 'Annuleren',
};
const localeClearLabelMap = {
  en: 'Clear',
  nl: 'Verwijderen',
};

const EditProject = () => {
  let location = useLocation();
  const classes = useStyles();
  const { t } = useTranslation();
  let history = useHistory();
  //const { setProjectEditSuccess } = useContext(DashboardContext);
  const [userContext] = useContext(UserContext);
  const [loading, setLoading] = useState(false);
  const [disabled, setDisabled] = useState(true);
  const [openStartDate, setOpenStartDate] = useState(false);
  const [openEndDate, setopenEndDate] = useState(false);

  const {
    label,
    typeInput,
    value,
    secondValue,
    name,
    rows,
    multiline,
    project,
    options,
    selectedOptions,
    endDate,
  } = location.state || {};

  const [fieldValue, setFieldValue] = useState('');
  const [firstValue, setFirstValue] = useState(value);
  const [secondsValue, setSecondsValue] = useState(secondValue);
  const [selectedsOptions, setSelectedsOptions] = useState(selectedOptions);
  const [autoOptions, setAutoOptions] = useState([]);

  const [locale, setLocale] = useState('en');
  const selectLocale = React.useCallback((locale) => {
    setLocale(i18n.languages[0]);
  }, []);

  // textfield and datepicker
  const DateInput = () => {
    return (
      <>
        <LocalizationProvider
          dateAdapter={AdapterDateFns}
          adapterLocale={localeMap[locale]}
        >
          <DatePicker
            onClick={() => setOpenStartDate(true)}
            onClose={() => setOpenStartDate(false)}
            open={openStartDate}
            clearable
            inputVariant='outlined'
            inputFormat='dd MMM yyyy'
            id={name}
            name={name}
            label={t('labels.start_date')}
            toolbarPlaceholder=''
            // toolbarTitle
            cancelText={localeCancelLabelMap[locale]}
            clearText={localeClearLabelMap[locale]}
            fullWidth
            onChange={(date) => {
              setFirstValue(date);
              setFieldValue(date);
              if (!date) {
                setDisabled(true);
              } else {
                setDisabled(false);
              }
            }}
            value={firstValue}
            renderInput={(params) => (
              <TextField
                {...params}
                variant='outlined'
                margin='dense'
                size='small'
                fullWidth
                onMouseDown={(e) => {
                  setOpenStartDate(true);
                }}
              />
            )}
          ></DatePicker>
        </LocalizationProvider>
        {endDate && (
          <Box mt={3}>
            <LocalizationProvider
              dateAdapter={AdapterDateFns}
              adapterLocale={localeMap[locale]}
              style={{ display: 'content' }}
            >
              <DatePicker
                onClick={() => setopenEndDate(true)}
                onClose={() => setopenEndDate(false)}
                open={openEndDate}
                clearable
                inputVariant='outlined'
                inputFormat='dd MMM yyyy'
                label={t('labels.end_date')}
                toolbarPlaceholder=''
                // toolbarTitle
                cancelLabel={localeCancelLabelMap[locale]}
                clearLabel={localeClearLabelMap[locale]}
                cancelText={localeCancelLabelMap[locale]}
                clearText={localeClearLabelMap[locale]}
                fullWidth
                onChange={(date) => {
                  setSecondsValue(date);
                  if (!date) {
                    setDisabled(true);
                  } else {
                    setDisabled(false);
                  }
                }}
                value={secondsValue}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant='outlined'
                    margin='dense'
                    size='small'
                    fullWidth
                    onMouseDown={(e) => {
                      setopenEndDate(true);
                    }}
                  />
                )}
              ></DatePicker>
            </LocalizationProvider>
          </Box>
        )}
      </>
    );
  };

  const CheckboxInput = () => {
    return (
      <>
        <FormControl component='fieldset'>
          <FormLabel component='legend'>{'Focus'}</FormLabel>
          <FormGroup>
            {options.map((option, i) => (
              <FormControlLabel
                key={i}
                control={
                  <Checkbox
                    checked={option.checked}
                    onChange={(e) => handleChangeFocus(e, option)}
                    name={option.label}
                  />
                }
                label={option.label}
              />
            ))}
          </FormGroup>
        </FormControl>
      </>
    );
  };

  // for checkbox
  const [focusValues, setFocusValues] = useState(options);
  const [vals, setVals] = useState([]);

  const handleChangeFocus = (e, opt) => {
    opt.checked = e.target.checked;
    setFocusValues([...focusValues]);
    if (e.target.checked) {
      vals.push(opt.value);
    } else {
      vals.splice(vals.indexOf(opt.value), 1);
    }
    setVals(vals);
    setFieldValue(vals.join(';'));
    if (vals.length === 0) {
      setDisabled(true);
    } else {
      setDisabled(false);
    }
  };

  const handleAutocompleteValues = (values) => {
    let vals = values.map((a) => a.value);
    setFieldValue(vals.join(';'));
    setAutoOptions(values);
    if (vals.length === 0) {
      setDisabled(true);
    } else {
      setDisabled(false);
    }
  };

  const handleSave = () => {
    setLoading(true);
    let id = project.id;
    var requestBody = !endDate
      ? {
          Project: { _id: id, [name]: fieldValue },
        }
      : { Project: { _id: id, [name]: fieldValue, endDate: secondsValue } };
    const apiEndPoint =
      process.env.NODE_ENV === 'development'
        ? process.env.REACT_APP_API_ENDPOINT
        : process.env.REACT_APP_API_ENDPOINT_PROD;
    axios({
      method: 'post',
      url: apiEndPoint + '/v1/partner/project/updateProject',
      data: requestBody,
      withCredentials: true,
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        Authorization: `Bearer ${userContext.token}`,
      },
    })
      .then((res) => {
        let projectName = '';
        if (name === 'name') {
          projectName = fieldValue;
        } else {
          projectName = project.name;
        }
        setLoading(false);
        history.push({
          pathname: `/app/projects/${project.id}`,
          state: {
            name: projectName,
            accountId: project.accountId,
          },
        });
      })
      .catch((error) => {
        setLoading(false);
      });
  };

  useEffect(() => {
    if (location.state.value) {
      let name = location.state.value;
      window.document.title = t('page_title.projectX', { name });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.state]);

  useEffect(() => {
    if (!project) {
      let pathname = location.pathname;
      let url = pathname.slice(0, -5);
      let id = url.substring(url.lastIndexOf('/') + 1);
      history.push(`/app/projects/${id}`);
    } else {
      let vals;
      if (typeInput === 'autocompletefield') {
        if (project[name]) {
          let selVals = selectedsOptions.split(';');
          vals = options.filter((opt) => selVals.indexOf(opt.value) !== -1);
          setSelectedsOptions(vals);
          setAutoOptions(vals);
          setFieldValue(project[name]);
        }
      }

      if (project.projectFocus && typeInput === 'checkboxfield') {
        let selectedVals = project.projectFocus.split(';');
        focusValues.map((val) => {
          if (
            selectedVals.length > 0 &&
            selectedVals.indexOf(val.value) !== -1
          ) {
            val.checked = true;
          } else {
            val.checked = false;
          }
          return val;
        });
        setVals(selectedVals);
      }
      if (typeInput === 'datefield') {
        setFieldValue(project[name]);
      }
    }
    selectLocale();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      {loading && <Loader loading={loading} />}
      <div className={classes.root + ' ' + classes.projectX}>
        <Grid container justifyContent='center'>
          <Grid container item xs={12} className={classes.container}>
            <Grid item xs={12}>
              <Grid item xs={12}>
                <Box>
                  <Typography variant='h6' align='center'>
                    {label} {t('labels.change').toLowerCase()}
                  </Typography>
                </Box>
                <Box my={3}>
                  <Typography
                    variant='body1'
                    align='center'
                    color='textSecondary'
                  >
                    {t('projectX.edit_subtitle', { label })}
                  </Typography>
                </Box>
              </Grid>
            </Grid>
            <Grid
              container
              item
              xs={12}
              className={classes.box}
              justifyContent='center'
            >
              <Grid container item xs={12}>
                <Grid item xs={12} className={classes.input}>
                  {typeInput === 'textfield' && (
                    <TextField
                      id={name}
                      name={name}
                      label={label}
                      variant='outlined'
                      multiline={multiline}
                      rows={rows}
                      fullWidth
                      defaultValue={value}
                      onChange={(e) => {
                        if (removeEmojis(e.target.value).length > 0) {
                          setFirstValue(removeEmojis(e.target.value));
                          setFieldValue(removeEmojis(e.target.value));
                        }
                        if (
                          !e.target.value ||
                          removeEmojis(e.target.value).length === 0
                        ) {
                          setDisabled(true);
                        } else {
                          setDisabled(false);
                        }
                      }}
                      InputProps={
                        name === 'subsidyNeed'
                          ? {
                              inputComponent: NumberFormatCustom,
                            }
                          : {}
                      }
                      inputProps={
                        name === 'name'
                          ? {
                              maxLength: 150,
                            }
                          : {
                              maxLength: 1000,
                            }
                      }
                      style={{ display: 'content' }}
                    />
                  )}
                  {typeInput === 'datefield' && <DateInput />}
                  {typeInput === 'autocompletefield' && (
                    <Autocomplete
                      className={classes.formControl}
                      multiple
                      value={autoOptions || []}
                      id={name}
                      options={options}
                      getOptionLabel={(option) => option.label}
                      disableCloseOnSelect
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          variant='outlined'
                          label={label}
                          placeholder=''
                          InputLabelProps={{
                            shrink: true,
                          }}
                        />
                      )}
                      onChange={(e, values) => {
                        setSelectedsOptions(values);
                        handleAutocompleteValues(values);
                      }}
                    />
                  )}
                  {typeInput === 'radiofield' && (
                    <FormControl
                      component='fieldset'
                      className={classes.formControl}
                    >
                      <FormLabel
                        component='legend'
                        style={{ marginBottom: 24 }}
                      >
                        {label}
                      </FormLabel>
                      <RadioGroup
                        name={name}
                        defaultValue={value}
                        onChange={(e) => {
                          setFirstValue(e.target.value);
                          setFieldValue(e.target.value);
                          if (!e.target.value) {
                            setDisabled(true);
                          } else {
                            setDisabled(false);
                          }
                        }}
                      >
                        {options.map((option, i) => (
                          <FormControlLabel
                            key={i}
                            value={option.value}
                            control={<Radio />}
                            label={option.label}
                          ></FormControlLabel>
                        ))}
                      </RadioGroup>
                    </FormControl>
                  )}
                  {typeInput === 'selectfield' && (
                    <FormControl
                      variant='outlined'
                      label={label}
                      style={{ width: '100%' }}
                    >
                      <InputLabel id='randdHours-label' shrink={true}>
                        {label}
                      </InputLabel>
                      <Select
                        fullWidth
                        id={name}
                        name={name}
                        input={<OutlinedInput notched label={label} />}
                        defaultValue={value}
                        onChange={(e) => {
                          setFirstValue(e.target.value);
                          setFieldValue(e.target.value);
                          if (!e.target.value) {
                            setDisabled(true);
                          } else {
                            setDisabled(false);
                          }
                        }}
                      >
                        {options.map((option, i) => (
                          <MenuItem key={i} value={option.value}>
                            {option.label}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  )}
                  {typeInput === 'checkboxfield' && <CheckboxInput />}
                </Grid>
              </Grid>
            </Grid>
            <Grid
              container
              item
              xs={12}
              className={classes.input}
              justifyContent='flex-end'
            >
              <Button
                color='primary'
                onClick={() => {
                  history.push({
                    pathname: `/app/projects/${project.id}`,
                    state: {
                      name: project.name,
                      accountId: project.accountId,
                    },
                  });
                }}
              >
                {t('buttons.cancel')}
              </Button>
              <Button
                color='primary'
                variant='contained'
                disabled={disabled}
                onClick={handleSave}
              >
                {t('labels.change')}
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </div>
    </>
  );
};

export default EditProject;
