import React, { useState, useEffect, useContext } from 'react';
import { useHistory, withRouter } from 'react-router-dom';
import {
  Grid,
  Divider,
  Typography,
  FormControl,
  FormControlLabel,
  RadioGroup,
  Radio,
  FormHelperText,
  FormLabel,
  Button,
} from '@mui/material';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import { theme as bdtheme } from '../../theme/BrightDesignTheme';
import { newProjectDoughnutData } from '../../data/picklistOptions';
import NivoDoughnut from '../../uiComponents/NivoDoughnut';
import { useMediaQuery } from 'react-responsive';
import { useStyles } from './styles';
import { ReactComponent as SadFace } from '../../assets/Sad_face_TwoColor_1.svg';
import { useTranslation } from 'react-i18next';
import { handleChange } from '../../JS/FormControls';
import { NewProjectContext } from '../../contexts/NewProject';
import { UserContext } from '../../contexts/UserContext';
import axios from 'axios';
import { yes_no_Optionss } from '../../data/picklistOptions';
import Loader from '../../uiComponents/Loader';
import NoResultsDialog from '../../uiComponents/NoResultsDialog';
import NewProjectBaseDetails from './NewProjectBaseDetails';
import CustomDialog from '../../uiComponents/CustomDialog';

const NewProjectTypeInvestEmployeesOptimized = (props) => {
  const classes = useStyles();
  const history = useHistory();
  const isLarge = useMediaQuery({ query: '(min-width: 1280px)' });

  const isLargeScreen = useMediaQuery({ query: '(min-width: 1280px)' });
  const { t } = useTranslation();

  const COLORS = [bdtheme.palette.primary.main, 'rgba(224, 224, 224, 1)'];
  const data = newProjectDoughnutData(
    props.progressStep,
    props.showMoreDetails
  );

  const {
    projectEmployees,
    setProjectEmployees,
    projectType,
    setNewProjectId,
    newProjectId,
    setPopularSubsidies,
    setOtherSubsidies,
    setAssignments,
    setSelectedPopularSubsidies,
    setSelectedAssignments,
    accountId,
  } = useContext(NewProjectContext);
  const [loading, setLoading] = React.useState(false);
  const [userContext] = useContext(UserContext);

  const radioOptions = yes_no_Optionss();
  const [disabled, setDisabled] = useState(true);
  const [nosubsidies, setNosubsidies] = useState(false);

  const [openDialogReset, setOpenDialogReset] = useState(false);
  const { showMoreDetails, setShowMoreDetails } = props;

  const ROUTES = [
    '/app/newproject/choose',
    '/app/newproject/upload',
    '/app/newproject/subsidies',
  ];
  const handleChangeSwitch = (event) => {
    if (!event.target.checked) {
      if (
        projectEmployees.learningWorkPrograms ||
        projectEmployees.investmentInEmployees ||
        projectEmployees.hiredWorkersAtDisadvantage ||
        projectEmployees.willHireWorkersAtDisadvantage
      ) {
        setOpenDialogReset(true);
      } else {
        setShowMoreDetails(event.target.checked);
      }
    } else {
      setShowMoreDetails(event.target.checked);
    }
  };

  const handleConfirmReset = () => {
    setProjectEmployees({
      ...projectEmployees,
      learningWorkPrograms: '',
      investmentInEmployees: '',
      hiredWorkersAtDisadvantage: '',
      willHireWorkersAtDisadvantage: '',
    });
    setShowMoreDetails(false);

    if (projectEmployees.id && projectEmployees.id !== null) {
      try {
        setLoading(true);

        const apiEndPoint =
          process.env.NODE_ENV === 'development'
            ? process.env.REACT_APP_API_ENDPOINT
            : process.env.REACT_APP_API_ENDPOINT_PROD;

        let body = {
          projectId: projectEmployees.id,
        };
        axios({
          method: 'post',
          url: apiEndPoint + '/v1/partner/project/resetProjectFields',
          withCredentials: true,
          data: body,
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
            Authorization: `Bearer ${userContext.token}`,
          },
        })
          .then((response) => {
            setLoading(false);
          })
          .catch((error) => {
            setLoading(false);
          });
      } catch (err) {}
    }
  };

  const handleNext = () => {
    setLoading(true);

    var body = {
      Project: projectEmployees,
      id: newProjectId,
      accountId: accountId,
    };

    const apiEndPoint =
      process.env.NODE_ENV === 'development'
        ? process.env.REACT_APP_API_ENDPOINT
        : process.env.REACT_APP_API_ENDPOINT_PROD;

    axios({
      method: 'post',
      url: apiEndPoint + '/v1/partner/project/addProject',
      data: body,
      withCredentials: true,
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        Authorization: `Bearer ${userContext.token}`,
      },
    })
      .then((res) => {
        setNewProjectId(res.data.projectId);
        let reqBody = {
          projectId: res.data.projectId,
        };
        axios({
          method: 'post',
          url: apiEndPoint + '/v1/partner/projectscan/projectscanOptimized',
          data: reqBody,
          withCredentials: true,
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
            Authorization: `Bearer ${userContext.token}`,
          },
        })
          .then((res) => {
            setLoading(false);
            props.setProgressStep(props.progressStep + 1);
            setPopularSubsidies(res.data.popularFirstWithScore);
            setOtherSubsidies(res.data.otherSubsidiesWithScore);
            setAssignments(res.data.assignments);
            const newSelectedPopular = res.data.popularFirstWithScore.map(
              (n) => n.id
            );
            setSelectedPopularSubsidies(newSelectedPopular);
            const newSelectedAssignments = res.data.assignments.map(
              (n) => n.subsidyId.id
            );
            setSelectedAssignments(newSelectedAssignments);
            showMoreDetails ? history.push(ROUTES[2]) : history.push(ROUTES[1]);
          })
          .catch((error) => {
            setLoading(false);
          });
      })
      .catch((error) => {
        setLoading(false);
      });
  };

  const handleBack = () => {
    if (props.progressStep !== 0) {
      history.push(ROUTES[0]);
    }
  };

  useEffect(() => {
    if (
      projectEmployees.name &&
      projectEmployees.idea &&
      projectEmployees.problemStatement &&
      projectEmployees.learningWorkPrograms &&
      projectEmployees.investmentInEmployees &&
      projectEmployees.hiredWorkersAtDisadvantage &&
      projectEmployees.willHireWorkersAtDisadvantage &&
      projectEmployees.subsidyNeed &&
      projectEmployees.projectTypeId
    ) {
      setDisabled(false);
    } else {
      setDisabled(true);
    }
  }, [projectEmployees]);

  useEffect(() => {
    if (
      projectEmployees.name &&
      projectEmployees.idea &&
      projectEmployees.problemStatement &&
      projectEmployees.subsidyNeed &&
      projectEmployees.projectTypeId
    ) {
      if (!showMoreDetails) {
        setDisabled(false);
      } else if (
        projectEmployees.learningWorkPrograms &&
        projectEmployees.investmentInEmployees &&
        projectEmployees.hiredWorkersAtDisadvantage &&
        projectEmployees.willHireWorkersAtDisadvantage
      ) {
        setDisabled(false);
      } else {
        setDisabled(true);
      }
    } else {
      setDisabled(true);
    }

    if (!projectEmployees.projectTypeId || !projectEmployees.accountId) {
      setProjectEmployees({
        ...projectEmployees,
        projectTypeId: projectType,
        accountId: accountId,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [projectEmployees, showMoreDetails]);

  useEffect(() => {
    if (
      projectEmployees?.learningWorkPrograms ||
      projectEmployees?.investmentInEmployees ||
      projectEmployees?.hiredWorkersAtDisadvantage ||
      projectEmployees?.willHireWorkersAtDisadvantage
    ) {
      setShowMoreDetails(true);
    } else {
      setShowMoreDetails(false);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    projectEmployees?.learningWorkPrograms,
    projectEmployees?.investmentInEmployees,
    projectEmployees?.hiredWorkersAtDisadvantage,
    projectEmployees?.willHireWorkersAtDisadvantage,
  ]);

  return (
    <>
      {loading && <Loader loading={loading} />}
      <Grid container justifyContent='center' className={classes.root}>
        <Grid
          container
          item
          xs={12}
          justifyContent='center'
          alignContent='center'
        >
          {/*<>SECTION START</>*/}

          <NewProjectBaseDetails
            classes={classes}
            isLarge={isLarge}
            isLargeScreen={isLargeScreen}
            project={projectEmployees}
            setProject={setProjectEmployees}
            handleChange={handleChange}
            showMoreDetails={showMoreDetails}
            handleChangeSwitch={handleChangeSwitch}
            label1={t('project-details.title')}
            label2={t('project-details.subtitle')}
            label3={t('project-details.project')}
            label4={t('labels.project_name')}
            label5={t('projectX.label2')}
            label6={t('projectX.label3')}
            label7={t('labels.subsidie')}
            label8={t('project-details.more_details')}
            helper1={t('helper_text.project_name')}
            helper2={t('helper_text.subsidie')}
            helper3={t('helper_text.description1')}
            helper4={t('helper_text.description2')}
            placeholder1={t('project-details.placeholder_idea')}
            placeholder2={t('project-details.placeholder_solution')}
          />

          {/*<>SECTION DETAILS</>*/}
          {showMoreDetails && (
            <>
              {' '}
              {/*<>SECTION</>*/}
              <Grid container item xs={12} className={classes.section}>
                <Grid container item xs={12}>
                  {/*<> SUBSECTION </>*/}
                  <Grid
                    container
                    item
                    xs={12}
                    justifyContent={isLarge ? 'flex-start' : 'center'}
                    className={classes.subSection}
                  >
                    <Grid item xs={12}>
                      <Typography
                        variant='overline'
                        color='textSecondary'
                        className={classes.subTitle}
                      >
                        {t('project-details.activities')}
                      </Typography>
                    </Grid>
                  </Grid>
                  <Grid
                    container
                    item
                    xs={12}
                    lg={isLarge ? 6 : 12}
                    justifyContent={isLarge ? 'flex-start' : 'center'}
                    className={classes.subSection}
                  >
                    <Grid container item xs={12}>
                      <Grid item xs={12}>
                        <FormControl
                          component='fieldset'
                          className={classes.formControl}
                        >
                          <FormLabel component='legend'>
                            {t('project-details.investEmployees_1')}
                          </FormLabel>
                          <RadioGroup
                            name={'learningWorkPrograms'}
                            value={projectEmployees.learningWorkPrograms}
                            onChange={(e) => {
                              handleChange(
                                e,
                                projectEmployees,
                                setProjectEmployees
                              );
                            }}
                          >
                            {radioOptions.map((option, i) => (
                              <FormControlLabel
                                key={i}
                                value={option.value}
                                control={<Radio />}
                                label={option.label}
                              ></FormControlLabel>
                            ))}
                          </RadioGroup>
                          <FormHelperText style={{ marginLeft: '0px' }}>
                            {t('helper_text.select1')}
                          </FormHelperText>
                        </FormControl>
                      </Grid>
                    </Grid>
                  </Grid>
                  {/*<> SUBSECTION </>*/}
                  <Grid
                    container
                    item
                    xs={12}
                    lg={isLarge ? 6 : 12}
                    justifyContent={isLarge ? 'flex-end' : 'center'}
                    className={classes.subSection}
                  >
                    <Grid container item xs={12}>
                      <Grid item xs={12}>
                        <FormControl
                          component='fieldset'
                          className={classes.formControl}
                        >
                          <FormLabel component='legend'>
                            {t('project-details.investEmployees_2')}
                          </FormLabel>
                          <RadioGroup
                            name={'investmentInEmployees'}
                            value={projectEmployees.investmentInEmployees}
                            onChange={(e) => {
                              handleChange(
                                e,
                                projectEmployees,
                                setProjectEmployees
                              );
                            }}
                          >
                            {radioOptions.map((option, i) => (
                              <FormControlLabel
                                key={i}
                                value={option.value}
                                control={<Radio />}
                                label={option.label}
                              ></FormControlLabel>
                            ))}
                          </RadioGroup>
                          <FormHelperText style={{ marginLeft: '0px' }}>
                            {t('helper_text.select1')}
                          </FormHelperText>
                        </FormControl>
                      </Grid>
                    </Grid>
                  </Grid>
                  {/*<> SUBSECTION </>*/}
                  <Grid
                    container
                    item
                    xs={12}
                    lg={isLarge ? 6 : 12}
                    justifyContent={isLarge ? 'flex-start' : 'center'}
                    className={classes.subSection}
                  >
                    <Grid container item xs={12}>
                      <Grid item xs={12}>
                        <FormControl
                          component='fieldset'
                          className={classes.formControl}
                        >
                          <FormLabel component='legend'>
                            {t('project-details.investEmployees_3')}
                          </FormLabel>
                          <RadioGroup
                            name={'hiredWorkersAtDisadvantage'}
                            value={projectEmployees.hiredWorkersAtDisadvantage}
                            onChange={(e) => {
                              handleChange(
                                e,
                                projectEmployees,
                                setProjectEmployees
                              );
                            }}
                          >
                            {radioOptions.map((option, i) => (
                              <FormControlLabel
                                key={i}
                                value={option.value}
                                control={<Radio />}
                                label={option.label}
                              ></FormControlLabel>
                            ))}
                          </RadioGroup>
                          <FormHelperText style={{ marginLeft: '0px' }}>
                            {t('helper_text.select1')}
                          </FormHelperText>
                        </FormControl>
                      </Grid>
                    </Grid>
                  </Grid>
                  {/*<> SUBSECTION </>*/}
                  <Grid
                    container
                    item
                    xs={12}
                    lg={isLarge ? 6 : 12}
                    justifyContent={isLarge ? 'flex-end' : 'center'}
                    className={classes.subSection}
                  >
                    <Grid item xs={12}>
                      <FormControl
                        component='fieldset'
                        className={classes.formControl}
                      >
                        <FormLabel component='legend'>
                          {t('project-details.investEmployees_4')}
                        </FormLabel>
                        <RadioGroup
                          name={'willHireWorkersAtDisadvantage'}
                          value={projectEmployees.willHireWorkersAtDisadvantage}
                          onChange={(e) => {
                            handleChange(
                              e,
                              projectEmployees,
                              setProjectEmployees
                            );
                          }}
                        >
                          {radioOptions.map((option, i) => (
                            <FormControlLabel
                              key={i}
                              value={option.value}
                              control={<Radio />}
                              label={option.label}
                            ></FormControlLabel>
                          ))}
                        </RadioGroup>
                        <FormHelperText style={{ marginLeft: '0px' }}>
                          {t('helper_text.select1')}
                        </FormHelperText>
                      </FormControl>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </>
          )}

          {/*<>BUTTONS SECTION</>*/}
          <Grid
            container
            item
            xs={12}
            justifyContent='center'
            className={classes.section + ' ' + classes.sectionMobile}
          >
            <Grid container item xs={12} className={classes.sectionButtons}>
              <Grid item xs={12}>
                <Divider />
              </Grid>
              <Grid
                container
                item
                xs={12}
                className={classes.buttons}
                style={
                  isLargeScreen
                    ? {}
                    : {
                        flexWrap: 'nowrap',
                        justifyContent: 'space-between',
                      }
                }
              >
                <>
                  {!isLargeScreen && (
                    <Grid item>
                      <div style={{ height: 40, width: 40 }}>
                        <NivoDoughnut
                          data={data}
                          center={props.center}
                          colors={COLORS}
                          smallFont
                        />
                      </div>
                    </Grid>
                  )}
                  <Grid
                    item
                    container
                    justifyContent={
                      isLargeScreen ? 'space-between' : 'flex-end'
                    }
                  >
                    <Button
                      style={{ marginRight: 16 }}
                      startIcon={<KeyboardArrowLeftIcon />}
                      onClick={handleBack}
                    >
                      {t('buttons.back')}
                    </Button>
                    <Button
                      color='primary'
                      variant='contained'
                      endIcon={<KeyboardArrowRightIcon />}
                      onClick={handleNext}
                      disabled={disabled}
                    >
                      {t('buttons.next')}
                    </Button>
                  </Grid>
                </>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      <NoResultsDialog
        openDialog={nosubsidies}
        setOpenDialog={setNosubsidies}
        title={t('select_subsidies.no_subsidies')}
        text={t('select_subsidies.no_subsidies_text')}
        image={
          <>
            <SadFace />
          </>
        }
        alignment='center'
        cancel={t('buttons.cancel_2')}
        confirm={t('dialog.to_dashboard')}
        handleConfirm={props.handleConfirm}
      />

      {/* RESET DETAILS */}

      <CustomDialog
        openDialog={openDialogReset}
        setOpenDialog={setOpenDialogReset}
        handleConfirm={handleConfirmReset}
        handleClose={() => {
          setOpenDialogReset(false);
        }}
        title={t('dialog.reset_project_2')}
        text={t('dialog.reset_project_3')}
        cancel={t('yes_no.no')}
        confirm={t('yes_no.yes')}
      />
    </>
  );
};

export default withRouter(NewProjectTypeInvestEmployeesOptimized);
