import React from 'react';
import {
  Grid,
  Divider,
  Typography,
  TextField,
  Box,
  FormControl,
  FormControlLabel,
  FormHelperText,
  FormGroup,
  Switch,
} from '@mui/material';

import { NumberFormatCustom } from '../../JS/FormControls';

const NewProjectBaseDetails = (props) => {
  const {
    classes,
    isLarge,
    isLargeScreen,
    project,
    setProject,
    handleChange,
    showMoreDetails,
    handleChangeSwitch,
    label1,
    label2,
    label3,
    label4,
    label5,
    label6,
    label7,
    label8,
    helper1,
    helper2,
    helper3,
    helper4,
    placeholder1,
    placeholder2,
  } = props;

  return (
    <Grid container item xs={12} className={classes.section}>
      {/*<>TITLE</>*/}
      <Grid
        container
        item
        xs={12}
        justifyContent='center'
        className='title-section'
      >
        <Grid item xs={12} className={classes.title}>
          <Box mb={1}>
            <Typography variant='h6'>{label1 && label1}</Typography>
          </Box>
          <Box pb={3}>
            <Typography variant='body2' className={classes.textDisabled}>
              {label2 && label2}
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={12} lg={isLarge ? 6 : 12}></Grid>
      </Grid>
      {/*<>PROJECT</>*/}
      <Grid
        container
        item
        xs={12}
        lg={isLarge ? 6 : 12}
        justifyContent={isLarge ? 'flex-start' : 'center'}
        className={classes.subSection}
      >
        <Grid container item xs={12}>
          <Grid item xs={12}>
            <Typography
              variant='overline'
              color='textSecondary'
              className={classes.subTitle}
            >
              {label3}
            </Typography>
            <TextField
              className={classes.helperText + ' ' + classes.formControl}
              id='name'
              name='name'
              label={label4}
              variant='outlined'
              helperText={helper1}
              type='text'
              fullWidth
              autoFocus={isLargeScreen}
              autoComplete='off'
              value={project.name ?? ''}
              onChange={(e) => {
                handleChange(e, project, setProject);
              }}
              inputProps={{
                maxLength: 150,
              }}
            />
          </Grid>
        </Grid>
      </Grid>

      <Grid
        container
        item
        xs={12}
        lg={isLarge ? 6 : 12}
        justifyContent={isLarge ? 'flex-end' : 'center'}
        className={classes.subSection}
      >
        <Grid item xs={12}>
          {isLarge && (
            <Typography
              variant='overline'
              color='textSecondary'
              className={classes.subTitle}
            >
              &nbsp;
            </Typography>
          )}
          <FormControl
            fullWidth
            className={classes.helperText + ' ' + classes.formControl}
          >
            <TextField
              id='amount'
              name='subsidyNeed'
              variant='outlined'
              type='currency'
              autoComplete='off'
              placeholder='€'
              label={label7}
              value={project.subsidyNeed ?? ''}
              onChange={(e) => {
                handleChange(e, project, setProject);
              }}
              InputProps={{
                inputComponent: NumberFormatCustom,
              }}
            />
            <FormHelperText id='outlined-amount-helper-text'>
              {helper2}
            </FormHelperText>
          </FormControl>
        </Grid>
      </Grid>

      <Grid
        container
        item
        xs={12}
        lg={isLarge ? 6 : 12}
        justifyContent={isLarge ? 'flex-start' : 'center'}
        className={classes.subSection}
      >
        <Grid container item xs={12}>
          <Grid item xs={12}>
            <TextField
              className={classes.helperText + ' ' + classes.formControl}
              id='description1'
              name='idea'
              label={label5}
              placeholder={placeholder1 ?? ''}
              variant='outlined'
              multiline
              rows={5}
              helperText={helper3}
              fullWidth
              autoComplete='off'
              value={project.idea ?? ''}
              onChange={(e) => {
                handleChange(e, project, setProject);
              }}
              inputProps={{
                maxLength: 1000,
                sx: {
                  '&::placeholder': {
                    fontSize: 14,
                  },
                },
              }}
            />
          </Grid>
        </Grid>
      </Grid>

      <Grid
        container
        item
        xs={12}
        lg={isLarge ? 6 : 12}
        justifyContent={isLarge ? 'flex-end' : 'center'}
        className={classes.subSection}
      >
        <Grid container item xs={12}>
          <Grid item xs={12}>
            <TextField
              className={classes.helperText + ' ' + classes.formControl}
              id='description2'
              name='problemStatement'
              label={label6}
              placeholder={placeholder2 ?? ''}
              variant='outlined'
              multiline
              rows={5}
              helperText={helper4}
              type='text'
              fullWidth
              autoComplete='off'
              value={project.problemStatement ?? ''}
              onChange={(e) => {
                handleChange(e, project, setProject);
              }}
              inputProps={{
                maxLength: 1000,
                sx: {
                  '&::placeholder': {
                    fontSize: 14,
                  },
                },
              }}
            />
          </Grid>
        </Grid>
      </Grid>

      <Grid container item xs={12}>
        <Grid
          container
          item
          xs={12}
          pb={3}
          px={1}
          justifyContent={isLarge ? 'flex-start' : 'center'}
          sx={{ maxWidth: !isLarge && '450px!important', margin: 'auto' }}
          // className={classes.subSection}
        >
          <FormGroup>
            <FormControlLabel
              control={
                <Switch
                  checked={showMoreDetails}
                  onChange={handleChangeSwitch}
                  inputProps={{ 'aria-label': 'controlled' }}
                />
              }
              label={label8}
            />
          </FormGroup>
        </Grid>
      </Grid>
      {showMoreDetails && (
        <Grid item xs={12}>
          <Divider className={classes.divider} />
        </Grid>
      )}
    </Grid>
  );
};

export default NewProjectBaseDetails;
