import React, { useState, createContext } from "react";

const UserContext = createContext([{}, () => {}]);

let initialState = {
  token: localStorage.getItem("partner-token"),
  user: localStorage.getItem("partner-user"),
};

const UserProvider = ({ children }) => {
  const [state, setState] = useState(initialState);

  return (
    <UserContext.Provider value={[state, setState]}>
      {children}
    </UserContext.Provider>
  );
};

export { UserContext, UserProvider };
