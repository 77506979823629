import React, { useState, useContext, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import BaseLayout from '../layouts/TopBarLevel1Layout';
import NewProjectSidebar from '../components/NewProject/NewProjectSidebar';
import SubsidiesListTopOptimized from '../components/NewProject/SubsidiesListTopOptimized';
import { NewProjectContext } from '../contexts/NewProject';

const NewProjectSubsidies = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const [progressStep, setProgressStep] = useState(3);
  const [projectProgress, setProjectProgress] = useState(3);
  const center = Math.round((100 / 5) * progressStep);
  const {
    setProjectType,
    setProjectProduct,
    setProjectInt,
    setProjectBusiness,
    setProjectEmployees,
    setPercentage,
    setNewProjectId,
    newProjectId,
    setIndustries,
    setActive,
    setExpand,
    setActivitiesInt,
    setActivities,
    setThemes,
    setTarget,
    showMoreDetails,
    setAccountId,
    accountId,
    setSectors,
    setSubSectors,
  } = useContext(NewProjectContext);

  const handleConfirm = () => {
    const backAccountId = accountId;
    setProjectProgress(0);
    setPercentage(0);
    setProjectType('');
    setNewProjectId('');
    setIndustries([]);
    setActive([]);
    setExpand([]);
    setActivitiesInt([]);
    setActivities([]);
    setThemes([]);
    setTarget([]);
    setAccountId('');
    setSectors([]);
    setSubSectors([]);
    setProjectProduct({
      name: '',
      idea: '',
      problemStatement: '',
      startDate: null,
      endDate: null,
      noOfRnDEmployees: '',
      rndHours: '',
      rndCosts: '',
      activitiesCarriedOutBy: '',
      industrySector: '',
      newness: '',
      projectFocus: '',
      preTRLLevel: '',
      postTRLLevel: '',
      subsidyNeed: '',
      projectTypeId: '',
      accountId: '',
      keySectors: '',
      subSectors: '',
      id: '',
    });
    setProjectInt({
      name: '',
      idea: '',
      problemStatement: '',
      startDate: null,
      endDate: null,
      countriesActive: '',
      countriesExpanding: '',
      activities: '',
      subsidyNeed: '',
      projectTypeId: '',
      accountId: '',
      id: '',
    });
    setProjectBusiness({
      name: '',
      idea: '',
      problemStatement: '',
      startDate: null,
      activities: '',
      subsidyNeed: '',
      projectTypeId: '',
      accountId: '',
      id: '',
    });
    setProjectEmployees({
      name: '',
      idea: '',
      problemStatement: '',
      learningWorkPrograms: '',
      investmentInEmployees: '',
      hiredWorkersAtDisadvantage: '',
      willHireWorkersAtDisadvantage: '',
      subsidyNeed: '',
      projectTypeId: '',
      accountId: '',
      id: '',
    });
    history.push(`/app/accounts/${backAccountId}`);
  };

  useEffect(() => {
    window.document.title = t('page_title.select_subsidies');
    window.scrollTo(0, 0);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <BaseLayout
        main={
          <>
            <SubsidiesListTopOptimized
              projectProgress={projectProgress}
              setProjectProgress={setProjectProgress}
              progressStep={progressStep}
              setProgressStep={setProgressStep}
              center={`${center}%`}
              showMoreDetail={showMoreDetails}
              newProjectId={newProjectId}
            />
          </>
        }
        sidebar={
          <NewProjectSidebar
            progressStep={progressStep}
            setProgressStep={setProgressStep}
            projectProgress={projectProgress}
            setProjectProgress={setProjectProgress}
            center={`${center}%`}
            showMoreDetails={showMoreDetails}
          />
        }
        dialog
        handleConfirm={handleConfirm}
        text={t('dialog.quit_new_project')}
        title={t('projects.card_new')}
      ></BaseLayout>
    </>
  );
};

export default NewProjectSubsidies;
