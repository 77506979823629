import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router } from 'react-router-dom';
import './index.css';
import { UserProvider } from './contexts/UserContext';
import App from './App';
import reportWebVitals from './reportWebVitals';
import './utils/i18n';

ReactDOM.render(
  <UserProvider>
    <Suspense fallback={null}>
      <Router onUpdate={() => window.scrollTo(0, 0)}>
        <App />
      </Router>
    </Suspense>
  </UserProvider>,
  document.getElementById('root')
);

reportWebVitals();
