import React, { useState, useEffect, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useStyles } from './styles';
import { useMediaQuery } from 'react-responsive';
import { Grid, Typography, Box } from '@mui/material';
import { UserContext } from '../../contexts/UserContext';
import Loader from '../../uiComponents/Loader';
import axios from 'axios';
import { useLocation } from 'react-router-dom';
import PopularSubsidiesTable from './PopularSubsidiesTable';
import OtherSubsidiesTable from './OtherSubsidiesTable';
import SubsidiesListFilter from './SubsidiesListFilter';
import AssignmentTable from './AssignmentTable';
import EuroIcon from '@mui/icons-material/Euro';
import RocketLaunchIcon from '@mui/icons-material/RocketLaunch';
import NoResultsScreen from '../../uiComponents/NoResultsScreen';
import { ReactComponent as SadFace } from '../../assets/Sad_face_TwoColor_1.svg';

const SubsidiesListOptimized = () => {
  const classes = useStyles();
  const isSmallScreen = useMediaQuery({ query: '(max-width: 767px)' });
  const { t } = useTranslation();
  const [userContext] = useContext(UserContext);
  const [loading, setLoading] = useState(false);
  const [popularSubsidies, setPopularSubsidies] = useState([]);
  const [selectedPopularSubsidies, setSelectedPopularSubsidies] = useState([]);
  const [otherSubsidies, setOtherSubsidies] = useState([]);
  const [selectedOtherSubsidies, setSelectedOtherSubsidies] = useState([]);
  const [assignments, setAssignments] = useState([]);
  const [selectedAssignments, setSelectedAssignments] = useState([]);
  // eslint-disable-next-line no-unused-vars

  let location = useLocation();

  useEffect(() => {
    setLoading(true);
    let id = location.pathname.substring(
      location.pathname.lastIndexOf('/') + 1
    );
    if (id !== 'projects') {
      let requestBody = {
        projectId: id,
      };
      const apiEndPoint =
        process.env.NODE_ENV === 'development'
          ? process.env.REACT_APP_API_ENDPOINT
          : process.env.REACT_APP_API_ENDPOINT_PROD;
      axios({
        method: 'post',
        url: apiEndPoint + '/v1/partner/projectscan/projectscanOptimized',
        data: requestBody,
        withCredentials: true,
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
          Authorization: `Bearer ${userContext.token}`,
        },
      })
        .then((res) => {
          setPopularSubsidies(res.data.popularFirstWithScore);
          setOtherSubsidies(res.data.otherSubsidiesWithScore);
          setAssignments(res.data.assignments);
          const newSelectedPopular = res.data.popularFirstWithScore.map(
            (n) => n.id
          );
          setSelectedPopularSubsidies(newSelectedPopular);
          const newSelectedAssignments = res.data.assignments.map(
            (n) => n.subsidyId.id
          );
          setSelectedAssignments(newSelectedAssignments);
          setLoading(false);
        })
        .catch((error) => {
          setLoading(false);
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const topPageRef = React.useRef();

  useEffect(() => {
    document.getElementById('anchor-div').scrollIntoView();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelectedPopular = popularSubsidies.map((n) => n.id);
      setSelectedPopularSubsidies(newSelectedPopular);
      const newSelectedother = otherSubsidies.map((n) => n.id);
      setSelectedOtherSubsidies(newSelectedother);
      const newSelectedAssignments = assignments.map((n) => n.subsidyId.id);
      setSelectedAssignments(newSelectedAssignments);
      return;
    }
    setSelectedOtherSubsidies([]);
    setSelectedPopularSubsidies([]);
    setSelectedAssignments([]);
  };

  return (
    <>
      {loading && <Loader loading={loading} />}
      <Grid
        container
        className={classes.root}
        style={
          isSmallScreen
            ? { paddingTop: 72, position: 'relative' }
            : { paddingTop: 96, position: 'relative' }
        }
      >
        <div
          ref={topPageRef}
          id='anchor-div'
          style={{ position: 'absolute', top: '-120px', left: '0' }}
        ></div>
        <Grid
          container
          item
          justifyContent='center'
          alignContent='flex-start'
          xs={12}
        >
          <Grid
            container
            item
            xs={12}
            justifyContent='space-between'
            className={classes.container}
          >
            <Grid item xs={12} className={classes.box}>
              <Box mb={2.5}>
                <Typography component='h6' variant='h6' align='center'>
                  {t('subsidies.title')}
                </Typography>
              </Box>
              <Box>
                <Typography
                  variant='body1'
                  color='textSecondary'
                  align='center'
                >
                  {t('subsidies.subtitle')}
                </Typography>
              </Box>
            </Grid>
            <Grid
              container
              item
              xs={12}
              className={classes.box + ' ' + classes.boxLastItem}
            >
              <SubsidiesListFilter
                hasSearch={true}
                loginAsUser={false}
                popularSubsidies={popularSubsidies}
                otherSubsidies={otherSubsidies}
                setPopularSubsidies={setPopularSubsidies}
                setOtherSubsidies={setOtherSubsidies}
                selectedPopularSubsidies={selectedPopularSubsidies}
                setSelectedPopularSubsidies={setSelectedPopularSubsidies}
                selectedOtherSubsidies={selectedOtherSubsidies}
                setSelectedOtherSubsidies={setSelectedOtherSubsidies}
                assignments={assignments}
                setAssignments={setAssignments}
                selectedAssignments={selectedAssignments}
                setSelectedAssignments={setSelectedAssignments}
                handleSelectAllClick={handleSelectAllClick}
              />
              {assignments && assignments.length > 0 && (
                <>
                  <Grid item xs={12} pt={1}>
                    <Grid container alignItems={'center'} py={2}>
                      <Grid item>
                        <EuroIcon
                          color='secondary'
                          sx={{ fontSize: 40, ml: 0 }}
                        />
                      </Grid>
                      <Grid item pl={2}>
                        <Typography variant='h6'>
                          {t('subsidies_dnd_list.potential_applications')}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                  <AssignmentTable
                    popularSubsidies={popularSubsidies}
                    setPopularSubsidies={setPopularSubsidies}
                    selectedPopularSubsidies={selectedPopularSubsidies}
                    setSelectedPopularSubsidies={setSelectedPopularSubsidies}
                    hasDelete={true}
                    loginAsUser={false}
                    hasAmount={true}
                    setLoading={setLoading}
                    otherSubsidies={otherSubsidies}
                    setOtherSubsidies={setOtherSubsidies}
                    selectedOtherSubsidies={selectedOtherSubsidies}
                    setSelectedOtherSubsidies={setSelectedOtherSubsidies}
                    assignments={assignments}
                    setAssignments={setAssignments}
                    selectedAssignments={selectedAssignments}
                    setSelectedAssignments={setSelectedAssignments}
                  />
                </>
              )}
              {popularSubsidies && popularSubsidies.length > 0 && (
                <>
                  <Grid item xs={12} pt={1}>
                    <Grid container alignItems={'center'} py={2}>
                      <Grid item>
                        <RocketLaunchIcon
                          color='secondary'
                          sx={{ fontSize: 40, ml: 0 }}
                        />
                      </Grid>
                      <Grid item pl={2}>
                        <Typography variant='h6'>
                          {t('subsidies_dnd_list.recommended_subsidies')}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                  <PopularSubsidiesTable
                    popularSubsidies={popularSubsidies}
                    setPopularSubsidies={setPopularSubsidies}
                    selectedPopularSubsidies={selectedPopularSubsidies}
                    setSelectedPopularSubsidies={setSelectedPopularSubsidies}
                    hasDelete={true}
                    loginAsUser={false}
                    hasAmount={true}
                    setLoading={setLoading}
                    otherSubsidies={otherSubsidies}
                    setOtherSubsidies={setOtherSubsidies}
                    selectedOtherSubsidies={selectedOtherSubsidies}
                    setSelectedOtherSubsidies={setSelectedOtherSubsidies}
                    assignments={assignments}
                    setAssignments={setAssignments}
                    selectedAssignments={selectedAssignments}
                    setSelectedAssignments={setSelectedAssignments}
                  />
                </>
              )}
              {otherSubsidies && otherSubsidies.length > 0 && (
                <>
                  <Grid item xs={12} pt={2} pb={2}>
                    {otherSubsidies &&
                      otherSubsidies.length > 0 &&
                      popularSubsidies.length > 0 && (
                        <Typography variant='h6'>
                          {t('subsidies_dnd_list.other_subsidies')}
                        </Typography>
                      )}
                  </Grid>
                  <OtherSubsidiesTable
                    otherSubsidies={otherSubsidies}
                    setOtherSubsidies={setOtherSubsidies}
                    selectedOtherSubsidies={selectedOtherSubsidies}
                    setSelectedOtherSubsidies={setSelectedOtherSubsidies}
                    hasDelete={true}
                    loginAsUser={false}
                    hasAmount={false}
                    setLoading={setLoading}
                    popularSubsidies={popularSubsidies}
                    setPopularSubsidies={setPopularSubsidies}
                    selectedPopularSubsidies={selectedPopularSubsidies}
                    setSelectedPopularSubsidies={setSelectedPopularSubsidies}
                    assignments={assignments}
                    setAssignments={setAssignments}
                    selectedAssignments={selectedAssignments}
                    setSelectedAssignments={setSelectedAssignments}
                  />
                </>
              )}
              {!loading &&
                assignments &&
                assignments.length === 0 &&
                otherSubsidies &&
                otherSubsidies.length === 0 &&
                popularSubsidies &&
                popularSubsidies.length === 0 && (
                  <>
                    <Grid item xs={12} pt={2} pb={2}>
                      <NoResultsScreen
                        title={t('select_subsidies.no_subsidies')}
                        subtitle={t('select_subsidies.no_subsidies_text')}
                        content={<SadFace />}
                        titleStyle={{
                          paddingBottom: 8,
                          justifyContent: 'center',
                        }}
                        subtitleStyle={{
                          paddingBottom: 8,
                          justifyContent: 'center',
                        }}
                        contentStyle={{
                          paddingBottom: 0,
                          justifyContent: 'center',
                        }}
                      />
                    </Grid>
                  </>
                )}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

export default SubsidiesListOptimized;
