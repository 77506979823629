import React from 'react';
import {
  Grid,
  Typography,
  Button,
  MenuItem,
  FormControl,
  Checkbox,
  FormControlLabel,
  Select,
  Divider,
} from '@mui/material';
import { account, kvk, fname, lname, email, phone, headers } from './utils';
import { WorkspaceContext } from '../../contexts/WorkspaceContext';

const UploadMap = (props) => {
  const {
    subtitle,
    col1,
    col2,
    col3,
    importBtn,
    cancelBtn,
    closeBtn,
    handleSubmit,
    setOpenMapLeadDialog,
    checkboxText,
  } = props;
  const { headerCols, csvToJSON, partialSuccess, partialRecords } =
    React.useContext(WorkspaceContext);

  const [disabledBtn, setDisabledBtn] = React.useState(false);
  const [checkboxVal, setCheckboxVal] = React.useState('No');

  const [results, setResults] = React.useState();
  const [orderedHeaders, setOrderedHeaders] = React.useState([]);
  const [mappedHeaders, setMappedHeaders] = React.useState({
    company: '',
    kvk: '',
    first_name: '',
    last_name: '',
    email: '',
    phone: '',
  });

  let reqBody = {
    company: '',
    kvk: '',
    first_name: '',
    last_name: '',
    email: '',
    phone: '',
  };

  const handleCheckbox = (name, value) => {
    if (value === true) {
      setCheckboxVal('Yes');
    } else {
      setCheckboxVal('No');
    }
  };
  function handleUnmap(key, value, keyToUnmap, index) {
    setMappedHeaders({
      ...mappedHeaders,
      [key]: value,
      [keyToUnmap]: 'Not Mapped',
    });
    switch (index) {
      case 0:
        setVal1('Not mapped');
        break;
      case 1:
        setVal2('Not mapped');
        break;
      case 2:
        setVal3('Not mapped');
        break;
      case 3:
        setVal4('Not mapped');
        break;
      case 4:
        setVal5('Not mapped');
        break;
      case 5:
        setVal6('Not mapped');
        break;
      default:
      //
    }
  }

  const [val1, setVal1] = React.useState(
    mappedHeaders.company ? mappedHeaders.company : ''
  );

  const handleChange1 = (event) => {
    let oldValues = Object.values(mappedHeaders);
    setVal1(event.target.value);
    setMappedHeaders({
      ...mappedHeaders,
      [event.target.name]: event.target.value,
    });

    let toUnmap = Object.values(mappedHeaders).indexOf(event.target.value);
    if (toUnmap !== -1) {
      let keyToUnmap = Object.keys(mappedHeaders).find(
        (k) => mappedHeaders[k] === oldValues[toUnmap]
      );
      handleUnmap(event.target.name, event.target.value, keyToUnmap, toUnmap);
    }
  };

  const [val2, setVal2] = React.useState(
    mappedHeaders.kvk ? mappedHeaders.kvk : 'Not mapped'
  );

  const handleChange2 = (event) => {
    let oldValues = Object.values(mappedHeaders);
    setVal2(event.target.value);
    if (event.target.value !== 'No data') {
      setMappedHeaders({
        ...mappedHeaders,
        kvk: event.target.value,
      });
    } else {
      setMappedHeaders({
        ...mappedHeaders,
        kvk: '',
      });
    }

    let toUnmap = Object.values(mappedHeaders).indexOf(event.target.value);
    if (toUnmap !== -1) {
      let keyToUnmap = Object.keys(mappedHeaders).find(
        (k) => mappedHeaders[k] === oldValues[toUnmap]
      );
      handleUnmap(event.target.name, event.target.value, keyToUnmap, toUnmap);
    }
  };
  const [val3, setVal3] = React.useState(
    mappedHeaders.first_name ? mappedHeaders.first_name : ''
  );

  const handleChange3 = (event) => {
    let oldValues = Object.values(mappedHeaders);
    setVal3(event.target.value);
    setMappedHeaders({
      ...mappedHeaders,
      first_name: event.target.value,
    });

    let toUnmap = Object.values(mappedHeaders).indexOf(event.target.value);
    if (toUnmap !== -1) {
      let keyToUnmap = Object.keys(mappedHeaders).find(
        (k) => mappedHeaders[k] === oldValues[toUnmap]
      );
      handleUnmap(event.target.name, event.target.value, keyToUnmap, toUnmap);
    }
  };
  const [val4, setVal4] = React.useState(
    mappedHeaders.last_name ? mappedHeaders.last_name : ''
  );

  const handleChange4 = (event) => {
    let oldValues = Object.values(mappedHeaders);
    setVal4(event.target.value);
    setMappedHeaders({
      ...mappedHeaders,
      last_name: event.target.value,
    });

    let toUnmap = Object.values(mappedHeaders).indexOf(event.target.value);
    if (toUnmap !== -1) {
      let keyToUnmap = Object.keys(mappedHeaders).find(
        (k) => mappedHeaders[k] === oldValues[toUnmap]
      );
      handleUnmap(event.target.name, event.target.value, keyToUnmap, toUnmap);
    }
  };
  const [val5, setVal5] = React.useState(
    mappedHeaders.email ? mappedHeaders.email : ''
  );

  const handleChange5 = (event) => {
    let oldValues = Object.values(mappedHeaders);
    setVal5(event.target.value);
    setMappedHeaders({
      ...mappedHeaders,
      email: event.target.value,
    });

    let toUnmap = Object.values(mappedHeaders).indexOf(event.target.value);
    if (toUnmap !== -1) {
      let keyToUnmap = Object.keys(mappedHeaders).find(
        (k) => mappedHeaders[k] === oldValues[toUnmap]
      );
      handleUnmap(event.target.name, event.target.value, keyToUnmap, toUnmap);
    }
  };
  const [val6, setVal6] = React.useState(
    mappedHeaders.phone ? mappedHeaders.phone : 'Not mapped'
  );

  const handleChange6 = (event) => {
    let oldValues = Object.values(mappedHeaders);
    setVal6(event.target.value);
    if (event.target.value !== 'No data') {
      setMappedHeaders({
        ...mappedHeaders,
        phone: event.target.value,
      });
    } else {
      setMappedHeaders({
        ...mappedHeaders,
        phone: '',
      });
    }

    let toUnmap = Object.values(mappedHeaders).indexOf(event.target.value);
    if (toUnmap !== -1) {
      let keyToUnmap = Object.keys(mappedHeaders).find(
        (k) => mappedHeaders[k] === oldValues[toUnmap]
      );
      handleUnmap(event.target.name, event.target.value, keyToUnmap, toUnmap);
    }
  };

  React.useEffect(() => {
    let handleheaders = ['', '', '', '', '', '', ...headerCols];
    let reqBody = {
      company: '',
      kvk: '',
      first_name: '',
      last_name: '',
      email: '',
      phone: '',
    };
    if (headerCols && headerCols.length > 0) {
      headerCols.forEach((item, i) => {
        if (account.includes(item.toLowerCase())) {
          handleheaders[0] = item;
          setVal1(item);
          reqBody.company = item;
        } else if (kvk.includes(item.toLowerCase())) {
          setVal2(item);
          handleheaders[1] = item;
          reqBody.kvk = item;
        } else if (fname.includes(item.toLowerCase())) {
          handleheaders[2] = item;
          reqBody.first_name = item;
          setVal3(item);
        } else if (lname.includes(item.toLowerCase())) {
          handleheaders[3] = item;
          reqBody.last_name = item;
          setVal4(item);
        } else if (email.includes(item.toLowerCase())) {
          handleheaders[4] = item;
          setVal5(item);
          reqBody.email = item;
        } else if (phone.includes(item.toLowerCase())) {
          handleheaders[5] = item;
          reqBody.phone = item;
          setVal6(item);
        }
      });
      var filtered = handleheaders.filter(function (el) {
        return el;
      });
      let uniques = Array.from(new Set(filtered));

      setOrderedHeaders([...uniques, 'Not mapped']);
      setMappedHeaders({ ...reqBody });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [headerCols]);

  React.useEffect(() => {
    let vals = { ...mappedHeaders };

    if (
      vals.company === 'Not Mapped' ||
      vals.first_name === 'Not Mapped' ||
      vals.last_name === 'Not Mapped' ||
      vals.email === 'Not Mapped' ||
      !mappedHeaders.company ||
      !mappedHeaders.first_name ||
      !mappedHeaders.last_name ||
      !mappedHeaders.email
    ) {
      setDisabledBtn(true);
    } else {
      setDisabledBtn(false);
    }

    let mapped = [...csvToJSON];
    let result = mapped.map(
      ({
        [mappedHeaders.company]: Company,
        [mappedHeaders.first_name]: FirstName,
        [mappedHeaders.last_name]: LastName,
        [mappedHeaders.email]: Email,
        [mappedHeaders.phone]: Phone,
        [mappedHeaders.kvk]: kvk,
      }) => ({ Company, FirstName, LastName, Email, Phone, kvk })
    );
    setResults(result);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mappedHeaders, orderedHeaders]);

  return (
    <>
      {headerCols && headerCols.length > 0 && (
        <Grid
          container
          key={headerCols}
          sx={{
            '*': {
              fontSize: 14,
            },
            maxHeight: 560,
          }}
        >
          {partialSuccess && partialRecords && partialRecords.length > 0 ? (
            <>
              {partialRecords &&
                partialRecords.length > 0 &&
                partialRecords.map((item, index) => (
                  <Grid
                    container
                    item
                    xs={12}
                    alignItems='center'
                    sx={{}}
                    key={index}
                  >
                    <Grid
                      item
                      xs={4}
                      sx={{ minWidth: 120, maxWidth: 150, padding: 2 }}
                    >
                      {item.email ? item.email : ''}
                    </Grid>
                    <Grid container item xs={12} sx={{}}>
                      <Divider sx={{ width: 1 }} />
                    </Grid>
                  </Grid>
                ))}
            </>
          ) : (
            <>
              <Grid container item xs={12} sx={{ pb: 4, fontSize: 16 }}>
                {subtitle}
              </Grid>
              <Grid
                container
                item
                xs={12}
                alignItems='center'
                sx={{ fontWeight: 700 }}
              >
                <Grid
                  item
                  xs={4}
                  sx={{ minWidth: 120, maxWidth: 150, padding: 2 }}
                >
                  {col2}
                </Grid>
                <Grid
                  item
                  xs={4}
                  sx={{ minWidth: 200, maxWidth: 200, padding: 2 }}
                >
                  {col1}
                </Grid>
                <Grid item xs={4} sx={{ padding: 2 }}>
                  {col3}
                </Grid>
              </Grid>

              <Grid container item xs={12} sx={{}}>
                <Divider sx={{ width: 1 }} />
              </Grid>

              <Grid container item xs={12} alignItems='center' sx={{}}>
                <Grid
                  item
                  xs={4}
                  sx={{ minWidth: 120, maxWidth: 150, padding: 2 }}
                >
                  {headers[0]}{' '}
                  <Typography component='span' sx={{ color: 'red' }}>
                    *
                  </Typography>
                </Grid>
                <Grid item xs={4} sx={{ px: 2 }}>
                  <FormControl fullWidth error={val1 === 'Not mapped'}>
                    <Select
                      variant='standard'
                      labelId='demo-simple-select-label'
                      id='demo-simple-select'
                      value={val1}
                      label=''
                      name={Object.keys(reqBody)[0]}
                      onChange={handleChange1}
                      sx={{
                        minWidth: 200,
                        maxWidth: 200,
                        color: (theme) => theme.palette.text.hint,
                      }}
                    >
                      {orderedHeaders &&
                        orderedHeaders.map(
                          (option, i) =>
                            option && (
                              <MenuItem
                                key={i}
                                value={option}
                                disabled={option === 'Not mapped'}
                              >
                                {option}
                              </MenuItem>
                            )
                        )}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={4} sx={{ padding: 2 }}>
                  {mappedHeaders.company
                    ? csvToJSON[0][mappedHeaders.company]
                    : ''}
                </Grid>
              </Grid>

              <Grid container item xs={12} sx={{}}>
                <Divider sx={{ width: 1 }} />
              </Grid>

              <Grid container item xs={12} alignItems='center' sx={{}}>
                <Grid
                  item
                  xs={4}
                  sx={{ minWidth: 120, maxWidth: 150, padding: 2 }}
                >
                  {headers[1]}
                </Grid>
                <Grid item xs={4} sx={{ px: 2 }}>
                  <FormControl fullWidth>
                    <Select
                      variant='standard'
                      labelId='demo-simple-select-label'
                      id='demo-simple-select'
                      value={val2 ? val2 : ''}
                      label=''
                      name={Object.keys(reqBody)[1]}
                      onChange={handleChange2}
                      sx={{
                        minWidth: 200,
                        maxWidth: 200,
                        color: (theme) => theme.palette.text.hint,
                      }}
                    >
                      {orderedHeaders &&
                        orderedHeaders.map(
                          (option, i) =>
                            option && (
                              <MenuItem key={i} value={option}>
                                {option}
                              </MenuItem>
                            )
                        )}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={4} sx={{ padding: 2 }}>
                  {mappedHeaders.kvk ? csvToJSON[0][mappedHeaders.kvk] : ''}
                </Grid>
              </Grid>

              <Grid container item xs={12} sx={{}}>
                <Divider sx={{ width: 1 }} />
              </Grid>

              <Grid container item xs={12} alignItems='center' sx={{}}>
                <Grid
                  item
                  xs={4}
                  sx={{ minWidth: 120, maxWidth: 150, padding: 2 }}
                >
                  {headers[2]}{' '}
                  <Typography component='span' sx={{ color: 'red' }}>
                    *
                  </Typography>
                </Grid>
                <Grid item xs={4} sx={{ px: 2 }}>
                  <FormControl fullWidth error={val3 === 'Not mapped'}>
                    <Select
                      key={JSON.stringify(mappedHeaders)}
                      variant='standard'
                      labelId='demo-simple-select-label'
                      id='demo-simple-select'
                      value={val3}
                      label=''
                      name={Object.keys(reqBody)[2]}
                      onChange={handleChange3}
                      sx={{
                        minWidth: 200,
                        maxWidth: 200,
                        color: (theme) => theme.palette.text.hint,
                      }}
                    >
                      {orderedHeaders &&
                        orderedHeaders.map(
                          (option, i) =>
                            option && (
                              <MenuItem
                                key={i}
                                value={option}
                                disabled={option === 'Not mapped'}
                              >
                                {option}
                              </MenuItem>
                            )
                        )}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={4} sx={{ padding: 2 }}>
                  {mappedHeaders.first_name
                    ? csvToJSON[0][mappedHeaders.first_name]
                    : ''}
                </Grid>
              </Grid>

              <Grid container item xs={12} sx={{}}>
                <Divider sx={{ width: 1 }} />
              </Grid>

              <Grid container item xs={12} alignItems='center' sx={{}}>
                <Grid
                  item
                  xs={4}
                  sx={{ minWidth: 120, maxWidth: 150, padding: 2 }}
                >
                  {headers[3]}{' '}
                  <Typography component='span' sx={{ color: 'red' }}>
                    *
                  </Typography>
                </Grid>
                <Grid item xs={4} sx={{ px: 2 }}>
                  <FormControl fullWidth error={val4 === 'Not mapped'}>
                    <Select
                      variant='standard'
                      labelId='demo-simple-select-label'
                      id='demo-simple-select'
                      value={val4}
                      label=''
                      name={Object.keys(reqBody)[3]}
                      onChange={handleChange4}
                      sx={{
                        minWidth: 200,
                        maxWidth: 200,
                        color: (theme) => theme.palette.text.hint,
                      }}
                    >
                      {orderedHeaders &&
                        orderedHeaders.map(
                          (option, i) =>
                            option && (
                              <MenuItem
                                key={i}
                                value={option}
                                disabled={option === 'Not mapped'}
                              >
                                {option}
                              </MenuItem>
                            )
                        )}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={4} sx={{ padding: 2 }}>
                  {mappedHeaders.last_name
                    ? csvToJSON[0][mappedHeaders.last_name]
                    : ''}
                </Grid>
              </Grid>

              <Grid container item xs={12} sx={{}}>
                <Divider sx={{ width: 1 }} />
              </Grid>

              <Grid container item xs={12} alignItems='center' sx={{}}>
                <Grid
                  item
                  xs={4}
                  sx={{ minWidth: 120, maxWidth: 150, padding: 2 }}
                >
                  {headers[4]}{' '}
                  <Typography component='span' sx={{ color: 'red' }}>
                    *
                  </Typography>
                </Grid>
                <Grid item xs={4} sx={{ px: 2 }}>
                  <FormControl fullWidth error={val5 === 'Not mapped'}>
                    <Select
                      variant='standard'
                      labelId='demo-simple-select-label'
                      id='demo-simple-select'
                      value={val5}
                      label=''
                      name={Object.keys(reqBody)[4]}
                      onChange={handleChange5}
                      sx={{
                        minWidth: 200,
                        maxWidth: 200,
                        color: (theme) => theme.palette.text.hint,
                      }}
                    >
                      {orderedHeaders &&
                        orderedHeaders.map(
                          (option, i) =>
                            option && (
                              <MenuItem
                                key={i}
                                value={option}
                                disabled={option === 'Not mapped'}
                              >
                                {option}
                              </MenuItem>
                            )
                        )}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={4} sx={{ padding: 2 }}>
                  {mappedHeaders.email ? csvToJSON[0][mappedHeaders.email] : ''}
                </Grid>
              </Grid>

              <Grid container item xs={12} sx={{}}>
                <Divider sx={{ width: 1 }} />
              </Grid>

              <Grid container item xs={12} alignItems='center' sx={{}}>
                <Grid
                  item
                  xs={4}
                  sx={{ minWidth: 120, maxWidth: 150, padding: 2 }}
                >
                  {headers[5]}
                </Grid>
                <Grid item xs={4} sx={{ px: 2 }}>
                  <FormControl fullWidth>
                    <Select
                      variant='standard'
                      labelId='demo-simple-select-label'
                      id='demo-simple-select'
                      value={val6 ? val6 : ''}
                      label=''
                      name={Object.keys(reqBody)[5]}
                      onChange={handleChange6}
                      sx={{
                        minWidth: 200,
                        maxWidth: 200,
                        color: (theme) => theme.palette.text.hint,
                      }}
                    >
                      {orderedHeaders &&
                        orderedHeaders.map(
                          (option, i) =>
                            option && (
                              <MenuItem key={i} value={option}>
                                {option}
                              </MenuItem>
                            )
                        )}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={4} sx={{ padding: 2 }}>
                  {mappedHeaders.phone ? csvToJSON[0][mappedHeaders.phone] : ''}
                </Grid>
              </Grid>

              <Grid container item xs={12} sx={{}}>
                <Divider sx={{ width: 1 }} />
              </Grid>

              <Grid container item xs={12} sx={{ padding: 2 }}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={checkboxVal !== 'No'}
                      name={'partnerParticipation'}
                      onChange={(e) => {
                        handleCheckbox(e.target.name, e.target.checked);
                      }}
                    />
                  }
                  label={
                    <Typography
                      variant='body2'
                      component='span'
                      display={'inline'}
                    >
                      {checkboxText}
                    </Typography>
                  }
                />
              </Grid>

              <Grid container item xs={12} sx={{}}>
                <Divider sx={{ width: 1 }} />
              </Grid>
            </>
          )}

          <Grid container item xs={12} sx={{ pt: 2, pr: 1 }}>
            <Button
              color='primary'
              onClick={(e) => {
                setOpenMapLeadDialog(false);
              }}
              sx={{
                ml: partialSuccess && 'auto',
                mb: partialSuccess && partialRecords.length >= 8 && 2,
              }}
            >
              {partialSuccess ? closeBtn : cancelBtn}
            </Button>
            {!partialSuccess && (
              <Button
                color='primary'
                onClick={(e) => {
                  handleSubmit(checkboxVal, results);
                }}
                disabled={disabledBtn || partialSuccess}
                sx={{ ml: 'auto', float: 'right' }}
              >
                {importBtn}
              </Button>
            )}
          </Grid>
        </Grid>
      )}
    </>
  );
};

export default UploadMap;
