import React from 'react';
// eslint-disable-next-line no-unused-vars
import { lighten } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import SortTableHead from './SortTableHead';
import NoResultsFound from './NoResultsFound';

import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
} from '@mui/material';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  paper: {
    width: '100%',
    marginBottom: theme.spacing(2),
  },
  table: {
    '& .MuiTableRow-hover': {},
  },
  tableRow: {
    cursor: 'pointer',
  },
  tableCell: {
    color: theme.palette.primary.main,
  },
}));

const DocumentsDownloadTable = (props) => {
  const classes = useStyles();
  const { data } = props;

  return (
    <div className={classes.root}>
      <TableContainer style={{}}>
        <Table
          className={classes.table}
          aria-labelledby='tableTitle'
          size={'medium'}
          aria-label='enhanced table'
        >
          {props.headCells && (
            <SortTableHead
              classes={classes}
              headCells={props.headCells}
              active={true}
            />
          )}
          {props.data.length > 0 ? (
            <TableBody>
              {data &&
                data.map((row, index) => {
                  return (
                    <TableRow
                      hover
                      tabIndex={-1}
                      key={row.id || index}
                      className={classes.tableRow}
                    >
                      <TableCell
                        id={row.name}
                        className={classes.tableCell}
                        onClick={() => {
                          props.handleDownloadDialogDocFile(
                            row.fileId,
                            row.filename,
                            row.mimeType
                          );
                        }}
                      >
                        <span title='Download'>{row.filename}</span>
                      </TableCell>
                    </TableRow>
                  );
                })}
            </TableBody>
          ) : (
            <NoResultsFound span={6} />
          )}
        </Table>
      </TableContainer>
    </div>
  );
};

export default DocumentsDownloadTable;
