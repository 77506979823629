export const chartTheme = {
  axis: {
    fontSize: '10px',
    tickColor: 'transparent',
    ticks: {
      line: {
        stroke: 'transparent',
      },
      text: {
        fill: '#A3A3A3',
        fontFamily: 'Lato',
        fontSize: 12,
      },
    },
    legend: {
      text: {
        fill: '#aaaaaa',
      },
    },
  },
  grid: {
    line: {
      stroke: '#F0F0F0',
    },
  },
  tooltip: {
    text: {
      fontSize: 12,
    },
  },
};
