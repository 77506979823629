import React, { useContext, useEffect, useState } from 'react';
import { withRouter } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import NumberFormat from 'react-number-format';
import { UserContext } from '../../contexts/UserContext';
import { WorkspaceContext } from '../../contexts/WorkspaceContext';
import { useIsMounted } from '../../utils/customHooks';
import { ReactComponent as DasboardImage } from '../../assets/dashboard.svg';
import EuroSymbolRoundedIcon from '@mui/icons-material/EuroSymbolRounded';
import Alert from '@mui/material/Alert';
import CloseIcon from '@mui/icons-material/Close';
import {
  Grid,
  Box,
  Typography,
  Card,
  CardContent,
  CardHeader,
  IconButton,
  Snackbar,
} from '@mui/material';
import { root, dashboardCard, hideImage } from '../styles';
import { useStyles } from './styles';
import { chartTheme } from './utils';
import axios from 'axios';
import Loader from '../../uiComponents/Loader';
import ComboChart from '../../uiComponents/chartComponents/ComboChart';
import LeadDialog from '../../uiComponents/LeadDialog';
import AddNewLead from '../Leads/AddNewLead';
import SendInvitation from '../Leads/SendInvitation';
import UploadMap from '../../uiComponents/uploadComponents/UploadMap';
import AddLeadButton from '../../uiComponents/AddLeadButton';

const DashboardDetails = () => {
  const { t } = useTranslation();
  const classes = useStyles();
  const isMounted = useIsMounted();
  const [userContext] = useContext(UserContext);
  const {
    leadsChartData,
    setLeadsChartData,
    dealsChartData,
    setDealsChartData,
    gmvData,
    setGmvData,
    headerCols,
    uploadError,
    setUploadError,
    uploadErrorMsg,
    setUploadErrorMsg,
    partialSuccess,
    setPartialSuccess,
    setPartialRecords,
  } = useContext(WorkspaceContext);

  const [loading, setLoading] = useState(false);

  const [openNewLeadDialog, setOpenNewLeadDialog] = useState(false);
  const [openMapLeadDialog, setOpenMapLeadDialog] = useState(false);
  const [openInvitationDialog, setOpenInvitationDialog] = useState(false);

  const [rowsInserted, setRowsInserted] = useState(0);
  const [successInserted, setSuccessInserted] = useState(false);

  const [firstScreen, setFirstScreen] = useState(true);
  // eslint-disable-next-line no-unused-vars
  const [refreshLeads, setRefreshLeads] = useState(false);

  const [successAddLead, setSuccessAddLead] = useState(false);
  const [successInvitation, setSuccessInvitation] = useState(false);
  const [errorInvitation, setErrorInvitation] = useState(false);

  const { setNewLead } = useContext(WorkspaceContext);

  const [anchorEl, setAnchorEl] = useState(null);
  const openAddMenu = Boolean(anchorEl);

  const handleClickAdd = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleCloseMenuAdd = () => {
    setAnchorEl(null);
  };

  const handleCloseSnackbar = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    if (partialSuccess === true) {
      setOpenMapLeadDialog(false);
    }
    setSuccessAddLead(false);
    setRefreshLeads(false);
    setUploadError(false);
    setRowsInserted(0);
    setUploadErrorMsg('');
    setPartialSuccess(false);
    setSuccessInserted(false);
    setSuccessInvitation(false);
    setErrorInvitation(false);
  };

  const handleCloseDialog = () => {
    setOpenNewLeadDialog(false);
    setOpenMapLeadDialog(false);
    setOpenInvitationDialog(false);
    setPartialSuccess(false);
    setPartialRecords([]);
    setFirstScreen(true);
    setNewLead({
      company: {
        name: '',
        dossier_number: '',
        establishment_number: '',
        establishment_city: '',
        establishment_street: '',
        correspondence_city: '',
        correspondence_street: '',
      },
      contact: {
        firstName: '',
        lastName: '',
        Email: '',
        phone: '',
        partnerParticipation: false,
        description: '',
      },
    });
  };

  let datas1 = Array.from(Array(12), (_, x) => {
    return { id: x + 1, value: 0 };
  });

  let datas2 = Array.from(Array(12), (_, x) => {
    return { id: x + 1, value: 0 };
  });

  let apiEndPoint =
    process.env.NODE_ENV === 'development'
      ? process.env.REACT_APP_API_ENDPOINT
      : process.env.REACT_APP_API_ENDPOINT_PROD;

  const handleUploadCsvData = (checkboxVal, results) => {
    setLoading(true);

    axios({
      method: 'post',
      url: apiEndPoint + '/v1/partner/leads/bulkUpload',
      withCredentials: true,
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        Authorization: `Bearer ${userContext.token}`,
      },
      data: {
        partnerParticipation: checkboxVal,
        records: results,
      },
    })
      .then((res) => {
        if (res.data.success) {
          setLoading(false);
          setOpenMapLeadDialog(false);
          setSuccessInserted(true);
          if (res.data.rowsInserted) {
            setRowsInserted(res.data.rowsInserted);
          }
        } else {
          setLoading(false);
          setOpenMapLeadDialog(false);
        }
        setLoading(false);
        setOpenMapLeadDialog(false);
      })
      .catch((error) => {
        if (isMounted.current) {
          if (error.response && error.response.status === 500) {
            if (error.response.data.success === false) {
              if (
                error.response.data.message ===
                'Email column is not a valid email adress'
              ) {
                setLoading(false);
                setUploadError(true);
                setUploadErrorMsg(t('leads.field_mapping.error2'));
                setOpenMapLeadDialog(false);
              } else if (
                error.response.data.message === 'Unexpected Error Occured'
              ) {
                setLoading(false);
                setUploadError(true);
                setUploadErrorMsg(t('leads.field_mapping.error3'));
                setOpenMapLeadDialog(false);
              } else if (
                error.response.data.message ===
                'Email already exists in our database'
              ) {
                setLoading(false);
                setOpenMapLeadDialog(false);
                setUploadError(true);
                setUploadErrorMsg(t('leads.field_mapping.error4'));
              }
            } else if (
              error.response.data.message ===
              'Some records failed. Email already exits in our database'
            ) {
              setLoading(false);
              setUploadError(true);
              setPartialSuccess(true);
              setPartialRecords(error.response.data.data);
              setUploadErrorMsg(
                t('leads.field_mapping.error5', {
                  records: error.response.data.data.length,
                })
              );
            }
          }
        }
        handleCloseMenuAdd();
        setLoading(false);
      });
  };

  const handleSendInvitation = (email) => {
    try {
      setLoading(true);

      axios({
        method: 'post',
        url: apiEndPoint + '/v1/partner/invite/sendReferralLink',
        withCredentials: true,
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
          Authorization: `Bearer ${userContext.token}`,
        },
        data: {
          email: email,
        },
      })
        .then((res) => {
          if (res.data.message === 'Email Sent' || res.data.success) {
            setSuccessInvitation(true);
          } else {
            setSuccessInvitation(false);
            setErrorInvitation(true);
          }
          setLoading(false);
        })
        .catch((error) => {
          if (isMounted.current) {
            setErrorInvitation(true);
          }
          setLoading(false);
        });
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    setLoading(true);
    let leads = [];
    let deals = [];

    axios({
      method: 'get',
      url: apiEndPoint + '/v1/partner/dashboard/getLeads',
      withCredentials: true,
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        Authorization: `Bearer ${userContext.token}`,
      },
    })
      .then((res) => {
        if (isMounted.current) {
          if (res.data && res.data.length > 0) {
            res.data.forEach((item, i) => {
              let { _id: id, count: value } = item;
              leads.push({ id, value });
            });
            leads.sort((a, b) => (a.id > b.id ? 1 : -1));
            let ids = leads.map((a) => a.id);
            datas1.forEach((item, i) => {
              if (ids.includes(item.id)) {
                item = leads[ids.indexOf(item.id)];
                datas1[i] = item;
              }
            });
          }
          setLeadsChartData(datas1);
          // setLoading(false);
        }
      })
      .then(() => {
        axios({
          method: 'get',
          url: apiEndPoint + '/v1/partner/dashboard/getDeals',
          withCredentials: true,
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
            Authorization: `Bearer ${userContext.token}`,
          },
        })
          .then((res) => {
            if (isMounted.current) {
              if (res.data && res.data.length > 0) {
                res.data.forEach((item) => {
                  let { month: id, count: value } = item;
                  deals.push({ id, value });
                });
                deals.sort((a, b) => (a.id > b.id ? 1 : -1));

                let ids = deals.map((a) => a.id);
                datas2.forEach((item, i) => {
                  if (ids.includes(item.id)) {
                    item = deals[ids.indexOf(item.id)];
                    datas2[i] = item;
                  }
                });
              }
              setDealsChartData(datas2);
            }
          })
          .then(() => {
            axios({
              method: 'get',
              url: apiEndPoint + '/v1/partner/dashboard/getGMVDetails',
              withCredentials: true,
              headers: {
                'Content-Type': 'application/json',
                Accept: 'application/json',
                Authorization: `Bearer ${userContext.token}`,
              },
            })
              .then((res) => {
                if (isMounted.current) {
                  setGmvData(res.data);
                  setLoading(false);
                }
              })
              .catch((error) => {
                if (isMounted.current) {
                  console.log(error);
                  setLoading(false);
                }
              });
          })
          .catch((error) => {
            if (isMounted.current) {
              console.log(error);
              setLoading(false);
            }
          });
      })
      .catch((error) => {
        if (isMounted.current) {
          console.log(error);
          setLoading(false);
        }
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (successAddLead || partialSuccess || successInserted) {
      setLoading(true);
      let leads = [];
      axios({
        method: 'get',
        url: apiEndPoint + '/v1/partner/dashboard/getLeads',
        withCredentials: true,
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
          Authorization: `Bearer ${userContext.token}`,
        },
      })
        .then((res) => {
          if (isMounted.current) {
            if (res.data && res.data.length > 0) {
              res.data.forEach((item, i) => {
                let { _id: id, count: value } = item;
                leads.push({ id, value });
              });
              leads.sort((a, b) => (a.id > b.id ? 1 : -1));
              let ids = leads.map((a) => a.id);
              datas1.forEach((item, i) => {
                if (ids.includes(item.id)) {
                  item = leads[ids.indexOf(item.id)];
                  datas1[i] = item;
                }
              });
            }
            setLeadsChartData(datas1);
            setLoading(false);
          }
        })

        .catch((error) => {
          if (isMounted.current) {
            console.log(error);
            setLoading(false);
          }
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [successAddLead, partialSuccess, successInserted]);

  useEffect(() => {
    window.document.title = t('page_title.dashboard');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      {loading && <Loader loading={loading} />}
      <Grid container className={classes.root}>
        <Grid
          container
          item
          alignContent={'center'}
          justifyContent='center'
          xs={12}
          sx={{
            ...root,
            height: 'auto',
            minHeight: 1,
            maxWidth: '1204px !important',
          }}
        >
          <Grid container item xs={12} sx={{ mb: 6, overflow: 'hidden' }}>
            <Grid
              item
              xs={12}
              md={6}
              justifyContent='center'
              sx={{ mx: 'auto' }}
            >
              <Box mb={2} mx={'auto'}>
                <Typography variant='h4' align={'center'} mx={2}>
                  {t('dashboard.welcome_user', {
                    name: userContext.user.firstName,
                  })}
                </Typography>
                <Typography
                  mx={2}
                  variant='body1'
                  align={'center'}
                  color='textSecondary'
                >
                  {t('login.text2')}
                </Typography>
              </Box>
            </Grid>
            <Grid
              container
              item
              md={6}
              justifyContent='center'
              sx={{ ...dashboardCard, ...hideImage }}
            >
              <Grid
                container
                item
                justifyContent='center'
                xs={12}
                sx={{ mx: 2 }}
              >
                <DasboardImage style={{ float: 'right' }} />
              </Grid>
            </Grid>
          </Grid>
          <Grid container item xs={12}>
            <Grid
              container
              item
              xs={12}
              md={6}
              justifyContent='center'
              sx={{ ...dashboardCard }}
            >
              <Card
                sx={{
                  py: 0,
                  mx: {
                    xs: 0,
                    sm: 2,
                  },
                  boxShadow: (theme) => theme.custom.boxshadow.boxShadow,
                  borderRadius: 2,
                  position: 'relative',
                }}
              >
                <CardHeader
                  sx={{
                    pb: 0,
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    zIndex: 1,
                  }}
                  title={
                    <Box display='flex' sx={{ alignItems: 'center', pl: 0 }}>
                      <>
                        <AddLeadButton
                          setOpenNewLeadDialog={setOpenNewLeadDialog}
                          setOpenMapLeadDialog={setOpenMapLeadDialog}
                          setOpenInvitationDialog={setOpenInvitationDialog}
                        />
                      </>
                    </Box>
                  }
                />
                <CardContent sx={{ px: 1, '&:last-child': {} }}>
                  <Grid
                    container
                    item
                    alignSelf='center'
                    sx={{
                      height: 1,
                      width: 1,
                      '& > div': {
                        minHeight: 1,
                        minWidth: 1,
                        '& > svg': {
                          minHeight: 1,
                          minWidth: 1,
                        },
                      },
                    }}
                  >
                    <Grid container item sx={{ width: 524, height: 280 }}>
                      <ComboChart
                        dealsChartData={dealsChartData}
                        leadsChartData={leadsChartData}
                        theme={chartTheme}
                      />
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>
            </Grid>
            <Grid
              container
              item
              xs={12}
              md={6}
              justifyContent='center'
              sx={{ ...dashboardCard }}
            >
              <Card
                sx={{
                  py: 0,
                  mx: {
                    xs: 0,
                    sm: 2,
                  },
                  boxShadow: (theme) => theme.custom.boxshadow.boxShadow,
                  borderRadius: 2,
                }}
              >
                <CardContent
                  sx={{ px: { xs: 3, sm: 0 }, height: 1, '&:last-child': {} }}
                >
                  <Grid
                    container
                    item
                    alignSelf='center'
                    sx={{
                      pb: 3,
                      pt: 2,
                      height: 1,
                      width: 1,
                      '& > div': {
                        minHeight: 1,
                        minWidth: 1,
                        '& > svg': {
                          minHeight: 1,
                          minWidth: 1,
                        },
                      },
                    }}
                  >
                    <Grid container item sx={{ width: 496, height: 230 }}>
                      <Grid container item alignItems='center'>
                        <Grid
                          container
                          item
                          xs={12}
                          sm={6}
                          justifyContent='center'
                          alignItems={'center'}
                        >
                          <Grid
                            container
                            item
                            xs={12}
                            alignItems='center'
                            justifyContent={{ xs: 'flex-start', sm: 'center' }}
                          >
                            <IconButton
                              sx={{
                                pointerEvents: 'none',
                                mr: 1,
                                backgroundColor: (theme) =>
                                  theme.palette.secondary.main,
                              }}
                            >
                              <EuroSymbolRoundedIcon
                                fontSize='small'
                                sx={{ color: 'white' }}
                              />
                            </IconButton>
                            <Typography
                              display='inline'
                              sx={{ fontSize: 32, fontWeight: 'bold' }}
                              color='text.primary'
                            >
                              <NumberFormat
                                value={gmvData.GMVInPipeline ?? 0}
                                displayType={'text'}
                                thousandSeparator={'.'}
                                decimalSeparator={','}
                                isNumericString
                                decimalScale={0}
                                fixedDecimalScale={true}
                              />
                            </Typography>
                          </Grid>
                          <Grid
                            container
                            item
                            xs={12}
                            alignItems='center'
                            justifyContent={{ xs: 'flex-start', sm: 'center' }}
                          >
                            <Typography sx={{ fontSize: 18, pb: 2 }}>
                              {t('dashboard.card_pipeline')}
                            </Typography>
                          </Grid>
                        </Grid>
                        <Grid
                          container
                          item
                          xs={12}
                          sm={6}
                          justifyContent='center'
                          alignItems={'center'}
                        >
                          <Grid
                            container
                            item
                            xs={12}
                            alignItems='center'
                            justifyContent={{ xs: 'flex-start', sm: 'center' }}
                          >
                            <IconButton
                              sx={{
                                pointerEvents: 'none',
                                mr: 1,
                                backgroundColor: (theme) =>
                                  theme.palette.secondary.main,
                              }}
                            >
                              <EuroSymbolRoundedIcon
                                fontSize='small'
                                sx={{ color: 'white' }}
                              />
                            </IconButton>
                            <Typography
                              display='inline'
                              sx={{ fontSize: 32, fontWeight: 'bold' }}
                              color='text.primary'
                            >
                              <NumberFormat
                                value={gmvData.GMVApplied ?? 0}
                                displayType={'text'}
                                thousandSeparator={'.'}
                                decimalSeparator={','}
                                isNumericString
                                decimalScale={0}
                                fixedDecimalScale={true}
                              />
                            </Typography>
                          </Grid>
                          <Grid
                            container
                            item
                            xs={12}
                            alignItems='center'
                            justifyContent={{ xs: 'flex-start', sm: 'center' }}
                          >
                            <Typography sx={{ fontSize: 18, pb: 2 }}>
                              {t('dashboard.card_applied')}
                            </Typography>
                          </Grid>
                        </Grid>
                      </Grid>
                      <Grid
                        container
                        item
                        direction='row'
                        alignItems='center'
                        alignContent='center'
                        justifyContent='center'
                      >
                        <Grid
                          container
                          item
                          xs={12}
                          alignItems='center'
                          alignContent='center'
                          justifyContent={{ xs: 'flex-start', sm: 'center' }}
                        >
                          <IconButton
                            sx={{
                              pointerEvents: 'none',
                              mr: 1,
                              backgroundColor: (theme) =>
                                theme.palette.secondary.main,
                            }}
                          >
                            <EuroSymbolRoundedIcon
                              fontSize='small'
                              sx={{
                                color: 'white',
                                // fontSize: { sm: '2.5rem' },
                              }}
                            />
                          </IconButton>
                          <Typography
                            display='inline'
                            sx={{
                              fontSize: { xs: 32 },
                              fontWeight: 'bold',
                              lineHeight: 1,
                              pb: 1,
                            }}
                            color='text.primary'
                          >
                            <NumberFormat
                              value={gmvData.GMVGranted ?? 0}
                              displayType={'text'}
                              thousandSeparator={'.'}
                              decimalSeparator={','}
                              isNumericString
                              decimalScale={0}
                              fixedDecimalScale={true}
                            />
                          </Typography>
                        </Grid>
                        <Grid
                          container
                          item
                          xs={12}
                          alignItems='center'
                          justifyContent={{ xs: 'flex-start', sm: 'center' }}
                        >
                          <Typography sx={{ fontSize: { xs: 18 }, pb: 3 }}>
                            {t('dashboard.card_granted')}
                          </Typography>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      <LeadDialog
        openDialog={openNewLeadDialog}
        setOpenDialog={setOpenNewLeadDialog}
        onClose={handleCloseDialog}
        hasTitle
        hasContent
        customMaxWidth={'md'}
        componentContent={
          <AddNewLead
            loading={loading}
            setLoading={setLoading}
            setOpenNewLeadDialog={setOpenNewLeadDialog}
            setFirstScreen={setFirstScreen}
            setRefreshLeads={setRefreshLeads}
            setSuccessAddLead={setSuccessAddLead}
          />
        }
        title={
          firstScreen
            ? t('leads.dialog_new_lead_title')
            : t('leads.dialog_new_lead_contact_title')
        }
      />

      <LeadDialog
        openDialog={openMapLeadDialog}
        setOpenDialog={setOpenMapLeadDialog}
        // onClose={handleCloseDialog}
        hasTitle
        hasContent
        customMaxWidth={'md'}
        componentContent={
          <UploadMap
            key={headerCols}
            col1={t('leads.field_mapping.col1')}
            col2={t('leads.field_mapping.col2')}
            col3={t('leads.field_mapping.col3')}
            error1={t('leads.field_mapping.error2')}
            error2={t('leads.field_mapping.error3')}
            error3={t('leads.field_mapping.error4')}
            error4={t('leads.field_mapping.error5')}
            error5={t('leads.field_mapping.error6')}
            error6={t('leads.field_mapping.error8')}
            importBtn={t('leads.field_mapping.import')}
            cancelBtn={t('labels.cancel')}
            closeBtn={t('labels.close')}
            setOpenMapLeadDialog={setOpenMapLeadDialog}
            subtitle={t('leads.field_mapping_subtitle')}
            checkboxText={t('leads.dialog_new_lead_checkbox')}
            handleSubmit={handleUploadCsvData}
          />
        }
        title={t('leads.field_mapping_title')}
      />

      <LeadDialog
        openDialog={openInvitationDialog}
        setOpenDialog={setOpenInvitationDialog}
        hasTitle
        hasContent
        customMaxWidth={'sm'}
        componentContent={
          <SendInvitation
            setOpenInvitationDialog={setOpenInvitationDialog}
            handleSendInvitation={handleSendInvitation}
            subtitle={t('leads.dialog_send_subtitle')}
            back={t('labels.cancel')}
            confirm={t('labels.send')}
          />
        }
        title={t('leads.dialog_send_title')}
      />

      <Snackbar
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        open={successAddLead}
        autoHideDuration={3000}
        onClose={handleCloseSnackbar}
        message={t('leads.success_new_lead')}
      />

      <Snackbar
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        open={successInserted}
        autoHideDuration={3000}
        onClose={handleCloseSnackbar}
        message={t('leads.field_mapping.success', { records: rowsInserted })}
      />

      <Snackbar
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        open={successInvitation}
        autoHideDuration={3000}
        onClose={handleCloseSnackbar}
        message={t('leads.success_send')}
      />

      <Snackbar
        open={uploadError}
        onClose={handleCloseSnackbar}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        style={{ top: 80 }}
        sx={{
          '@media screen and (min-width:1280px)': {},
        }}
      >
        <Alert
          severity='error'
          style={{ position: 'relative', paddingRight: 46 }}
        >
          {uploadErrorMsg}
          <IconButton
            size='small'
            aria-label='close'
            color='inherit'
            onClick={handleCloseSnackbar}
            style={{
              marginLeft: 10,
              position: 'absolute',
              right: 10,
              top: 10,
            }}
          >
            <CloseIcon fontSize='small' />
          </IconButton>
        </Alert>
      </Snackbar>

      <Snackbar
        open={errorInvitation}
        onClose={handleCloseSnackbar}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        style={{ top: 80 }}
      >
        <Alert
          severity='error'
          style={{ position: 'relative', paddingRight: 46 }}
        >
          {t('leads.error_send')}
          <IconButton
            size='small'
            aria-label='close'
            color='inherit'
            onClick={handleCloseSnackbar}
            style={{
              marginLeft: 10,
              position: 'absolute',
              right: 10,
              top: 10,
            }}
          >
            <CloseIcon fontSize='small' />
          </IconButton>
        </Alert>
      </Snackbar>
    </>
  );
};

export default withRouter(DashboardDetails);
