import React, { useState, useContext } from 'react';
import { useHistory } from 'react-router-dom';
import {
  Grid,
  Divider,
  Box,
  Typography,
  Button,
  FormControl,
  InputLabel,
  Select,
  OutlinedInput,
  MenuItem,
  TextField,
  Autocomplete,
  RadioGroup,
  FormControlLabel,
  Radio,
} from '@mui/material';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import { useStyles } from './styles';
import { theme as bdtheme } from '../../theme/BrightDesignTheme';
import { doughnutData } from '../../data/picklistOptions';
import NivoDoughnut from '../../uiComponents/NivoDoughnut';
import { useMediaQuery } from 'react-responsive';
import { useTranslation } from 'react-i18next';
import Loader from '../../uiComponents/Loader';
import { NewProjectContext } from '../../contexts/NewProject';
import {
  sectorOptionsNl,
  sectorOptionsEn,
  rechtsvormOptionss,
  mkbOptionss,
  provinceOptions,
} from '../../data/picklistOptions';
import { UserContext } from '../../contexts/UserContext';
import axios from 'axios';
import i18n from 'i18next';

const NewProjectAccountDetails = (props) => {
  const currentLanguage = i18n.languages[0];
  const classes = useStyles();
  const isLargeScreen = useMediaQuery({ query: '(min-width: 1280px)' });
  const isSmallScreen = useMediaQuery({ query: '(max-width: 767px)' });
  const { t } = useTranslation();
  const mkbOptions = mkbOptionss();
  const rechtsvormOptions = rechtsvormOptionss();
  const [sectorOptions, setSectorOptions] = useState([]);
  const [sectors, setSectors] = useState([]);
  const [loading, setLoading] = useState(false);
  const [disabled, setDisabled] = useState(false);
  const [userContext] = useContext(UserContext);

  const COLORS = [bdtheme.palette.primary.main, 'rgba(224, 224, 224, 1)'];
  const data = doughnutData(props.progressStep, props.showMoreDetails);

  const history = useHistory();
  const ROUTES = ['/app/newproject/choose'];

  const { newProjectAccount, setNewProjectAccount } =
    useContext(NewProjectContext);

  const handleNext = () => {
    setLoading(true);
    const apiEndPoint =
      process.env.NODE_ENV === 'development'
        ? process.env.REACT_APP_API_ENDPOINT
        : process.env.REACT_APP_API_ENDPOINT_PROD;
    let body = {
      account: newProjectAccount,
    };
    axios({
      method: 'post',
      url: apiEndPoint + '/v1/partner/project/updateAccount',
      withCredentials: true,
      data: body,
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        Authorization: `Bearer ${userContext.token}`,
      },
    })
      .then((response) => {
        setLoading(false);
        if (response.data.success) {
          history.push(ROUTES[0]);
        }
      })
      .catch((error) => {
        setLoading(false);
      });
  };

  React.useEffect(() => {
    if (sectors.length > 0) {
      let sectorValues = sectors.map((a) => a.value);
      setNewProjectAccount({
        ...newProjectAccount,
        keySectors: sectorValues.join(';'),
      });
    } else {
      setNewProjectAccount({
        ...newProjectAccount,
        keySectors: '',
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sectors]);

  React.useEffect(() => {
    if (
      newProjectAccount.legalEntity === null ||
      newProjectAccount.legalEntity === '' ||
      newProjectAccount.legalEntity === undefined ||
      newProjectAccount.sme === null ||
      newProjectAccount.sme === '' ||
      newProjectAccount.sme === undefined ||
      newProjectAccount.keySectors === null ||
      newProjectAccount.keySectors === '' ||
      newProjectAccount.keySectors === undefined ||
      newProjectAccount.province === null ||
      newProjectAccount.province === '' ||
      newProjectAccount.province === undefined
    ) {
      setDisabled(true);
    } else {
      setDisabled(false);
    }
  }, [newProjectAccount]);

  React.useEffect(() => {
    if (currentLanguage === 'nl') {
      setSectorOptions(sectorOptionsNl);
      if (newProjectAccount.keySectors) {
        let secVal;
        let sectorValues = newProjectAccount?.keySectors
          ? newProjectAccount.keySectors.split(';')
          : null;
        secVal = sectorOptionsNl.filter(
          (opt) => sectorValues.indexOf(opt.value) !== -1
        );
        setSectors(secVal);
      }
    } else {
      setSectorOptions(sectorOptionsEn);
      if (newProjectAccount.keySectors) {
        let secVal;
        let sectorValues = newProjectAccount?.keySectors
          ? newProjectAccount.keySectors.split(';')
          : null;
        secVal = sectorOptionsEn.filter(
          (opt) => sectorValues.indexOf(opt.value) !== -1
        );
        setSectors(secVal);
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      {loading && <Loader loading={loading} />}
      <Grid container justifyContent='center' className={classes.root}>
        <Grid
          container
          item
          xs={12}
          justifyContent='center'
          alignContent='center'
        >
          <Grid
            container
            item
            xs={12}
            justifyContent='center'
            alignContent='center'
            sx={{ maxWidth: '1024px !important' }}
          >
            <Grid container item xs={12} className={classes.section}>
              <Grid
                container
                item
                xs={12}
                justifyContent='center'
                className='title-section'
              >
                <Grid item xs={12} lg={12} className={classes.typeSection}>
                  <Box mb={1}>
                    <Typography variant='h6'>
                      {t('new_project_account.title')}
                    </Typography>
                  </Box>
                  <Box pb={2}>
                    <Typography
                      variant='body2'
                      className={classes.textDisabled}
                    >
                      {t('new_project_account.subtitle')}
                    </Typography>
                  </Box>
                </Grid>
              </Grid>
            </Grid>
            <Grid
              container
              alignItems={'flex-start'}
              className={'question-content'}
              spacing={4}
            >
              <Grid container item xs={12} md={6} lg={6}>
                <Grid
                  item
                  xs={12}
                  className={classes.questionFull}
                  id='q4'
                  pt={2}
                >
                  <Typography variant='h6' className={classes.titleFull}>
                    {t('questions.question4')}
                  </Typography>
                  <FormControl
                    variant='outlined'
                    className={classes.formControlFullPage}
                  >
                    <InputLabel id='province-label' shrink={true}>
                      {t('labels.province')}
                    </InputLabel>
                    <Select
                      id='province'
                      name='acc_province__c'
                      value={
                        newProjectAccount?.province
                          ? newProjectAccount.province
                          : null
                      }
                      input={
                        <OutlinedInput notched label={t('labels.province')} />
                      }
                      onChange={(event) => {
                        setNewProjectAccount({
                          ...newProjectAccount,
                          province: event.target.value,
                        });
                      }}
                    >
                      {provinceOptions.map((option, i) => (
                        <MenuItem key={i} value={option.value}>
                          {option.label}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
              </Grid>
              <Grid container item xs={12} md={6} lg={6}>
                <Grid
                  item
                  xs={12}
                  className={classes.questionFull}
                  id='q4'
                  pt={2}
                >
                  <Typography variant='h6' className={classes.titleFull}>
                    {t('questions.question3')}
                  </Typography>
                  <Autocomplete
                    multiple
                    value={sectors}
                    id='sectors'
                    options={sectorOptions}
                    getOptionLabel={(option) => option.label}
                    defaultValue={[]}
                    className={
                      classes.formControlFullPage +
                      ' ' +
                      classes.autocompleteMultiple
                    }
                    disableCloseOnSelect
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        variant='outlined'
                        label='Sectors'
                        placeholder=''
                        InputLabelProps={{
                          shrink: true,
                        }}
                      />
                    )}
                    onChange={(e, values) => {
                      setSectors(values);
                    }}
                  />
                </Grid>
              </Grid>
              <Grid container item xs={12} md={6} lg={6}>
                <Grid
                  item
                  xs={12}
                  className={classes.questionFull}
                  id='q4'
                  pt={2}
                >
                  <Typography variant='h6' className={classes.titleFull}>
                    {t('questions.question1')}
                  </Typography>
                  <FormControl
                    variant='outlined'
                    label='Rechtsvorm'
                    className={classes.formControlFullPage}
                  >
                    <InputLabel id='rechtsvorm-label' shrink={true}>
                      {t('labels.legal_form')}
                    </InputLabel>
                    <Select
                      id='rechtsvorm'
                      name='Rechtsvorm__c'
                      input={
                        <OutlinedInput notched label={t('labels.legal_form')} />
                      }
                      value={
                        newProjectAccount?.legalEntity
                          ? newProjectAccount.legalEntity
                          : null
                      }
                      onChange={(event) => {
                        setNewProjectAccount({
                          ...newProjectAccount,
                          legalEntity: event.target.value,
                        });
                      }}
                    >
                      {[
                        rechtsvormOptions[0],
                        ...rechtsvormOptions.slice(2, 4),
                        rechtsvormOptions[8],
                        rechtsvormOptions[1],
                      ].map((option, i) => (
                        <MenuItem key={i} value={option.value}>
                          {option.label}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
              </Grid>
              <Grid container item xs={12} md={6} lg={6}>
                <Grid
                  item
                  xs={12}
                  className={classes.questionFull}
                  id='q4'
                  pt={2}
                >
                  <Typography variant='h6' className={classes.titleFull}>
                    {t('questions.question2_3')}
                  </Typography>
                  <FormControl
                    component='fieldset'
                    className={classes.formControlFullPage}
                  >
                    <RadioGroup
                      aria-label={'mkb'}
                      name={'acc_sme__c'}
                      value={
                        newProjectAccount?.sme ? newProjectAccount.sme : null
                      }
                      onChange={(event) => {
                        setNewProjectAccount({
                          ...newProjectAccount,
                          sme: event.target.value,
                        });
                      }}
                    >
                      {mkbOptions.map((tile, i) => (
                        <FormControlLabel
                          key={i}
                          value={tile.value}
                          control={<Radio />}
                          label={tile.label}
                        ></FormControlLabel>
                      ))}
                    </RadioGroup>
                  </FormControl>
                </Grid>
              </Grid>
            </Grid>
            <Grid
              container
              item
              xs={12}
              justifyContent='center'
              className={classes.section + ' ' + classes.sectionMobile}
            >
              <Grid container item xs={12} className={classes.sectionButtons}>
                <Grid item xs={12}>
                  <Box mt={!isSmallScreen ? 6 : 0}>
                    <Divider />
                  </Box>
                </Grid>
                <Grid container item xs={12} className={classes.button}>
                  <>
                    {!isLargeScreen && (
                      <Grid item>
                        <div style={{ height: 40, width: 40 }}>
                          <NivoDoughnut
                            data={data}
                            center={props.center}
                            colors={COLORS}
                            smallFont
                          />
                        </div>
                      </Grid>
                    )}
                    <Grid item>
                      <Button
                        color='primary'
                        variant='contained'
                        label='Volgende'
                        endIcon={<KeyboardArrowRightIcon />}
                        onClick={handleNext}
                        disabled={disabled}
                      >
                        {t('buttons.next')}
                      </Button>
                    </Grid>
                  </>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

export default NewProjectAccountDetails;
