import React from 'react';
import i18n from '../../utils/i18n';
import { ReactComponent as DutchFlag } from '../../assets/077-netherlands.svg';
import { ReactComponent as GermanFlag } from '../../assets/077-germany.svg';
import { ReactComponent as EuFlag } from '../../assets/077-eu.svg';

export const MapCountryFlag = {
  Netherlands: <DutchFlag />,
  Germany: <GermanFlag />,
  Eu: <EuFlag />,
};

export const groupStatusMap = {
  Potentieel: { key: 0, label: 'Potentieel', value: 'Potentieel' },
  Concept: { key: 1, label: 'Concept', value: 'Concept' },
  'Klaar voor review': {
    key: 2,
    label: 'Klaar voor review',
    value: 'Klaar voor review',
  },
  'Wacht op akkoord': {
    key: 3,
    label: 'Wacht op akkoord',
    value: 'Wacht op akkoord',
  },
  'Klaar voor indienen': {
    key: 4,
    label: 'Klaar voor indienen',
    value: 'Klaar voor indienen',
  },
  Ingediend: { key: 5, label: 'Ingediend', value: 'Ingediend' },
  'In behandeling': {
    key: 6,
    label: 'In behandeling',
    value: 'In behandeling',
  },
  Beschikt: {
    key: 7,
    label: 'Beschikt',
    value: 'Beschikt;Geëffectueerd;Afgerond;Ruling;Ruling afgerond',
  },
  Geëffectueerd: { key: 8, label: 'Beschikt', value: 'Geëffectueerd' },
  Afgerond: { key: 9, label: 'Beschikt', value: 'Afgerond' },
  Afgewezen: { key: 10, label: 'Afgewezen', value: 'Afgewezen' },
  Ingetrokken: { key: 11, label: 'Ingetrokken', value: 'Ingetrokken' },
  Ruling: { key: 12, label: 'Beschikt', value: 'Ruling' },
  'Ruling afgerond': { key: 13, label: 'Beschikt', value: 'Ruling afgerond' },
};

export const groupStatusData = () => {
  return [
    {
      key: 0,
      label: i18n.t('application_list_status.potential'),
      value: 'Potentieel',
      selected: false,
    },
    {
      key: 1,
      label: i18n.t('application_list_status.concept'),
      value: 'Concept',
      selected: false,
    },
    {
      key: 2,
      label: i18n.t('application_list_status.ready_for_review'),
      value: 'Klaar voor review',
      selected: false,
    },
    {
      key: 3,
      label: i18n.t('application_list_status.waiting_for_approval'),
      value: 'Wacht op akkoord',
      selected: false,
    },
    {
      key: 4,
      label: i18n.t('application_list_status.ready_for_applying'),
      value: 'Klaar voor indienen',
      selected: false,
    },
    {
      key: 5,
      label: i18n.t('application_list_status.applied'),
      value: 'Ingediend',
      selected: false,
    },
    {
      key: 6,
      label: i18n.t('application_list_status.in_processing'),
      value: 'In behandeling',
      selected: false,
    },
    {
      key: 7,
      label: i18n.t('application_list_status.granted'),
      value: 'Beschikt;Geëffectueerd;Afgerond;Ruling;Ruling afgerond',
      selected: false,
    },
    {
      key: 8,
      label: i18n.t('application_list_status.rejected'),
      value: 'Afgewezen',
      selected: false,
    },
    {
      key: 9,
      label: i18n.t('application_list_status.withdrawn'),
      value: 'Ingetrokken',
      selected: false,
    },
  ];
};

export function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

export function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

export function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}
