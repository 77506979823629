import React from 'react';
import { grey } from '@mui/material/colors';
import {
  Box,
  Grid,
  Card,
  CardHeader,
  CardContent,
  Typography,
  Tooltip,
} from '@mui/material';
import BusinessIcon from '@mui/icons-material/Business';
import SimpleCardComponent from './SimpleCardComponent';
import useMediaQuery from '@mui/material/useMediaQuery';

const SectionCard = (props) => {
  const {
    minWidth,
    maxWidth,
    // eslint-disable-next-line no-unused-vars
    variant,
    data,
    noData,
    title,
    subtitle,
    locale,
    createLabel,
    closeLabel,
    pr,
    expanded,
    setExpanded,
    expandedId,
    setExpandedId,
  } = props;
  const isLargeScreen = useMediaQuery('(min-width:1280px)');
  return (
    <Box
      sx={{
        minWidth: 1,
        height: isLargeScreen ? '480px' : 1,
        pb: 0.5,
      }}
    >
      <Card
        variant={variant ? variant : 'outlined'}
        sx={{
          boxShadow: 'none',
          bgcolor: grey[50],
          borderRadius: 2,
          padding: 0,
          minWidth: minWidth ? minWidth : 236,
          maxWidth: maxWidth ? maxWidth : 256,
          height: 1,
        }}
      >
        <CardHeader
          title={
            <Tooltip title={subtitle && subtitle} placement='top'>
              <Typography>{title && title}</Typography>
            </Tooltip>
          }
          /* subheader={
            <Typography variant="body2" sx={{ color: 'text.hint' }}>
              {subtitle && subtitle}
            </Typography> 
          } */
        />
        <CardContent
          sx={{
            mt: 1,
            padding: 0,
            minHeight: '100% !important',
            overflow: 'auto !important',
            height: 1,
          }}
        >
          <Grid container alignContent={'flex-start'} sx={{}}>
            <Grid
              alignContent='center'
              container
              item
              xs={12}
              sx={{
                '@media screen and (min-width:997px)': {
                  mb: 12,
                },
                mb: 0,
              }}
            >
              {data &&
                data.length > 0 &&
                data.map((item, i) => {
                  return (
                    <Grid
                      key={i}
                      alignContent='center'
                      container
                      item
                      xs={12}
                      sx={{ px: 1, pb: 1 }}
                    >
                      <SimpleCardComponent
                        dateFormat={'dd MMM yyyy'}
                        locale={locale}
                        data={item}
                        pr={pr}
                        createLabel={createLabel}
                        closeLabel={closeLabel}
                        showMoreInfo={
                          item &&
                          (item.StageName === 'Closed Won' ||
                            item.StageName === 'Closed Lost' ||
                            item.StageName === 'Closed, no potential')
                        }
                        iconSide={<BusinessIcon sx={{ color: 'text.hint' }} />}
                        hasActions={true}
                        expanded={expanded}
                        setExpanded={setExpanded}
                        expandedId={expandedId}
                        setExpandedId={setExpandedId}
                      />
                    </Grid>
                  );
                })}
              {data && data.length === 0 && (
                <Grid
                  justifyContent='center'
                  container
                  item
                  xs={12}
                  sx={{ px: 1, pb: 1 }}
                >
                  <Typography align='center'>{noData}</Typography>
                </Grid>
              )}
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </Box>
  );
};

export default SectionCard;
