import React, { useState, useEffect, useContext } from 'react';
import { useHistory, withRouter } from 'react-router-dom';
import {
  Grid,
  Divider,
  Typography,
  TextField,
  OutlinedInput,
  Select,
  InputLabel,
  MenuItem,
  FormControl,
  FormControlLabel,
  RadioGroup,
  Radio,
  FormHelperText,
  FormGroup,
  Checkbox,
  FormLabel,
  Button,
  Slider,
} from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import { theme as bdtheme } from '../../theme/BrightDesignTheme';
import { newProjectDoughnutData } from '../../data/picklistOptions';
import NivoDoughnut from '../../uiComponents/NivoDoughnut';
import { useMediaQuery } from 'react-responsive';
import { useStyles } from './styles';
import trl from '../../assets/trl.png';
import { ReactComponent as SadFace } from '../../assets/Sad_face_TwoColor_1.svg';
import { useTranslation } from 'react-i18next';
import { handleChange, NumberFormatCustom } from '../../JS/FormControls';
import { marksTrl } from '../../data/projectOptions';
import { NewProjectContext } from '../../contexts/NewProject';
import { UserContext } from '../../contexts/UserContext';
import axios from 'axios';
import Loader from '../../uiComponents/Loader';
import NoResultsDialog from '../../uiComponents/NoResultsDialog';
import CustomDialog from '../../uiComponents/CustomDialog';
import NewProductBaseDetails from './NewProductBaseDetails';

const NewProjectTypeProductOptimized = (props) => {
  const classes = useStyles();
  const history = useHistory();
  const isLarge = useMediaQuery({ query: '(min-width: 1280px)' });
  const isLargeScreen = useMediaQuery({ query: '(min-width: 1280px)' });
  const isSmall = useMediaQuery({ query: '(max-width: 767px)' });
  const { t } = useTranslation();

  const COLORS = [bdtheme.palette.primary.main, 'rgba(224, 224, 224, 1)'];
  const data = newProjectDoughnutData(
    props.progressStep,
    props.showMoreDetails
  );
  const {
    projectProduct,
    setProjectProduct,
    projectType,
    setNewProjectId,
    industries,
    setIndustries,
    newProjectId,
    setPopularSubsidies,
    setOtherSubsidies,
    setAssignments,
    setSelectedPopularSubsidies,
    setSelectedAssignments,
    accountId,
    sectors,
    setSectors,
    subSectors,
    setSubSectors,
    newProjectAccount,
  } = useContext(NewProjectContext);
  const [loading, setLoading] = useState(false);
  const [userContext] = useContext(UserContext);

  const { showMoreDetails, setShowMoreDetails } = props;

  const [openDialogReset, setOpenDialogReset] = useState(false);

  const [disabled, setDisabled] = useState(true);
  const [nosubsidies, setNosubsidies] = useState(false);

  // multiselect checkbox
  const [focusValues, setFocusValues] = useState(props.focusOptions);
  const [vals, setVals] = useState([]);

  // slider employees and Trl
  const [valueRnd, setValueRnd] = useState(0);

  const handleSliderRnd = (event, newValue) => {
    setValueRnd(newValue);
    setProjectProduct({
      ...projectProduct,
      noOfRnDEmployees: newValue,
    });
  };

  const [valueTrl, setValueTrl] = useState(null);

  const handleSliderTrl = (event, newValue) => {
    setValueTrl(newValue);
    setProjectProduct({
      ...projectProduct,
      preTRLLevel: valueLabelFormat(newValue),
    });
  };

  function valueLabelFormat(value) {
    const x = marksTrl.findIndex((mark, i) => mark.value === value);
    return marksTrl[parseInt(x)].label;
  }

  function preventHorizontalKeyboardNavigation(event) {
    if (event.key === 'ArrowLeft' || event.key === 'ArrowRight') {
      event.preventDefault();
    }
  }

  const handleChangeSwitch = (event) => {
    if (!event.target.checked) {
      if (
        projectProduct.subsidyNeed ||
        projectProduct.noOfRnDEmployees >= 0 ||
        projectProduct.rndHours ||
        projectProduct.rndCosts ||
        projectProduct.activitiesCarriedOutBy ||
        industries.length > 0 ||
        projectProduct.newness ||
        projectProduct.projectFocus ||
        projectProduct.internalResources ||
        projectProduct.externalResources ||
        projectProduct.preTRLLevel
      ) {
        setOpenDialogReset(true);
      } else {
        setShowMoreDetails(event.target.checked);
      }
    } else {
      setShowMoreDetails(event.target.checked);
    }
  };

  const handleConfirmReset = () => {
    setProjectProduct({
      ...projectProduct,
      noOfRnDEmployees: '',
      rndHours: '',
      rndCosts: '',
      activitiesCarriedOutBy: '',
      industrySector: '',
      newness: '',
      projectFocus: '',
      preTRLLevel: '',
      internalResources: '',
      externalResources: '',
      subsidyNeed: '',
    });
    setShowMoreDetails(false);
    setFocusValues(props.focusOptions);
    setIndustries([]);
    setVals([]);

    if (newProjectId) {
      try {
        setLoading(true);
        let vals = industries.map((a) => a.value);
        projectProduct.industrySector = vals.join(';');

        let valsSector = sectors.map((a) => a.value);
        projectProduct.keySectors = valsSector.join(';');

        let valsSubSector = subSectors.map((a) => a.value);
        projectProduct.subSectors = valsSubSector.join(';');

        const apiEndPoint =
          process.env.NODE_ENV === 'development'
            ? process.env.REACT_APP_API_ENDPOINT
            : process.env.REACT_APP_API_ENDPOINT_PROD;

        let body = {
          projectId: newProjectId,
        };
        axios({
          method: 'post',
          url: apiEndPoint + '/v1/partner/project/resetProjectFields',
          withCredentials: true,
          data: body,
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
            Authorization: `Bearer ${userContext.token}`,
          },
        })
          .then((response) => {
            setLoading(false);
          })
          .catch((error) => {
            console.log('error', error);
            setLoading(false);
          });
      } catch (err) {}
    }
  };

  const handleChangeFocus = (e, opt) => {
    opt.checked = e.target.checked;
    setFocusValues([...focusValues]);
    if (e.target.checked) {
      vals.push(opt.value);
    } else {
      vals.splice(vals.indexOf(opt.value), 1);
    }
    setVals(vals);
    setProjectProduct({
      ...projectProduct,
      projectFocus: [...vals].join(';'),
    });
  };

  const ROUTES = [
    '/app/newproject/choose',
    '/app/newproject/upload',
    '/app/newproject/subsidies',
  ];
  const handleNext = () => {
    setLoading(true);
    let vals = industries.map((a) => a.value);
    projectProduct.industrySector = vals.join(';');

    let valsSector = sectors.map((a) => a.value);
    projectProduct.keySectors = valsSector.join(';');

    let valsSubSector = subSectors.map((a) => a.value);
    projectProduct.subSectors = valsSubSector.join(';');

    let body = {
      Project: projectProduct,
      id: newProjectId,
      accountId: accountId,
    };

    const apiEndPoint =
      process.env.NODE_ENV === 'development'
        ? process.env.REACT_APP_API_ENDPOINT
        : process.env.REACT_APP_API_ENDPOINT_PROD;

    // projectscanOptimized
    axios({
      method: 'post',
      url: apiEndPoint + '/v1/partner/project/addProject',
      data: body,
      withCredentials: true,
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        Authorization: `Bearer ${userContext.token}`,
      },
    })
      .then((res) => {
        setNewProjectId(res.data.projectId);
        let reqBody = {
          projectId: res.data.projectId,
        };
        axios({
          method: 'post',
          url: apiEndPoint + '/v1/partner/projectscan/projectscanOptimized',
          data: reqBody,
          withCredentials: true,
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
            Authorization: `Bearer ${userContext.token}`,
          },
        })
          .then((res) => {
            setLoading(false);
            props.setProgressStep(props.progressStep + 1);
            setPopularSubsidies(res.data.popularFirstWithScore);
            setOtherSubsidies(res.data.otherSubsidiesWithScore);
            setAssignments(res.data.assignments);
            const newSelectedPopular = res.data.popularFirstWithScore.map(
              (n) => n.id
            );
            setSelectedPopularSubsidies(newSelectedPopular);
            const newSelectedAssignments = res.data.assignments.map(
              (n) => n.subsidyId.id
            );
            setSelectedAssignments(newSelectedAssignments);
            showMoreDetails ? history.push(ROUTES[2]) : history.push(ROUTES[1]);
          })
          .catch((error) => {
            setLoading(false);
          });
      })
      .catch((error) => {
        setLoading(false);
      });
  };

  const handleBack = () => {
    if (props.progressStep !== 0) {
      history.push(ROUTES[0]);
    }
  };

  useEffect(() => {
    if (
      projectProduct.name &&
      projectProduct.idea &&
      projectProduct.problemStatement &&
      projectProduct.intendedStartDate &&
      projectProduct.projectTypeId &&
      sectors.length > 0 &&
      subSectors.length > 0
    ) {
      if (!showMoreDetails) {
        setDisabled(false);
      } else if (
        projectProduct.noOfRnDEmployees !== undefined &&
        projectProduct.noOfRnDEmployees !== '' &&
        projectProduct.rndHours &&
        projectProduct.rndCosts &&
        projectProduct.activitiesCarriedOutBy &&
        industries.length > 0 &&
        projectProduct.newness &&
        projectProduct.projectFocus &&
        projectProduct.preTRLLevel
      ) {
        setDisabled(false);
      } else {
        setDisabled(true);
      }
    } else {
      setDisabled(true);
    }
  }, [projectProduct, industries, vals, showMoreDetails, sectors, subSectors]);

  useEffect(() => {
    let vals1;
    if (projectProduct.industrySector) {
      let selVals = projectProduct.industrySector.split(';');
      vals1 = props.industryOptions.filter(
        (opt) => selVals.indexOf(opt.value) !== -1
      );
      setIndustries(vals1);
    }

    let valsSector;
    if (projectProduct.keySectors) {
      let selVals = projectProduct.keySectors.split(';');
      valsSector = props.sectorOptions.filter(
        (opt) => selVals.indexOf(opt.value) !== -1
      );
      setSectors(valsSector);
    }

    let valsSubSector;
    if (projectProduct.subSectors) {
      let selVals = projectProduct.subSectors.split(';');
      valsSubSector = props.subSectorOptions.filter(
        (opt) => selVals.indexOf(opt.value) !== -1
      );
      setSubSectors(valsSubSector);
    }

    if (projectProduct.projectFocus) {
      let selectedVals = projectProduct.projectFocus.split(';');
      focusValues.map((val) => {
        if (selectedVals.length > 0 && selectedVals.indexOf(val.value) !== -1) {
          val.checked = true;
        } else {
          val.checked = false;
        }
        return val;
      });
      setVals(selectedVals);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!projectProduct.projectTypeId || !projectProduct.accountId) {
      setProjectProduct({
        ...projectProduct,
        projectTypeId: projectType,
        accountId: accountId,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [projectProduct, industries, sectors, subSectors, vals, showMoreDetails]);

  useEffect(() => {
    if (!projectProduct.keySectors) {
      if (
        newProjectAccount.keySectors !== null ||
        newProjectAccount.keySectors !== ''
      ) {
        let vals1;
        let selVals = newProjectAccount.keySectors.split(';');
        vals1 = props.sectorOptions.filter(
          (opt) => selVals.indexOf(opt.value) !== -1
        );
        setSectors(vals1);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (
      projectProduct.subsidyNeed ||
      (projectProduct.hasOwnProperty('noOfRnDEmployees') &&
        projectProduct?.noOfRnDEmployees !== '' &&
        projectProduct?.noOfRnDEmployees !== 0 &&
        projectProduct?.noOfRnDEmployees !== undefined) ||
      projectProduct.rndHours ||
      projectProduct.rndCosts ||
      projectProduct.activitiesCarriedOutBy ||
      industries.length > 0 ||
      projectProduct.newness ||
      projectProduct.projectFocus ||
      projectProduct.preTRLLevel ||
      projectProduct.internalResources ||
      projectProduct.externalResources
    ) {
      setShowMoreDetails(true);
    } else {
      setShowMoreDetails(false);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    projectProduct.subsidyNeed,
    projectProduct.noOfRnDEmployees,
    projectProduct.rndHours,
    projectProduct.rndCosts,
    projectProduct.activitiesCarriedOutBy,
    industries,
    projectProduct.newness,
    projectProduct.projectFocus,
    projectProduct.preTRLLevel,
    projectProduct.internalResources,
    projectProduct.externalResources,
  ]);

  useEffect(() => {
    let vals1;
    if (projectProduct.industrySector) {
      let selVals = projectProduct.industrySector.split(';');
      vals1 = props.industryOptions.filter(
        (opt) => selVals.indexOf(opt.value) !== -1
      );
      setIndustries(vals1);
    }

    let valsSector;
    if (projectProduct.keySectors) {
      let selVals = projectProduct.keySectors.split(';');
      valsSector = props.sectorOptions.filter(
        (opt) => selVals.indexOf(opt.value) !== -1
      );
      setSectors(valsSector);
    }

    let valsSubSector;
    if (projectProduct.subSectors) {
      let selVals = projectProduct.subSectors.split(';');
      valsSubSector = props.subSectorOptions.filter(
        (opt) => selVals.indexOf(opt.value) !== -1
      );
      setSubSectors(valsSubSector);
    }

    if (projectProduct.projectFocus) {
      let selectedVals = projectProduct.projectFocus.split(';');
      focusValues.map((val) => {
        if (selectedVals.length > 0 && selectedVals.indexOf(val.value) !== -1) {
          val.checked = true;
        } else {
          val.checked = false;
        }
        return val;
      });
      setVals(selectedVals);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    projectProduct.industrySector,
    projectProduct.projectFocus,
    projectProduct.keySectors,
    projectProduct.subSectors,
  ]);

  useEffect(() => {
    if (projectProduct.preTRLLevel) {
      try {
        let x = projectProduct.preTRLLevel;
        let labels = marksTrl.map((item) => item.label);
        let i = labels.indexOf(x);
        setValueTrl(i);
      } catch (err) {}
    }
    if (
      projectProduct.noOfRnDEmployees >= 0 &&
      projectProduct.noOfRnDEmployees !== undefined &&
      projectProduct.noOfRnDEmployees !== ''
    ) {
      try {
        setValueRnd(projectProduct.noOfRnDEmployees);
      } catch (err) {}
    } else {
      setProjectProduct({
        ...projectProduct,
        noOfRnDEmployees: 0,
      });
      setValueRnd(0);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [projectProduct.preTRLLevel, projectProduct.noOfRnDEmployees]);

  return (
    <>
      {loading && <Loader loading={loading} />}
      <Grid container justifyContent='center' className={classes.root}>
        <Grid
          container
          item
          xs={12}
          justifyContent='center'
          alignContent='center'
        >
          {/*<>SECTION</>*/}

          <NewProductBaseDetails
            classes={classes}
            isLarge={isLarge}
            isLargeScreen={isLargeScreen}
            project={projectProduct}
            setProject={setProjectProduct}
            handleChange={handleChange}
            showMoreDetails={showMoreDetails}
            handleChangeSwitch={handleChangeSwitch}
            label1={t('project-details.title')}
            label2={t('project-details.subtitle')}
            label3={t('project-details.project')}
            label4={t('labels.project_name')}
            label5={t('projectX.label2')}
            label6={t('projectX.label3')}
            label7={t('project-details.intended_start')}
            label8={t('project-details.more_details')}
            helper1={t('helper_text.project_name')}
            helper2={t('helper_text.subsidie')}
            helper3={t('helper_text.description1')}
            helper4={t('helper_text.description2')}
            placeholder1={t('project-details.placeholder_idea_1')}
            placeholder1_1={t('project-details.placeholder_idea_2')}
            placeholder1_2={t('project-details.placeholder_idea_3')}
            placeholder1_3={t('project-details.placeholder_idea_4')}
            placeholder2={t('project-details.placeholder_solution_1')}
            sectorOptions={props.sectorOptions}
            subSectorOptions={props.subSectorOptions}
            sectors={sectors}
            setSectors={setSectors}
            subSectors={subSectors}
            setSubSectors={setSubSectors}
          />

          {/*<>SECTION DETAILS START</>*/}
          {showMoreDetails && (
            <Grid container item xs={12} className={classes.section}>
              <Grid container item xs={12}>
                <Grid
                  container
                  item
                  xs={12}
                  lg={isLarge ? 6 : 12}
                  justifyContent={isLarge ? 'flex-start' : 'center'}
                  className={classes.subSection}
                >
                  {/*<>RESEARCH</>*/}
                  <Grid container item xs={12}>
                    <Grid item xs={12}>
                      <Typography
                        variant='overline'
                        color='textSecondary'
                        className={classes.subTitle}
                      >
                        {t('project-details.randd')}
                      </Typography>
                    </Grid>
                    <Grid item xs={12} mb={4}>
                      <Typography
                        mb={4}
                        sx={{ pb: 3 }}
                        variant='body2'
                        color='textSecondary'
                        className={classes.subTitle}
                      >
                        {t('project-details.employees_subtitle')}
                      </Typography>
                      <Slider
                        value={valueRnd}
                        onChange={handleSliderRnd}
                        aria-label='Default'
                        valueLabelDisplay='on'
                        color={'primary'}
                        max={250}
                        track={false}
                        step={1}
                        sx={{
                          width: !isLargeScreen ? '80%' : '100%',
                          ml: !isLargeScreen ? '32px' : '0px',
                        }}
                      />
                    </Grid>

                    <Grid item xs={12}>
                      <FormControl
                        variant='outlined'
                        label={'R&D ' + t('labels.hours')}
                        className={
                          classes.helperText + ' ' + classes.formControl
                        }
                      >
                        <FormLabel component='legend' id='randdHours-label'>
                          {'R&D ' +
                            t('labels.hours') +
                            t('project-details.rnd_addition')}
                        </FormLabel>
                        <RadioGroup
                          aria-label={'randdActivity'}
                          id='randdHours'
                          name='rndHours'
                          value={projectProduct.rndHours}
                          onChange={(e) => {
                            handleChange(e, projectProduct, setProjectProduct);
                          }}
                          sx={{ flexDirection: 'row !important' }}
                        >
                          {props.rdHoursOptions.map((tile, i) => (
                            <FormControlLabel
                              key={i}
                              value={tile.value}
                              control={<Radio />}
                              label={tile.label}
                              sx={{ width: '45% !important' }}
                            ></FormControlLabel>
                          ))}
                        </RadioGroup>

                        <FormHelperText>
                          {t('helper_text.select1')}
                        </FormHelperText>
                      </FormControl>
                    </Grid>
                    <Grid item xs={12}>
                      <FormControl
                        variant='outlined'
                        label={'R&D ' + t('labels.costs')}
                        className={
                          classes.helperText + ' ' + classes.formControl
                        }
                      >
                        <FormLabel component='legend' id='randdCost-label'>
                          {'R&D ' +
                            t('labels.costs') +
                            t('project-details.rnd_addition')}
                        </FormLabel>
                        <RadioGroup
                          aria-label={'randdActivity'}
                          id='randdCost'
                          name='rndCosts'
                          value={projectProduct.rndCosts}
                          onChange={(e) => {
                            handleChange(e, projectProduct, setProjectProduct);
                          }}
                          sx={{ flexDirection: 'row !important' }}
                        >
                          {props.rdCostOptions.map((tile, i) => (
                            <FormControlLabel
                              key={i}
                              value={tile.value}
                              control={<Radio />}
                              label={tile.label}
                              sx={{ width: '45% !important' }}
                            ></FormControlLabel>
                          ))}
                        </RadioGroup>

                        <FormHelperText>
                          {t('helper_text.select1')}
                        </FormHelperText>
                      </FormControl>
                    </Grid>
                  </Grid>
                </Grid>
                {!isLarge && (
                  <Grid item xs={12}>
                    <Divider className={classes.divider} />
                  </Grid>
                )}
                {/*<>MARKET</>*/}
                <Grid
                  container
                  item
                  xs={12}
                  lg={isLarge ? 6 : 12}
                  justifyContent={isLarge ? 'flex-end' : 'center'}
                  className={classes.subSection}
                >
                  <Grid container item xs={12}>
                    <Grid item xs={12}>
                      <Typography
                        variant='overline'
                        color='textSecondary'
                        className={classes.subTitle}
                      >
                        {t('project-details.market')}
                      </Typography>
                    </Grid>
                    <Grid item xs={12}>
                      <Autocomplete
                        multiple
                        id='industries'
                        value={industries}
                        options={props.industryOptions}
                        getOptionLabel={(option) => option.label}
                        defaultValue={[]}
                        className={
                          classes.formControl +
                          ' ' +
                          classes.autocompleteMultiple
                        }
                        disableCloseOnSelect
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            variant='outlined'
                            label={t('labels.industry')}
                            placeholder=''
                            className={classes.helperText}
                            helperText={t('helper_text.select2')}
                            InputLabelProps={{
                              shrink: true,
                            }}
                          />
                        )}
                        onChange={(e, values) => {
                          setIndustries(values);
                        }}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <FormControl
                        variant='outlined'
                        label={t('project-details.new')}
                        className={
                          classes.helperText + ' ' + classes.formControl
                        }
                      >
                        <InputLabel id='newProduct-label' shrink={true}>
                          {t('project-details.new')}
                        </InputLabel>
                        <Select
                          id='newProduct'
                          name='newness'
                          input={
                            <OutlinedInput
                              notched
                              label={t('project-details.new')}
                            />
                          }
                          value={projectProduct.newness}
                          onChange={(e) => {
                            handleChange(e, projectProduct, setProjectProduct);
                          }}
                        >
                          {props.newProductOptions.map((option, i) => (
                            <MenuItem key={i} value={option.value}>
                              {option.label}
                            </MenuItem>
                          ))}
                        </Select>
                        <FormHelperText>
                          {t('helper_text.select1')}
                        </FormHelperText>
                      </FormControl>
                    </Grid>
                    <Grid item xs={12}>
                      <FormControl
                        component='fieldset'
                        className={classes.formControl}
                      >
                        <FormLabel component='legend'>{'Focus'}</FormLabel>
                        <FormGroup>
                          {focusValues.map((option, i) => (
                            <FormControlLabel
                              key={i}
                              control={
                                <Checkbox
                                  checked={option.checked}
                                  onChange={(e) => handleChangeFocus(e, option)}
                                  name={option.label}
                                />
                              }
                              label={option.label}
                            />
                          ))}
                        </FormGroup>
                        <FormHelperText style={{ marginLeft: '0px' }}>
                          {t('helper_text.select1')}
                        </FormHelperText>
                      </FormControl>
                    </Grid>
                    <Grid item xs={12}>
                      <FormControl
                        component='fieldset'
                        className={classes.formControl}
                      >
                        <FormLabel component='legend'>
                          {t('project-details.randd_activity_addition')}
                        </FormLabel>
                        <RadioGroup
                          aria-label={'randdActivity'}
                          name={'activitiesCarriedOutBy'}
                          value={projectProduct.activitiesCarriedOutBy}
                          onChange={(e) => {
                            handleChange(e, projectProduct, setProjectProduct);
                          }}
                        >
                          {props.rdActivitiesOptions.map((tile, i) => (
                            <FormControlLabel
                              key={i}
                              value={tile.value}
                              control={<Radio />}
                              label={tile.label}
                            ></FormControlLabel>
                          ))}
                        </RadioGroup>
                        <FormHelperText style={{ marginLeft: '0px' }}>
                          {t('helper_text.select1')}
                        </FormHelperText>
                      </FormControl>
                    </Grid>
                    <Grid
                      container
                      item
                      xs={12}
                      // lg={isLarge ? 6 : 12}
                      justifyContent={isLarge ? 'flex-end' : 'center'}
                      className={classes.subSection}
                    >
                      <Grid container item xs={12}></Grid>
                    </Grid>
                  </Grid>
                </Grid>

                <Grid item xs={12}>
                  <Divider className={classes.divider} />
                </Grid>
              </Grid>

              {/* SECOND SECTION */}

              <Grid container item xs={12}>
                <Grid
                  container
                  item
                  xs={12}
                  lg={isLarge ? 6 : 12}
                  justifyContent={isLarge ? 'flex-start' : 'center'}
                  className={classes.subSection}
                >
                  {/*<> BUDGET </>*/}
                  <Grid container item xs={12}>
                    <Grid item xs={12}>
                      <Typography
                        variant='overline'
                        color='textSecondary'
                        className={classes.subTitle}
                      >
                        {t('project-details.budget')}
                      </Typography>
                    </Grid>
                    <Grid item xs={12} mb={4}>
                      <FormControl
                        fullWidth
                        className={
                          classes.helperText + ' ' + classes.formControl
                        }
                      >
                        <TextField
                          id='amount'
                          name='subsidyNeed'
                          variant='outlined'
                          type='currency'
                          autoComplete='off'
                          placeholder='€'
                          label={t('project-details.budget_label')}
                          value={projectProduct.subsidyNeed ?? ''}
                          onChange={(e) => {
                            handleChange(e, projectProduct, setProjectProduct);
                          }}
                          InputProps={{
                            inputComponent: NumberFormatCustom,
                          }}
                        />
                        <FormHelperText id='outlined-amount-helper-text'>
                          {t('helper_text.subsidie')}
                        </FormHelperText>
                      </FormControl>
                    </Grid>

                    <Grid item xs={12}>
                      <FormControl
                        variant='outlined'
                        label={'R&D ' + t('labels.hours')}
                        className={
                          classes.helperText + ' ' + classes.formControl
                        }
                      >
                        <FormLabel component='legend' id='randdHours-label'>
                          {t('project-details.internal_resources')}
                        </FormLabel>
                        <RadioGroup
                          aria-label={'internalResources'}
                          id='internalResources'
                          name='internalResources'
                          value={projectProduct.internalResources}
                          onChange={(e) => {
                            handleChange(e, projectProduct, setProjectProduct);
                          }}
                          sx={{ flexDirection: 'row !important' }}
                        >
                          {props.rdCostOptions.map((tile, i) => (
                            <FormControlLabel
                              key={i}
                              value={tile.value}
                              control={<Radio />}
                              label={tile.label}
                              sx={{ width: '45% !important' }}
                            ></FormControlLabel>
                          ))}
                        </RadioGroup>

                        <FormHelperText>
                          {t('project-details.internal_resources_helper_text')}
                        </FormHelperText>
                      </FormControl>
                    </Grid>
                    <Grid item xs={12}>
                      <FormControl
                        variant='outlined'
                        label={'R&D ' + t('labels.costs')}
                        className={
                          classes.helperText + ' ' + classes.formControl
                        }
                      >
                        <FormLabel
                          component='legend'
                          id='externalResources-label'
                        >
                          {t('project-details.external_resources')}
                        </FormLabel>
                        <RadioGroup
                          aria-label={'externalResources'}
                          id='externalResources'
                          name='externalResources'
                          value={projectProduct.externalResources}
                          onChange={(e) => {
                            handleChange(e, projectProduct, setProjectProduct);
                          }}
                          sx={{ flexDirection: 'row !important' }}
                        >
                          {props.rdCostOptions.map((tile, i) => (
                            <FormControlLabel
                              key={i}
                              value={tile.value}
                              control={<Radio />}
                              label={tile.label}
                              sx={{ width: '45% !important' }}
                            ></FormControlLabel>
                          ))}
                        </RadioGroup>

                        <FormHelperText>
                          {t('project-details.external_resources_helper_text')}
                        </FormHelperText>
                      </FormControl>
                    </Grid>
                  </Grid>
                </Grid>
                {!isLarge && (
                  <Grid item xs={12}>
                    <Divider className={classes.divider} />
                  </Grid>
                )}
                {/*<> TRL </>*/}
                <Grid
                  container
                  item
                  xs={12}
                  lg={isLarge ? 6 : 12}
                  justifyContent={isLarge ? 'flex-end' : 'center'}
                  className={classes.subSection}
                  sx={(theme) => ({
                    [theme.breakpoints.down('1280')]: {
                      mb: theme.spacing(6),
                    },
                  })}
                >
                  <Grid container item xs={12}>
                    <Grid item xs={12}>
                      <Typography
                        variant='overline'
                        color='textSecondary'
                        className={classes.subTitle}
                      >
                        {t('projectX.label30')}
                      </Typography>
                    </Grid>
                    <Grid container item xs={12}>
                      <Grid item xs={5}>
                        <Slider
                          sx={{
                            '& input[type="range"]': {
                              WebkitAppearance: 'slider-vertical',
                            },
                            ...(!projectProduct.preTRLLevel && {
                              '& .MuiSlider-thumb': {
                                display: 'none',
                              },
                            }),
                            ...(isSmall && { height: '400px !important' }),
                          }}
                          orientation={isSmall ? 'vertical' : 'vertical'}
                          value={valueTrl || 0}
                          name='preTRLLevel'
                          onChange={handleSliderTrl}
                          max={8}
                          aria-label='Trl'
                          valueLabelFormat={valueLabelFormat}
                          marks={marksTrl}
                          onKeyDown={preventHorizontalKeyboardNavigation}
                          mx='auto'
                        />
                      </Grid>
                      <Grid
                        item
                        xs={7}
                        style={{
                          backgroundImage: `url(${trl})`,
                          backgroundRepeat: 'no-repeat',
                          height: '420px',
                          width: '244px',
                          float: 'right',
                          backgroundPosition: 'center',
                          backgroundSize: 'contain',
                        }}
                      ></Grid>
                    </Grid>
                    <Grid
                      container
                      item
                      xs={12}
                      justifyContent={isLarge ? 'flex-end' : 'center'}
                      className={classes.subSection}
                    >
                      <Grid container item xs={12}></Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          )}

          {/*<>SECTION DETAILS END</>*/}

          {/*<>BUTTONS SECTION</>*/}
          <Grid
            container
            item
            xs={12}
            justifyContent='center'
            className={classes.section + ' ' + classes.sectionMobile}
          >
            <Grid container item xs={12} className={classes.sectionButtons}>
              <Grid item xs={12}>
                <Divider />
              </Grid>
              <Grid
                container
                item
                xs={12}
                className={classes.buttons}
                style={
                  isLargeScreen
                    ? {}
                    : {
                        flexWrap: 'nowrap',
                        justifyContent: 'space-between',
                      }
                }
              >
                <>
                  {!isLargeScreen && (
                    <Grid item>
                      <div style={{ height: 40, width: 40 }}>
                        <NivoDoughnut
                          data={data}
                          center={props.center}
                          colors={COLORS}
                          smallFont
                        />
                      </div>
                    </Grid>
                  )}
                  <Grid
                    item
                    container
                    justifyContent={
                      isLargeScreen ? 'space-between' : 'flex-end'
                    }
                  >
                    <Button
                      style={{ marginRight: 16 }}
                      startIcon={<KeyboardArrowLeftIcon />}
                      onClick={handleBack}
                    >
                      {t('buttons.back')}
                    </Button>
                    <Button
                      color='primary'
                      variant='contained'
                      endIcon={<KeyboardArrowRightIcon />}
                      onClick={handleNext}
                      disabled={disabled}
                    >
                      {t('buttons.next')}
                    </Button>
                  </Grid>
                </>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      <NoResultsDialog
        openDialog={nosubsidies}
        setOpenDialog={setNosubsidies}
        title={t('select_subsidies.no_subsidies')}
        text={t('select_subsidies.no_subsidies_text')}
        image={
          <>
            <SadFace />
          </>
        }
        alignment='center'
        cancel={t('buttons.cancel_2')}
        confirm={t('dialog.to_dashboard')}
        handleConfirm={props.handleConfirm}
      />

      {/* RESET DETAILS */}

      <CustomDialog
        openDialog={openDialogReset}
        setOpenDialog={setOpenDialogReset}
        handleConfirm={handleConfirmReset}
        handleClose={() => {
          setOpenDialogReset(false);
        }}
        title={t('dialog.reset_project_2')}
        text={t('dialog.reset_project_3')}
        cancel={t('yes_no.no')}
        confirm={t('yes_no.yes')}
      />
    </>
  );
};

export default withRouter(NewProjectTypeProductOptimized);
