import React, { useState, useContext, useEffect } from 'react';
import { styled, useTheme, alpha } from '@mui/material/styles';
import { List, Chip } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import CheckIcon from '@mui/icons-material/Check';
import axios from 'axios';
import { groupStatusData } from './utils';
import { UserContext } from '../../contexts/UserContext';

const ListItem = styled('li')(({ theme }) => ({
  margin: theme.spacing(0.5),
}));

const ChipsComponent = (props) => {
  const [userContext] = useContext(UserContext);
  const theme = useTheme();
  const [statusData, setStatusData] = useState(groupStatusData);
  let searchTermStatus = props.status ?? '';
  const handleClick = (key) => {
    let d = [...statusData];
    let s = searchTermStatus;
    if (s) {
      if (key !== 7) {
        if (!d[key].selected) {
          if (s.indexOf(d[key].value) === -1) {
            searchTermStatus = s.concat(';' + d[key].value);
            d[key].selected = !d[key].selected;
          }
        } else {
          if (props.status.includes(';')) {
            let c = s.split(';');
            let index = c.indexOf(d[key].value);
            c.splice(index, 1);
            searchTermStatus = c.join(';');
            d[key].selected = !d[key].selected;
          }
        }
      } else {
        let values = d[key].value.split(';');
        if (!d[key].selected) {
          searchTermStatus = s.concat(';' + d[key].value);
          d[key].selected = !d[key].selected;
        } else {
          if (
            props.status.includes(
              'Beschikt;Geëffectueerd;Afgerond;Ruling;Ruling afgerond'
            ) &&
            props.status.length > 54
          ) {
            let c = s.split(';');
            values.forEach((val) => {
              let index = c.indexOf(val);
              c.splice(index, 1);
            });
            searchTermStatus = c.join(';');
            d[key].selected = !d[key].selected;
          }
        }
      }
    } else {
      searchTermStatus = d[key].value;
    }
    setStatusData(d);
    props.setStatus(searchTermStatus);
    props.setTodoPage(0);
    props.setDonePage(0);
    handleFilterSearch(searchTermStatus);
  };

  const handleFilterSearch = (searchTermStatus) => {
    props.setLoading(true);
    let startOfDate = props.startDate
      ? new Date(new Date(props.startDate).setHours(0, 0, 0, 0))
      : null;
    let endOfDate = props.endDate
      ? new Date(new Date(props.endDate).setHours(23, 59, 59, 999))
      : null;
    const apiEndPoint =
      process.env.NODE_ENV === 'development'
        ? process.env.REACT_APP_API_ENDPOINT
        : process.env.REACT_APP_API_ENDPOINT_PROD;
    axios({
      method: 'get',
      url: apiEndPoint + '/v1/partner/applications/getApplications',
      withCredentials: true,
      params: {
        text: props.searchText,
        status: searchTermStatus,
        from: startOfDate
          ? new Date(
              startOfDate.getTime() -
                startOfDate.getTimezoneOffset() * 60 * 1000
            )
          : null,
        to: endOfDate
          ? new Date(
              endOfDate.getTime() - endOfDate.getTimezoneOffset() * 60 * 1000
            )
          : null,
      },
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        Authorization: `Bearer ${userContext.token}`,
      },
    })
      .then((res) => {
        props.setApplications(res.data);
        props.setLoading(false);
      })
      .catch((error) => {
        props.setLoading(false);
      });
  };

  const handleUpdateListChips = () => {
    setStatusData((prev) => {
      let d = [...prev];
      let s = props.status;
      d.forEach((chip, i) => {
        if (s && s.indexOf(chip.value) !== -1) {
          chip.selected = true;
        } else {
          chip.selected = false;
        }
      });
      return d;
    });
  };

  useEffect(() => {
    handleUpdateListChips();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div>
      <List
        sx={{
          display: 'flex',
          justifyContent: 'flex-start',
          flexWrap: 'wrap',
          listStyle: 'none',
          p: 0,
          mt: 1,
        }}
        component='ul'
      >
        {statusData.map((item) => {
          return (
            <ListItem key={item.key}>
              <Chip
                clickable={true}
                icon={
                  item.selected ? (
                    <CheckIcon fontSize='small' />
                  ) : (
                    <AddIcon fontSize='small' />
                  )
                }
                variant='outlined'
                label={item.label}
                color={item.selected ? 'primary' : 'default'}
                onClick={() => {
                  handleClick(item.key);
                }}
                style={
                  item.selected
                    ? {
                        backgroundColor: alpha(
                          theme.palette.primary.main,
                          theme.palette.action.selectedOpacity
                        ),
                      }
                    : {
                        backgroundColor: alpha(
                          theme.palette.text.disabled,
                          0.05
                        ),
                      }
                }
              />
            </ListItem>
          );
        })}
      </List>
    </div>
  );
};

export default ChipsComponent;
