/* MAIN COMPONENTS HAVE 24PX PADDING HORIZONTALY (16PX < LG) */
import React, { useState, useContext, useEffect } from 'react';
import { useHistory, withRouter } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import BaseLayout from '../layouts/TopBarLevel1Layout';
import NewProjectTypeProduct from '../components/NewProject/NewProjectTypeProductOptimized';
import NewProjectTypeInternational from '../components/NewProject/NewProjectTypeInternationalOptimized';
import NewProjectTypeInvestBusiness from '../components/NewProject/NewProjectTypeInvestBusinessOptimized';
import NewProjectTypeInvestEmployees from '../components/NewProject/NewProjectTypeInvestEmployeesOptimized';
import NewProjectSidebar from '../components/NewProject/NewProjectSidebar';
import { NewProjectContext } from '../contexts/NewProject';

import {
  focusOptionss,
  industryOptionsEn,
  industryOptionsNl,
  sectorOptionsEn,
  sectorOptionsNl,
  subSectorOptionsEn,
  subSectorOptionsNl,
  trlOptions,
  rdHoursOptions,
  rdCostOptions,
  newProductOptionss,
  rdActivitiesOptionss,
  countryList,
  internationalActivitiesEn,
  internationalActivitiesNl,
  activitiesBussinessEn,
  activitiesBussinessNl,
  yesNoOptions,
} from '../data/projectOptions';

import i18n from 'i18next';

const NewProjectDetails = () => {
  const { t } = useTranslation();

  const focusOptions = focusOptionss();
  const newProductOptions = newProductOptionss();
  const rdActivitiesOptions = rdActivitiesOptionss();
  const history = useHistory();

  const {
    projectType,
    setPercentage,
    setProjectType,
    setProjectProduct,
    setProjectInt,
    setProjectBusiness,
    setProjectEmployees,
    setNewProjectId,
    setIndustries,
    setActive,
    setExpand,
    setActivitiesInt,
    setActivities,
    setThemes,
    setTarget,
    showMoreDetails,
    setShowMoreDetails,
    setAccountId,
    accountId,
    setSectors,
    setSubSectors,
  } = useContext(NewProjectContext);

  const [progressStep, setProgressStep] = useState(2);
  const [projectProgress, setProjectProgress] = useState(2);
  let totalSteps = showMoreDetails ? 5 : 4;
  const center = Math.round((100 / totalSteps) * progressStep);

  const handleConfirm = () => {
    const backAccountId = accountId;
    setProjectProgress(0);
    setPercentage(0);
    setProjectType('');
    setNewProjectId('');
    setIndustries([]);
    setActive([]);
    setExpand([]);
    setActivitiesInt([]);
    setActivities([]);
    setThemes([]);
    setTarget([]);
    setAccountId('');
    setSectors([]);
    setSubSectors([]);
    setProjectProduct({
      name: '',
      idea: '',
      problemStatement: '',
      startDate: null,
      endDate: null,
      noOfRnDEmployees: '',
      rndHours: '',
      rndCosts: '',
      activitiesCarriedOutBy: '',
      industrySector: '',
      newness: '',
      projectFocus: '',
      preTRLLevel: '',
      postTRLLevel: '',
      subsidyNeed: '',
      projectTypeId: '',
      accountId: '',
      keySectors: '',
      subSectors: '',
      id: '',
    });
    setProjectInt({
      name: '',
      idea: '',
      problemStatement: '',
      startDate: null,
      endDate: null,
      countriesActive: '',
      countriesExpanding: '',
      activities: '',
      subsidyNeed: '',
      projectTypeId: '',
      accountId: '',
      id: '',
    });
    setProjectBusiness({
      name: '',
      idea: '',
      problemStatement: '',
      startDate: null,
      activities: '',
      subsidyNeed: '',
      projectTypeId: '',
      accountId: '',
      id: '',
    });
    setProjectEmployees({
      name: '',
      idea: '',
      problemStatement: '',
      learningWorkPrograms: '',
      investmentInEmployees: '',
      hiredWorkersAtDisadvantage: '',
      willHireWorkersAtDisadvantage: '',
      subsidyNeed: '',
      projectTypeId: '',
      accountId: '',
      id: '',
    });
    history.push(`/app/accounts/${backAccountId}`);
  };

  useEffect(() => {
    window.document.title = t('page_title.add_project');
    window.scrollTo(0, 0);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <BaseLayout
        main={
          <>
            {projectType === '0123X000001GJUGQA4' && (
              <NewProjectTypeProduct
                trlOptions={trlOptions}
                rdHoursOptions={rdHoursOptions}
                rdCostOptions={rdCostOptions}
                rdActivitiesOptions={rdActivitiesOptions}
                industryOptions={
                  i18n.languages[0] === 'nl'
                    ? industryOptionsNl
                    : industryOptionsEn
                }
                sectorOptions={
                  i18n.languages[0] === 'nl' ? sectorOptionsNl : sectorOptionsEn
                }
                subSectorOptions={
                  i18n.languages[0] === 'nl'
                    ? subSectorOptionsNl
                    : subSectorOptionsEn
                }
                newProductOptions={newProductOptions}
                focusOptions={focusOptions}
                progressStep={progressStep}
                setProgressStep={setProgressStep}
                projectProgress={projectProgress}
                setProjectProgress={setProjectProgress}
                center={`${center}%`}
                handleConfirm={handleConfirm}
                showMoreDetails={showMoreDetails}
                setShowMoreDetails={setShowMoreDetails}
              />
            )}
            {projectType === '0123X000001GJUCQA4' && (
              <NewProjectTypeInternational
                countryList={countryList}
                internationalActivities={
                  i18n.languages[0] === 'nl'
                    ? internationalActivitiesNl
                    : internationalActivitiesEn
                }
                progressStep={progressStep}
                setProgressStep={setProgressStep}
                projectProgress={projectProgress}
                setProjectProgress={setProjectProgress}
                center={`${center}%`}
                handleConfirm={handleConfirm}
                showMoreDetails={showMoreDetails}
                setShowMoreDetails={setShowMoreDetails}
              />
            )}
            {projectType === '0123X000001GJUDQA4' && (
              <NewProjectTypeInvestBusiness
                activitiesBusiness={
                  i18n.languages[0] === 'nl'
                    ? activitiesBussinessNl
                    : activitiesBussinessEn
                }
                progressStep={progressStep}
                setProgressStep={setProgressStep}
                projectProgress={projectProgress}
                setProjectProgress={setProjectProgress}
                center={`${center}%`}
                handleConfirm={handleConfirm}
                showMoreDetails={showMoreDetails}
                setShowMoreDetails={setShowMoreDetails}
              />
            )}
            {projectType === '0123X000001GJUEQA4' && (
              <NewProjectTypeInvestEmployees
                yesNoOptions={yesNoOptions}
                progressStep={progressStep}
                setProgressStep={setProgressStep}
                projectProgress={projectProgress}
                setProjectProgress={setProjectProgress}
                center={`${center}%`}
                handleConfirm={handleConfirm}
                showMoreDetails={showMoreDetails}
                setShowMoreDetails={setShowMoreDetails}
              />
            )}
          </>
        }
        sidebar={
          <NewProjectSidebar
            progressStep={progressStep}
            setProgressStep={setProgressStep}
            projectProgress={projectProgress}
            setProjectProgress={setProjectProgress}
            center={`${center}%`}
            showMoreDetails={showMoreDetails}
          />
        }
        dialog
        handleConfirm={handleConfirm}
        text={t('dialog.quit_new_project')}
        title={t('projects.card_new')}
      ></BaseLayout>
    </>
  );
};

export default withRouter(NewProjectDetails);
