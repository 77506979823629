import React, { useContext, useEffect, useCallback } from 'react';
import { Switch, Route, useLocation, Redirect } from 'react-router-dom';
import { ThemeProvider, StyledEngineProvider } from '@mui/material/styles';
import { theme } from './theme/BrightDesignTheme';
import GlobalStyles from './theme/globalStyles';
import './App.css';
import { LoginProvider } from './contexts/LoginContext';
import { UserContext } from './contexts/UserContext';
import { useIsMounted } from './utils/customHooks';
import Login from './pages/Login';
import ForgotPassword from './pages/ForgotPassword';
import ResetPassword from './pages/ResetPassword';
import Dashboard from './pages/Dashboard';
import axios from 'axios';
import ReactGA from 'react-ga';
import useHotjar from 'react-use-hotjar';
import LoginAsUser from './pages/LoginAsUser';
import { WorkspaceProvider } from './contexts/WorkspaceContext';
import { NewProjectProvider } from './contexts/NewProject';
import NewProjectAccountPage from './pages/NewProjectAccountPage';
import NewProjectType from './pages/NewProjectType';
import NewProjectDetails from './pages/NewProjectDetails';
import NewProjectUploadDocuments from './pages/NewProjectUploadDocuments';
import NewProjectSubsidies from './pages/NewProjectSubsidies';

function App() {
  const [userContext, setUserContext] = useContext(UserContext);
  // eslint-disable-next-line no-unused-vars
  const isMounted = useIsMounted();
  const location = useLocation();

  const verifyUser = useCallback(() => {
    var apiEndPoint =
      process.env.NODE_ENV === 'development'
        ? process.env.REACT_APP_API_ENDPOINT
        : process.env.REACT_APP_API_ENDPOINT_PROD;

    axios({
      method: 'post',
      url: apiEndPoint + '/v1/partner/auth/refresh-tokens',
      withCredentials: true,
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
      },
    })
      .then((response) => {
        if (response.data) {
          localStorage.setItem('partner-token', response.data.access.token);
          if (userContext.user == null) {
            setUserContext((oldValues) => {
              return { ...oldValues, token: null, user: null };
            });
          } else {
            setUserContext((oldValues) => {
              return {
                ...oldValues,
                token: response.data.access.token,
              };
            });
          }
        }
      })
      .catch((err) => {
        localStorage.setItem('partner-token', null);
        localStorage.setItem('partner-user', null);
        setUserContext((oldValues) => {
          return { ...oldValues, token: null, user: null };
        });
      })
      .finally(() => {
        setTimeout(verifyUser, 60 * 60 * 1000);
      });
  }, [setUserContext]);

  useEffect(() => {
    verifyUser();
  }, [verifyUser]);

  const { initHotjar } = useHotjar();

  useEffect(() => {
    initHotjar(
      process.env.NODE_ENV === 'development'
        ? process.env.REACT_APP_HOTJAR_ID
        : process.env.REACT_APP_HOTJAR_ID_PROD,
      6,
      false,
      console.info
    );
  }, [initHotjar]);

  useEffect(() => {
    let trackingId =
      process.env.NODE_ENV === 'development'
        ? process.env.REACT_APP_GA_ID
        : process.env.REACT_APP_GA_ID_PROD;

    ReactGA.initialize(trackingId, {
      debug: false,
    });
    ReactGA.pageview(location.pathname);
    if (userContext.user !== null) {
      ReactGA.set({ dimension1: userContext.user.id });
      ReactGA.set({ userId: userContext.user.id });
      ReactGA.set({ dimension4: 'E-User' });
    }
  }, [location, userContext]);

  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={theme}>
        <GlobalStyles />
        <div className='App'>
          <LoginProvider>
            <Switch>
              <Redirect exact from='/' to='/login' />
              <Route path='/login' component={Login} />
              <Route path='/loginAsUser' component={LoginAsUser} />
              <Route path='/forgotpassword' component={ForgotPassword} />
              <Route path='/reset/:token' component={ResetPassword} />
              {userContext.token ? (
                <>
                  <WorkspaceProvider>
                    <NewProjectProvider>
                      <Route path='/app/dashboard' component={Dashboard} />
                      <Route path='/app/deals' component={Dashboard} />
                      <Route path='/app/applications' component={Dashboard} />
                      <Route path='/app/projects' component={Dashboard} />
                      <Route path='/app/account' component={Dashboard} />
                      <Route path='/app/accounts' component={Dashboard} />
                      <Route
                        exact
                        path='/app/newproject/account'
                        component={NewProjectAccountPage}
                      />
                      <Route
                        exact
                        path='/app/newproject/choose'
                        component={NewProjectType}
                      />
                      <Route
                        path='/app/newproject/addproject'
                        component={NewProjectDetails}
                      />
                      <Route
                        path='/app/newproject/upload'
                        component={NewProjectUploadDocuments}
                      />
                      <Route
                        path='/app/newproject/subsidies'
                        component={NewProjectSubsidies}
                      />
                    </NewProjectProvider>
                  </WorkspaceProvider>
                </>
              ) : (
                <>
                  <Redirect to='/login' />
                </>
              )}
            </Switch>
          </LoginProvider>
        </div>
      </ThemeProvider>
    </StyledEngineProvider>
  );
}

export default App;
