import React, { useState, useEffect, useContext } from 'react';
import { useLocation } from 'react-router-dom';
import { useStyles } from './styles';
import { useMediaQuery } from 'react-responsive';
import { useTheme } from '@mui/material/styles';
import { useTranslation } from 'react-i18next';
import 'react-dropzone-uploader/dist/styles.css';
import { Grid, Typography, Card, CardContent, Box } from '@mui/material';
import { ReactComponent as FilesFolder } from '../../assets/FilesAndFolderTwoColor.svg';
import { UserContext } from '../../contexts/UserContext';
import Loader from '../../uiComponents/Loader';
import axios from 'axios';
import DocumentUploader from '../../uiComponents/DocumentUploader';

const DocumentsList = () => {
  let location = useLocation();
  const classes = useStyles();
  const theme = useTheme();
  const isLargeScreen = useMediaQuery({ query: '(min-width: 1280px)' });
  const isSmallScreen = useMediaQuery({ query: '(max-width: 767px)' });
  const { t } = useTranslation();
  const [userContext] = useContext(UserContext);
  const [projectType, setProjectType] = useState('');
  const [loading, setLoading] = useState(false);
  const [mongodbProjectId, setMongodbProjectId] = useState('');

  const [pitchdeckId, setPitchdeckId] = useState([]);
  const [roadmapId, setRoadmapId] = useState([]);
  const [financialModelId, setFinancialModelId] = useState([]);
  const [businessPlanId, setBusinessPlanId] = useState([]);
  const [budgetPlanId, setBudgetPlanId] = useState([]);
  const [projectPlanId, setProjectPlanId] = useState([]);
  const [quoteId, setQuoteId] = useState([]);
  const [capexId, setCapexId] = useState([]);
  const [othersId, setOthersId] = useState([]);

  const apiEndPoint =
    process.env.NODE_ENV === 'development'
      ? process.env.REACT_APP_API_ENDPOINT
      : process.env.REACT_APP_API_ENDPOINT_PROD;

  const getUploadParamsPitchdeck = ({ file }) => {
    const body = new FormData();
    body.append('pitchdeck', file);
    body.append('projectId', mongodbProjectId);
    return { url: apiEndPoint + '/v1/partner/upload/', body };
  };

  const getUploadParamsFinancialModel = ({ file }) => {
    const body = new FormData();
    body.append('financial_model', file);
    body.append('projectId', mongodbProjectId);
    return { url: apiEndPoint + '/v1/partner/upload/', body };
  };

  const getUploadParamsRoadmap = ({ file }) => {
    const body = new FormData();
    body.append('roadmap', file);
    body.append('projectId', mongodbProjectId);
    return { url: apiEndPoint + '/v1/partner/upload/', body };
  };

  const getUploadParamsBusinessPlan = ({ file }) => {
    const body = new FormData();
    body.append('business_plan', file);
    body.append('projectId', mongodbProjectId);
    return { url: apiEndPoint + '/v1/partner/upload/', body };
  };

  const getUploadParamsBudgetPlan = ({ file }) => {
    const body = new FormData();
    body.append('budget_plan', file);
    body.append('projectId', mongodbProjectId);
    return { url: apiEndPoint + '/v1/partner/upload/', body };
  };

  const getUploadParamsProjectPlan = ({ file }) => {
    const body = new FormData();
    body.append('project_plan', file);
    body.append('projectId', mongodbProjectId);
    return { url: apiEndPoint + '/v1/partner/upload/', body };
  };

  const getUploadParamsQuote = ({ file }) => {
    const body = new FormData();
    body.append('quote', file);
    body.append('projectId', mongodbProjectId);
    return { url: apiEndPoint + '/v1/partner/upload/', body };
  };

  const getUploadParamsCapex = ({ file }) => {
    const body = new FormData();
    body.append('capex', file);
    body.append('projectId', mongodbProjectId);
    return { url: apiEndPoint + '/v1/partner/upload/', body };
  };

  const getUploadParamsOthers = ({ file }) => {
    const body = new FormData();
    body.append('others', file);
    body.append('projectId', mongodbProjectId);
    return { url: apiEndPoint + '/v1/partner/upload/', body };
  };

  const handleChangeStatusParamsPitchdeck = ({ xhr, remove }, status) => {
    if (xhr) {
      xhr.onreadystatechange = () => {
        if (xhr.readyState === 4) {
          fetchFiles();
          remove();
        }
      };
    }
  };

  const handleChangeStatusRoadmap = ({ xhr, remove }, status) => {
    if (xhr) {
      xhr.onreadystatechange = () => {
        if (xhr.readyState === 4) {
          fetchFiles();
          remove();
        }
      };
    }
  };

  const handleChangeStatusFinancialModel = ({ xhr, remove }, status) => {
    if (xhr) {
      xhr.onreadystatechange = () => {
        if (xhr.readyState === 4) {
          fetchFiles();
          remove();
        }
      };
    }
  };

  const handleChangeStatusBusinessPlan = ({ xhr, remove }, status) => {
    if (xhr) {
      xhr.onreadystatechange = () => {
        if (xhr.readyState === 4) {
          fetchFiles();
          remove();
        }
      };
    }
  };

  const handleChangeStatusParamsBudgetPlan = ({ xhr, remove }, status) => {
    if (xhr) {
      xhr.onreadystatechange = () => {
        if (xhr.readyState === 4) {
          fetchFiles();
          remove();
        }
      };
    }
  };

  const handleChangeStatusParamsProjectPlan = ({ xhr, remove }, status) => {
    if (xhr) {
      xhr.onreadystatechange = () => {
        if (xhr.readyState === 4) {
          fetchFiles();
          remove();
        }
      };
    }
  };

  const handleChangeStatusParamsQuote = ({ xhr, remove }, status) => {
    if (xhr) {
      xhr.onreadystatechange = () => {
        if (xhr.readyState === 4) {
          fetchFiles();
          remove();
        }
      };
    }
  };

  const handleChangeStatusParamsCapex = ({ xhr, remove }, status) => {
    if (xhr) {
      xhr.onreadystatechange = () => {
        if (xhr.readyState === 4) {
          fetchFiles();
          remove();
        }
      };
    }
  };

  const handleChangeStatusParamsOthers = ({ xhr, remove }, status) => {
    if (xhr) {
      xhr.onreadystatechange = () => {
        if (xhr.readyState === 4) {
          fetchFiles();
          remove();
        }
      };
    }
  };

  const deleteFile = (id) => {
    setLoading(true);
    axios({
      method: 'post',
      url: apiEndPoint + '/v1/partner/upload/delete',
      data: { documentId: id },
      withCredentials: true,
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        Authorization: `Bearer ${userContext.token}`,
      },
    }).then((res) => {
      if (res.data.success) {
        fetchFiles();
      } else {
        console.log('error', res);
      }
      setLoading(false);
    });
  };

  const downloadFile = (idFile, filename) => {
    setLoading(true);
    axios({
      method: 'get',
      url: apiEndPoint + '/v1/partner/upload/download',
      withCredentials: true,
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        Authorization: `Bearer ${userContext.token}`,
      },
      responseType: 'blob',
      params: {
        documentId: idFile,
      },
    })
      .then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', filename);
        document.body.appendChild(link);
        link.click();
        link.parentNode.removeChild(link);
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
      });
  };

  const fetchFiles = () => {
    setLoading(true);
    let id = location.pathname.substring(
      location.pathname.lastIndexOf('/') + 1
    );
    var requestBody = {
      projectId: id,
    };
    axios({
      method: 'post',
      url: apiEndPoint + '/v1/partner/project/getProjectDocuments',
      data: requestBody,
      withCredentials: true,
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        Authorization: `Bearer ${userContext.token}`,
      },
    })
      .then((res) => {
        setProjectType(res.data.projectType);
        setMongodbProjectId(res.data.projectId);
        let pitchdecks = [];
        let roadmaps = [];
        let financials = [];
        let businessPlans = [];
        let budgetPlans = [];
        let projectPlans = [];
        let quotes = [];
        let capexes = [];
        let others = [];

        if (res.data.projectDocuments.length > 0) {
          res.data.projectDocuments.forEach((projectDocument) => {
            if (projectDocument.documentType === 'pitchdeck') {
              pitchdecks.push({
                id: projectDocument.id,
                filename: projectDocument.filename,
              });
            } else if (projectDocument.documentType === 'roadmap') {
              roadmaps.push({
                id: projectDocument.id,
                filename: projectDocument.filename,
              });
            } else if (projectDocument.documentType === 'financial_model') {
              financials.push({
                id: projectDocument.id,
                filename: projectDocument.filename,
              });
            } else if (projectDocument.documentType === 'business_plan') {
              businessPlans.push({
                id: projectDocument.id,
                filename: projectDocument.filename,
              });
            } else if (projectDocument.documentType === 'budget_plan') {
              budgetPlans.push({
                id: projectDocument.id,
                filename: projectDocument.filename,
              });
            } else if (projectDocument.documentType === 'project_plan') {
              projectPlans.push({
                id: projectDocument.id,
                filename: projectDocument.filename,
              });
            } else if (projectDocument.documentType === 'quote') {
              quotes.push({
                id: projectDocument.id,
                filename: projectDocument.filename,
              });
            } else if (projectDocument.documentType === 'capex') {
              capexes.push({
                id: projectDocument.id,
                filename: projectDocument.filename,
              });
            } else if (projectDocument.documentType === 'others') {
              others.push({
                id: projectDocument.id,
                filename: projectDocument.filename,
              });
            }
          });
        }
        setPitchdeckId(pitchdecks);
        setRoadmapId(roadmaps);
        setFinancialModelId(financials);
        setBusinessPlanId(businessPlans);
        setBudgetPlanId(budgetPlans);
        setProjectPlanId(projectPlans);
        setQuoteId(quotes);
        setCapexId(capexes);
        setOthersId(others);
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
      });
  };

  useEffect(() => {
    setLoading(true);
    let id = location.pathname.substring(
      location.pathname.lastIndexOf('/') + 1
    );
    var requestBody = {
      projectId: id,
    };
    axios({
      method: 'post',
      url: apiEndPoint + '/v1/partner/project/getProjectDocuments',
      data: requestBody,
      withCredentials: true,
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        Authorization: `Bearer ${userContext.token}`,
      },
    })
      .then((res) => {
        setProjectType(res.data.projectType);
        setMongodbProjectId(res.data.projectId);
        let pitchdecks = [];
        let roadmaps = [];
        let financials = [];
        let businessPlans = [];
        let budgetPlans = [];
        let projectPlans = [];
        let quotes = [];
        let capexes = [];
        let others = [];

        if (res.data.projectDocuments.length > 0) {
          res.data.projectDocuments.forEach((projectDocument) => {
            if (projectDocument.documentType === 'pitchdeck') {
              pitchdecks.push({
                id: projectDocument.id,
                filename: projectDocument.filename,
              });
            } else if (projectDocument.documentType === 'roadmap') {
              roadmaps.push({
                id: projectDocument.id,
                filename: projectDocument.filename,
              });
            } else if (projectDocument.documentType === 'financial_model') {
              financials.push({
                id: projectDocument.id,
                filename: projectDocument.filename,
              });
            } else if (projectDocument.documentType === 'business_plan') {
              businessPlans.push({
                id: projectDocument.id,
                filename: projectDocument.filename,
              });
            } else if (projectDocument.documentType === 'budget_plan') {
              budgetPlans.push({
                id: projectDocument.id,
                filename: projectDocument.filename,
              });
            } else if (projectDocument.documentType === 'project_plan') {
              projectPlans.push({
                id: projectDocument.id,
                filename: projectDocument.filename,
              });
            } else if (projectDocument.documentType === 'quote') {
              quotes.push({
                id: projectDocument.id,
                filename: projectDocument.filename,
              });
            } else if (projectDocument.documentType === 'capex') {
              capexes.push({
                id: projectDocument.id,
                filename: projectDocument.filename,
              });
            } else if (projectDocument.documentType === 'others') {
              others.push({
                id: projectDocument.id,
                filename: projectDocument.filename,
              });
            }
          });
        }
        setPitchdeckId(pitchdecks);
        setRoadmapId(roadmaps);
        setFinancialModelId(financials);
        setBusinessPlanId(businessPlans);
        setBudgetPlanId(budgetPlans);
        setProjectPlanId(projectPlans);
        setQuoteId(quotes);
        setCapexId(capexes);
        setOthersId(others);
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const topPageRef = React.useRef();

  useEffect(() => {
    document.getElementById('anchor-div').scrollIntoView();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      {loading && <Loader loading={loading} />}
      <Grid
        container
        className={classes.root}
        style={
          isSmallScreen
            ? { paddingTop: 72, position: 'relative' }
            : { paddingTop: 96, position: 'relative' }
        }
      >
        <div
          ref={topPageRef}
          id='anchor-div'
          style={{ position: 'absolute', top: '-120px', left: '0' }}
        ></div>

        <Grid
          container
          item
          justifyContent='center'
          alignContent='flex-start'
          xs={12}
        >
          <Grid
            container
            item
            xs={12}
            justifyContent='space-between'
            className={classes.container}
          >
            <Grid item xs={12} className={classes.box}>
              <Box mb={2.5}>
                <Typography component='h6' variant='h6' align='center'>
                  {t('documents.title')}
                </Typography>
              </Box>
              <Box>
                <Typography
                  variant='body1'
                  color='textSecondary'
                  align='center'
                >
                  {t('documents.subtitle')}
                </Typography>
              </Box>
            </Grid>
            <Grid container item xs={12} className={classes.box}>
              <Grid item xs={12}>
                <Card
                  className={classes.card}
                  variant='outlined'
                  style={(theme.custom.boxshadow, { marginRight: 24 })}
                >
                  <CardContent>
                    <Grid container>
                      <Grid
                        alignContent='center'
                        container
                        item
                        xs={12}
                        md={isLargeScreen ? 7 : 6}
                      >
                        <Grid item xs={12}>
                          <Box mb={1}>
                            <Typography>{t('documents.card_title')}</Typography>
                          </Box>
                        </Grid>
                        <Grid item xs={12}>
                          <Box>
                            <Typography variant='body2' color='textSecondary'>
                              {t('documents.card_subtitle')}
                            </Typography>
                          </Box>
                        </Grid>
                      </Grid>
                      <Grid item xs={12} md={isLargeScreen ? 5 : 6}>
                        <FilesFolder
                          style={{
                            width: isLargeScreen ? 'auto' : '100%',
                            float: 'right',
                          }}
                        />
                      </Grid>
                    </Grid>
                  </CardContent>
                </Card>
              </Grid>
            </Grid>
            <Grid container item xs={12} className={classes.box}>
              {/* 1 */}
              {projectType === '0123X000001GJUGQA4' && (
                <Grid item xs={12}>
                  <DocumentUploader
                    label={t('upload.label3')}
                    inputContent={t('upload.label5')}
                    getUploadParams={getUploadParamsPitchdeck}
                    onChangeStatus={handleChangeStatusParamsPitchdeck}
                    initialFiles={pitchdeckId}
                    deleteFile={deleteFile}
                    downloadFile={downloadFile}
                  />
                </Grid>
              )}
              {/* 2 */}
              {projectType === '0123X000001GJUGQA4' && (
                <Grid item xs={12}>
                  <DocumentUploader
                    label={t('upload.label1')}
                    inputContent={t('upload.label5')}
                    getUploadParams={getUploadParamsRoadmap}
                    onChangeStatus={handleChangeStatusRoadmap}
                    initialFiles={roadmapId}
                    deleteFile={deleteFile}
                    downloadFile={downloadFile}
                  />
                </Grid>
              )}
              {/* 3 */}
              {projectType === '0123X000001GJUGQA4' && (
                <Grid item xs={12}>
                  <DocumentUploader
                    label={t('upload.label2')}
                    inputContent={t('upload.label5')}
                    getUploadParams={getUploadParamsFinancialModel}
                    onChangeStatus={handleChangeStatusFinancialModel}
                    initialFiles={financialModelId}
                    deleteFile={deleteFile}
                    downloadFile={downloadFile}
                  />
                </Grid>
              )}
              {/* 4 */}
              {projectType === '0123X000001GJUGQA4' && (
                <Grid item xs={12}>
                  <DocumentUploader
                    label={t('upload.label4')}
                    inputContent={t('upload.label5')}
                    getUploadParams={getUploadParamsBusinessPlan}
                    onChangeStatus={handleChangeStatusBusinessPlan}
                    initialFiles={businessPlanId}
                    deleteFile={deleteFile}
                    downloadFile={downloadFile}
                  />
                </Grid>
              )}
              {/* 5 */}
              {(projectType === '0123X000001GJUCQA4' ||
                projectType === '0123X000001GJUFQA4') && (
                <Grid item xs={12}>
                  <DocumentUploader
                    label={t('upload.label6')}
                    inputContent={t('upload.label5')}
                    getUploadParams={getUploadParamsBudgetPlan}
                    onChangeStatus={handleChangeStatusParamsBudgetPlan}
                    initialFiles={budgetPlanId}
                    deleteFile={deleteFile}
                    downloadFile={downloadFile}
                  />
                </Grid>
              )}
              {/* 6 plan */}
              {(projectType === '0123X000001GJUCQA4' ||
                projectType === '0123X000001GJUEQA4' ||
                projectType === '0123X000001GJUFQA4') && (
                <Grid item xs={12}>
                  <DocumentUploader
                    label={t('upload.label7')}
                    inputContent={t('upload.label5')}
                    getUploadParams={getUploadParamsProjectPlan}
                    onChangeStatus={handleChangeStatusParamsProjectPlan}
                    initialFiles={projectPlanId}
                    deleteFile={deleteFile}
                    downloadFile={downloadFile}
                  />
                </Grid>
              )}
              {/* 7 */}
              {projectType === '0123X000001GJUDQA4' && (
                <Grid item xs={12}>
                  <DocumentUploader
                    label={t('upload.label8')}
                    inputContent={t('upload.label5')}
                    getUploadParams={getUploadParamsQuote}
                    onChangeStatus={handleChangeStatusParamsQuote}
                    initialFiles={quoteId}
                    deleteFile={deleteFile}
                    downloadFile={downloadFile}
                  />
                </Grid>
              )}
              {/* 8 */}
              {projectType === '0123X000001GJUDQA4' && (
                <Grid item xs={12}>
                  <DocumentUploader
                    label={t('upload.label9')}
                    inputContent={t('upload.label5')}
                    getUploadParams={getUploadParamsCapex}
                    onChangeStatus={handleChangeStatusParamsCapex}
                    initialFiles={capexId}
                    deleteFile={deleteFile}
                    downloadFile={downloadFile}
                  />
                </Grid>
              )}
              {/**/}
              <Grid item xs={12}>
                <DocumentUploader
                  label={t('upload.label10')}
                  inputContent={t('upload.label5')}
                  getUploadParams={getUploadParamsOthers}
                  onChangeStatus={handleChangeStatusParamsOthers}
                  initialFiles={othersId}
                  deleteFile={deleteFile}
                  downloadFile={downloadFile}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

export default DocumentsList;
