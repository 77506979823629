import React from 'react';
import DropzoneBox from '../uiComponents/DropzoneBox';
import { default as File } from '../uiComponents/UploadedFile';
import { Box, Typography, Divider } from '@mui/material';

const DocumentUploader = (props) => {
  const {
    label,
    inputContent,
    getUploadParams,
    onChangeStatus,
    initialFiles,
    deleteFile,
    downloadFile,
  } = props;
  return (
    <>
      <Box mb={2.5} mt={5.5}>
        <Typography variant='overline' color='textSecondary'>
          {label}
        </Typography>
      </Box>
      <Box
        mb={2.5}
        mt={5.5}
        sx={{
          boxShadow: 'rgb(224 224 224) 0px 0px 0px 1px',
          borderRadius: '4px',
        }}
      >
        <DropzoneBox
          inputContent={inputContent}
          getUploadParams={getUploadParams}
          onChangeStatus={onChangeStatus}
          multiple={false}
          maxFiles={'1'}
          maxwidth={'unset'}
        />
        {initialFiles.map((file) => {
          return (
            <>
              <Divider />
              <div>
                <File
                  name={file.filename}
                  id={file.id}
                  deleteFile={deleteFile}
                  downloadFile={downloadFile}
                />
              </div>
            </>
          );
        })}
      </Box>
    </>
  );
};

export default DocumentUploader;
