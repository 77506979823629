import React from 'react';
import useMediaQuery from '@mui/material/useMediaQuery';
import { Grid, Typography } from '@mui/material';
import { selectFilters } from '../styles';
import SingleselectField from '../../uiComponents/inputComponents/SingleselectField';

const FilterDeals = (props) => {
  const {
    subtitle,
    label,
    label2,
    options,
    stageOptions,
    selectDealsFilter,
    setSelectDealsFilter,
    showSecondFilter,
    selectStageFilter,
    setSelectStageFilter,
  } = props;
  const isLargeScreen = useMediaQuery('(min-width:1280px)');
  // const isSmallScreen = useMediaQuery('(max-width:768px)');

  const handleSelectFilter = (val) => {
    setSelectDealsFilter(val);
  };

  const handleSelectStage = (val) => {
    setSelectStageFilter(val);
  };

  return (
    <Grid container item xs={12}>
      <Grid container item xs={12} md={isLargeScreen ? 12 : 12} sx={{ mb: 4 }}>
        <Typography
          variant="body2"
          color="textSecondary"
          sx={{ color: 'text.hint' }}
        >
          {subtitle}
        </Typography>
      </Grid>
      <Grid
        container
        item
        xs={12}
        md={isLargeScreen ? 12 : 12}
        sx={{
          ...selectFilters,
        }}
      >
        <SingleselectField
          label={label && label}
          options={options}
          val={selectDealsFilter ?? 'This Year'}
          handleSelect={handleSelectFilter}
        />
      </Grid>
      {showSecondFilter && (
        <Grid
          container
          item
          xs={12}
          md={isLargeScreen ? 12 : 12}
          sx={{
            mt: 4,
            ...selectFilters,
          }}
        >
          <SingleselectField
            label={label2 && label2}
            options={stageOptions}
            val={selectStageFilter ?? 'Contacted'}
            handleSelect={handleSelectStage}
          />
        </Grid>
      )}
    </Grid>
  );
};

export default FilterDeals;
