// eslint-disable-next-line no-unused-vars
import React from 'react';
// eslint-disable-next-line no-unused-vars
import { Typography } from '@mui/material';
import i18n from '../utils/i18n';
import IBAN from 'iban';

const regexVAT =
  /^((AT)?U[0-9]{8}|(BE)?0[0-9]{9}|(BG)?[0-9]{9,10}|(CY)?[0-9]{8}L|(CZ)?[0-9]{8,10}|(DE)?[0-9]{9}|(DK)?[0-9]{8}|(EE)?[0-9]{9}|(EL|GR)?[0-9]{9}|(ES)?[0-9A-Z][0-9]{7}[0-9A-Z]|(FI)?[0-9]{8}|(FR)?[0-9A-Z]{2}[0-9]{9}|(GB)?([0-9]{9}([0-9]{3})?|[A-Z]{2}[0-9]{3})|(HU)?[0-9]{8}|(IE)?[0-9]S[0-9]{5}L|(IT)?[0-9]{11}|(LT)?([0-9]{9}|[0-9]{12})|(LU)?[0-9]{8}|(LV)?[0-9]{11}|(MT)?[0-9]{8}|(NL)?[0-9]{9}B[0-9]{2}|(PL)?[0-9]{10}|(PT)?[0-9]{9}|(RO)?[0-9]{2,10}|(SE)?[0-9]{12}|(SI)?[0-9]{8}|(SK)?[0-9]{10})$/;

const regEmail =
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
// user details
export function userDetailsSectionsData(data) {
  return data
    ? {
        userData: [
          {
            fieldName: 'firstName',
            label: i18n.t('my_account.firstname'),
            inputType: 'text',
            hasEdit: true,
            isRequired: true,
            maxLength: 60,
            minLength: 1,
            pattern: new RegExp('(^.{1,60}$)'),
            errorMsg: i18n.t('input_error.firstname'),
            valid: /(^.{1,60}$)/.test(data.firstName),
            value: data.firstName ?? '',
          },
          {
            fieldName: 'lastName',
            label: i18n.t('my_account.lastname'),
            inputType: 'text',
            hasEdit: true,
            isRequired: true,
            maxLength: 60,
            minLength: 1,
            pattern: new RegExp('(^.{1,60}$)'),
            errorMsg: i18n.t('input_error.lastName'),
            valid: /(^.{1,60}$)/.test(data.lastName),
            value: data.lastName ?? '',
          },
          {
            fieldName: 'email',
            label: i18n.t('my_account.email'),
            inputType: 'text',
            hasEdit: false,
            isRequired: true,
            maxLength: 60,
            minLength: 1,
            pattern: regEmail,
            errorMsg: i18n.t('input_error.email'),
            valid: regEmail.test(data.email),
            value: data.email ?? '',
          },
          {
            fieldName: 'phone',
            label: i18n.t('my_account.phone'),
            inputType: 'phone',
            hasEdit: true,
            isRequired: true,
            maxLength: 60,
            minLength: 10,
            // 12
            pattern: new RegExp('(^.{10,60}$)'),
            // pattern: new RegExp('(^[+]?[0-9]{10,60}$)'),
            errorMsg: i18n.t('input_error.phone'),
            valid: /(^.{10,60}$)/.test(data.phone?.split(' ').join('')),
            // valid: /(^[+]?[0-9]{10,60}$)/.test(data.phone),
            value: data.phone ?? '',
          },
        ],
      }
    : {};
}
// company details
export function companyDetailsSectionsData(data) {
  return data
    ? {
        companyData: [
          {
            fieldName: 'name',
            label: i18n.t('my_account.companyname'),
            inputType: 'text',
            hasEdit: true,
            isRequired: true,
            maxLength: 60,
            minLength: 1,
            pattern: new RegExp('(^.{1,60}$)'),
            errorMsg: i18n.t('input_error.companyname'),
            valid: /(^.{1,60}$)/.test(data.name),
            value: data.name ?? '',
          },
          {
            fieldName: 'registrationNumber',
            label: i18n.t('my_account.kvk'),
            inputType: 'number',
            hasEdit: true,
            isRequired: true,
            maxLength: 8,
            minLength: 8,
            pattern: new RegExp('(^[0-9]{8}$)'),
            errorMsg: i18n.t('input_error.kvk'),
            valid: /(^[0-9]{8}$)/.test(data.registrationNumber),
            value: data.registrationNumber ?? '',
          },
          {
            fieldName: 'vatNumber',
            label: i18n.t('my_account.vat'),
            inputType: 'text',
            hasEdit: true,
            isRequired: true,
            maxLength: 20,
            minLength: 4,
            // pattern: new RegExp('(^.{1,12}$)'),
            pattern: new RegExp(
              '(^((AT)?U[0-9]{8}|(BE)?0[0-9]{9}|(BG)?[0-9]{9,10}|(CY)?[0-9]{8}L|(CZ)?[0-9]{8,10}|(DE)?[0-9]{9}|(DK)?[0-9]{8}|(EE)?[0-9]{9}|(EL|GR)?[0-9]{9}|(ES)?[0-9A-Z][0-9]{7}[0-9A-Z]|(FI)?[0-9]{8}|(FR)?[0-9A-Z]{2}[0-9]{9}|(GB)?([0-9]{9}([0-9]{3})?|[A-Z]{2}[0-9]{3})|(HU)?[0-9]{8}|(IE)?[0-9]S[0-9]{5}L|(IT)?[0-9]{11}|(LT)?([0-9]{9}|[0-9]{12})|(LU)?[0-9]{8}|(LV)?[0-9]{11}|(MT)?[0-9]{8}|(NL)?[0-9]{9}B[0-9]{2}|(PL)?[0-9]{10}|(PT)?[0-9]{9}|(RO)?[0-9]{2,10}|(SE)?[0-9]{12}|(SI)?[0-9]{8}|(SK)?[0-9]{10})$)',
            ),
            errorMsg: i18n.t('input_error.vat'),
            valid: regexVAT.test(data.vatNumber),
            /*valid:
              /(^[A-Za-z]{2,4}(?=.{2,12}$)[-_\s0-9]*(?:[a-zA-Z][-_\s0-9]*){0,2}$)/.test(
                data.vatNumber,
              ),*/
            // valid: /(^.{0,12}$)/.test(data.vatNumber),
            value: data.vatNumber ?? '',
          },
          {
            fieldName: 'ibanNumber',
            label: i18n.t('my_account.iban'),
            inputType: 'text',
            hasEdit: true,
            isRequired: true,
            maxLength: 40,
            minLength: 1,
            pattern: new RegExp('(^[A-Z]{2}(?:[ ]?[0-9]){18,20}$)'),
            errorMsg: i18n.t('input_error.iban'),
            // valid: /(^[A-Z]{2}(?:[ ]?[0-9]){18,20}$)/.test(data.ibanNumber),
            valid: IBAN.isValid(data.ibanNumber),
            value: data.ibanNumber ?? '',
          },
          {
            fieldName: 'billingStreet',
            label: i18n.t('my_account.billing_street'),
            inputType: 'text',
            hasEdit: true,
            isRequired: true,
            maxLength: 60,
            minLength: 1,
            validType: 'address',
            pattern: new RegExp('(^[1-9][0-9\\s ]{0,5}$)'),
            errorMsg: i18n.t('input_error.street'),
            valid: /(^[1-9][0-9\\s ]{0,5}$)/.test(
              data.billingStreet?.split(' ')[
                data.billingStreet?.split(' ').length - 1
              ],
            ),
            value: data.billingStreet ?? '',
          },
          {
            fieldName: 'billingHouseNumberAddition',
            label: i18n.t('my_account.house_number_addition'),
            inputType: 'text',
            hasEdit: true,
            isRequired: false,
            maxLength: 12,
            minLength: 0,
            pattern: new RegExp('(^.{0,12}$)'),
            errorMsg: i18n.t('input_error.house_number_addition'),
            valid: /(^.{0,12}$)/.test(data.billingHouseNumberAddition),
            value: data.billingHouseNumberAddition ?? '',
          },
          {
            fieldName: 'billingCity',
            label: i18n.t('my_account.billing_city'),
            inputType: 'text',
            hasEdit: true,
            isRequired: true,
            maxLength: 30,
            minLength: 1,
            pattern: new RegExp('(^.{1,30}$)'),
            errorMsg: i18n.t('input_error.city'),
            valid: /(^.{1,30}$)/.test(data.billingCity),
            value: data.billingCity ?? '',
          },
          {
            fieldName: 'billingPostcode',
            label: i18n.t('my_account.billing_postal_code'),
            inputType: 'text',
            hasEdit: true,
            isRequired: true,
            maxLength: 6,
            minLength: 6,
            pattern: new RegExp('(^[1-9][0-9]{3}[A-Z]{2}$)'),
            errorMsg: i18n.t('input_error.postal_code'),
            valid: /(^[1-9][0-9]{3}[A-Z]{2}$)/.test(data.billingPostcode),
            value: data.billingPostcode ?? '',
          },
        ],
      }
    : {};
}

export const userDetailsSectionsDatas = (data) => {
  return [
    {
      sectionId: 'first-section',
      title: 'My profile',
      content: [
        {
          label: 'voornaam',
          inputType: 'text',
          value: data.firstName,
        },
        {
          label: 'achternaam',
          inputType: 'text',
          value: data.lastName,
        },
        {
          label: 'email',
          inputType: 'text',
          value: data.email,
        },
        {
          label: 'phone',
          inputType: 'text',
          value: data.phone,
        },
      ],
    },
  ];
};
