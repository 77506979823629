import React, { createContext, useState } from 'react';

export const LoginContext = createContext();

export const LoginProvider = ({ children }) => {
  const [passReset, setPassReset] = useState(false);
  const [locale, setLocale] = useState('nl');

  return (
    <LoginContext.Provider
      value={{
        passReset,
        setPassReset,
        locale,
        setLocale,
      }}
    >
      {children}
    </LoginContext.Provider>
  );
};
