import React, { useState, useEffect, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useIsMounted } from '../../utils/customHooks';
import axios from 'axios';
import { ReactComponent as Startup } from '../../assets/MoneytreeTwoColor.svg';
import { UserContext } from '../../contexts/UserContext';
import { WorkspaceContext } from '../../contexts/WorkspaceContext';
import { root, box, dealsBox } from '../styles';
import { Grid, Box, Typography } from '@mui/material';
import {
  dealsFilterOptions,
  dealsStageOptions,
} from '../../utils/dataMapValues';
import Loader from '../../uiComponents/Loader';
import PageDescriptionCard from '../../uiComponents/PageDescriptionCard';
import FilterDeals from './FilterDeals';
import DealsMainContent from './DealsMainContent';
import DealsSmallContent from './DealsSmallContent';
import { useStyles } from './styles';

const DealsDetails = () => {
  const classes = useStyles();
  const isLargeScreen = useMediaQuery('(min-width:1280px)');
  const isSmallScreen = useMediaQuery('(max-width:996px)');
  const { t } = useTranslation();
  const isMounted = useIsMounted();
  // eslint-disable-next-line no-unused-vars
  const [userContext, setUserContext] = useContext(UserContext);
  const { locale } = useContext(WorkspaceContext);
  const [loading, setLoading] = useState(false);

  const filterOptions = dealsFilterOptions();
  const stageOptions = dealsStageOptions();

  const [dataContacted, setDataContacted] = useState([]);
  const [dataInterested, setDataInterested] = useState([]);
  const [dataWaiting, setDataWaiting] = useState([]);
  const [dataWon, setDataWon] = useState([]);
  const [dataLost, setDataLost] = useState([]);

  const [expandedCard, setExpandedCard] = useState(false);
  const [expandedId, setExpandedId] = useState('');

  const [selectDealsFilter, setSelectDealsFilter] = useState('All Time');
  const [selectStageFilter, setSelectStageFilter] = useState('Closed');

  const apiEndPoint =
    process.env.NODE_ENV === 'development'
      ? process.env.REACT_APP_API_ENDPOINT
      : process.env.REACT_APP_API_ENDPOINT_PROD;

  useEffect(() => {
    setLoading(true);

    axios({
      method: 'get',
      url: apiEndPoint + '/v1/partner/deals/getDeals',
      withCredentials: true,
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        Authorization: `Bearer ${userContext.token}`,
      },
      params: {
        option: selectDealsFilter ? selectDealsFilter : 'All Time',
      },
    })
      .then((res) => {
        let rawData1 = [];
        let rawData2 = [];
        let rawData3 = [];
        let rawData4 = [];
        let rawData5 = [];
        if (res.data && res.data.length > 0) {
          res.data.forEach((item) => {
            switch (item.StageName) {
              case `Afspraak gehad (D)`:
              case 'Contact gehad (S)':
                rawData1.push(item);
                break;
              case 'Interesse voor voorstel (C)':
                rawData2.push(item);
                break;
              case 'Offerte wacht op goedkeuring (B)':
              case 'Binnen!':
                rawData3.push(item);
                break;
              case 'Closed Won':
                rawData4.push(item);
                break;
              case 'Closed Lost':
              case 'Closed, no potential':
                rawData5.push(item);
                break;
              default:
                rawData1.push(item);
            }
          });
        }

        if (isMounted.current) {
          setDataContacted(rawData1);
          setDataInterested(rawData2);
          setDataWaiting(rawData3);
          setDataWon(rawData4);
          setDataLost(rawData5);
          setLoading(false);
        }
      })
      .catch((error) => {
        if (isMounted.current) {
          setLoading(false);
        }
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectDealsFilter]);

  useEffect(() => {
    window.document.title = t('page_title.deals');
    setSelectDealsFilter('All Time');
    setSelectStageFilter('Closed');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      {loading && <Loader loading={loading} />}
      <Grid container className={classes.root}>
        <Grid
          container
          item
          xs={12}
          alignItems={'flex-start'}
          alignContent={'flex-start'}
          sx={{ ...root, height: 1 }}
        >
          <Grid item xs={12}>
            <Box mb={2}>
              <Typography variant='h5' align='center'>
                {t('deals.title_1')}
              </Typography>
            </Box>
            <Box mb={3}>
              <Typography
                align='center'
                color='textSecondary'
                sx={{ color: 'text.hint' }}
              >
                {t('deals.subtitle_1')}
              </Typography>
            </Box>
          </Grid>

          <Grid container item xs={12} sx={box}>
            <Grid item xs={12} sx={{ mb: 3 }}>
              <PageDescriptionCard
                customMaxWidth={1024}
                hasContent
                hasCardSide={isLargeScreen ? true : false}
                cardTitle={t('deals.title_2')}
                cardContentComponent={
                  <FilterDeals
                    minWidth={230}
                    label={t('deals.labels.create_date')}
                    label2={t('deals.filter.label_2')}
                    placeholder={t('deals.labels.create_date')}
                    subtitle={t('deals.subtitle_2')}
                    options={filterOptions}
                    stageOptions={stageOptions}
                    selectDealsFilter={selectDealsFilter}
                    setSelectDealsFilter={setSelectDealsFilter}
                    showSecondFilter={isSmallScreen}
                    selectStageFilter={selectStageFilter}
                    setSelectStageFilter={setSelectStageFilter}
                  />
                }
                cardImage={
                  <Startup
                    style={{
                      width: isLargeScreen ? 'auto' : '100%',
                      float: 'right',
                    }}
                  />
                }
              />
            </Grid>
          </Grid>

          <Grid
            container
            item
            xs={12}
            sx={{
              ...box,
              ...dealsBox,
            }}
          >
            <Grid item xs={12} sx={{ height: 1 }}>
              {isSmallScreen ? (
                <DealsSmallContent
                  selectDealsFilter={selectDealsFilter}
                  data={
                    selectStageFilter && selectStageFilter === 'Contacted'
                      ? dataContacted
                      : selectStageFilter === 'Interested'
                      ? dataInterested
                      : selectStageFilter === 'Waiting'
                      ? dataWaiting
                      : selectStageFilter === 'Closed'
                      ? [...dataWon, ...dataLost]
                      : []
                  }
                  title={
                    selectStageFilter && selectStageFilter === 'Contacted'
                      ? t('deals.section.title1')
                      : selectStageFilter === 'Interested'
                      ? t('deals.section.title2')
                      : selectStageFilter === 'Waiting'
                      ? t('deals.section.title3')
                      : selectStageFilter === 'Closed'
                      ? t('deals.section.title4')
                      : ''
                  }
                  subtitle={
                    selectStageFilter && selectStageFilter === 'Contacted'
                      ? t('deals.section.subtitle1')
                      : selectStageFilter === 'Interested'
                      ? t('deals.section.subtitle2')
                      : selectStageFilter === 'Waiting'
                      ? t('deals.section.subtitle3')
                      : selectStageFilter === 'Closed'
                      ? t('deals.section.subtitle4')
                      : ''
                  }
                  expanded={expandedCard}
                  setExpanded={setExpandedCard}
                  expandedId={expandedId}
                  setExpandedId={setExpandedId}
                  // data={dataStageName[selectDealsFilter]}
                />
              ) : (
                <DealsMainContent
                  customMaxWidth={1024}
                  selectDealsFilter={selectDealsFilter}
                  dataContacted={dataContacted}
                  dataInterested={dataInterested}
                  dataWaiting={dataWaiting}
                  dataWon={dataWon}
                  dataLost={dataLost}
                  locale={locale}
                  expanded={expandedCard}
                  setExpanded={setExpandedCard}
                  expandedId={expandedId}
                  setExpandedId={setExpandedId}
                />
              )}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

export default DealsDetails;
