import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles((theme) =>
  createStyles({
    '@global': {
      '*': {
        boxSizing: 'border-box',
        margin: 0,
        padding: 0,
      },
      html: {
        '-webkit-font-smoothing': 'antialiased',
        '-moz-osx-font-smoothing': 'grayscale',
        height: '100%',
        width: '100%',
      },
      body: {
        height: '100%',
        width: '100%',
        margin: '0 !important',
        padding: '0 !important',
        overflow: 'auto !important',
      },
      a: {
        textDecoration: 'none',
        color: 'inherit',
      },
      '#root': {
        height: '100%',
        width: '100%',
      },
      '.Mui-focused': {
        '& .MuiAutocomplete-popupIndicator': {
          color: theme.palette.primary.main,
        },
        '& .MuiIconButton': {
          color: theme.palette.primary.main,
        },
        '& .MuiIconButton-edgeEnd': {
          color: theme.palette.primary.main,
        },
        '& .MuiInputAdornment-root': {
          '& .MuiIconButton-root': {
            color: theme.palette.primary.main,
          },
        },
        '& .MuiSelect-icon': {
          fill: theme.palette.primary.main,
        },
      },
      '.MuiSvgIcon-root.activeIcon': {
        '&::hover': {
          color: theme.palette.primary.main,
        },
      },
    },
  }),
);

const GlobalStyles = () => {
  useStyles();

  return null;
};

export default GlobalStyles;
