import React from 'react';
import makeStyles from '@mui/styles/makeStyles';
import {
  Grid,
  Button,
  IconButton,
  Menu,
  MenuItem,
  Typography,
  Divider,
  Link,
  Tooltip,
} from '@mui/material';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import FiberManualRecordRoundedIcon from '@mui/icons-material/FiberManualRecordRounded';
import { useHistory } from 'react-router-dom';
import CustomDialog from './CustomDialog';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles((theme) => ({
  listItem: {
    '& .MuiListItem-button:hover': {
      backgroundColor: '#ffffff',
      cursor: 'default',
    },
    '& .MuiMenuItem-root:hover': {
      backgroundColor: '#ffffff',
      cursor: 'default',
    },
  },

  button: {
    '&:hover': {
      backgroundColor: 'none',
    },
    '& button': {
      border: '1px solid #E0E0E0',
      '&:hover': {
        border: '1px solid #E0E0E0',
        backgroundColor: '#FAFAFA',
      },
    },
  },
}));

const ProfileMenu = (props) => {
  const history = useHistory();
  const classes = useStyles();
  const { t } = useTranslation();
  const [openDialog, setOpenDialog] = React.useState(false);

  return (
    <>
      <div className={props.classUser}>
        <IconButton
          aria-controls='simple-menu'
          aria-haspopup='true'
          onClick={props.handleClick}
          size='large'
        >
          <Tooltip
            title={
              <div>
                <Typography variant='body2' style={{ fontWeight: 'bold' }}>
                  SubsidyCloud Account
                </Typography>
                <Typography style={{}}>
                  {props.user.firstName} {props.user.lastName}
                </Typography>
                <Typography variant='body2'>{props.user.email}</Typography>
              </div>
            }
            placement='bottom-end'
          >
            <AccountCircleIcon
              fontSize='large'
              style={{
                color: '#36B37E',
              }}
            />
          </Tooltip>
        </IconButton>

        <Menu
          id='simple-menu'
          anchorEl={props.anchorEl}
          keepMounted
          open={Boolean(props.anchorEl)}
          onClose={props.handleClose}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          style={{ marginLeft: '-12px', marginTop: '-4px' }}
          className={classes.listItem}
        >
          <MenuItem>
            <Grid container>
              <Grid
                container
                item
                xs={12}
                justifyContent='center'
                style={{
                  marginBottom: 12,
                }}
              >
                <AccountCircleIcon
                  fontSize='large'
                  style={{
                    color: '#BDBDBD',
                    width: 72,
                    height: 72,
                  }}
                />
              </Grid>
              <Grid container item xs={12} justifyContent='center'>
                <Typography
                  style={{
                    fontWeight: 'bold',
                  }}
                >
                  {props.user.firstName} {props.user.lastName}
                </Typography>
              </Grid>
              <Grid container item xs={12} justifyContent='center'>
                <Typography variant='body2'>{props.user.email}</Typography>
              </Grid>
            </Grid>
          </MenuItem>
          <MenuItem>
            <Grid
              container
              justifyContent='center'
              style={{
                marginBottom: 18,
                marginTop: 10,
              }}
            >
              <Grid
                container
                item
                xs={12}
                justifyContent='center'
                className={classes.button}
              >
                <Button
                  variant='outlined'
                  style={{ color: 'rgba(0, 0, 0, 0.87)' }}
                  onClick={() => {
                    history.push('/app/account');
                  }}
                >
                  {t('my_account.go_to')}
                </Button>
              </Grid>
            </Grid>
          </MenuItem>
          <MenuItem style={{ padding: 0 }}>
            <Divider style={{ width: '100%', height: 1 }} />
          </MenuItem>
          <MenuItem>
            <Grid
              container
              justifyContent='center'
              style={{
                marginBottom: 8,
                marginTop: 8,
              }}
            >
              <Grid
                container
                item
                xs={12}
                justifyContent='center'
                className={classes.button}
              >
                <Button
                  variant='outlined'
                  style={{ color: 'rgba(0, 0, 0, 0.87)' }}
                  onClick={() => {
                    setOpenDialog(true);
                  }}
                >
                  {t('login.logout')}
                </Button>
              </Grid>
            </Grid>
          </MenuItem>
          <MenuItem style={{ padding: 0 }}>
            <Divider style={{ width: '100%', height: 1 }} />
          </MenuItem>
          <MenuItem>
            <Grid container style={{ padding: 4, paddingTop: 12 }}>
              <Grid
                container
                item
                xs={12}
                justifyContent='center'
                alignItems='center'
              >
                <Typography
                  variant='caption'
                  component='span'
                  display={'inline'}
                >
                  <Link
                    href={t('my_account.privacy_link')}
                    target='_blank'
                    rel='noopener noreferrer'
                    color='textSecondary'
                    underline='hover'
                  >
                    {t('my_account.privacy')}
                  </Link>
                </Typography>{' '}
                <FiberManualRecordRoundedIcon
                  style={{
                    color: '#757575',
                    width: 8,
                    height: 8,
                    margin: '0 12px',
                  }}
                />{' '}
                <Typography
                  variant='caption'
                  component='span'
                  display={'inline'}
                >
                  <Link
                    href={t('my_account.conditions_link')}
                    target='_blank'
                    rel='noopener noreferrer'
                    color='textSecondary'
                    underline='hover'
                  >
                    {t('my_account.conditions')}
                  </Link>
                </Typography>
              </Grid>
            </Grid>
          </MenuItem>
        </Menu>
      </div>

      <CustomDialog
        openDialog={openDialog}
        setOpenDialog={setOpenDialog}
        handleConfirm={props.handlelogout}
        handleClose={props.handleClose}
        title={t('login.logout')}
        text={t('dialog.text3')}
        cancel={t('buttons.close')}
        confirm={t('login.logout')}
      />
    </>
  );
};

export default ProfileMenu;
