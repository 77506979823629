import React, { useState, useEffect } from 'react';
import makeStyles from '@mui/styles/makeStyles';
import moment from 'moment';
import SortTableHead from './SortTableHead';
import NoResultsFound from './NoResultsFound';
import { getComparator, stableSort } from './utils';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TablePagination,
  TableRow,
} from '@mui/material';
import i18n from 'i18next';
import { withRouter } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  table: {
    '& .MuiTableRow-hover': {},
  },
  tableCell: {
    color: theme.palette.primary.main,
  },
  tableRow: {
    cursor: 'pointer',
  },
}));

const ApplicationDocumentTable = (props) => {
  const classes = useStyles();
  const [order, setOrder] = useState('asc');
  const [orderBy, setOrderBy] = useState('name');
  const { page, setPage } = props;
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [locale, setLocale] = useState('en');
  const selectLocale = React.useCallback((locale) => {
    setLocale(i18n.languages[0]);
  }, []);
  useEffect(() => {
    selectLocale();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  return (
    <div className={classes.root}>
      <TableContainer style={{}}>
        <Table
          className={classes.table}
          aria-labelledby='tableTitle'
          aria-label='enhanced table'
        >
          {props.headCells && (
            <SortTableHead
              classes={classes}
              order={order}
              orderBy={orderBy}
              onRequestSort={handleRequestSort}
              rowCount={props.data.length}
              headCells={props.headCells}
              active={true}
            />
          )}
          {props.data && props.data.length > 0 ? (
            <TableBody>
              {stableSort(props.data, getComparator(order, orderBy))
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row, index) => {
                  return (
                    <TableRow
                      hover
                      tabIndex={-1}
                      key={row.id || index}
                      className={classes.tableRow}
                    >
                      <TableCell
                        id={row.name}
                        className={classes.tableCell}
                        onClick={() => {
                          props.handleOpenFile(
                            row.fileId,
                            row.filename,
                            row.type
                          );
                        }}
                      >
                        <span title='Download'>{row.filename}</span>
                      </TableCell>
                      <TableCell style={{ cursor: 'initial' }}>
                        {row.createdAt &&
                          moment(row.createdAt)
                            .locale(locale)
                            .format('DD MMM YYYY')}
                      </TableCell>
                    </TableRow>
                  );
                })}
            </TableBody>
          ) : (
            <NoResultsFound span={6} />
          )}
        </Table>
      </TableContainer>
      {props.data && props.data.length > 10 && (
        <TablePagination
          rowsPerPageOptions={[]}
          component='div'
          count={props.data.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      )}
    </div>
  );
};

export default withRouter(ApplicationDocumentTable);
