import makeStyles from '@mui/styles/makeStyles';
import { grey } from '@mui/material/colors';

export const useStyles = makeStyles((theme) => ({
  root: {
    padding: '48px 24px',
    minHeight: 'calc(100% - 72px)',
    [theme.breakpoints.down('768')]: {
      minHeight: 'calc(100% - 64px)',
      padding: '24px 16px',
    },
  },
  container: {
    maxWidth: 1024,
  },
  card: {
    width: '100%',
    borderRadius: 8,
    '& .MuiCardContent-root': {
      padding: 24,
      [theme.breakpoints.down('768')]: {
        padding: 16,
      },
    },
    '& .MuiCardActions-root': {
      padding: '6px 24px',
      [theme.breakpoints.down('768')]: {
        padding: '6px 16px',
      },
    },
  },
  box: {
    marginBottom: 24,
  },
  searchField: {
    '& input + div svg': {
      fill: theme.palette.text.secondary,
    },
    '& input + div svg:hover': {
      fill: theme.palette.primary.main,
    },
    '& .Mui-focused svg': {
      fill: theme.palette.primary.main,
    },
  },
  filterItem: {
    paddingRight: 24,
    paddingBottom: 10,
  },
  formControlStatus: {
    minWidth: 185,
    maxWidth: 185,
    '& .MuiSelect-root.MuiInputBase-input': {},
  },
  resetButton: {
    '&:hover': {
      borderColor: grey[400],
    },
  },
  formControlSelect: {
    height: 32,

    color: '#fff',
    '& .Mui-focused': {
      border: 'none !important',
      borderColor: 'transparent !important',
    },
    '& .MuiOutlinedInput-root.Mui-focused': {
      border: 'none',
      borderColor: 'transparent',
    },
    '& .MuiOutlinedInput-input': {
      padding: 0,
      fontSize: 14,
    },
    '& .MuiInputBase-input': {
      height: 32,
    },
    '& .MuiSelect-outlined.MuiSelect-outlined': {
      paddingRight: 40,
      paddingLeft: 10,
      fontSize: 14,
      lineHeight: 2,
      backgroundColor: theme.palette.primary.main,
      border: 'none',
      borderColor: 'transparent',
    },
    '& .MuiSelect-selectMenu': {
      //paddingRight: 40,
      //paddingLeft: 10,
      //height: 32,
      color: '#fff',
      //padding: 0,
    },
    '& .MuiSelect-select': {
      //paddingRight: 40,
      //paddingLeft: 10,
      //height: 32,
      color: '#fff',
      //padding: 0,
    },
    '& .MuiSelect-root': {
      //paddingRight: 40,
      //paddingLeft: 10,
      //height: 32,
      //color: '#fff',
      //padding: 0,
    },
    '& .MuiSelect-root span': {
      //paddingLeft: 10,
      height: 32,
      color: '#fff',
      padding: 0,
    },
    '& .MuiSvgIcon-root': {
      // fontSize: '1.125rem',
    },
    '& .MuiSelect-icon': {
      padding: 7,
      height: 32,
      width: 32,
      top: 'calc(50% - 16px)',
      right: 0,
      backgroundColor: theme.palette.primary.dark,
      fill: '#fff',
      borderRadius: '0 4px 4px 0',
    },
  },
  mainContainer: {
    height: '100%',
    overflow: 'hidden',
    '& > div': {
      height: '100%',
      width: '100%',
    },
  },
  innerTabPanel: {
    height: 'calc(100% - 49px)',
    overflowY: 'auto',
  },
  innerTabPanelScroll: {
    paddingBottom: 56,
  },
  customTab: {
    textTransform: 'none',
    fontWeight: 700,
    minWidth: 'fit-content',
    paddingLeft: 16,
    paddingRight: 16,
  },
  containerTitle: {
    fontWeight: 300,
  },
  table: {
    minWidth: 976,
    '& .MuiTableRow-hover': {
      cursor: 'pointer',
    },
  },
  detailsHeader: {
    //paddingBottom: 16,
    zIndex: 1,
  },
  infoSide: {
    overflow: 'hidden',
  },
  infoSideIconContainer: {
    width: 64,
    height: 64,
    borderRadius: '50%',
    background: grey[300],
  },
  infoSideIcon: {
    fontSize: 40,
    '&.MuiSvgIcon-root': {
      fill: '#fff',
    },
  },
  infoSideText: {
    padding: '0 24px',
  },
  actionSide: {
    //flexGrow: 1,
    splitBtn: {
      pointerEvents: 'none ',
    },
    // '& :not:(.mailDownloadButton)': {
    '& .MuiButton-root.MuiButtonGroup-grouped.MuiButtonBase-root:first-of-type':
      {
        pointerEvents: 'none !important',
      },
    '& .MuiButton-root:first-of-type': {
      borderRadius: '4px 0 0 4px !important',
      width: 'fit-content',
      minWidth: 96,
    },
    '& .MuiButton-root:last-of-type': {
      borderRadius: '0 4px 4px 0 !important',
      backgroundColor: theme.palette.primary.dark,
    },
    '& > div > div > div': {
      alignItems: 'flex-end',
    },
    // },
  },
  mailDownload: {
    maxWidth: 312,
    marginLeft: 'auto',
    '& .mailDownloadButton': {
      '& .MuiButton-root:first-of-type': {
        borderRadius: '20px !important',
        width: 'fit-content',
        minWidth: 96,
      },
      '& .MuiButton-root:last-of-type': {
        borderRadius: '20px !important',
        backgroundColor: 'none',
      },
    },
  },
  mailDownloadButton: {
    '& .MuiButton-root:first-of-type': {
      borderRadius: '20px !important',
      width: 'fit-content',
      minWidth: 96,
    },
    '& .MuiButton-root:last-of-type': {
      borderRadius: '20px !important',
      backgroundColor: 'transparent !important',
      '&:hover': {
        backgroundColor: 'rgba(35, 123, 255, 0.04) !important',
      },
    },
  },
  websiteIcon: {
    width: 'fit-content',
    '&:hover svg': {
      fill: theme.palette.primary.main,
    },
  },
  sectionRow: {
    alignItems: 'center',
    padding: '8px 0',
    '& > div:first-of-type': {
      paddingRight: 16,
    },
    '& .MuiChip-root': {
      backgroundColor: grey[200],
    },
  },

  subheaderFixed: {},
  tabpanelFixed: {
    overflowY: 'auto',
  },
  tabpanelScroll: {
    paddingBottom: 56,
  },
  inputFieldEdit: {
    maxWidth: 292,
  },
  deleteIcon: {
    '&:hover': {
      // fill: theme.palette.primary.main,
    },
  },
  dropzoneBox: {
    '& .dzu-previewContainer': {
      display: 'none !important',
    },
  },
}));
