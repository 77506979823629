import React from 'react';
import DashboardDetails from '../components/Dashboard/DashboardDetails';
import DashboardLayout from '../layouts/DashboardLayout';

const Dashboard = (props) => {
  React.useEffect(() => {
    window.scrollTo(0, 0);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <>
        <DashboardLayout header='Dashboard' main={<DashboardDetails />} />
      </>
    </>
  );
};

export default Dashboard;
