import React, { useState } from 'react';
import { useTheme } from '@mui/material/styles';
import withStyles from '@mui/styles/withStyles';
import {
  Grid,
  Divider,
  Typography,
  TextField,
  Box,
  FormControlLabel,
  FormGroup,
  Switch,
  Tooltip,
  Slider,
  ListItem,
  List,
  Autocomplete,
} from '@mui/material';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { useTranslation } from 'react-i18next';
import { subSectorsMap } from '../../data/projectOptions';

const LargeTooltip = withStyles({
  tooltip: {
    width: 350,
    maxWidth: 'unset',
  },
})(Tooltip);

const NewProductBaseDetails = (props) => {
  const {
    classes,
    isLarge,
    isLargeScreen,
    project,
    setProject,
    handleChange,
    showMoreDetails,
    handleChangeSwitch,
    label1,
    label2,
    label3,
    label4,
    label5,
    label6,
    label7,
    label8,
    helper1,
    helper3,
    helper4,
    placeholder1,
    placeholder2,
    sectorOptions,
    subSectorOptions,
    sectors,
    setSectors,
    subSectors,
    setSubSectors,
  } = props;

  const [subSectorDependentOptions, setSubSectorDependentOptions] = useState(
    []
  );

  const theme = useTheme();
  const { t } = useTranslation();
  const marks = [
    {
      value: 0,
      label: '',
    },
    {
      value: 1,
      label: '',
    },
    {
      value: 2,
      label: '',
    },
    {
      value: 3,
      label: '',
    },
    {
      value: 4,
      label: '',
    },
    {
      value: 5,
      label: '',
    },
    {
      value: 6,
      label: '',
    },
    {
      value: 7,
      label: '',
    },
    {
      value: 8,
      label: '',
    },
    {
      value: 9,
      label: '',
    },
    {
      value: 10,
      label: '',
    },
    {
      value: 11,
      label: '',
    },
    {
      value: 12,
      label: '',
    },
    {
      value: 13,
      label: '',
    },
    {
      value: 14,
      label: '',
    },
    {
      value: 15,
      label: '',
    },
    {
      value: 16,
      label: '',
    },
    {
      value: 17,
      label: '',
    },

    {
      value: 18,
      label: '',
    },
  ];

  const startDates = [
    { label: t('months_options.m_1'), value: '6 Months Ago' },
    { label: t('months_options.m_2'), value: '5 Months Ago' },
    { label: t('months_options.m_3'), value: '4 Months Ago' },
    { label: t('months_options.m_4'), value: '3 Months Ago' },
    { label: t('months_options.m_5'), value: '2 Months Ago' },
    { label: t('months_options.m_6'), value: '1 Months Ago' },
    { label: t('months_options.m_7'), value: 'This Month' },
    { label: t('months_options.m_8'), value: 'Next Month' },
    { label: t('months_options.m_9'), value: 'Next 2 Month' },
    { label: t('months_options.m_10'), value: 'Next 3 Month' },
    { label: t('months_options.m_11'), value: 'Next 4 Month' },
    { label: t('months_options.m_12'), value: 'Next 5 Month' },
    { label: t('months_options.m_13'), value: 'Next 6 Month' },
    { label: t('months_options.m_14'), value: 'Next 7 Month' },
    { label: t('months_options.m_15'), value: 'Next 8 Month' },
    { label: t('months_options.m_16'), value: 'Next 9 Month' },
    { label: t('months_options.m_17'), value: 'Next 10 Month' },
    { label: t('months_options.m_18'), value: 'Next 11 Month' },
    { label: t('months_options.m_19'), value: 'Next 12 Month' },
  ];
  const [value, setValue] = React.useState(6);

  const handleSlider = (event, newValue) => {
    setValue(newValue);
    setProject({ ...project, intendedStartDate: valueLabelFormat(newValue) });
  };

  function valueLabelFormat(value) {
    const x = marks.findIndex((mark, i) => mark.value === value);
    return startDates[parseInt(x)].value;
  }

  function valueLabelFormatSlider(value) {
    const x = marks.findIndex((mark, i) => mark.value === value);
    return startDates[parseInt(x)].label;
  }

  React.useEffect(() => {
    if (project.intendedStartDate) {
      try {
        let x = project.intendedStartDate;
        let i;
        startDates.forEach((startDate, index) => {
          if (startDate.value === x) {
            i = index;
          }
        });
        setValue(i);
      } catch (err) {}
    } else {
      setValue(6);
      setProject({ ...project, intendedStartDate: valueLabelFormat(6) });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [project]);

  React.useEffect(() => {
    let dependentSubSectorsOptions = [];
    let subSectorOptionsValues = new Set();
    let sectorValues = sectors.map((a) => a.value);
    sectorValues.forEach((item) => {
      subSectorsMap[item].forEach((item) => {
        subSectorOptionsValues.add(item);
      });
    });
    subSectorOptions.forEach((subSector) => {
      subSectorOptionsValues.forEach((subSectorValue) => {
        if (subSector.value === subSectorValue) {
          dependentSubSectorsOptions.push(subSector);
        }
      });
    });
    let subSectorValues = subSectors.map((a) => a.value);
    let dependentSubSectorsValues = [];
    subSectorValues.forEach((item) => {
      if (dependentSubSectorsOptions) {
        dependentSubSectorsValues = dependentSubSectorsOptions.filter(
          (opt) => subSectorValues.indexOf(opt.value) !== -1
        );
      }
    });
    setSubSectors(dependentSubSectorsValues);
    setSubSectorDependentOptions(dependentSubSectorsOptions);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sectors]);

  const Description = () => {
    return (
      <>
        <Typography>{props.placeholder1}</Typography>
        <br />
        <Typography>{props.placeholder1_1}</Typography>
        <List sx={{ listStyle: 'inside', p: 0 }}>
          <ListItem sx={{ display: 'list-item', py: 0 }}>
            {props.placeholder1_2}
          </ListItem>
          <ListItem sx={{ display: 'list-item', py: 0 }}>
            {props.placeholder1_3}
          </ListItem>
        </List>
      </>
    );
  };

  return (
    <>
      <Grid container item xs={12} className={classes.section}>
        <Grid container item xs={12}>
          {/*<>TITLE</>*/}
          <Grid
            container
            item
            xs={12}
            justifyContent='center'
            className='title-section'
          >
            <Grid item xs={12} className={classes.title}>
              <Box mb={1}>
                <Typography variant='h6'>{label1 && label1}</Typography>
              </Box>
              <Box pb={3}>
                <Typography variant='body2' className={classes.textDisabled}>
                  {label2 && label2}
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={12} lg={isLarge ? 6 : 12}></Grid>
          </Grid>
          <Grid
            container
            item
            xs={12}
            lg={isLarge ? 6 : 12}
            justifyContent={isLarge ? 'flex-start' : 'center'}
            className={classes.subSection}
          >
            <Grid container item xs={12}>
              <Typography
                variant='overline'
                color='textSecondary'
                className={classes.subTitle}
              >
                {label3}
              </Typography>
              <TextField
                className={classes.helperText + ' ' + classes.formControl}
                id='name'
                name='name'
                label={label4}
                variant='outlined'
                helperText={helper1}
                type='text'
                fullWidth
                autoFocus={isLargeScreen}
                autoComplete='off'
                value={project.name ?? ''}
                onChange={(e) => {
                  handleChange(e, project, setProject);
                }}
                inputProps={{
                  maxLength: 150,
                }}
              />
            </Grid>
            <Grid container item xs={12}>
              <Grid container item xs={12} sx={{ position: 'relative' }}>
                <Grid item xs={12}>
                  <TextField
                    className={classes.helperText + ' ' + classes.formControl}
                    id='description1'
                    name='idea'
                    label={label5}
                    placeholder={
                      placeholder1 +
                      ' ' +
                      props.placeholder1_1 +
                      ' ' +
                      props.placeholder1_2 +
                      ', ' +
                      props.placeholder1_3
                    }
                    variant='outlined'
                    multiline
                    rows={5}
                    helperText={helper3}
                    fullWidth
                    autoComplete='off'
                    value={project.idea ?? ''}
                    onChange={(e) => {
                      handleChange(e, project, setProject);
                    }}
                    inputProps={{
                      maxLength: 1000,
                      sx: {
                        '&::placeholder': {
                          fontSize: 14,
                        },
                      },
                    }}
                  />
                </Grid>
                {isLargeScreen && (
                  <LargeTooltip
                    title={<Description />}
                    aria-label='info'
                    placement='top-start'
                  >
                    <InfoOutlinedIcon
                      style={{
                        color: theme.palette.text.secondary,
                        position: 'absolute',
                        bottom: 72,
                        right: '-26px', // '-36px'
                      }}
                      className={classes.activeIconState}
                    />
                  </LargeTooltip>
                )}
              </Grid>
            </Grid>
            <Grid container item xs={12}>
              <Grid container item xs={12} sx={{ position: 'relative' }}>
                <Grid item xs={12}>
                  <TextField
                    className={classes.helperText + ' ' + classes.formControl}
                    id='description2'
                    name='problemStatement'
                    label={label6}
                    placeholder={placeholder2 ?? ''}
                    variant='outlined'
                    multiline
                    rows={5}
                    helperText={helper4}
                    type='text'
                    fullWidth
                    autoComplete='off'
                    value={project.problemStatement ?? ''}
                    onChange={(e) => {
                      handleChange(e, project, setProject);
                    }}
                    inputProps={{
                      maxLength: 1000,
                      sx: {
                        '&::placeholder': {
                          fontSize: 14,
                        },
                      },
                    }}
                  />
                </Grid>
                {isLargeScreen && (
                  <LargeTooltip
                    title={placeholder2}
                    aria-label='info'
                    placement='top-start'
                  >
                    <InfoOutlinedIcon
                      style={{
                        color: theme.palette.text.secondary,
                        position: 'absolute',
                        bottom: 72,
                        right: '-26px', // '-36px'
                      }}
                      className={classes.activeIconState}
                    />
                  </LargeTooltip>
                )}
              </Grid>
            </Grid>
          </Grid>
          <Grid
            container
            item
            xs={12}
            lg={isLarge ? 6 : 12}
            justifyContent={isLarge ? 'flex-start' : 'center'}
            className={classes.subSection}
          >
            <Grid container item xs={12}>
              {isLargeScreen && (
                <Grid item xs={12}>
                  <Typography
                    variant='body2'
                    color='textSecondary'
                    className={classes.subTitle}
                  >
                    &nbsp;
                  </Typography>
                </Grid>
              )}

              <Grid item xs={12} sx={{ ...(!isLargeScreen && { mb: 5 }) }}>
                <Typography
                  variant='body2'
                  color='textSecondary'
                  className={classes.subTitle}
                  sx={{ pb: 4 }}
                >
                  {label7}
                </Typography>
                <Slider
                  value={value}
                  name='intendedStartDate'
                  onChange={handleSlider}
                  aria-label='Default'
                  valueLabelDisplay='on'
                  valueLabelFormat={valueLabelFormatSlider}
                  max={18}
                  track={false}
                  sx={{
                    width: !isLargeScreen ? '80%' : '100%',
                    ml: !isLargeScreen ? '32px' : '0px',
                  }}
                  marks={marks}
                />
              </Grid>
            </Grid>
            <Grid container item xs={12}>
              <Grid container item xs={12} sx={{ position: 'relative' }}>
                <Grid item xs={12}>
                  <Typography
                    variant='overline'
                    color='textSecondary'
                    className={classes.subTitle}
                  >
                    {t('labels.sectors')}
                  </Typography>
                  <Autocomplete
                    multiple
                    id='sectors'
                    value={sectors}
                    options={sectorOptions}
                    getOptionLabel={(option) => option.label}
                    defaultValue={[]}
                    className={
                      classes.formControl + ' ' + classes.autocompleteMultiple
                    }
                    disableCloseOnSelect
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        variant='outlined'
                        label={t('labels.sectors')}
                        placeholder=''
                        className={classes.helperText}
                        helperText={t('project-details.sectors_label')}
                        InputLabelProps={{
                          shrink: true,
                        }}
                      />
                    )}
                    onChange={(e, values) => {
                      setSectors(values);
                    }}
                  />
                  {isLargeScreen && (
                    <LargeTooltip
                      title={t('project-details.sectors_label')}
                      aria-label='info'
                      placement='top-start'
                    >
                      <InfoOutlinedIcon
                        style={{
                          color: theme.palette.text.secondary,
                          position: 'absolute',
                          bottom: 72,
                          right: '-26px', // '-36px'
                        }}
                        className={classes.activeIconState}
                      />
                    </LargeTooltip>
                  )}
                </Grid>
              </Grid>
            </Grid>
            <Grid container item xs={12}>
              <Grid container item xs={12} sx={{ position: 'relative' }}>
                <Grid item xs={12}>
                  {subSectorDependentOptions.length > 0 && (
                    <>
                      <Typography
                        variant='overline'
                        color='textSecondary'
                        className={classes.subTitle}
                      >
                        {t('labels.subSectors')}
                      </Typography>
                      <Autocomplete
                        multiple
                        id='subSectors'
                        value={subSectors}
                        options={subSectorDependentOptions}
                        getOptionLabel={(option) => option.label}
                        defaultValue={[]}
                        className={
                          classes.formControl +
                          ' ' +
                          classes.autocompleteMultiple
                        }
                        disableCloseOnSelect
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            variant='outlined'
                            label={t('labels.subSectors')}
                            placeholder=''
                            className={classes.helperText}
                            helperText={t('project-details.subSectors_label')}
                            InputLabelProps={{
                              shrink: true,
                            }}
                          />
                        )}
                        onChange={(e, values) => {
                          setSubSectors(values);
                        }}
                      />
                      {isLargeScreen && (
                        <LargeTooltip
                          title={t('project-details.subSectors_label')}
                          aria-label='info'
                          placement='top-start'
                        >
                          <InfoOutlinedIcon
                            style={{
                              color: theme.palette.text.secondary,
                              position: 'absolute',
                              bottom: 72,
                              right: '-26px', // '-36px'
                            }}
                            className={classes.activeIconState}
                          />
                        </LargeTooltip>
                      )}
                    </>
                  )}
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Grid container item xs={12} className={classes.section}>
        <Grid container item xs={12}>
          <Grid
            container
            item
            xs={12}
            pb={3}
            px={1}
            justifyContent={isLarge ? 'flex-start' : 'center'}
            sx={{ maxWidth: !isLarge && '450px!important', margin: 'auto' }}
          >
            <FormGroup>
              <FormControlLabel
                control={
                  <Switch
                    checked={showMoreDetails}
                    onChange={handleChangeSwitch}
                    inputProps={{ 'aria-label': 'controlled' }}
                  />
                }
                label={label8}
              />
            </FormGroup>
          </Grid>
        </Grid>
        {showMoreDetails && (
          <Grid item xs={12}>
            <Divider className={classes.divider} />
          </Grid>
        )}
      </Grid>
    </>
  );
};

export default NewProductBaseDetails;
