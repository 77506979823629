import React, { createContext, useState, useContext } from 'react';
import { LoginContext } from './LoginContext';

export const WorkspaceContext = createContext();

export const WorkspaceProvider = ({ children }) => {
  const { locale, setLocale } = useContext(LoginContext);

  const [account, setAccount] = useState({
    province: '',
    userType: '',
    name: '',
    registrationNumber: '',
    type: '',
    legalEntity: '',
    sme: '',
    anbiStatus: '',
    keySectors: '',
    investmentNeed: '',
    employees: '',
    salesforceAccountId: '',
    plan: '',
    postcode: '',
    street: '',
    vatNumber: '',
    ibanNumber: '',
    city: '',
    billingCity: '',
    billingCountry: '',
    billingPostcode: '',
    billingStreet: '',
    country: '',
    id: '',
  });

  const [leadsChartData, setLeadsChartData] = useState(
    Array.from(Array(12), (_, x) => {
      return { id: x + 1, value: 0 };
    })
  );
  const [dealsChartData, setDealsChartData] = useState(
    Array.from(Array(12), (_, x) => {
      return { id: x + 1, value: 0 };
    })
  );
  const [gmvData, setGmvData] = useState([]);

  const [newLead, setNewLead] = useState({
    company: {
      name: '',
      dossier_number: '',
      establishment_number: '',
      establishment_city: '',
      establishment_street: '',
      correspondence_city: '',
      correspondence_street: '',
      sme: '',
      keySectors: '',
      subSectors: '',
      doTheScan: false,
    },
    contact: {
      firstName: '',
      lastName: '',
      Email: '',
      phone: '',
      partnerParticipation: false,
      description: '',
    },
  });

  const [pageLeads, setPageLeads] = useState(0);
  const [selectDealsFilter, setSelectDealsFilter] = useState('This Year');
  const [headerCols, setHeaderCols] = useState(null);
  const [csvToJSON, setCsvToJSON] = useState();
  const [uploadError, setUploadError] = useState(false);
  const [uploadErrorMsg, setUploadErrorMsg] = useState('');
  const [partialSuccess, setPartialSuccess] = useState(false);
  const [partialRecords, setPartialRecords] = useState([]);

  return (
    <WorkspaceContext.Provider
      value={{
        locale,
        setLocale,
        account,
        setAccount,
        leadsChartData,
        setLeadsChartData,
        dealsChartData,
        setDealsChartData,
        gmvData,
        setGmvData,
        pageLeads,
        setPageLeads,
        newLead,
        setNewLead,
        selectDealsFilter,
        setSelectDealsFilter,
        headerCols,
        setHeaderCols,
        csvToJSON,
        setCsvToJSON,
        uploadError,
        setUploadError,
        uploadErrorMsg,
        setUploadErrorMsg,
        partialSuccess,
        setPartialSuccess,
        partialRecords,
        setPartialRecords,
      }}
    >
      {children}
    </WorkspaceContext.Provider>
  );
};
