import makeStyles from '@mui/styles/makeStyles';

const drawerWidth = 295;
export const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    height: '100%',
    flexGrow: 1,
  },
  appBar: {
    width: `100%`,
    marginLeft: drawerWidth,
    backgroundColor: '#ffffff',
    boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.08)',
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    [theme.breakpoints.up('1280')]: {
      width: `calc(100% - ${drawerWidth}px)`,
    },
    [theme.breakpoints.down('1280')]: {
      marginLeft: 0,
    },
  },
  menuButton: {
    marginRight: theme.spacing(2),
    [theme.breakpoints.down('1280')]: {
      marginRight: 0,
    },
  },
  hide: {
    display: 'none',
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },

  drawerPaper: {
    width: drawerWidth,
  },
  drawerHeader: {
    display: 'flex',
    alignItems: 'center',
    ...theme.mixins.toolbar,
    justifyContent: 'space-between',
    [theme.breakpoints.up('1280')]: {
      justifyContent: 'space-between',
    },
    '& button': {
      marginRight: 24,
    },
    [theme.breakpoints.down('1280')]: {
      boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.08)',
    },
  },
  toolbar: theme.mixins.toolbar,
  toolbarContent: {
    display: 'flex',
    padding: 0,
    paddingRight: 16,
    [theme.breakpoints.down('1280')]: {
      justifyContent: 'space-between',
    },
    [theme.breakpoints.down('768')]: {},
    '& a': {
      [theme.breakpoints.down('768')]: {},
    },
    '& h6': {
      fontWeight: 700,
      [theme.breakpoints.down('768')]: {},
    },
  },
  imageLogo: {
    display: 'block',
    marginTop: theme.spacing(1.5),
    marginLeft: theme.spacing(3),
    [theme.breakpoints.down('1280')]: {
      marginTop: theme.spacing(0),
    },
    [theme.breakpoints.down('768')]: {
      marginTop: theme.spacing(0),
      marginLeft: theme.spacing(2),
    },
  },
  user: {
    flexGrow: 1,
    '& button': {
      float: 'right',
      [theme.breakpoints.down('1280')]: {
        display: 'block !important',
      },
    },
  },
  content: {
    flexGrow: 1,
    backgroundColor: '#ffffff',
    overflowY: 'auto',
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),

    [theme.breakpoints.down('768')]: {},
  },
  menuList: {
    '& .MuiListItem-root': {
      cursor: 'pointer',
      '&:hover': {
        backgroundColor: theme.palette.primary.background,
      },
      '&.Mui-selected': {
        '&:hover': {
          backgroundColor: 'white',
        },
      },
    },
    '& .Mui-selected': {
      color: theme.palette.primary.main,
      cursor: 'default',
      '&:hover': {},
      '& svg': {
        fill: theme.palette.primary.main,
      },
    },
    '& .MuiListItem-root.Mui-selected': {
      [theme.breakpoints.down('1024')]: {
        backgroundColor: '#ffffff',
      },
    },
    [theme.breakpoints.down('1280')]: {
      padding: '12px 0',
    },
  },
  projectTabs: {
    paddingLeft: 64,
    [theme.breakpoints.down('768')]: {
      paddingLeft: '16px !important',
      paddingRight: '16px !important',
    },
    '& .MuiTab-root': {
      minWidth: 'unset',
      padding: '12px 20px',
    },
  },
}));
