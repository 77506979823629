import React, { useState } from 'react';
import { TextField } from '@mui/material';
import IBAN from 'iban';
import NumberFormatCustom from './NumberFormatCustom';

const TextfieldCustom = (props) => {
  const {
    // eslint-disable-next-line no-unused-vars
    index,
    field,
    variant,
    rows,
    setEditField,
    handleUpdateField,
    setShowErrorUpdate,
    handlePhoneLeadingZero,
    autoFocus,
    readonly,
  } = props;

  const [value, setValue] = useState(field && field.value ? field.value : '');
  // eslint-disable-next-line no-unused-vars
  const [errorMsg, setErrMsg] = useState(
    field && field.errorMsg ? field.errorMsg : '',
  );
  const [hasError, setHasError] = useState(
    (field && field.hasOwnProperty('valid') && !field.valid) || false,
  );

  const [hasChange, setHasChange] = useState(false);

  return (
    <TextField
      error={hasError}
      helperText={hasError && errorMsg}
      autoFocus={autoFocus && autoFocus}
      disabled={field.hasOwnProperty('disabledField') && field.disabledField}
      variant={variant ? variant : 'outlined'}
      size="small"
      fullWidth
      multiline={
        field.hasOwnProperty('inputType') && field.inputType === 'textarea'
      }
      rows={rows ? rows : 5}
      value={value}
      name={field.name}
      inputProps={{
        maxLength: field.maxLength ? field.maxLength : '',
        minLength: field.minLength ? field.minLength : '',
        readOnly: readonly,
        'data-zero': field.inputType === 'phone' ? true : false,
      }}
      InputProps={
        field.inputType === 'number' // || field.inputType === 'phone'
          ? {
              inputComponent: NumberFormatCustom,
            }
          : {}
      }
      onKeyUp={(e) => {
        if (e.key === 'Enter') {
          e.target.blur();
          setHasChange(false);
        }
      }}
      onChange={(e) => {
        setHasChange(true);
        setValue(e.target.value);
        if (
          field.hasOwnProperty('validType') &&
          field.validType === 'address'
        ) {
          if (e.target.value.trim()) {
            const splitVal = e.target.value.trim().split(' ');
            const splitNum = splitVal[splitVal.length - 1];
            if (
              splitNum.length > 0 &&
              splitVal.length > 1 &&
              field.pattern.test(splitNum)
            ) {
              setHasError(false);
            } else {
              setHasError(true);
            }
          } else {
            setHasError(true);
          }
        } else if (field.fieldName === 'ibanNumber') {
          if (!IBAN.isValid(e.target.value.trim())) {
            setHasError(true);
          } else {
            setHasError(false);
          }
        } else if (
          // field.hasOwnProperty('isRequired') &&
          // field.isRequired &&
          field.hasOwnProperty('pattern') &&
          !field.pattern.test(e.target.value.trim())
        ) {
          setHasError(true);
        } else {
          setHasError(false);
        }
      }}
      onBlur={(e) => {
        setEditField(null);

        if (field) {
          if (field.hasOwnProperty('isRequired') && field.isRequired) {
            if (
              e.target.value.trim() &&
              e.target.value.trim() !== field.value &&
              !hasError
            ) {
              if (handlePhoneLeadingZero && e.target.value.trim() === 9) {
                // handleUpdateField(field.fieldName, '0' + e.target.value.trim());
              } else {
                handleUpdateField(field.fieldName, e.target.value.trim());
              }
            }
          } else if (e.target.value.trim() !== field.value && !hasError) {
            handleUpdateField(field.fieldName, e.target.value.trim());
            setHasChange(false);
          } else if (
            e.target.value.trim() !== field.value &&
            hasError &&
            hasChange
          ) {
            setShowErrorUpdate(true);
          }
          if (
            field &&
            e.target.value.trim() &&
            e.target.value.trim() !== field.value &&
            hasError &&
            hasChange
          ) {
            setShowErrorUpdate(true);
            setHasChange(false);
          }
          if (e.target.value.trim() === '') {
            setHasChange(false);
          }
        }
      }}
    />
  );
};

export default TextfieldCustom;
