import React, { useState, useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useIsMounted } from '../../utils/customHooks';
import axios from 'axios';
import { UserContext } from '../../contexts/UserContext';
import { WorkspaceContext } from '../../contexts/WorkspaceContext';
import {
  Button,
  TextField,
  Grid,
  InputAdornment,
  IconButton,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Snackbar,
  DialogActions,
  Dialog,
  AppBar,
  Toolbar,
  Slide,
  Typography,
  Autocomplete,
  Box,
  Alert,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import EmailIcon from '@mui/icons-material/Email';
import MuiPhoneNumber from 'material-ui-phone-number';
import { useHistory } from 'react-router-dom';
import WebViewer from '@pdftron/pdfjs-express-viewer';
import { useStyles } from './styles';
import SendIcon from '@mui/icons-material/Send';
import { Portal } from '@mui/base/Portal';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction='up' ref={ref} {...props} />;
});

const AddLeadSecondScreen = (props) => {
  const classes = useStyles();
  const [openPreview, setOpenPreview] = React.useState(false);
  const viewer = React.useRef(null);
  const [sbiCodeSubsidies, setSbiCodeSubsidies] = useState([]);
  const [openDownloadSubsidyScanDialog, setOpenDownloadSubsidyScanDialog] =
    useState(false);
  const [createdAccountId, setCreatedAccountId] = useState('');
  const [createdAccountName, setCreatedAccountName] = useState('');
  const [contact, setContact] = React.useState([]);
  const [contacts, setContacts] = React.useState([]);
  const history = useHistory();
  const {
    back,
    confirm,
    setStartScreen,
    setFirstScreen,
    setLoading,
    setOpenNewLeadDialog,
    setSelectedCompany,
    setHasValue,
  } = props;
  const [showError, setShowError] = useState(false);
  const [showSuccess, setShowSuccess] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  const { t } = useTranslation();
  const isMounted = useIsMounted();
  // eslint-disable-next-line no-unused-vars
  const [userContext, setUserContext] = useContext(UserContext);
  const [openSendDialog, setOpenSendDialog] = useState(false);
  const { newLead, setNewLead } = useContext(WorkspaceContext);
  const [finish, setFinish] = useState(false);
  const [errorAddLed, setErrorAddLed] = useState(false);

  const regEmail =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

  const apiEndPoint =
    process.env.NODE_ENV === 'development'
      ? process.env.REACT_APP_API_ENDPOINT
      : process.env.REACT_APP_API_ENDPOINT_PROD;

  const handleCloseSnackbar = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setErrorAddLed(false);
    setShowSuccess(false);
    setShowError(false);
  };

  const handleContactDetails = (prop, value) => {
    setNewLead((oldValues) => {
      return {
        ...oldValues,
        contact: {
          ...oldValues.contact,
          [prop]: value,
        },
      };
    });
  };

  const handleGoBackScreen = () => {
    setStartScreen(true);
    setFirstScreen(true);
    setHasValue(true);
  };

  const handleGenerateSubsidyScanwWithoutKVK = (accountId) => {
    try {
      setLoading(true);
      axios({
        method: 'post',
        url: apiEndPoint + '/v1/partner/leads/getCompanyDetails',
        withCredentials: true,
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
          Authorization: `Bearer ${userContext.token}`,
        },
        data: {
          accountId: accountId,
          company: newLead.company,
          sectors: newLead.company.keySectors,
          subSectors: newLead.company.subSectors,
          sme: newLead.company.sme,
        },
      })
        .then((res) => {
          setSbiCodeSubsidies(res.data.subsidies);
          setContacts(res.data.contacts);
          if (res.data.subsidies.length > 0) {
            setOpenPreview(true);
            downloadSubsidyScanPDF(accountId, res.data.subsidies);
          } else {
            setOpenDownloadSubsidyScanDialog(true);
            setLoading(false);
          }
        })
        .catch((error) => {
          setLoading(false);
        });
    } catch (error) {
      console.log(error);
    }
  };

  const downloadSubsidyScanPDF = (accountId, subsidies) => {
    let url = '/v1/subsidyscan/generateSBICodeSubsidyScan';
    try {
      axios({
        method: 'post',
        url: apiEndPoint + url,
        data: { accountId: accountId, subsidies: subsidies },
        withCredentials: true,
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
          Authorization: `Bearer ${userContext.token}`,
        },
        responseType: 'blob',
      })
        .then((res) => {
          setLoading(false);
          WebViewer(
            {
              path: '/webviewer/public',
              licenseKey: process.env.REACT_APP_PDFJS_API_KEY,
            },
            viewer.current
          ).then((instance) => {
            instance.UI.loadDocument(new Blob([res.data]), {
              filename: createdAccountName + '-Subsidiescan.pdf',
            });
            const { Core } = instance;
            Core.documentViewer.addEventListener('documentLoaded', () => {
              console.log('document loaded');
            });
          });
        })
        .catch((error) => {
          setLoading(false);
        });
    } catch (err) {
      console.log(err);
    }
  };

  const handleAddNewLead = () => {
    setLoading(true);
    let account = { ...newLead.company };
    let contact = { ...newLead.contact };
    delete contact['partnerParticipation'];
    let trimmed = Object.keys(contact).map(
      (k) => (contact[k] = contact[k].trim())
    );
    const reqBody = {
      Account: account,
      partnerParticipation: 'No',
      Contact: {
        firstName: trimmed[0],
        lastName: trimmed[1],
        Email: trimmed[2],
        phone: trimmed[3],
      },
      description: trimmed[4],
    };
    axios({
      method: 'post',
      url: apiEndPoint + '/v1/partner/leads/createAccount',
      withCredentials: true,
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        Authorization: `Bearer ${userContext.token}`,
      },
      data: reqBody,
    })
      .then((res) => {
        if (res && res.data) {
          if (isMounted.current) {
            setCreatedAccountId(res.data.account.id);
            setCreatedAccountName(res.data.account.name);
            if (newLead.company.doTheScan) {
              handleGenerateSubsidyScanwWithoutKVK(res.data.account.id);
            } else {
              setErrorAddLed(false);
              setNewLead({
                company: {
                  name: '',
                  dossier_number: '',
                  establishment_number: '',
                  establishment_city: '',
                  establishment_street: '',
                  correspondence_city: '',
                  correspondence_street: '',
                  sme: '',
                  keySectors: '',
                  subSectors: '',
                  doTheScan: false,
                },
                contact: {
                  firstName: '',
                  lastName: '',
                  Email: '',
                  phone: '',
                  partnerParticipation: false,
                  description: '',
                },
              });
              setSelectedCompany('');
              setFinish(false);
              setFirstScreen(true);
              setOpenNewLeadDialog(false);
              setLoading(false);
              history.push({
                pathname: `/app/accounts/${res.data.account.id}`,
                state: { name: res.data.account.name },
              });
            }
          }
        } else {
          if (isMounted.current) {
            setErrorAddLed(true);
            setLoading(false);
          }
        }
      })
      .catch((error) => {
        if (isMounted.current) {
          setErrorAddLed(true);
          setLoading(false);
        }
      });
  };

  useEffect(() => {
    if (newLead && newLead.contact) {
      const isComplete = () => {
        if (
          newLead.contact?.firstName &&
          /(^.{1,60}$)/.test(newLead.contact?.firstName) &&
          newLead.contact?.lastName &&
          /(^.{1,60}$)/.test(newLead.contact?.lastName) &&
          newLead.contact?.Email &&
          regEmail.test(newLead.contact?.Email) &&
          newLead.contact?.phone &&
          /(^.{12,60}$)/.test(newLead.contact?.phone.split(' ').join(''))
        ) {
          return true;
        } else {
          return false;
        }
      };
      if (isMounted.current) {
        setFinish(isComplete);
      }
    } else {
      if (isMounted.current) {
        setFinish(false);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [newLead.contact]);

  const handleSendScan = () => {
    setLoading(true);
    let url = '/v1/partner/leads/sendScan';
    const apiEndPoint =
      process.env.NODE_ENV === 'development'
        ? process.env.REACT_APP_API_ENDPOINT
        : process.env.REACT_APP_API_ENDPOINT_PROD;
    try {
      axios({
        method: 'post',
        url: apiEndPoint + url,
        data: {
          accountId: createdAccountId,
          subsidies: sbiCodeSubsidies,
          contact: contact,
        },
        withCredentials: true,
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
          Authorization: `Bearer ${userContext.token}`,
        },
      })
        .then((res) => {
          setShowSuccess(true);
          setSuccessMessage(t('quick_subsidyscan.send_scan_success_message'));
          setOpenSendDialog(false);
          setLoading(false);
        })
        .catch((error) => {
          setOpenSendDialog(false);
          setShowError(true);
          setErrorMessage(t('quick_subsidyscan.send_scan_error_message'));
          setLoading(false);
        });
    } catch (err) {
      console.log(err);
      setOpenSendDialog(false);
      setShowError(true);
      setErrorMessage(t('quick_subsidyscan.send_scan_error_message'));
      setLoading(false);
    }
  };

  /*const handleCancelNewLead = () => {
    setNewLead({
      company: {
        name: '',
        dossier_number: '',
        establishment_number: '',
        establishment_city: '',
        establishment_street: '',
        correspondence_city: '',
        correspondence_street: '',
        sme: '',
        keySectors: '',
        subSectos: '',
      },
    });
  };*/

  return (
    <>
      <Portal>
        <Snackbar
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          open={showSuccess}
          autoHideDuration={3000}
          onClose={handleCloseSnackbar}
          message={successMessage}
        />
        <Snackbar
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
          open={showError}
          autoHideDuration={3000}
          onClose={handleCloseSnackbar}
        >
          <Alert
            onClose={handleCloseSnackbar}
            severity='error'
            sx={{ width: '100%' }}
          >
            {errorMessage}
          </Alert>
        </Snackbar>
      </Portal>
      <Grid container mt={2}>
        <Grid item xs={12} md={6} sx={{ pb: 3, pr: { md: 2 } }}>
          <TextField
            variant='outlined'
            required={true}
            error={
              newLead &&
              newLead.contact?.firstName &&
              Boolean(!/(^.{1,60}$)/.test(newLead.contact?.firstName))
                ? Boolean(!/(^.{1,60}$)/.test(newLead.contact?.firstName))
                : false
            }
            name='firstName'
            fullWidth
            label={t('labels.first_name')}
            placeholder={t('labels.first_name')}
            value={newLead.contact?.firstName || ''}
            onChange={(e) => {
              handleContactDetails(e.target.name, e.target.value);
            }}
            inputProps={{
              fontSize: 14,
            }}
            InputLabelProps={{ shrink: true }}
          />
        </Grid>
        <Grid item xs={12} md={6} sx={{ pb: 3, pl: { md: 2 } }}>
          <TextField
            variant='outlined'
            error={
              newLead &&
              newLead.contact?.lastName &&
              Boolean(!/(^.{1,60}$)/.test(newLead.contact?.lastName))
                ? Boolean(!/(^.{1,60}$)/.test(newLead.contact?.lastName))
                : false
            }
            name='lastName'
            fullWidth
            label={t('labels.last_name')}
            placeholder={t('labels.last_name')}
            value={newLead.contact?.lastName || ''}
            required={true}
            inputProps={{
              fontSize: 14,
            }}
            onChange={(e) => {
              handleContactDetails(e.target.name, e.target.value);
            }}
          />
        </Grid>
        <Grid item xs={12} md={6} sx={{ pb: 3, pr: { md: 2 } }}>
          <TextField
            variant='outlined'
            required={true}
            error={
              newLead &&
              newLead.contact?.Email &&
              Boolean(!regEmail.test(newLead.contact?.Email))
                ? Boolean(!regEmail.test(newLead.contact?.Email))
                : false
            }
            name='Email'
            fullWidth
            label={t('labels.email')}
            placeholder={t('labels.email')}
            value={newLead.contact?.Email || ''}
            onChange={(e) => {
              handleContactDetails(e.target.name, e.target.value);
            }}
            InputProps={{
              endAdornment: (
                <InputAdornment position='end'>
                  <EmailIcon />
                </InputAdornment>
              ),
            }}
            inputProps={{
              fontSize: 14,
            }}
          />
        </Grid>
        <Grid item xs={12} md={6} sx={{ pb: 3, pl: { md: 2 } }}>
          <MuiPhoneNumber
            defaultCountry={'nl'}
            required={true}
            variant='outlined'
            regions={['america', 'europe', 'asia', 'oceania', 'africa']}
            error={
              newLead &&
              newLead.contact?.phone &&
              Boolean(
                !/(^.{12,60}$)/.test(newLead.contact?.phone.split(' ').join(''))
              )
                ? Boolean(
                    !/(^.{12,60}$)/.test(
                      newLead.contact?.phone.split(' ').join('')
                    )
                  )
                : false
            }
            name='phone'
            fullWidth
            label={t('labels.phone')}
            placeholder={t('labels.phone')}
            value={newLead.contact?.phone || ''}
            onChange={(value) => {
              handleContactDetails('phone', value);
            }}
            inputProps={{
              fontSize: 14,
            }}
          />
        </Grid>
        <Grid item xs={12} sx={{ pb: 3 }}>
          <TextField
            variant='outlined'
            required={false}
            name='description'
            multiline
            rows={3}
            fullWidth
            label={t('labels.description')}
            placeholder={t('labels.description_placeholder')}
            value={newLead.contact?.description || ''}
            inputProps={{
              fontSize: 14,
              maxLength: 500,
            }}
            onChange={(e) => {
              handleContactDetails(e.target.name, e.target.value);
            }}
          />
          <Typography color='textSecondary' sx={{ mt: 1 }}>
            {t('labels.description_placeholder_1')}
          </Typography>
          <Typography color='textSecondary'>
            {t('labels.description_placeholder_2')}
          </Typography>
        </Grid>
        <Grid
          container
          justifyContent={'space-between'}
          item
          xs={12}
          sx={{ pt: 3 }}
        >
          {back && (
            <Grid item sx={{}}>
              <Button
                onClick={() => {
                  handleGoBackScreen();
                }}
                color='primary'
              >
                {back}
              </Button>
            </Grid>
          )}{' '}
          {confirm && (
            <Grid item sx={{}}>
              <Button
                disabled={!finish}
                onClick={() => {
                  handleAddNewLead();
                }}
                color='primary'
              >
                {confirm}
              </Button>
            </Grid>
          )}
        </Grid>

        <Snackbar
          open={errorAddLed}
          onClose={handleCloseSnackbar}
          autoHideDuration={3000}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
          style={{ top: 80 }}
          action={
            <IconButton
              size='small'
              aria-label='close'
              color='inherit'
              onClick={handleCloseSnackbar}
            >
              <CloseIcon fontSize='small' />
            </IconButton>
          }
        >
          <Alert
            severity='error'
            style={{ position: 'relative', paddingRight: 46 }}
          >
            {t('leads.existing_contact_error')}
            <IconButton
              size='small'
              aria-label='close'
              color='inherit'
              onClick={handleCloseSnackbar}
              style={{
                marginLeft: 10,
                position: 'absolute',
                right: 10,
                top: 10,
              }}
            >
              <CloseIcon fontSize='small' />
            </IconButton>
          </Alert>
        </Snackbar>
      </Grid>
      <Dialog
        open={openDownloadSubsidyScanDialog}
        onClose={() => {
          setOpenDownloadSubsidyScanDialog(false);
          setErrorAddLed(false);
          setNewLead({
            company: {
              name: '',
              dossier_number: '',
              establishment_number: '',
              establishment_city: '',
              establishment_street: '',
              correspondence_city: '',
              correspondence_street: '',
              sme: '',
              keySectors: '',
              subSectors: '',
              doTheScan: false,
            },
            contact: {
              firstName: '',
              lastName: '',
              Email: '',
              phone: '',
              partnerParticipation: false,
              description: '',
            },
          });
          setSelectedCompany('');
          setFinish(false);
          setFirstScreen(true);
          setOpenNewLeadDialog(false);
          history.push({
            pathname: `/app/accounts/${createdAccountId}`,
            state: { name: createdAccountName },
          });
        }}
        maxWidth={`sm`}
        aria-labelledby='alert-dialog-title'
        aria-describedby='alert-dialog-description'
      >
        <DialogTitle
          id='alert-dialog-title'
          onClose={() => {
            setErrorAddLed(false);
            setNewLead({
              company: {
                name: '',
                dossier_number: '',
                establishment_number: '',
                establishment_city: '',
                establishment_street: '',
                correspondence_city: '',
                correspondence_street: '',
                sme: '',
                keySectors: '',
                subSectors: '',
                doTheScan: false,
              },
              contact: {
                firstName: '',
                lastName: '',
                Email: '',
                phone: '',
                partnerParticipation: false,
                description: '',
              },
            });
            setSelectedCompany('');
            setFinish(false);
            setFirstScreen(true);
            setOpenNewLeadDialog(false);
            history.push({
              pathname: `/app/accounts/${createdAccountId}`,
              state: { name: createdAccountName },
            });
          }}
        >
          {t('subsidyscan.subsidyscan_download_dialog.title')}
          <IconButton
            aria-label='close'
            onClick={() => {
              setOpenDownloadSubsidyScanDialog(false);
              setErrorAddLed(false);
              setNewLead({
                company: {
                  name: '',
                  dossier_number: '',
                  establishment_number: '',
                  establishment_city: '',
                  establishment_street: '',
                  correspondence_city: '',
                  correspondence_street: '',
                  sme: '',
                  keySectors: '',
                  subSectors: '',
                  doTheScan: false,
                },
                contact: {
                  firstName: '',
                  lastName: '',
                  Email: '',
                  phone: '',
                  partnerParticipation: false,
                  description: '',
                },
              });
              setSelectedCompany('');
              setFinish(false);
              setFirstScreen(true);
              setOpenNewLeadDialog(false);
              history.push({
                pathname: `/app/accounts/${createdAccountId}`,
                state: { name: createdAccountName },
              });
            }}
            sx={{
              position: 'absolute',
              right: 12,
              top: 12,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>

        <DialogContent>
          <DialogContentText id='alert-dialog-description'>
            {sbiCodeSubsidies.length > 0 && (
              <Typography
                variant={'body1'}
                color='textPrimary'
                component='span'
              >
                {t('subsidyscan.subsidyscan_download_dialog.subsidies_title')}
              </Typography>
            )}
            {sbiCodeSubsidies.length === 0 && (
              <Typography
                variant={'body1'}
                color='textPrimary'
                component='span'
              >
                {t(
                  'subsidyscan.subsidyscan_download_dialog.no_subsidies_title'
                )}
              </Typography>
            )}
          </DialogContentText>
        </DialogContent>

        <DialogActions>
          <Button
            onClick={() => {
              setOpenDownloadSubsidyScanDialog(false);
              setErrorAddLed(false);
              setNewLead({
                company: {
                  name: '',
                  dossier_number: '',
                  establishment_number: '',
                  establishment_city: '',
                  establishment_street: '',
                  correspondence_city: '',
                  correspondence_street: '',
                  sme: '',
                  keySectors: '',
                  subSectors: '',
                  doTheScan: false,
                },
                contact: {
                  firstName: '',
                  lastName: '',
                  Email: '',
                  phone: '',
                  partnerParticipation: false,
                  description: '',
                },
              });
              setSelectedCompany('');
              setFinish(false);
              setFirstScreen(true);
              setOpenNewLeadDialog(false);
              history.push({
                pathname: `/app/accounts/${createdAccountId}`,
                state: { name: createdAccountName },
              });
            }}
            color='primary'
            variant={'text'}
          >
            {t('buttons.close')}
          </Button>
          {sbiCodeSubsidies.length > 0 && (
            <Button
              color='primary'
              variant={'contained'}
              onClick={() => {
                downloadSubsidyScanPDF();
              }}
            >
              {t('subsidyscan.button.download')}
            </Button>
          )}
        </DialogActions>
      </Dialog>
      <Dialog
        fullScreen
        open={openPreview}
        onClose={() => {
          setOpenPreview(false);
          setErrorAddLed(false);
          setNewLead({
            company: {
              name: '',
              dossier_number: '',
              establishment_number: '',
              establishment_city: '',
              establishment_street: '',
              correspondence_city: '',
              correspondence_street: '',
              sme: '',
              keySectors: '',
              subSectors: '',
              doTheScan: false,
            },
            contact: {
              firstName: '',
              lastName: '',
              Email: '',
              phone: '',
              partnerParticipation: false,
              description: '',
            },
          });
          setSelectedCompany('');
          setFinish(false);
          setFirstScreen(true);
          setOpenNewLeadDialog(false);
          history.push({
            pathname: `/app/accounts/${createdAccountId}`,
            state: { name: createdAccountName },
          });
        }}
        TransitionComponent={Transition}
      >
        <AppBar sx={{ position: 'relative' }} color={'transparent'}>
          <Toolbar>
            <>
              <IconButton
                edge='start'
                onClick={() => {
                  setOpenPreview(false);
                  setErrorAddLed(false);
                  setNewLead({
                    company: {
                      name: '',
                      dossier_number: '',
                      establishment_number: '',
                      establishment_city: '',
                      establishment_street: '',
                      correspondence_city: '',
                      correspondence_street: '',
                      sme: '',
                      keySectors: '',
                      subSectors: '',
                      doTheScan: false,
                    },
                    contact: {
                      firstName: '',
                      lastName: '',
                      Email: '',
                      phone: '',
                      partnerParticipation: false,
                      description: '',
                    },
                  });
                  setSelectedCompany('');
                  setFinish(false);
                  setFirstScreen(true);
                  setOpenNewLeadDialog(false);
                  history.push({
                    pathname: `/app/accounts/${createdAccountId}`,
                    state: { name: createdAccountName },
                  });
                }}
                aria-label='close'
                color={'primary'}
              >
                <CloseIcon />
              </IconButton>
              <Typography sx={{ ml: 2, flex: 1 }} variant='h6' component='div'>
                {t('buttons.close')}
              </Typography>
              <Button
                variant='contained'
                startIcon={<SendIcon />}
                onClick={() => {
                  setOpenSendDialog(true);
                }}
              >
                {t('quick_subsidyscan.send_scan_button_label')}
              </Button>
            </>
          </Toolbar>
        </AppBar>
        <DialogContent>
          <div className={classes.webviewerContainer}>
            <div className={classes.webviewerFrame} ref={viewer}></div>
          </div>
        </DialogContent>
      </Dialog>
      <Dialog
        fullWidth={true}
        maxWidth={`sm`}
        open={openSendDialog}
        aria-labelledby='alert-dialog-title'
        aria-describedby='alert-dialog-description'
      >
        <DialogTitle id='alert-dialog-title'>
          {t('quick_subsidyscan.send_Scan_dialog_title')}
        </DialogTitle>
        <DialogContent>
          <Grid container spacing={2}>
            <Grid item xs={12} mt={1}>
              <Autocomplete
                id={'contact'}
                multiple
                isOptionEqualToValue={(option, value) => option.id === value.id}
                getOptionLabel={(option) =>
                  option.firstName + ' ' + option.lastName
                }
                options={contacts}
                value={contact}
                fullWidth={true}
                autoHighlight
                onChange={(e, value) => {
                  if (value) {
                    setContact(value);
                  } else {
                    setContact([]);
                  }
                }}
                renderOption={(props, option) => (
                  <Box component='li' {...props} key={option.id}>
                    <Grid container>
                      <Grid item xs={10}>
                        {option.firstName + ' ' + option.lastName}
                      </Grid>
                    </Grid>
                  </Box>
                )}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label={t(
                      'quick_subsidyscan.send_Scan_contact_person_label'
                    )}
                    helperText={t(
                      'quick_subsidyscan.send_Scan_contact_person_helper_text'
                    )}
                    InputProps={{
                      ...params.InputProps,
                      autoComplete: 'new-password',
                    }}
                    variant='outlined'
                  />
                )}
              />
            </Grid>
            <Grid item xs={12}>
              <Typography variant='body1'>
                {t('quick_subsidyscan.send_Scan_dialog_sub_title')}
              </Typography>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              setOpenSendDialog(false);
            }}
          >
            {t('buttons.cancel')}
          </Button>
          <Button
            onClick={() => {
              handleSendScan();
            }}
            disabled={contact.length === 0}
            color='primary'
            variant='contained'
          >
            {t('buttons.confirm')}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default AddLeadSecondScreen;
