import i18n from '../utils/i18n';

export const rechtsvormOptionss = () => {
  return [
    {
      label: i18n.t('legal_form.BV'),
      value: 'BV = Besloten Vennootschap',
    },
    { label: i18n.t('legal_form.1MANS'), value: '1MANS = Eenmanszaak' },
    {
      label: i18n.t('legal_form.VOF'),
      value: 'VOF = Vennootschap onder Firma',
    },
    {
      label: i18n.t('legal_form.NV'),
      value: 'NV = Naamloze Vennootschap',
    },
    { label: i18n.t('legal_form.STICH'), value: 'STICH = Stichting' },
    { label: i18n.t('legal_form.MTS'), value: 'MTS = Maatschap' },
    { label: i18n.t('legal_form.VER'), value: 'VER = Vereniging' },
    {
      label: i18n.t('legal_form.COOP'),
      value: 'COOP = Coöperatieve Vereniging',
    },
    {
      label: i18n.t('legal_form.CV'),
      value: 'CV = Commanditaire Vennootschap',
    },
    {
      label: i18n.t('legal_form.OW'),
      value: 'OW = Onderlinge Waarborgmij',
    },
    {
      label: i18n.t('legal_form.EES'),
      value: 'EES = Eur Econ Samenwerkingsverband',
    },
    { label: i18n.t('legal_form.OV'), value: 'OV = Overige' },
  ];
};

export const mkbOptionss = () => {
  return [
    { label: i18n.t('sme_yes_no.yes'), value: 'Yes' },
    { label: i18n.t('sme_yes_no.no'), value: 'No' },
  ];
};

export const scanmkbOptionss = () => {
  return [
    { label: i18n.t('quick_subsidyscan.sme_yes_no.yes'), value: 'Yes' },
    { label: i18n.t('quick_subsidyscan.sme_yes_no.no'), value: 'No' },
  ];
};

export const yes_no_Optionss = () => {
  return [
    { label: i18n.t('yes_no.yes'), value: 'Yes' },
    { label: i18n.t('yes_no.no'), value: 'No' },
  ];
};

export const sectorOptionsNl = [
  { label: 'Agri & Food', value: 'Agriculture and Food' },
  { label: 'Hightech Systemen en Materialen', value: 'HTSM' },
  { label: 'Energie', value: 'Energy' },
  { label: 'Water & Maritiem', value: 'Water' },
  { label: 'Informatie en communicatie technologie', value: 'ICT' },
  { label: 'Logistiek', value: 'Logistics' },
  { label: 'Life Sciences & Health', value: 'Life Sciences & Health' },
  { label: 'Creatieve Industrie', value: 'Creative industries' },
  { label: 'Chemische Industrie', value: 'Chemical industry' },
  {
    label: 'Tuinbouw en uitgangsmaterialen',
    value: 'Horticulture and starting materials',
  },
  { label: 'Overige', value: 'Others' },
];

export const sectorOptionsEn = [
  { label: 'Agriculture and Food', value: 'Agriculture and Food' },
  { label: 'HTSM', value: 'HTSM' },
  { label: 'Energy', value: 'Energy' },
  { label: 'Water & Maritime', value: 'Water' },
  { label: 'Information, communication & technology', value: 'ICT' },
  { label: 'Logistics', value: 'Logistics' },
  { label: 'Life Sciences & Health', value: 'Life Sciences & Health' },
  { label: 'Creative industries', value: 'Creative industries' },
  { label: 'Chemical industry', value: 'Chemical industry' },
  {
    label: 'Horticulture and starting materials',
    value: 'Horticulture and starting materials',
  },
  { label: 'Others', value: 'Others' },
];

export const provinceOptions = [
  { label: 'Zuid-Holland', value: 'Zuid Holland' },
  { label: 'Limburg', value: 'Limburg' },
  { label: 'Noord Brabant', value: 'Noord Brabant' },
  { label: 'Overijssel', value: 'Overijssel' },
  { label: 'Drenthe', value: 'Drenthe' },
  { label: 'Noord Holland', value: 'Noord Holland' },
  { label: 'Utrecht', value: 'Utrecht' },
  { label: 'Groningen', value: 'Groningen' },
  { label: 'Flevoland', value: 'Flevoland' },
  { label: 'Friesland', value: 'Friesland' },
  { label: 'Zeeland', value: 'Zeeland' },
  { label: 'Gelderland', value: 'Gelderland' },
];

export const amountOptions = [
  { label: '< € 20K', value: '<20K' },
  { label: '€ 20K - 50K', value: '20K - 50K' },
  { label: '€ 50K - 100K', value: '50K - 100K' },
  { label: '€ 100K - 250K', value: '100K - 250K' },
  { label: '> € 250K', value: '>250K' },
];

export const doughnutData = (progressStep, showMoreDetails) => {
  let totalSteps = showMoreDetails ? 11 : 10;
  return [
    {
      id: 'Progress',
      label: 'Progress',
      value: Math.round((100 / totalSteps) * progressStep),
    },
    {
      id: 'Remaining',
      label: 'Remaining',
      value: 100 - Math.round((100 / totalSteps) * progressStep),
    },
  ];
};

export const newProjectDoughnutData = (progressStep, showMoreDetails) => {
  let totalSteps = showMoreDetails ? 4 : 3;
  return [
    {
      id: 'Progress',
      label: 'Progress',
      value: Math.round((100 / totalSteps) * progressStep),
    },
    {
      id: 'Remaining',
      label: 'Remaining',
      value: 100 - Math.round((100 / totalSteps) * progressStep),
    },
  ];
};
