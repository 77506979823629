import React, { useState, useEffect, useContext } from 'react';
import { withRouter, useHistory, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useStyles } from './styles';
import { useTheme } from '@mui/material/styles';
//import { DashboardContext } from '../../Contexts/DashboardContext';
import { UserContext } from '../../contexts/UserContext';
import Loader from '../../uiComponents/Loader';
import axios from 'axios';
import {
  Box,
  Grid,
  Typography,
  Card,
  CardContent,
  Tooltip,
  IconButton,
  Divider,
  Snackbar,
} from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import { useMediaQuery } from 'react-responsive';
import NumberFormat from 'react-number-format';
import { ReactComponent as OnlineResearch } from '../../assets/OnlineresearchTwoColor.svg';
import { subSectorsMap } from '../../data/projectOptions';
import {
  rdHoursOptions,
  rdCostOptions,
  focusOptionss,
  industryOptionsEn,
  industryOptionsNl,
  sectorOptionsEn,
  sectorOptionsNl,
  subSectorOptionsEn,
  subSectorOptionsNl,
  newProductOptionss,
  rdActivitiesOptionss,
  countryList,
  internationalActivitiesEn,
  internationalActivitiesNl,
  activitiesBussinessEn,
  themeOptionsEn,
  targetOptionsEn,
  activitiesBussinessNl,
  themeOptionsNl,
  targetOptionsNl,
  trlOptions,
} from '../../data/projectOptions';
import { yes_no_Optionss } from '../../data/picklistOptions';
import i18n from 'i18next';

const ProjectX = (props, ref) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const theme = useTheme();
  let location = useLocation();
  let history = useHistory();
  //const { projectEditSuccess, setProjectEditSuccess } =
  //useContext(DashboardContext);
  const [userContext] = useContext(UserContext);
  const [loading, setLoading] = useState(false);
  const isLargeScreen = useMediaQuery({ query: '(min-width: 1280px)' });
  const focusOptions = focusOptionss();
  const newProductOptions = newProductOptionss();
  const rdActivitiesOptions = rdActivitiesOptionss();
  const yesNoOptions = yes_no_Optionss();
  const industryOptions =
    i18n.languages[0] === 'nl' ? industryOptionsNl : industryOptionsEn;
  const sectorOptions =
    i18n.languages[0] === 'nl' ? sectorOptionsNl : sectorOptionsEn;
  const subSectorOptions =
    i18n.languages[0] === 'nl' ? subSectorOptionsNl : subSectorOptionsEn;
  const internationalActivities =
    i18n.languages[0] === 'nl'
      ? internationalActivitiesNl
      : internationalActivitiesEn;
  const activitiesBusiness =
    i18n.languages[0] === 'nl' ? activitiesBussinessNl : activitiesBussinessEn;
  const themeOptions =
    i18n.languages[0] === 'nl' ? themeOptionsNl : themeOptionsEn;
  const targetOptions =
    i18n.languages[0] === 'nl' ? targetOptionsNl : targetOptionsEn;
  const [project, setProject] = useState({});
  const [industries, setIndustries] = useState('');
  const [sectors, setSectors] = useState('');
  const [subSectors, setSubSectors] = useState('');
  const [focus, setFocus] = useState('');
  const [newness, setNewness] = useState('');
  const [activitiesRD, setActivitiesRD] = useState('');
  const [countriesActive, setCountriesActive] = useState('');
  const [countriesExpand, setCountriesExpand] = useState('');
  const [target, setTarget] = useState('');
  const [themes, setThemes] = useState('');
  const [employees1, setEmployees1] = useState('');
  const [employees2, setEmployees2] = useState('');
  const [employees3, setEmployees3] = useState('');
  const [employees4, setEmployees4] = useState('');
  const [whatActivities, setWhatActivities] = useState('');
  const [subSectorDependentOptions, setSubSectorDependentOptions] = useState(
    []
  );
  const handleCloseSnackbar = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    //setProjectEditSuccess(false);
  };

  const labelTypes = {
    '0123X000001GJUGQA4': t('choose_project.type1'),
    '0123X000001GJUCQA4': t('choose_project.type2'),
    '0123X000001GJUDQA4': t('choose_project.type3'),
    '0123X000001GJUEQA4': t('choose_project.type4'),
    '0123X000001GJUFQA4': t('choose_project.type5'),
  };

  // TRANSLATE VALUES
  useEffect(() => {
    let ind = [];
    let sec = [];
    let subSec = [];
    let foc = [];
    let news = [];
    let act = [];
    let countriesA = [];
    let countriesE = [];
    let theme = [];
    let targ = [];
    let actInt = [];
    let actBiz = [];
    //industryOptions
    if (project && project.industrySector) {
      let values = project.industrySector.split(';');
      values.forEach((val) => {
        Object.values(industryOptions).forEach((item) => {
          if (val === item.value) {
            ind.push(item.label);
          }
        });
      });
    }
    //sectorOptions
    if (project && project.keySectors) {
      let values = project.keySectors.split(';');
      values.forEach((val) => {
        Object.values(sectorOptions).forEach((item) => {
          if (val === item.value) {
            sec.push(item.label);
          }
        });
      });
      let dependentSubSectorsOptions = [];
      let subSectorOptionsValues = new Set();
      values.forEach((item) => {
        subSectorsMap[item].forEach((item) => {
          subSectorOptionsValues.add(item);
        });
      });
      subSectorOptions.forEach((subSector) => {
        subSectorOptionsValues.forEach((subSectorValue) => {
          if (subSector.value === subSectorValue) {
            dependentSubSectorsOptions.push(subSector);
          }
        });
      });
      setSubSectorDependentOptions(dependentSubSectorsOptions);
    }
    //subSectorOptions
    if (project && project.subSectors) {
      let values = project.subSectors.split(';');
      values.forEach((val) => {
        Object.values(subSectorOptions).forEach((item) => {
          if (val === item.value) {
            subSec.push(item.label);
          }
        });
      });
    }
    //focusOptions
    if (project && project.projectFocus) {
      let values = project.projectFocus.split(';');
      values.forEach((val) => {
        Object.values(focusOptions).forEach((item) => {
          if (val === item.value) {
            foc.push(item.label);
          }
        });
      });
    }
    //newProductOptions
    if (project && project.newness) {
      let values = project.newness.split(';');
      values.forEach((val) => {
        Object.values(newProductOptions).forEach((item) => {
          if (val === item.value) {
            news.push(item.label);
          }
        });
      });
    }
    //rdActivitiesOptions
    if (project && project.activitiesCarriedOutBy) {
      let values = project.activitiesCarriedOutBy.split(';');
      values.forEach((val) => {
        Object.values(rdActivitiesOptions).forEach((item) => {
          if (val === item.value) {
            act.push(item.label);
          }
        });
      });
    }
    //countryList
    if (project && project.countriesActive) {
      let values = project.countriesActive.split(';');
      values.forEach((val) => {
        Object.values(countryList).forEach((item) => {
          if (val === item.value) {
            countriesA.push(item.label);
          }
        });
      });
    }
    //countryList
    if (project && project.countriesExpanding) {
      let values = project.countriesExpanding.split(';');
      values.forEach((val) => {
        Object.values(countryList).forEach((item) => {
          if (val === item.value) {
            countriesE.push(item.label);
          }
        });
      });
    }
    //themeOptions
    if (project && project.projectThemes) {
      let values = project.projectThemes.split(';');
      values.forEach((val) => {
        Object.values(themeOptions).forEach((item) => {
          if (val === item.value) {
            theme.push(item.label);
          }
        });
      });
    }
    //targetOptions
    if (project && project.projectTargetGroup) {
      let values = project.projectTargetGroup.split(';');
      values.forEach((val) => {
        Object.values(targetOptions).forEach((item) => {
          if (val === item.value) {
            targ.push(item.label);
          }
        });
      });
    }
    //employees yesNoOptions
    if (project && project.willHireWorkersAtDisadvantage) {
      let value = project.willHireWorkersAtDisadvantage;
      Object.values(yesNoOptions).forEach((item) => {
        if (value === item.value) {
          setEmployees4(item.label);
        }
      });
    }
    if (project && project.hiredWorkersAtDisadvantage) {
      let value = project.hiredWorkersAtDisadvantage;
      Object.values(yesNoOptions).forEach((item) => {
        if (value === item.value) {
          setEmployees3(item.label);
        }
      });
    }
    if (project && project.investmentInEmployees) {
      let value = project.investmentInEmployees;
      Object.values(yesNoOptions).forEach((item) => {
        if (value === item.value) {
          setEmployees2(item.label);
        }
      });
    }
    if (project && project.learningWorkPrograms) {
      let value = project.learningWorkPrograms;
      Object.values(yesNoOptions).forEach((item) => {
        if (value === item.value) {
          setEmployees1(item.label);
        }
      });
    }
    //project.activities
    if (project && project.activities) {
      let values = project.activities.split(';');
      if (project.projectTypeId === '0123X000001GJUCQA4') {
        values.forEach((val) => {
          Object.values(internationalActivities).forEach((item) => {
            if (val === item.value) {
              actInt.push(item.label);
            }
          });
        });
        setWhatActivities(actInt.join(', '));
      }
      if (project && project.projectTypeId === '0123X000001GJUDQA4') {
        values.forEach((val) => {
          Object.values(activitiesBusiness).forEach((item) => {
            if (val === item.value) {
              actBiz.push(item.label);
            }
          });
        });
        setWhatActivities(actBiz.join(', '));
      }
    }

    setIndustries(ind.join(', '));
    setSectors(sec.join(', '));
    setSubSectors(subSec.join(', '));
    setFocus(foc.join(', '));
    setNewness(news.join(', '));
    setActivitiesRD(act.join(', '));
    setCountriesExpand(countriesE.join(', '));
    setCountriesActive(countriesA.join(', '));
    setThemes(theme.join(', '));
    setTarget(targ.join(', '));

    if (project) {
      let name = project.name;
      window.document.title = t('page_title.projectX', { name });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [project]);

  useEffect(() => {
    setLoading(true);
    let id = location.pathname.substring(
      location.pathname.lastIndexOf('/') + 1
    );
    var requestBody = {
      projectId: id,
    };
    const apiEndPoint =
      process.env.NODE_ENV === 'development'
        ? process.env.REACT_APP_API_ENDPOINT
        : process.env.REACT_APP_API_ENDPOINT_PROD;
    axios({
      method: 'post',
      url: apiEndPoint + '/v1/partner/project/getProject',
      data: requestBody,
      withCredentials: true,
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        Authorization: `Bearer ${userContext.token}`,
      },
    })
      .then((res) => {
        setProject(res.data.project);
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
      });

    if (location.state) {
      let name = location.state.name;
      window.document.title = t('page_title.projectX', { name });
    } else {
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location, userContext]);

  const topPageRef = React.useRef();

  const CardRow = (props) => {
    const { label, value, onClick, type } = props;

    return (
      <>
        <Grid container item xs={11}>
          <Grid item xs={12} md={4} style={{ paddingRight: 16 }}>
            <Typography
              variant='overline'
              color='textSecondary'
              style={{ display: 'content' }}
            >
              {label}
            </Typography>
          </Grid>
          <Grid
            wrap='nowrap'
            justifyContent='space-between'
            container
            item
            xs={12}
            md={8}
            style={{ paddingRight: 16 }}
          >
            {type === 'formattedNumber' ? (
              <NumberFormat
                value={value}
                displayType={'text'}
                thousandSeparator={'.'}
                decimalSeparator={','}
                isNumericString
                prefix={'€'}
              />
            ) : (
              <Tooltip title={<div>{value}</div>} placement='top-start'>
                <Typography noWrap={isLargeScreen}>{value}</Typography>
              </Tooltip>
            )}
          </Grid>
        </Grid>
        <Grid
          container
          item
          xs={1}
          alignContent='center'
          justifyContent='flex-end'
        >
          <IconButton color='primary' size='small' onClick={onClick}>
            <EditIcon />
          </IconButton>
        </Grid>
      </>
    );
  };

  useEffect(() => {
    document.getElementById('anchor-div').scrollIntoView();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      {loading && <Loader loading={loading} />}
      {project && (
        <div
          className={classes.root + ' ' + classes.projectX}
          style={{ position: 'relative' }}
        >
          <div
            ref={topPageRef}
            id='anchor-div'
            style={{ position: 'absolute', top: '-120px', left: '0' }}
          ></div>

          <Grid container justifyContent='center'>
            <Grid container item xs={12} className={classes.container}>
              <Grid item xs={12}>
                <Box mb={2}>
                  <Typography variant='h6' align='center'>
                    {t('projectX.title')}
                  </Typography>
                </Box>
                <Box mb={3}>
                  <Typography align='center' color='textSecondary'>
                    {t('projectX.subtitle')}
                  </Typography>
                </Box>
              </Grid>
              {/*1*/}
              <Grid container item xs={12} className={classes.box}>
                <Grid item xs={12}>
                  <Card
                    className={classes.card}
                    variant='outlined'
                    style={(theme.custom.boxshadow, { marginRightt: 24 })}
                  >
                    <CardContent>
                      <Grid container>
                        <Grid
                          alignContent='center'
                          container
                          item
                          xs={12}
                          md={isLargeScreen ? 7 : 6}
                        >
                          <Grid item xs={12}>
                            <Box mb={1}>
                              <Typography>
                                {t('projectX.card_title')}
                              </Typography>
                            </Box>
                          </Grid>
                          <Grid item xs={12}>
                            <Box>
                              <Typography variant='body2' color='textSecondary'>
                                {t('projectX.card_subtitle')}
                              </Typography>
                            </Box>
                          </Grid>
                        </Grid>
                        <Grid item xs={12} md={isLargeScreen ? 5 : 6}>
                          <OnlineResearch
                            style={{
                              width: isLargeScreen ? 'auto' : '100%',
                              float: 'right',
                            }}
                          />
                        </Grid>
                      </Grid>
                    </CardContent>
                  </Card>
                </Grid>
              </Grid>
              {/*2*/}
              <Grid container item xs={12} className={classes.box}>
                <Grid item xs={12}>
                  <Card
                    className={classes.card}
                    variant='outlined'
                    style={(theme.custom.boxshadow, { marginRightt: 24 })}
                  >
                    <CardContent>
                      <Grid container>
                        <Grid item xs={12}>
                          <Box mb={1}>
                            <Typography
                              component='h6'
                              variant='h6'
                              className={classes.boxCardHeader}
                            >
                              {t('project-details.project')}
                            </Typography>
                          </Box>
                        </Grid>
                        <Grid
                          container
                          item
                          xs={12}
                          className={classes.mTop12 + ' ' + classes.mBottom12}
                        >
                          <Grid container item xs={12} md={11}>
                            <Grid item xs={12} md={4}>
                              <Typography
                                variant='overline'
                                color='textSecondary'
                              >
                                {t('projectX.type')}
                              </Typography>
                            </Grid>
                            <Grid item xs={12} md={8}>
                              <Typography color='textSecondary'>
                                {labelTypes[project.projectTypeId]}
                              </Typography>
                            </Grid>
                          </Grid>
                          <Grid item md={1}></Grid>
                        </Grid>
                        <Grid item xs={12}>
                          <Divider />
                        </Grid>
                        <Grid
                          container
                          item
                          xs={12}
                          className={classes.mTop12 + ' ' + classes.mBottom12}
                        >
                          <CardRow
                            label={t('projectX.label1')}
                            value={project.name || ''}
                            onClick={() => {
                              history.push({
                                pathname: `/app/projects/${project.id}/edit`,
                                state: {
                                  project: project,
                                  selectedId: project.id,
                                  label: t('projectX.label1'),
                                  value: project.name,
                                  typeInput: 'textfield',
                                  name: 'name',
                                  rows: '',
                                  multiline: false,
                                },
                              });
                            }}
                          ></CardRow>
                        </Grid>
                        <Grid item xs={12}>
                          <Divider />
                        </Grid>
                        <Grid
                          container
                          item
                          xs={12}
                          className={classes.mTop12 + ' ' + classes.mBottom12}
                        >
                          <CardRow
                            label={t('projectX.label2')}
                            value={project.idea || ''}
                            onClick={() => {
                              history.push({
                                pathname: `/app/projects/${project.id}/edit`,
                                state: {
                                  project: project,
                                  selectedId: project.id,
                                  label: t('projectX.label2'),
                                  value: project.idea,
                                  secondValue: '',
                                  typeInput: 'textfield',
                                  name: 'idea',
                                  rows: '5',
                                  multiline: true,
                                },
                              });
                            }}
                          ></CardRow>
                        </Grid>
                        <Grid item xs={12}>
                          <Divider />
                        </Grid>
                        <Grid container item xs={12} className={classes.mTop12}>
                          <CardRow
                            label={t('projectX.label3')}
                            value={project.problemStatement || ''}
                            onClick={() => {
                              history.push({
                                pathname: `/app/projects/${project.id}/edit`,
                                state: {
                                  project: project,
                                  selectedId: project.id,
                                  label: t('projectX.label3'),
                                  value: project.problemStatement,
                                  secondValue: '',
                                  typeInput: 'textfield',
                                  name: 'problemStatement',
                                  rows: '5',
                                  multiline: true,
                                },
                              });
                            }}
                          ></CardRow>
                        </Grid>
                      </Grid>
                    </CardContent>
                  </Card>
                </Grid>
              </Grid>
              {/* 3 PERIODE */}
              {project.projectTypeId !== '0123X000001GJUEQA4' && (
                <Grid container item xs={12} className={classes.box}>
                  <Grid item xs={12}>
                    <Card
                      className={classes.card}
                      variant='outlined'
                      style={(theme.custom.boxshadow, { marginRightt: 24 })}
                    >
                      <CardContent>
                        <Grid container>
                          <Grid item xs={12}>
                            <Box mb={1}>
                              <Typography
                                component='h6'
                                variant='h6'
                                className={classes.boxCardHeader}
                              >
                                {t('project-details.period')}
                              </Typography>
                            </Box>
                          </Grid>
                          <Grid
                            container
                            item
                            xs={12}
                            className={classes.mTop12}
                          >
                            {project.projectTypeId === '0123X000001GJUDQA4' ? (
                              <CardRow
                                label={t('projectX.label29')}
                                value={
                                  project.startDate
                                    ? new Date(
                                        project.startDate
                                      ).toLocaleDateString()
                                    : ''
                                }
                                onClick={() => {
                                  history.push({
                                    pathname: `/app/projects/${project.id}/edit`,
                                    state: {
                                      project: project,
                                      selectedId: project.id,
                                      label: t('projectX.label4'),
                                      value: project.startDate,
                                      secondValue: '',
                                      typeInput: 'datefield',
                                      name: 'startDate',
                                      rows: '',
                                      multiline: false,
                                      endDate: false,
                                    },
                                  });
                                }}
                              ></CardRow>
                            ) : (
                              <CardRow
                                label={t('projectX.label4')}
                                value={
                                  project.startDate
                                    ? new Date(
                                        project.startDate
                                      ).toLocaleDateString() +
                                      t('projectX.label28') +
                                      new Date(
                                        project.endDate
                                      ).toLocaleDateString()
                                    : ''
                                }
                                onClick={() => {
                                  history.push({
                                    pathname: `/app/projects/${project.id}/edit`,
                                    state: {
                                      project: project,
                                      selectedId: project.id,
                                      label: t('projectX.label4'),
                                      value: project.startDate,
                                      secondValue: project.endDate,
                                      typeInput: 'datefield',
                                      name: 'startDate',
                                      rows: '',
                                      multiline: false,
                                      endDate: true,
                                    },
                                  });
                                }}
                              ></CardRow>
                            )}
                          </Grid>
                        </Grid>
                      </CardContent>
                    </Card>
                  </Grid>
                </Grid>
              )}
              {/* 4 Research & Development */}
              {project.projectTypeId === '0123X000001GJUGQA4' && (
                <Grid container item xs={12} className={classes.box}>
                  <Grid item xs={12}>
                    <Card
                      className={classes.card}
                      variant='outlined'
                      style={(theme.custom.boxshadow, { marginRightt: 24 })}
                    >
                      <CardContent>
                        <Grid container>
                          <Grid item xs={12}>
                            <Box mb={1}>
                              <Typography
                                component='h6'
                                variant='h6'
                                className={classes.boxCardHeader}
                              >
                                {t('project-details.randd')}
                              </Typography>
                            </Box>
                          </Grid>
                          <Grid
                            container
                            item
                            xs={12}
                            className={classes.mTop12 + ' ' + classes.mBottom12}
                          >
                            <CardRow
                              label={t('projectX.label5')}
                              value={project.noOfRnDEmployees || ''}
                              onClick={() => {
                                history.push({
                                  pathname: `/app/projects/${project.id}/edit`,
                                  state: {
                                    project: project,
                                    selectedId: project.id,
                                    label: t('projectX.label5'),
                                    value: project.noOfRnDEmployees,
                                    secondValue: '',
                                    typeInput: 'textfield',
                                    name: 'noOfRnDEmployees',
                                    rows: '',
                                    multiline: false,
                                  },
                                });
                              }}
                            ></CardRow>
                          </Grid>
                          <Grid item xs={12}>
                            <Divider />
                          </Grid>
                          <Grid
                            container
                            item
                            xs={12}
                            className={classes.mTop12 + ' ' + classes.mBottom12}
                          >
                            <CardRow
                              label={t('projectX.label6')}
                              value={project.rndHours || ''}
                              onClick={() => {
                                history.push({
                                  pathname: `/app/projects/${project.id}/edit`,
                                  state: {
                                    project: project,
                                    selectedId: project.id,
                                    label: t('projectX.label6'),
                                    value: project.rndHours,
                                    typeInput: 'selectfield',
                                    name: 'rndHours',
                                    options: rdHoursOptions,
                                  },
                                });
                              }}
                            ></CardRow>
                          </Grid>
                          <Grid item xs={12}>
                            <Divider />
                          </Grid>
                          <Grid
                            container
                            item
                            xs={12}
                            className={classes.mTop12 + ' ' + classes.mBottom12}
                          >
                            <CardRow
                              label={t('projectX.label7')}
                              value={project.rndCosts || ''}
                              onClick={() => {
                                history.push({
                                  pathname: `/app/projects/${project.id}/edit`,
                                  state: {
                                    project: project,
                                    selectedId: project.id,
                                    label: t('projectX.label7'),
                                    value: project.rndCosts,
                                    typeInput: 'selectfield',
                                    name: 'rndCosts',
                                    options: rdCostOptions,
                                  },
                                });
                              }}
                            ></CardRow>
                          </Grid>
                          <Grid item xs={12}>
                            <Divider />
                          </Grid>
                          <Grid
                            container
                            item
                            xs={12}
                            className={classes.mTop12}
                          >
                            <CardRow
                              label={t('projectX.label8')}
                              value={activitiesRD || ''}
                              onClick={() => {
                                history.push({
                                  pathname: `/app/projects/${project.id}/edit`,
                                  state: {
                                    project: project,
                                    selectedId: project.id,
                                    label: t('projectX.label8'),
                                    value: project.activitiesCarriedOutBy,
                                    typeInput: 'radiofield',
                                    name: 'activitiesCarriedOutBy',
                                    options: rdActivitiesOptions,
                                  },
                                });
                              }}
                            ></CardRow>
                          </Grid>
                        </Grid>
                      </CardContent>
                    </Card>
                  </Grid>
                </Grid>
              )}
              {/* 5 Markt */}
              {project.projectTypeId === '0123X000001GJUGQA4' && (
                <Grid container item xs={12} className={classes.box}>
                  <Grid item xs={12}>
                    <Card
                      className={classes.card}
                      variant='outlined'
                      style={(theme.custom.boxshadow, { marginRightt: 24 })}
                    >
                      <CardContent>
                        <Grid container>
                          <Grid item xs={12}>
                            <Box mb={1}>
                              <Typography
                                component='h6'
                                variant='h6'
                                className={classes.boxCardHeader}
                              >
                                {t('project-details.market')}
                              </Typography>
                            </Box>
                          </Grid>
                          <Grid
                            container
                            item
                            xs={12}
                            className={classes.mTop12 + ' ' + classes.mBottom12}
                          >
                            <CardRow
                              label={t('labels.sectors')}
                              value={sectors || ''}
                              onClick={() => {
                                history.push({
                                  pathname: `/client/app/projects/${project._id}/edit`,
                                  state: {
                                    project: project,
                                    selectedId: project._id,
                                    label: t('labels.sectors'),
                                    selectedOptions: project.keySectors,
                                    typeInput: 'autocompletefield',
                                    name: 'keySectors',
                                    options: sectorOptions,
                                  },
                                });
                              }}
                            ></CardRow>
                          </Grid>
                          <Grid item xs={12}>
                            <Divider />
                          </Grid>
                          <Grid
                            container
                            item
                            xs={12}
                            className={classes.mTop12 + ' ' + classes.mBottom12}
                          >
                            <CardRow
                              label={t('labels.subSectors')}
                              value={subSectors || ''}
                              onClick={() => {
                                history.push({
                                  pathname: `/client/app/projects/${project._id}/edit`,
                                  state: {
                                    project: project,
                                    selectedId: project._id,
                                    label: t('labels.subSectors'),
                                    selectedOptions: project.subSectors,
                                    typeInput: 'autocompletefield',
                                    name: 'subSectors',
                                    options: subSectorDependentOptions,
                                  },
                                });
                              }}
                            ></CardRow>
                          </Grid>
                          <Grid item xs={12}>
                            <Divider />
                          </Grid>
                          <Grid
                            container
                            item
                            xs={12}
                            className={classes.mTop12 + ' ' + classes.mBottom12}
                          >
                            <CardRow
                              label={t('projectX.label9')}
                              value={industries || ''}
                              onClick={() => {
                                history.push({
                                  pathname: `/app/projects/${project.id}/edit`,
                                  state: {
                                    project: project,
                                    selectedId: project.id,
                                    label: t('projectX.label9'),
                                    selectedOptions: project.industrySector,
                                    typeInput: 'autocompletefield',
                                    name: 'industrySector',
                                    options: industryOptions,
                                  },
                                });
                              }}
                            ></CardRow>
                          </Grid>
                          <Grid item xs={12}>
                            <Divider />
                          </Grid>
                          <Grid
                            container
                            item
                            xs={12}
                            className={classes.mTop12 + ' ' + classes.mBottom12}
                          >
                            <CardRow
                              label={t('projectX.label10')}
                              value={newness || ''}
                              onClick={() => {
                                history.push({
                                  pathname: `/app/projects/${project.id}/edit`,
                                  state: {
                                    project: project,
                                    selectedId: project.id,
                                    label: t('projectX.label10'),
                                    value: project.newness,
                                    typeInput: 'selectfield',
                                    name: 'newness',
                                    options: newProductOptions,
                                  },
                                });
                              }}
                            ></CardRow>
                          </Grid>
                          <Grid item xs={12}>
                            <Divider />
                          </Grid>
                          <Grid
                            container
                            item
                            xs={12}
                            className={classes.mTop12}
                          >
                            <CardRow
                              label={t('focus.focus')}
                              value={focus || ''}
                              onClick={() => {
                                history.push({
                                  pathname: `/app/projects/${project.id}/edit`,
                                  state: {
                                    project: project,
                                    selectedId: project.id,
                                    label: t('focus.focus'),
                                    selectedOptions: project.projectFocus,
                                    typeInput: 'checkboxfield',
                                    name: 'projectFocus',
                                    options: focusOptions,
                                  },
                                });
                              }}
                            ></CardRow>
                          </Grid>
                        </Grid>
                      </CardContent>
                    </Card>
                  </Grid>
                </Grid>
              )}
              {/* 5.B International - Countries */}
              {project.projectTypeId === '0123X000001GJUCQA4' && (
                <Grid container item xs={12} className={classes.box}>
                  <Grid item xs={12}>
                    <Card
                      className={classes.card}
                      variant='outlined'
                      style={(theme.custom.boxshadow, { marginRightt: 24 })}
                    >
                      <CardContent>
                        <Grid container>
                          <Grid item xs={12}>
                            <Box mb={1}>
                              <Typography
                                component='h6'
                                variant='h6'
                                className={classes.boxCardHeader}
                              >
                                {t('projectX.label11')}
                              </Typography>
                            </Box>
                          </Grid>
                          <Grid
                            container
                            item
                            xs={12}
                            className={classes.mTop12 + ' ' + classes.mBottom12}
                          >
                            <CardRow
                              label={t('projectX.label12_1')}
                              value={countriesActive || ''}
                              onClick={() => {
                                history.push({
                                  pathname: `/app/projects/${project.id}/edit`,
                                  state: {
                                    project: project,
                                    selectedId: project.id,
                                    label: t('projectX.label12_1'),
                                    selectedOptions: project.countriesActive,
                                    typeInput: 'autocompletefield',
                                    name: 'countriesActive',
                                    options: countryList,
                                  },
                                });
                              }}
                            ></CardRow>
                          </Grid>
                          <Grid item xs={12}>
                            <Divider />
                          </Grid>
                          <Grid
                            container
                            item
                            xs={12}
                            className={classes.mTop12}
                          >
                            <CardRow
                              label={t('projectX.label13_1')}
                              value={countriesExpand || ''}
                              onClick={() => {
                                history.push({
                                  pathname: `/app/projects/${project.id}/edit`,
                                  state: {
                                    project: project,
                                    selectedId: project.id,
                                    label: t('projectX.label13_1'),
                                    selectedOptions: project.countriesExpanding,
                                    typeInput: 'autocompletefield',
                                    name: 'countriesExpanding',
                                    options: countryList,
                                  },
                                });
                              }}
                            ></CardRow>
                          </Grid>
                        </Grid>
                      </CardContent>
                    </Card>
                  </Grid>
                </Grid>
              )}
              {/* 5.D  employees activities */}
              {project.projectTypeId === '0123X000001GJUEQA4' && (
                <>
                  <Grid container item xs={12} className={classes.box}>
                    <Grid item xs={12}>
                      <Card
                        className={classes.card}
                        variant='outlined'
                        style={(theme.custom.boxshadow, { marginRightt: 24 })}
                      >
                        <CardContent>
                          <Grid container>
                            <Grid item xs={12}>
                              <Box mb={1}>
                                <Typography
                                  component='h6'
                                  variant='h6'
                                  className={classes.boxCardHeader}
                                >
                                  {t('projectX.label20')}
                                </Typography>
                              </Box>
                            </Grid>
                            <Grid
                              container
                              item
                              xs={12}
                              className={
                                classes.mTop12 + ' ' + classes.mBottom12
                              }
                            >
                              <CardRow
                                label={t('projectX.label14')}
                                value={employees1 || ''}
                                onClick={() => {
                                  history.push({
                                    pathname: `/app/projects/${project.id}/edit`,
                                    state: {
                                      project: project,
                                      selectedId: project.id,
                                      label: t('projectX.label13'),
                                      value: project.learningWorkPrograms,
                                      typeInput: 'radiofield',
                                      name: 'learningWorkPrograms',
                                      options: yesNoOptions,
                                    },
                                  });
                                }}
                              ></CardRow>
                            </Grid>
                            <Grid item xs={12}>
                              <Divider />
                            </Grid>
                            <Grid
                              container
                              item
                              xs={12}
                              className={
                                classes.mTop12 + ' ' + classes.mBottom12
                              }
                            >
                              <CardRow
                                label={t('projectX.label15')}
                                value={employees2 || ''}
                                onClick={() => {
                                  history.push({
                                    pathname: `/app/projects/${project.id}/edit`,
                                    state: {
                                      project: project,
                                      selectedId: project.id,
                                      label: t('projectX.label15'),
                                      value: project.investmentInEmployees,
                                      typeInput: 'radiofield',
                                      name: 'investmentInEmployees',
                                      options: yesNoOptions,
                                    },
                                  });
                                }}
                              ></CardRow>
                            </Grid>
                            <Grid item xs={12}>
                              <Divider />
                            </Grid>
                            <Grid
                              container
                              item
                              xs={12}
                              className={
                                classes.mTop12 + ' ' + classes.mBottom12
                              }
                            >
                              <CardRow
                                label={t('projectX.label16')}
                                value={employees3 || ''}
                                onClick={() => {
                                  history.push({
                                    pathname: `/app/projects/${project.id}/edit`,
                                    state: {
                                      project: project,
                                      selectedId: project.id,
                                      label: t('projectX.label16'),
                                      value: project.hiredWorkersAtDisadvantage,
                                      typeInput: 'radiofield',
                                      name: 'hiredWorkersAtDisadvantage',
                                      options: yesNoOptions,
                                    },
                                  });
                                }}
                              ></CardRow>
                            </Grid>
                            <Grid item xs={12}>
                              <Divider />
                            </Grid>
                            <Grid
                              container
                              item
                              xs={12}
                              className={classes.mTop12}
                            >
                              <CardRow
                                label={t('projectX.label17')}
                                value={employees4 || ''}
                                onClick={() => {
                                  history.push({
                                    pathname: `/app/projects/${project.id}/edit`,
                                    state: {
                                      project: project,
                                      selectedId: project.id,
                                      label: t('projectX.label17'),
                                      value:
                                        project.willHireWorkersAtDisadvantage,
                                      typeInput: 'radiofield',
                                      name: 'willHireWorkersAtDisadvantage',
                                      options: yesNoOptions,
                                    },
                                  });
                                }}
                              ></CardRow>
                            </Grid>
                          </Grid>
                        </CardContent>
                      </Card>
                    </Grid>
                  </Grid>
                </>
              )}
              {/* 5.E Social */}
              {project.projectTypeId === '0123X000001GJUFQA4' && (
                <Grid container item xs={12} className={classes.box}>
                  <Grid item xs={12}>
                    <Card
                      className={classes.card}
                      variant='outlined'
                      style={(theme.custom.boxshadow, { marginRightt: 24 })}
                    >
                      <CardContent>
                        <Grid container>
                          <Grid item xs={12}>
                            <Box mb={1}>
                              <Typography
                                component='h6'
                                variant='h6'
                                className={classes.boxCardHeader}
                              >
                                {t('project-details.themes')}
                              </Typography>
                            </Box>
                          </Grid>
                          <Grid
                            container
                            item
                            xs={12}
                            className={classes.mTop12 + ' ' + classes.mBottom12}
                          >
                            <CardRow
                              label={t('project-details.themes')}
                              value={themes || ''}
                              onClick={() => {
                                history.push({
                                  pathname: `/app/projects/${project.id}/edit`,
                                  state: {
                                    project: project,
                                    selectedId: project.id,
                                    label: t('project-details.themes'),
                                    selectedOptions: project.projectThemes,
                                    typeInput: 'autocompletefield',
                                    name: 'projectThemes',
                                    options: themeOptions,
                                  },
                                });
                              }}
                            ></CardRow>
                          </Grid>
                          <Grid item xs={12}>
                            <Divider />
                          </Grid>
                          <Grid
                            container
                            item
                            xs={12}
                            className={classes.mTop12}
                          >
                            <CardRow
                              label={t('project-details.target')}
                              value={target || ''}
                              onClick={() => {
                                history.push({
                                  pathname: `/app/projects/${project.id}/edit`,
                                  state: {
                                    project: project,
                                    selectedId: project.id,
                                    label: t('project-details.target'),
                                    selectedOptions: project.projectTargetGroup,
                                    typeInput: 'autocompletefield',
                                    name: 'projectTargetGroup',
                                    options: targetOptions,
                                  },
                                });
                              }}
                            ></CardRow>
                          </Grid>
                        </Grid>
                      </CardContent>
                    </Card>
                  </Grid>
                </Grid>
              )}
              {/*6*/}
              <Grid container item xs={12}>
                <Grid item xs={12}>
                  <Card
                    className={classes.card}
                    variant='outlined'
                    style={(theme.custom.boxshadow, { marginRightt: 24 })}
                  >
                    <CardContent>
                      <Grid container>
                        <Grid item xs={12}>
                          <Box mb={1}>
                            <Typography
                              component='h6'
                              variant='h6'
                              className={classes.boxCardHeader}
                            >
                              {t('project-details.subsidie')}
                            </Typography>
                          </Box>
                        </Grid>
                        <Grid
                          container
                          item
                          xs={12}
                          className={classes.mTop12 + ' ' + classes.mBottom12}
                        >
                          <CardRow
                            label={t('projectX.label18')}
                            value={project.subsidyNeed || ''}
                            type='formattedNumber'
                            onClick={() => {
                              history.push({
                                pathname: `/app/projects/${project.id}/edit`,
                                state: {
                                  project: project,
                                  selectedId: project.id,
                                  label: t('projectX.budget_label'),
                                  value: project.subsidyNeed,
                                  typeInput: 'textfield',
                                  name: 'subsidyNeed',
                                  multiline: false,
                                  rows: '',
                                },
                              });
                            }}
                          ></CardRow>
                        </Grid>
                        {project.projectTypeId === '0123X000001GJUGQA4' && (
                          <>
                            <Grid item xs={12}>
                              <Divider />
                            </Grid>
                            <Grid
                              container
                              item
                              xs={12}
                              className={
                                classes.mTop12 + ' ' + classes.mBottom12
                              }
                            >
                              <CardRow
                                label={t('projectX.internal_resources')}
                                value={project.internalResources || ''}
                                onClick={() => {
                                  history.push({
                                    pathname: `/app/projects/${project.id}/edit`,
                                    state: {
                                      project: project,
                                      selectedId: project.id,
                                      label: t('projectX.internal_resources'),
                                      value: project.internalResources,
                                      typeInput: 'selectfield',
                                      name: 'internalResources',
                                      options: rdCostOptions,
                                    },
                                  });
                                }}
                              ></CardRow>
                            </Grid>
                          </>
                        )}
                        {project.projectTypeId === '0123X000001GJUGQA4' && (
                          <>
                            <Grid item xs={12}>
                              <Divider />
                            </Grid>
                            <Grid
                              container
                              item
                              xs={12}
                              className={
                                classes.mTop12 + ' ' + classes.mBottom12
                              }
                            >
                              <CardRow
                                label={t('projectX.external_resources')}
                                value={project.externalResources || ''}
                                onClick={() => {
                                  history.push({
                                    pathname: `/app/projects/${project.id}/edit`,
                                    state: {
                                      project: project,
                                      selectedId: project.id,
                                      label: t('projectX.external_resources'),
                                      value: project.externalResources,
                                      typeInput: 'selectfield',
                                      name: 'externalResources',
                                      options: rdCostOptions,
                                    },
                                  });
                                }}
                              ></CardRow>
                            </Grid>
                          </>
                        )}
                        {/* 7 TRL */}
                        {project.projectTypeId === '0123X000001GJUGQA4' && (
                          <>
                            <Grid item xs={12}>
                              <Divider />
                            </Grid>
                            <Grid
                              container
                              item
                              xs={12}
                              className={
                                classes.mTop12 + ' ' + classes.mBottom12
                              }
                            >
                              <CardRow
                                label={t('labels.pre')}
                                value={project.preTRLLevel || ''}
                                onClick={() => {
                                  history.push({
                                    pathname: `/app/projects/${project.id}/edit`,
                                    state: {
                                      project: project,
                                      selectedId: project.id,
                                      label: t('labels.pre'),
                                      value: project.preTRLLevel,
                                      typeInput: 'selectfield',
                                      name: 'preTRLLevel',
                                      options: trlOptions,
                                    },
                                  });
                                }}
                              ></CardRow>
                            </Grid>
                          </>
                        )}
                        {/* 8 International - Activities - Business */}
                        {(project.projectTypeId === '0123X000001GJUCQA4' ||
                          project.projectTypeId === '0123X000001GJUDQA4') && (
                          <>
                            <Grid item xs={12}>
                              <Divider />
                            </Grid>
                            <Grid
                              container
                              item
                              xs={12}
                              className={classes.mTop12}
                            >
                              <CardRow
                                label={t('projectX.label19')}
                                value={whatActivities || ''}
                                onClick={() => {
                                  history.push({
                                    pathname: `/app/projects/${project.id}/edit`,
                                    state: {
                                      project: project,
                                      selectedId: project.id,
                                      label: t('project-details.themes'),
                                      selectedOptions: project.activities,
                                      typeInput: 'autocompletefield',
                                      name: 'activities',
                                      options:
                                        project.projectTypeId ===
                                        '0123X000001GJUCQA4'
                                          ? internationalActivities
                                          : activitiesBusiness,
                                    },
                                  });
                                }}
                              ></CardRow>
                            </Grid>
                          </>
                        )}
                      </Grid>
                    </CardContent>
                  </Card>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </div>
      )}

      {/*<Snackbar
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        open={projectEditSuccess}
        autoHideDuration={3000}
        onClose={handleCloseSnackbar}
        message={t('dialog.edit_success')}
      />*/}
    </>
  );
};

export default withRouter(ProjectX);
