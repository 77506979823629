import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Grid, Card, CardContent } from '@mui/material';
import { UserContext } from '../../contexts/UserContext';
import SectionCard from '../../uiComponents/SectionCard';

const DealsMainContent = (props) => {
  const {
    customMinWidth,
    customMaxWidth,
    variant,
    dataContacted,
    dataInterested,
    dataWaiting,
    dataWon,
    dataLost,
    locale,
    expanded,
    setExpanded,
    expandedId,
    setExpandedId,
  } = props;

  const { t } = useTranslation();
  // eslint-disable-next-line no-unused-vars
  const [userContext, setUserContext] = useContext(UserContext);

  return (
    <Box
      sx={{
        minWidth: customMinWidth ? customMinWidth : 275,
        maxWidth: customMaxWidth ? customMaxWidth : 1280,
        margin: 'auto',
        height: 1,
        overflowY: 'hidden',
      }}
    >
      <Card
        variant={variant ? variant : 'standard'}
        sx={{
          borderRadius: 2,
          padding: 0,
          height: 1,
          overflowY: 'hidden',
        }}
      >
        <CardContent
          sx={{
            padding: 0,
            overflowX: 'auto',
            '&:last-child': {
              pb: 0.5,
            },
            height: 1,
            overflowY: 'hidden',
          }}
        >
          <Grid
            container
            wrap='nowrap'
            alignItems={'flex-start'}
            sx={{ height: 1, overflowY: 'hidden' }}
          >
            <Grid
              alignContent='center'
              container
              item
              xs
              sx={{ width: 'auto', height: 1, overflowY: 'hidden' }}
            >
              <SectionCard
                maxWidth={264}
                locale={locale}
                title={t('deals.section.title1')}
                subtitle={t('deals.section.subtitle1')}
                createLabel={t('deals.section.label_create_date')}
                closeLabel={t('deals.section.label_close_date')}
                data={dataContacted}
                noData={t('messages.no_results_available')}
                pr={true}
                mh={true}
                expanded={expanded}
                setExpanded={setExpanded}
                expandedId={expandedId}
                setExpandedId={setExpandedId}
              />{' '}
            </Grid>
            <Grid
              alignContent='center'
              container
              item
              xs
              sx={{ width: 'auto', height: 1, overflowY: 'hidden' }}
            >
              <SectionCard
                maxWidth={264}
                locale={locale}
                title={t('deals.section.title2')}
                subtitle={t('deals.section.subtitle2')}
                createLabel={t('deals.section.label_create_date')}
                closeLabel={t('deals.section.label_close_date')}
                data={dataInterested}
                noData={t('messages.no_results_available')}
                pr={true}
                mh={true}
                expanded={expanded}
                setExpanded={setExpanded}
                expandedId={expandedId}
                setExpandedId={setExpandedId}
              />{' '}
            </Grid>
            <Grid
              alignContent='center'
              container
              item
              xs
              sx={{ width: 'auto', height: 1, overflowY: 'hidden' }}
            >
              <SectionCard
                maxWidth={264}
                locale={locale}
                title={t('deals.section.title3')}
                subtitle={t('deals.section.subtitle3')}
                createLabel={t('deals.section.label_create_date')}
                closeLabel={t('deals.section.label_close_date')}
                data={dataWaiting}
                noData={t('messages.no_results_available')}
                pr={true}
                mh={true}
                expanded={expanded}
                setExpanded={setExpanded}
                expandedId={expandedId}
                setExpandedId={setExpandedId}
              />{' '}
            </Grid>
            <Grid
              alignContent='center'
              container
              item
              xs
              sx={{ width: 'auto', height: 1, overflowY: 'hidden' }}
            >
              <SectionCard
                maxWidth={264}
                locale={locale}
                title={t('deals.section.title4')}
                subtitle={t('deals.section.subtitle4')}
                createLabel={t('deals.section.label_create_date')}
                closeLabel={t('deals.section.label_close_date')}
                data={[...dataWon, ...dataLost]}
                noData={t('messages.no_results_available')}
                pr={true}
                mh={true}
                expanded={expanded}
                setExpanded={setExpanded}
                expandedId={expandedId}
                setExpandedId={setExpandedId}
              />{' '}
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </Box>
  );
};

export default DealsMainContent;
