export const root = {
  padding: 3,
  pt: 6,
  '@media screen and (max-width:1280px)': {
    pt: 3,
  },
  '@media screen and (max-width:767px)': {
    padding: 2,
  },
};
export const rootContent = {};

export const dashboardCard = {
  mx: 'auto',
  '& > div': {
    width: 540,
    maxWidth: 540,
    mb: 3,
    // mx: 2,
  },
};
export const hideImage = {
  '@media screen and (max-width:900px)': {
    display: 'none',
  },
};
export const rootAccountDetails = {};

export const selectFilters = {
  '& > div': {
    '@media screen and (min-width:768px)': {
      maxWidth: 336,
    },
    '@media screen and (max-width:768px)': {
      maxWidth: '100% !important',
    },
  },
  '@media screen and (max-width:800px)': {
    maxWidth: '100% !important',
  },
};

export const box = {};

export const dealsBox = {
  height: 'calc(100% - 336px)',
  maxHeight: 'calc(100% - 336px)',
  '@media screen and (max-width:1279px)': {
    height: 'calc(100% - 304px)',
    maxHeight: 'calc(100% - 304px)',
  },
  '@media screen and (max-width:996px)': {
    height: 'auto', //'100%',
    maxHeight: 'auto', //'100%',
    '& > div': {
      '& > div': {
        mb: 2,
      },
    },
  },
};

export const boxFilters1 = {
  minWidth: 'fit-content',
  maxWidth: 'fit-content',
  '@media screen and (max-width:800px)': {
    justifyContent: 'space-between',
    mt: 3,
  },
  '@media screen and (max-width:617px)': {},
};

export const boxFilters2 = {
  mr: {
    xs: 'auto',
    sm: 'unset',
  },
  mt: {
    xs: 1,
    sm: 'unset',
  },
  '@media screen and (max-width:800px)': {
    flexGrow: 1,
    '& > div': {
      maxWidth: 'unset !important',
      width: 1,
    },
  },
  '@media screen and (max-width:617px)': {
    width: 1,
    mt: 2,
  },
};

export const boxFilters3 = {
  padding: 3,
  pt: 0.5,
  pb: 0,
  mr: {
    xs: 'auto',
    sm: 'unset',
  },
  mt: {
    xs: 1,
    sm: 'unset',
  },
  '@media screen and (max-width:354px)': {},
  '@media screen and (max-width:617px)': {
    flexGrow: 1,
    width: 1,
    mt: 2,
    '& > div': {
      maxWidth: 'unset !important',
      width: 1,
    },
  },
};

export const boxFilters4 = {
  mr: {
    xs: 'auto',
    sm: 'unset',
  },
  mt: {
    xs: 1,
    sm: 'unset',
  },
  '@media screen and (max-width:800px)': {
    flexGrow: 1,
    '& > div': {
      maxWidth: 'unset !important',
      width: 1,
    },
  },
  '@media screen and (max-width:617px)': {
    width: 1,
    mt: 3,
  },
  '@media screen and (max-width:560px)': {},
};

export const leadsTable = {
  '@media screen and (min-width:768px)': {
    display: 'block',
  },
  '@media screen and (max-width:767px)': {
    display: 'none',
  },
};

export const leadsAccordion = {
  '@media screen and (max-width:768px)': {
    display: 'block',
  },
  '@media screen and (min-width:768px)': {
    display: 'none',
  },
};

export const applicationsTable = {};
