import React from 'react';
import {
  FormControl,
  Select,
  MenuItem,
  InputLabel,
  OutlinedInput,
} from '@mui/material';

const SingleselectField = (props) => {
  const {
    // index,
    options,
    field,
    val,
    handleSelect,
    // disabled,
    label,
    idSection,
  } = props;

  const [value, setValue] = React.useState(
    field && field.value ? field.value : val ? val : '',
  );

  return (
    <FormControl fullWidth>
      <InputLabel id="rechtsvorm-label" shrink={true}>
        {label && label}
      </InputLabel>
      <Select
        labelId="demo-simple-select-label"
        id="demo-simple-select"
        size="small"
        fullWidth
        data-section={idSection}
        // disabled={field.disabled ? field.disabled : false}
        onFocus={() => {}}
        value={value}
        onChange={(e) => {
          setValue(e.target.value);
          handleSelect && handleSelect(e.target.value);
        }}
        displayEmpty
        input={<OutlinedInput notched label={label && label} />}
        // InputLabelProps={{ shrink: true }}
      >
        {options &&
          options.map((item, i) => {
            return (
              <MenuItem value={item.value || ''} key={item.value || ''}>
                {item.label}
              </MenuItem>
            );
          })}
      </Select>
    </FormControl>
  );
};

export default SingleselectField;
