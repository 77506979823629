import React, { createContext, useState } from 'react';

export const NewProjectContext = createContext([{}, () => {}]);

export const NewProjectProvider = ({ children }) => {
  const [projectProgress, setProjectProgress] = useState(0);
  const [percentage, setPercentage] = useState(0);
  const [projectType, setProjectType] = useState('');
  const [newProjectId, setNewProjectId] = useState('');
  const [temporary, setTemporary] = useState(false);
  // project details type product
  const [industries, setIndustries] = useState([]);
  const [sectors, setSectors] = useState([]);
  const [subSectors, setSubSectors] = useState([]);
  // project type international
  const [active, setActive] = useState([]);
  const [expand, setExpand] = useState([]);
  const [activitiesInt, setActivitiesInt] = useState([]);
  // type business
  const [activities, setActivities] = useState([]);
  // project details type social
  const [themes, setThemes] = useState([]);
  const [target, setTarget] = useState([]);
  const [popularSubsidies, setPopularSubsidies] = useState([]);
  const [otherSubsidies, setOtherSubsidies] = useState([]);
  const [assignments, setAssignments] = useState([]);
  const [selectedPopularSubsidies, setSelectedPopularSubsidies] = useState([]);
  const [selectedOtherSubsidies, setSelectedOtherSubsidies] = useState([]);
  const [selectedAssignments, setSelectedAssignments] = useState([]);
  const [accountId, setAccountId] = useState('');
  const [newProjectAccount, setNewProjectAccount] = useState({});
  const [projectProduct, setProjectProduct] = useState({
    name: '',
    idea: '',
    problemStatement: '',
    startDate: null,
    endDate: null,
    noOfRnDEmployees: '',
    rndHours: '',
    rndCosts: '',
    activitiesCarriedOutBy: '',
    industrySector: '',
    newness: '',
    projectFocus: '',
    preTRLLevel: '',
    postTRLLevel: '',
    subsidyNeed: '',
    projectTypeId: '',
    id: '',
    internalResources: '',
    externalResources: '',
    intendedStartDate: '',
    keySectors: '',
    subSectors: '',
  });

  const [projectInt, setProjectInt] = useState({
    name: '',
    idea: '',
    problemStatement: '',
    startDate: null,
    endDate: null,
    countriesActive: '',
    countriesExpanding: '',
    activities: '',
    subsidyNeed: '',
    projectTypeId: '',
    id: '',
  });

  const [projectBusiness, setProjectBusiness] = useState({
    name: '',
    idea: '',
    problemStatement: '',
    startDate: null,
    activities: '',
    subsidyNeed: '',
    projectTypeId: '',
    id: '',
  });

  const [projectEmployees, setProjectEmployees] = useState({
    name: '',
    idea: '',
    problemStatement: '',
    learningWorkPrograms: '',
    investmentInEmployees: '',
    hiredWorkersAtDisadvantage: '',
    willHireWorkersAtDisadvantage: '',
    subsidyNeed: '',
    projectTypeId: '',
    id: '',
  });

  const [showMoreDetails, setShowMoreDetails] = useState(false);

  return (
    <NewProjectContext.Provider
      value={{
        projectProgress,
        setProjectProgress,
        percentage,
        setPercentage,
        projectType,
        setProjectType,
        projectProduct,
        setProjectProduct,
        projectInt,
        setProjectInt,
        projectBusiness,
        setProjectBusiness,
        projectEmployees,
        setProjectEmployees,
        newProjectId,
        setNewProjectId,
        industries,
        setIndustries,
        active,
        setActive,
        expand,
        setExpand,
        activitiesInt,
        setActivitiesInt,
        activities,
        setActivities,
        themes,
        setThemes,
        target,
        setTarget,
        showMoreDetails,
        setShowMoreDetails,
        popularSubsidies,
        setPopularSubsidies,
        otherSubsidies,
        setOtherSubsidies,
        selectedPopularSubsidies,
        setSelectedPopularSubsidies,
        selectedOtherSubsidies,
        setSelectedOtherSubsidies,
        temporary,
        setTemporary,
        assignments,
        setAssignments,
        selectedAssignments,
        setSelectedAssignments,
        accountId,
        setAccountId,
        newProjectAccount,
        setNewProjectAccount,
        sectors,
        setSectors,
        subSectors,
        setSubSectors,
      }}
    >
      {children}
    </NewProjectContext.Provider>
  );
};
