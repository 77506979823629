import React, { useState, useEffect, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import BaseLayout from '../layouts/BaseLayout';
import { useIsMounted } from '../utils/customHooks';
import { root, rootContent } from './styles';
import { Grid, Snackbar, IconButton } from '@mui/material';
import Alert from '@mui/material/Alert';
import CloseIcon from '@mui/icons-material/Close';
import ForgotPasswordDetails from '../components/ForgotPassword/ForgotPasswordDetails';
import LoginSideComponent from '../components/Login/LoginSideComponent';
import Loader from '../uiComponents/Loader';
import { UserContext } from '../contexts/UserContext';
import axios from 'axios';
import i18n from '../utils/i18n';
import { withRouter } from 'react-router-dom';

const ForgotPassword = ({ history }) => {
  const { t } = useTranslation();
  const isMounted = useIsMounted();
  // eslint-disable-next-line no-unused-vars
  const [userContext, setUserContext] = useContext(UserContext);

  const [loading, setLoading] = useState(false);
  const [email, setEmail] = useState('');
  const [showSuccess, setShowSuccess] = useState(false);
  const [showError, setShowError] = useState(false);
  const handleCloseSnackbar = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setShowSuccess(false);
    setShowError(false);
  };

  useEffect(() => {
    window.document.title = t('page_title.forgot_pass');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      {loading && <Loader loading={loading} />}
      <BaseLayout
        main={
          <>
            <Grid
              container
              sx={root}
              justifyContent={'center'}
              alignItems={'center'}
              className='ForgotPasswordPage'
            >
              <Grid container item wrap='nowrap' sx={rootContent} xs={12}>
                <Grid
                  container
                  item
                  justifyContent={'flex-start'}
                  alignItems={'center'}
                  xs={6}
                  sx={{ display: { xs: 'none', md: 'flex' } }}
                >
                  <LoginSideComponent />
                </Grid>
                <Grid
                  container
                  item
                  xs
                  sx={{ justifyContent: { xs: 'center', md: 'flex-end' } }}
                >
                  {' '}
                  <ForgotPasswordDetails
                    email={email}
                    setEmail={setEmail}
                    handleForgotPassword={handleForgotPassword}
                  />
                </Grid>
              </Grid>
            </Grid>

            <Snackbar
              open={showError}
              onClose={handleCloseSnackbar}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'center',
              }}
              style={{ top: 80 }}
            >
              <Alert
                severity='error'
                style={{ position: 'relative', paddingRight: 46 }}
              >
                {t('forgot_password.error')}
                <IconButton
                  size='small'
                  aria-label='close'
                  color='inherit'
                  onClick={handleCloseSnackbar}
                  style={{
                    marginLeft: 10,
                    position: 'absolute',
                    right: 10,
                    top: 10,
                  }}
                >
                  <CloseIcon fontSize='small' />
                </IconButton>
              </Alert>
            </Snackbar>

            <Snackbar
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
              }}
              open={showSuccess}
              autoHideDuration={3000}
              onClose={handleCloseSnackbar}
              message={t('forgot_password.success')}
            />
          </>
        }
      ></BaseLayout>
    </>
  );

  function handleForgotPassword(e) {
    e.preventDefault();
    setLoading(true);

    var requestBody = {
      email: email,
      language: i18n.languages[0],
    };

    let apiEndPoint =
      process.env.NODE_ENV === 'development'
        ? process.env.REACT_APP_API_ENDPOINT
        : process.env.REACT_APP_API_ENDPOINT_PROD;

    axios({
      method: 'post',
      url: apiEndPoint + '/v1/partner/auth/forgot-password',
      data: requestBody,
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
      },
    })
      .then((response) => {
        if (isMounted.current) {
          setShowSuccess(true);
          setLoading(false);
        }
      })
      .catch((error) => {
        if (isMounted.current) {
          setShowError(true);
          setLoading(false);
        }
      });
  }
};

export default withRouter(ForgotPassword);
