import React from 'react';
import { dropzone, dropzoneLabel, preview } from './styles';
import 'react-dropzone-uploader/dist/styles.css';
import Dropzone from 'react-dropzone-uploader';
import PreviewUploadFile from './PreviewUploadFile';

const DropzoneBox = (props) => {
  const {
    inputContent,
    getUploadParams,
    onChangeStatus,
    multiple,
    maxFiles,
    maxwidth,
  } = props;
  return (
    <>
      <Dropzone
        accept='.pdf,.doc,.docx,.ppt'
        getUploadParams={getUploadParams}
        onChangeStatus={onChangeStatus}
        multiple={multiple}
        PreviewComponent={PreviewUploadFile}
        maxFiles={maxFiles}
        inputContent={inputContent}
        styles={{
          dropzone: {
            ...dropzone,
            maxWidth: maxwidth,
          },
          inputLabel: dropzoneLabel,
          preview: preview,
        }}
      />
    </>
  );
};

export default DropzoneBox;
