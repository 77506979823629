import React from 'react';
import {
  Typography,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  IconButton,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

const LeadDialog = (props) => {
  const {
    openDialog,
    setOpenDialog,
    hasFullWidth,
    customMaxWidth,
    hasTitle,
    title,
    hasContent,
    hasDividers,
    hasGutterBottom,
    text,
    componentContent,
    hasActions,
    cancel,
    confirm,
    handleConfirm,
    sxBtnCancel,
    sxBtnConfirm,
    variantCancel,
    variantConfirm,
  } = props;

  return (
    <Dialog
      open={openDialog}
      onClose={props.onClose}
      fullWidth={hasFullWidth ? hasFullWidth : true}
      maxWidth={customMaxWidth ? customMaxWidth : `md`}
      aria-labelledby='alert-dialog-title'
      aria-describedby='alert-dialog-description'
    >
      {hasTitle && (
        <DialogTitle id='alert-dialog-title' onClose={props.handleClose}>
          {title}
          {props.onCloseTitle ? (
            <IconButton
              aria-label='close'
              onClick={props.onCloseTitle}
              sx={{
                position: 'absolute',
                right: 12,
                top: 12,
                color: (theme) => theme.palette.grey[500],
              }}
            >
              <CloseIcon />
            </IconButton>
          ) : null}
        </DialogTitle>
      )}
      {hasContent && (
        <DialogContent dividers={hasDividers}>
          {text && (
            <DialogContentText id='alert-dialog-description'>
              <Typography
                variant={'body1'}
                color='textPrimary'
                component='span'
                gutterBottom={hasGutterBottom}
              >
                {text}
              </Typography>
            </DialogContentText>
          )}
          {componentContent && componentContent}
        </DialogContent>
      )}
      {hasActions && (
        <DialogActions>
          {cancel && (
            <Button
              onClick={() => {
                setOpenDialog(false);
                if (props.handleClose) {
                  props.handleClose();
                }
                if (props.handleCancel) {
                  props.handleCancel();
                }
              }}
              color='primary'
              variant={variantCancel ? variantCancel : 'text'}
              sx={sxBtnCancel && sxBtnCancel}
            >
              {cancel}
            </Button>
          )}
          {confirm && (
            <Button
              onClick={() => {
                setOpenDialog(false);
                handleConfirm();
                if (props.handleClose) {
                  props.handleClose();
                }
              }}
              color='primary'
              variant={variantConfirm ? variantConfirm : 'contained'}
              sx={sxBtnConfirm && sxBtnConfirm}
            >
              {confirm}
            </Button>
          )}
        </DialogActions>
      )}
    </Dialog>
  );
};

export default LeadDialog;
