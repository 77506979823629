import React from 'react';
import { useTranslation } from 'react-i18next';
// import useMediaQuery from '@mui/material/useMediaQuery';
import { Box, Grid, Card, CardContent } from '@mui/material';
import SectionCard from '../../uiComponents/SectionCard';

const DealsSmallContent = (props) => {
  const {
    variant,
    data,
    title,
    subtitle,
    // eslint-disable-next-line no-unused-vars
    dataLost,
    locale,
    expanded,
    setExpanded,
    expandedId,
    setExpandedId,
  } = props;
  const { t } = useTranslation();

  return (
    <Box
      sx={{
        /* minWidth: customMinWidth ? customMinWidth : 275,
        maxWidth: customMaxWidth ? customMaxWidth : 1280, */
        margin: 'auto',
      }}
    >
      <Card
        variant={variant ? variant : 'standard'}
        sx={{
          // boxShadow: (theme) => theme.custom.boxshadow.boxShadow,
          borderRadius: 2,
          padding: 0,
        }}
      >
        <CardContent
          sx={{
            padding: 0,
            overflowX: 'auto',
            '&:last-child': {
              pb: 0.5,
            },
          }}
        >
          <Grid container wrap="nowrap">
            <Grid
              alignContent="center"
              container
              item
              sx={{ width: 'auto', minWidth: 1 }}
              // xs
              // xs={isLargeScreen && hasCardSide ? 7 : 12}
            >
              <SectionCard
                minWidth={1}
                maxWidth={1}
                locale={locale}
                title={title}
                subtitle={subtitle}
                createLabel={t('deals.section.label_create_date')}
                closeLabel={t('deals.section.label_close_date')}
                data={data}
                noData={t('messages.no_results_available')}
                expanded={expanded}
                setExpanded={setExpanded}
                expandedId={expandedId}
                setExpandedId={setExpandedId}
              />{' '}
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </Box>
  );
};

export default DealsSmallContent;
