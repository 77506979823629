import React from 'react';
import { useTableStyles as useStyles } from './styles';
import { Grid } from '@mui/material';
import SubsidiesListDnd from './SubsidiesListDnd';
import SubsidiesListFilter from './SubsidiesListFilter';

const SubsidiesListTopOptimized = (props) => {
  const {
    projectProgress,
    setProjectProgress,
    progressStep,
    setProgressStep,
    center,
    newProjectId,
    showMoreDetails,
  } = props;

  const classes = useStyles();

  return (
    <Grid container className={classes.root} justifyContent='center'>
      <Grid item xs={12} sx={{ maxWidth: '792px !important' }}>
        <SubsidiesListFilter hasSearch={true} />
      </Grid>
      <Grid item xs={12} sx={{ maxWidth: '792px !important' }}>
        <SubsidiesListDnd
          projectProgress={projectProgress}
          setProjectProgress={setProjectProgress}
          progressStep={progressStep}
          setProgressStep={setProgressStep}
          center={`${center}`}
          showMoreDetails={true}
          newProjectId={newProjectId}
          showMoreDetail={showMoreDetails}
        />
      </Grid>
    </Grid>
  );
};

export default SubsidiesListTopOptimized;
