import React, { useState, useEffect, useContext } from 'react';
import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useIsMounted } from '../../utils/customHooks';
import useMediaQuery from '@mui/material/useMediaQuery';
import { ReactComponent as OnlineResearch } from '../../assets/OnlineprotectionTwoColor.svg';
import { UserContext } from '../../contexts/UserContext';
import { WorkspaceContext } from '../../contexts/WorkspaceContext';
import Loader from '../../uiComponents/Loader';
import PageDescriptionCard from '../../uiComponents/PageDescriptionCard';
import DetailsTableCard from '../../uiComponents/DetailsTableCard';
import { root, rootAccountDetails, box } from '../styles';
import { Grid, Box, Typography, Snackbar, IconButton } from '@mui/material';
import Alert from '@mui/material/Alert';
import CloseIcon from '@mui/icons-material/Close';

import axios from 'axios';
import {
  userDetailsSectionsData,
  companyDetailsSectionsData,
} from '../../dataMaps/userUtils';

const MyAccountDetails = () => {
  // eslint-disable-next-line no-unused-vars
  // eslint-disable-next-line no-unused-vars
  const location = useLocation();
  // let from = location.state?.from?.pathname || '/app';
  const { t } = useTranslation();
  const isMounted = useIsMounted();
  // eslint-disable-next-line no-unused-vars
  const [userContext, setUserContext] = useContext(UserContext);
  // eslint-disable-next-line no-unused-vars
  const { account, setAccount } = useContext(WorkspaceContext);
  const [loading, setLoading] = useState(false);
  // eslint-disable-next-line no-unused-vars
  const isLargeScreen = useMediaQuery('(min-width:1280px)');
  const [userData, setUserData] = useState();
  const [companyData, setCompanyData] = useState();
  // const [account, setAccount] = useState();

  const [showErrorUpdate, setShowErrorUpdate] = useState(false);
  const [successUpdate, setSuccessUpdate] = useState(false);

  const handleCloseSnackbar = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setShowErrorUpdate(false);
    setSuccessUpdate(false);
  };

  useEffect(() => {
    try {
      if (account) {
        const compdat = companyDetailsSectionsData(account);
        setCompanyData(compdat.companyData);
      } else {
        // throw 'Account error!!!';
      }
    } catch (e) {
      console.log(e);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [account]);

  useEffect(() => {
    try {
      if (userContext.user) {
        const userdat = userDetailsSectionsData(userContext.user);
        setUserData(userdat.userData);
      } else {
        // throw 'Account error!!!';
      }
    } catch (e) {
      console.log(e);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userContext.user]);

  const handleUpdateUser = (prop, val) => {
    setLoading(true);
    const user = [...userData];
    const obj = user.reduce(
      (acc, current) => ({ ...acc, [current.fieldName]: current.value }),
      {}
    );

    const requestObject = {
      ...obj,
      [prop]: val,
      id: userContext.user.id,
    };
    const requestBody = {};
    requestBody.user = requestObject;

    let apiEndPoint =
      process.env.NODE_ENV === 'development'
        ? process.env.REACT_APP_API_ENDPOINT
        : process.env.REACT_APP_API_ENDPOINT_PROD;

    axios({
      method: 'post',
      url: apiEndPoint + '/v1/partner/auth/updateUser',
      data: requestBody,
      withCredentials: true,
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        Authorization: `Bearer ${userContext.token}`,
      },
    })
      .then((res) => {
        if (isMounted.current) {
          setUserContext((oldValues) => {
            return {
              ...oldValues,
              user: res.data,
            };
          });
          setSuccessUpdate(true);
          setLoading(false);
        }
      })
      .catch((error) => {
        if (isMounted.current) {
          setShowErrorUpdate(true);
          setLoading(false);
        }
      });
  };

  const handleUpdateAccount = (prop, val) => {
    setLoading(true);
    const company = [...companyData];
    const obj = company.reduce(
      (acc, current) => ({ ...acc, [current.fieldName]: current.value }),
      {}
    );

    const requestObject = {
      ...obj,
      [prop]: val,
      id: account.id,
    };
    const requestBody = {};
    requestBody.account = requestObject;

    let apiEndPoint =
      process.env.NODE_ENV === 'development'
        ? process.env.REACT_APP_API_ENDPOINT
        : process.env.REACT_APP_API_ENDPOINT_PROD;

    axios({
      method: 'post',
      url: apiEndPoint + '/v1/partner/auth/updateUserAccount',
      data: requestBody,
      withCredentials: true,
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        Authorization: `Bearer ${userContext.token}`,
      },
    })
      .then((res) => {
        if (isMounted.current) {
          setAccount(res.data);
          setSuccessUpdate(true);
          setLoading(false);
        }
      })
      .catch((error) => {
        if (isMounted.current) {
          setShowErrorUpdate(true);
          setLoading(false);
        }
      });
  };

  useEffect(() => {
    const source = axios.CancelToken.source();
    let mounted = true;

    setLoading(true);

    let apiEndPoint =
      process.env.NODE_ENV === 'development'
        ? process.env.REACT_APP_API_ENDPOINT
        : process.env.REACT_APP_API_ENDPOINT_PROD;

    axios({
      method: 'get',
      url: apiEndPoint + '/v1/partner/auth/getUserAccount',
      withCredentials: true,
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        Authorization: `Bearer ${userContext.token}`,
      },
    })
      .then((res) => {
        if (mounted) {
          setAccount(res.data);
          setLoading(false);
        }
      })
      .catch((error) => {
        if (mounted) {
          console.log(error);
          setLoading(false);
        }
      });

    return () => {
      mounted = false;
      source.cancel();
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    window.document.title = t('page_title.my_account');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      {loading && <Loader loading={loading} />}
      <Grid
        container
        justifyContent='center'
        alignItems={'flex-start'}
        alignContent={'flex-start'}
        sx={{ height: 1, overflowY: 'auto' }}
        className='my-account-details'
      >
        <Grid container item xs={12} sx={{ ...root, ...rootAccountDetails }}>
          <Grid item xs={12}>
            <Box mb={2}>
              <Typography variant='h5' align='center'>
                {t('my_account.title_1')}
              </Typography>
            </Box>
            <Box mb={3}>
              <Typography
                align='center'
                color='textSecondary'
                sx={{ color: 'text.hint' }}
              >
                {t('my_account.subtitle_1')}
              </Typography>
            </Box>
          </Grid>
          <Grid container item xs={12} sx={box}>
            <Grid item xs={12} sx={{ mb: 3 }}>
              <PageDescriptionCard
                hasContent
                hasCardSide={isLargeScreen ? true : false}
                cardTitle={t('my_account.title_2')}
                cardContent={t('my_account.subtitle_2')}
                cardAction={'Add New'}
                cardImage={
                  <OnlineResearch
                    style={{
                      width: isLargeScreen ? 'auto' : '100%',
                      float: 'right',
                    }}
                  />
                }
              />
            </Grid>
          </Grid>
          <Grid container item xs={12} sx={box}>
            <Grid item xs={12} sx={{ mb: 3 }}>
              <DetailsTableCard
                data={userData}
                cardTitle={t('my_account.title_3')}
                handleUpdateField={handleUpdateUser}
                setShowErrorUpdate={setShowErrorUpdate}
              />
            </Grid>
          </Grid>
          <Grid container item xs={12} sx={box}>
            <Grid item xs={12}>
              <DetailsTableCard
                data={companyData}
                cardTitle={t('my_account.title_4')}
                handleUpdateField={handleUpdateAccount}
                setShowErrorUpdate={setShowErrorUpdate}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      <Snackbar
        open={showErrorUpdate}
        onClose={handleCloseSnackbar}
        autoHideDuration={3000}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        style={{ top: 80 }}
        action={
          <IconButton
            size='small'
            aria-label='close'
            color='inherit'
            onClick={handleCloseSnackbar}
          >
            <CloseIcon fontSize='small' />
          </IconButton>
        }
      >
        <Alert
          severity='error'
          style={{ position: 'relative', paddingRight: 46 }}
        >
          {t('messages.error_update')}
          <IconButton
            size='small'
            aria-label='close'
            color='inherit'
            onClick={handleCloseSnackbar}
            style={{
              marginLeft: 10,
              position: 'absolute',
              right: 10,
              top: 10,
            }}
          >
            <CloseIcon fontSize='small' />
          </IconButton>
        </Alert>
      </Snackbar>

      <Snackbar
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        open={successUpdate}
        autoHideDuration={3000}
        onClose={handleCloseSnackbar}
        message={t('messages.success_update')}
      />
    </>
  );
};

export default MyAccountDetails;
